import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import FloatInput from 'src/components/Common/FloatInput';
import { CHECK_VALUE_IN_ARRAY } from 'src/helpers/jobsheet_validation';
import CheckboxInput from '../CheckboxInput';
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { additionalExpensesDocumentLinkLoading, clearAdditionalExpenseDocumentLink, clearFranchiseDocumentLink, deleteAdditionalExpensesDocumentLoading, deleteProofDocumentLoading, franchiseUploadedDocumentDeleteLoading, getFranchiseUploadedDocumentLinkLoading, jobSheetClearMessage, proofDocumentLinkLoading } from 'src/store/actions';
import { useParams } from 'react-router';
import { ErrorMessage, FieldArray } from 'formik';
import TooltipMEL from '../Tooltip';
import Dropzone from 'react-dropzone';
import { FILE_UPLOAD_SIZE } from 'src/constants/commonConstants';
import { useToast } from 'src/components/Common/ReactToaster';
import { hasFranchiseRoleHandler, hasFranchiseSubuserRoleHandler, onDropRejectedFileHandler } from 'src/helpers/common_helpers';
import JobsheetImagePreview from 'src/components/Common/JobsheetImagePreviewer/JobsheetImagePreview';
import DeleteModal from 'src/pages/Calendar/DeleteModal';
import FileImg from 'src/assets/images/files.png';
import ErrorText from '../ErrorText';


interface TabProps {
  activeTab: string | number;
  status: string;
  handleChange?: any;
  changeFlow: any;
  onBlur?: () => void;
  values: any;
  touch: any;
  error: any;
  validationConfiguration?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  accordion?: any;
  changeAccorditionHandler?: any;
  selectedFiles: any;
  setSelectedFiles: any;
  correctedFiles: any;
  setCorrectedFiles: any;
  addtionalExpensesFiles: any;
  setAddtionalExpensesFiles: any;
  proofDocumentFiles: any;
  setProofDocumentFiles: any;
  setBase64stringForUploadedFiles: any;
}

const AdditionalExpense = (props: TabProps) => {
  const { id } = useParams();
  const convertId: any = Number(id);
  const { showToast } = useToast();
  const [previousValue, setPreviousValue] = useState({
    previousAmountValue: 0,
    previousTaxValue: 0
  });
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteFranchiseModal, setDeleteFranchiseModal] = useState<boolean>(false);
  const [franchiseUploadfiles, setFranchiseUploadfiles] = useState<any>([]);
  const [aprovefiles, setaprovefiles] = useState<any>(null);
  const [perfectionFiles, setPerfectionFiles] = useState<any>(null);
  const [perfectionImageDeleteModel, setPerfectionImageDeleteModel] = useState(false);
  const { franchiseDocumet, franchiseDocumetLink, withoutQueryAdditionaDetails,proofDocumentDetails, additionalExpensesLink,proofDocumentLink } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  const fileInputRef = useRef<any>(null);


  const dispatch = useDispatch();

  const removeAdditionalExpenseFile = (document?: any) => {
    if (document?.id) {
      dispatch(deleteAdditionalExpensesDocumentLoading(document?.id, id));
    } else {
    }
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAddPartDetails = (values: any, push: any, setFieldTouched: any) => {
    push({
      id: 0,
      jobId: convertId,
      expensesName: "",
      taxCode: null,
      taxPercentage: 0,
      amountWithoutTax: 0,
      netAmount: 0,
      files: []
    });
    values['additionalExpenses'] = [{
      id: 0,
      jobId: convertId,
      expensesName: "",
      taxCode: null,
      taxPercentage: 0,
      amountWithoutTax: 0,
      netAmount: 0,
      files: [],
      fileName: ""
    }];
    props.changeFlow(props.values, props.values.status, props.setFieldError, props.setFieldTouched, props.setFieldValue);
  };

  const handleAddProofDocumentDetails = (values: any, push: any, setFieldTouched: any) => {
    push({
      id: 0,
      jobId: convertId,
      proofName: "",
      files: []
    });
    values['proofDocuments'] = [{
      id: 0,
      jobId: convertId,
      proofName: "",
      files: [],
      fileName: ""
    }];
    props.changeFlow(props.values, props.values.status, props.setFieldError, props.setFieldTouched, props.setFieldValue);
  };

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFiles(files: any) {
    
    let filesModified = files.map((file: any) => {
      
      const isImage = file.type.startsWith('image/');
      const preview = isImage ? URL.createObjectURL(file) : FileImg; // use a generic icon for non-image files
      return Object.assign(file, {
        preview: preview,
        formattedSize: formatBytes(file.size),
        isImage: isImage
      });
    }
    );
    const hasNonImageFile = filesModified.some((file: any) => !file?.isImage);
    const hasImageFileMaxSizeExist = filesModified.some((file: any) => file.size > FILE_UPLOAD_SIZE);
    
    // if (!hasNonImageFile) {
    if (!hasImageFileMaxSizeExist) {
      
      props.setSelectedFiles(() => {
        let allFiles: any[] = [...props.selectedFiles, ...files];
        return allFiles;
      });
    } else {
      showToast("File size exceeds 5MB", { type: "error" });
      props.setSelectedFiles(() => {
        let allFiles: any[] = [...props.selectedFiles];
        return allFiles;
      });
    }
    // }
    // else {
    //   showToast("Please upload the image file", { type: "error" })
    //   props.setSelectedFiles(() => {
    //     let allFiles: any[] = [...props.selectedFiles]
    //     return allFiles
    //   });
    // }
  }

  function handleAcceptedCorrectFiles(files: any) {
    
    let filesModified = files.map((file: any) => {
      
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith('image/')
      });
    }
    );

    
    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE) {
        props.setCorrectedFiles(files);
      } else {
        showToast("File size exceeds 5MB", { type: "error" });
        props.setCorrectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" });
      props.setCorrectedFiles([]);
    }
  }

  const deleteConfirmationHandler = (data: any) => {
    setaprovefiles(data);
    setDeleteModal(true);
  };

  const deleteConfirmationHandlerForPerfectionImage = (data: any) => {
    setPerfectionFiles(data);
    setPerfectionImageDeleteModel(true);
  };

  const removeApproveDocument = () => {
    const fileName = Array(aprovefiles);
    let deletedFiles = props.selectedFiles?.filter((data: any, index: number) => data.path !== fileName[0]?.path);
    props.setSelectedFiles(deletedFiles);
    showToast("document deleted successfully", { type: "success" });
    setDeleteModal(false);
  };

  const removePerfectionImageDocument = () => {
    const fileName = Array(perfectionFiles);
    let deletedFiles = props.correctedFiles?.filter((data: any, index: number) => data.path !== fileName[0]?.path);
    props.setCorrectedFiles(deletedFiles);
    setPerfectionImageDeleteModel(false);
  };

  const viewFranchiseDocuments = (data: any) => {
    dispatch(getFranchiseUploadedDocumentLinkLoading(data.id));
  };

  const viewAdditionalExpensesDocument = (id: any) => {
    dispatch(additionalExpensesDocumentLinkLoading(id));
  };

  const viewProofDocument = (id: any) => {
    dispatch(proofDocumentLinkLoading(id));
  };

  const removeFranchiseUploadeddocument = () => {
    dispatch(franchiseUploadedDocumentDeleteLoading(franchiseUploadfiles?.id, id));
    setDeleteFranchiseModal(false);
  };

  const deleteFranchiseDocument = (data: any) => {
    setDeleteFranchiseModal(true);
    setFranchiseUploadfiles(data);
  };

  useEffect(() => {
    if (franchiseDocumetLink && franchiseDocumetLink !== "") {
      const newWindow = window.open(franchiseDocumetLink);
      

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }

      dispatch(clearFranchiseDocumentLink());
    }
  }, [franchiseDocumetLink]);

  useEffect(() => {
    if (additionalExpensesLink != "" && additionalExpensesLink != undefined && additionalExpensesLink != null) {
      const newWindow = window.open(additionalExpensesLink);

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
      dispatch(clearAdditionalExpenseDocumentLink());
    }
  }, [additionalExpensesLink]);

  useEffect(() => {
    if (proofDocumentLink != "" && proofDocumentLink != undefined && proofDocumentLink != null) {
      const newWindow = window.open(proofDocumentLink);

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
      dispatch(clearAdditionalExpenseDocumentLink());
    }
  }, [proofDocumentLink]);

  const handleChangeAmount = (currentIndex: number, amountWithTax: number, taxPercentage: number, setFieldValue: any) => {
    
    const netAmount = ((+amountWithTax * taxPercentage) / 100) + +amountWithTax;
    setFieldValue(`additionalExpenses[${currentIndex}].netAmount`, +netAmount);
  };

  const handleFileRead = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFilesToBase64 = async (files: any) => {
    if (files?.length > 0) {
      const fileArray = Array.from(files);
      const promises = fileArray.map(file => handleFileRead(file));
      return await Promise.all(promises);
    } else {
      return;
    }
  };

  return (
    <div className={`job-sheet-tab-content ${props.activeTab === "Additional Expenses" ? "" : "d-none"}`}>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeApproveDocument();
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />

      <DeleteModal
        show={perfectionImageDeleteModel}
        onDeleteClick={() => {
          removePerfectionImageDocument();
        }}
        onCloseClick={() => {
          setPerfectionImageDeleteModel(false);
        }}
      />

      <DeleteModal
        show={deleteFranchiseModal}
        onDeleteClick={() => {
          removeFranchiseUploadeddocument();
        }}
        onCloseClick={() => {
          setDeleteFranchiseModal(false);
        }}
      />
      <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
        <div className='dealer-title-header font-size-15 border-bottom'>{(hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())  ? 'Additional Expense Information' : "Proof Document Information"}</div>
        <CardBody className='p-0'>
          {(hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) && 
          <div className='px-3 pb-3'>

            {/* new implementation to chnage dynamic */}
            <FieldArray name="additionalExpenses">
              {({ insert, remove, push }) => (
                <div>
                  <Row className='mt-1'>
                    <div className='dealer-title-header font-size-15 border-bottom additional-expense-no-expense'>
                      <span className='pull-left'> Add Expenses</span>
                      <div className="add-btn-container mt-0 pull-right">
                        <CheckboxInput
                          name="zeroAdditional"
                          label="No Expenses"
                          value={props.values.zeroAdditional}
                          className="no-top-margin"
                          onChange={
                            (e: any) => {
                              if (e.target.checked) {
                                props.setFieldValue("additionalExpenses", []);
                              } else {
                                props.setFieldValue("additionalExpenses", withoutQueryAdditionaDetails?.length > 0 ? withoutQueryAdditionaDetails?.filter((data) => data.isDeleted == false).map((expense: any) => {
                                  let data = expense;
                                  return data;
                                }) : [{
                                  id: 0,
                                  jobId: convertId,
                                  expensesName: "",
                                  taxCode: "",
                                  taxPercentage: 0,
                                  amountWithoutTax: 0,
                                  files: []
                                }]);

                              }
                              props.handleChange(e);
                            }
                          }
                        />
                      </div>
                    </div>
                  </Row>
                  {props.values.additionalExpenses?.length > 0 &&
                    props.values.additionalExpenses.map(
                      (value: any, index: any) => {
                        return <div key={index}>
                          <Row>
                            <Col lg={2} className="mt-mb">
                              <FloatInput
                                label="Expense Name"
                                type="text"
                                name={`additionalExpenses[${index}].expensesName`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.expensesName}
                                required={true}
                                disabled={false}
                                invalid={props.touch.additionalExpenses?.[index]?.expensesName && props.error.additionalExpenses?.[index]?.expensesName ? true : false}
                              />
                            </Col>
                            <Col lg={2} className="mt-mb">
                              <FloatInput
                                label="Tax code"
                                type="text"
                                name={`additionalExpenses[${index}].taxCode`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.taxCode}
                                invalid={props.touch.additionalExpenses?.[index]?.taxCode && props.error.additionalExpenses?.[index]?.taxCode ? true : false}
                                required={false}
                                disabled={false}
                              />
                            </Col>
                            <Col lg={2} className="mt-mb">
                              <FloatInput
                                label="Amount"
                                type="number"
                                onChange={(e: any) => {
                                  props.handleChange(e);
                                  handleChangeAmount(index, e.target.value, value.taxPercentage, props.setFieldValue);
                                }}
                                onBlur={props.onBlur}
                                value={value.amountWithoutTax}
                                name={`additionalExpenses[${index}].amountWithoutTax`}
                                invalid={props.touch.additionalExpenses?.[index]?.amountWithoutTax && props.error.additionalExpenses?.[index]?.amountWithoutTax ? true : false}
                                required={false}
                                disabled={false}
                              />
                            </Col>
                            <Col lg={2} className="mt-mb">
                              <FloatInput
                                label="Tax Percentage"
                                type="number"
                                name={`additionalExpenses[${index}].taxPercentage`}
                                onChange={(e: any) => {
                                  props.handleChange(e);
                                  handleChangeAmount(index, value.amountWithoutTax, e.target.value, props.setFieldValue);
                                }}
                                onBlur={props.onBlur}
                                value={value.taxPercentage}
                                invalid={props.touch.additionalExpenses?.[index]?.taxPercentage && props.error.additionalExpenses?.[index]?.taxPercentage ? true : false}
                                required={false}
                                disabled={false}
                              />
                            </Col>
                            <Col lg={2} className="mt-mb">
                              <FloatInput
                                label="Net Amount"
                                type="number"
                                name={`additionalExpenses[${index}].netAmount`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.netAmount}
                                invalid={props.touch.additionalExpenses?.[index]?.netAmount && props.error.additionalExpenses?.[index]?.netAmount ? true : false}
                                required={false}
                                disabled={false}
                              />
                            </Col>

                            <Col lg={2} className="mt-mb-textarea">
                              <div className="d-flex align-items-start">
                                <div className="d-flex w-100">
                                  {
                                    (props.values.additionalExpenses[`${index}`].files == "" || props.values.additionalExpenses[`${index}`].files == null) &&
                                    <div style={{ width: "90%" }}>
                                      <Input
                                        id="Attachements"
                                        // ref={fileInputRef}
                                        className={`form-control ${props.error["files"] && "is-invalid"}`}
                                        type="file"
                                        name={`additionalExpenses[${index}].files`}
                                        onBlur={props.onBlur}
                                        invalid={props.touch["files"] && props.error["files"] ? true : false}
                                        value={value.files}
                                        onChange={async (event) => {
                                          const files: any = event.target.files;
                                          const fileArray = Array.from(files);
                                          const base64files = handleFilesToBase64(fileArray);
                                          props.setFieldValue(`additionalExpenses[${index}].fileName`, files?.[0]?.name);
                                          base64files.then((res) => {
                                            
                                            props.setBase64stringForUploadedFiles((prevState: any) => {
                                              const newState = [...prevState];
                                              newState[index] = res?.[0];
                                              return newState;
                                            });
                                          });
                                          props.setAddtionalExpensesFiles((prevState: any) => {
                                            const newState = [...prevState];
                                            newState[index] = fileArray;
                                            return newState;
                                          });
                                          props.handleChange(event);
                                        }}
                                      />
                                    </div>

                                  }

                                  {
                                    (props.values.additionalExpenses[`${index}`].files != "" && props.values.additionalExpenses[`${index}`].files != null) &&
                                    <div style={{ width: "90%" }}>
                                      <div className='additionalExpenses'>
                                        {
                                          props.values.additionalExpenses[`${index}`].files && <>
                                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.values.additionalExpenses[`${index}`].fileName}
                                            </div>
                                            <div title={props.values.additionalExpenses[`${index}`].files} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                              {
                                                ((withoutQueryAdditionaDetails?.filter((data: any) => data.isDeleted == false).length > 0) && withoutQueryAdditionaDetails[index]?.document != null && props.values.additionalExpenses[index].document?.id) &&
                                                <div style={{ cursor: "pointer" }}>
                                                  <FeatherIcon size={18} icon="eye" onClick={() => {
                                                    viewAdditionalExpensesDocument(props.values.additionalExpenses[index].document.id);
                                                  }} />
                                                </div>
                                              }
                                              {
                                                (props.values.additionalExpenses[`${index}`].files) && <div style={{ cursor: "pointer" }}>
                                                  <i className="far fa-trash-alt selected-file-delete-icon" onClick={() => props.setFieldValue(`additionalExpenses[${index}].files`, [])}>
                                                  </i>

                                                  {/* <FeatherIcon onClick={handleIconClick} size={18} icon="upload" /> */}
                                                  {/* <Input
                                                    id="Attachements"
                                                    ref={fileInputRef}
                                                    className={`form-control ${props.error["files"] && "is-invalid"}`}
                                                    type="file"
                                                    name={`additionalExpenses[${index}].files`}
                                                    onBlur={props.onBlur}
                                                    invalid={props.touch["files"] && props.error["files"] ? true : false}
                                                    // value={value.files}
                                                    onChange={async (event) => {
                                                      ;
                                                      const files: any = event.target.files;
                                                      const fileArray = Array.from(files);
                                                      const base64files = handleFilesToBase64(fileArray)
                                                      props.setFieldValue(`additionalExpenses[${index}].fileName`, files?.[0]?.name);
                                                      base64files.then((res) => {
                                                        console.log("res", res)
                                                        props.setBase64stringForUploadedFiles((prevState: any) => {
                                                          const newState = [...prevState];
                                                          newState[index] = res?.[0];
                                                          return newState;
                                                        });
                                                      })
                                                      props.setAddtionalExpensesFiles((prevState: any) => {
                                                        const newState = [...prevState];
                                                        newState[index] = fileArray;
                                                        return newState;
                                                      });
                                                      props.handleChange(event)
                                                    }}
                                                  /> */}
                                                </div>
                                              }
                                            </div>
                                          </>
                                        }
                                      </div>
                                    </div>
                                  }

                                  {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "additionalExpense") &&
                                    <div className="justify-content-center" style={{ marginLeft: "10px" }}>
                                      <TooltipMEL title="Remove part" position="left">
                                        <i className="fas fa-times-circle pointer color-red" onClick={() => {
                                          ;
                                          if (value.id == 0) {
                                            remove(index);
                                            props.setAddtionalExpensesFiles((prevState: any) => {
                                              const newState = [...prevState];
                                              newState.splice(index, 1);
                                              return newState;
                                            });
                                          } else {
                                            dispatch(deleteAdditionalExpensesDocumentLoading(value.id, value.jobId));
                                          }
                                        }}></i>
                                      </TooltipMEL>
                                    </div>}
                                </div>

                              </div>
                            </Col>
                          </Row>
                        </div>;
                      }
                    )

                  }

                  {(!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "additionalExpenseDynamicAdd") && !props.values.zeroAdditional) &&
                    <div className="add-btn-container">
                      {<div className="test" onClick={() => { handleAddPartDetails(props.values, push, props.setFieldTouched); }}>
                        <TooltipMEL title="Add parts" position='bottom'>
                          <i className="fas fa-plus-circle"></i><span>Add</span>
                        </TooltipMEL>
                      </div>
                      }
                    </div>}

                 

                  <hr className='common-bottom-color' />
                </div>
              )}
            </FieldArray>
          </div>
}
          <div className='px-3 pb-3'>

            {/* new implementation to chnage dynamic */}
            <FieldArray name="proofDocuments">
              {({ insert, remove, push }) => (
                <div>
                  <Row className='mt-1'>
                    <div className='dealer-title-header font-size-15 border-bottom additional-expense-no-expense'>
                      <span className='pull-left'> Add Documents</span>
                    </div>
                  </Row>
                  {props.values.proofDocuments?.length > 0 &&
                    props.values.proofDocuments.map(
                      (value: any, index: any) => {
                        return <div key={index}>
                          <Row>
                            
                            <Col lg={2} className="mt-mb">
                              <FloatInput
                                label="Name"
                                type="text"
                                name={`proofDocuments[${index}].proofName`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.proofName}
                                invalid={props.touch.proofDocuments?.[index]?.proofName && props.error.proofDocuments?.[index]?.proofName ? true : false}
                                required={true}
                                disabled={false}
                              />
                            </Col>

                            <Col lg={2} className="mt-mb-textarea">
                              <div className="d-flex align-items-start">
                                <div className="d-flex w-100">
                                  {
                                    (props.values.proofDocuments[`${index}`].files == "" || props.values.proofDocuments[`${index}`].files == null) &&
                                    <div style={{ width: "90%" }}>
                                      <Input
                                        // id="Attachements"
                                        // ref={fileInputRef}
                                        className={`form-control ${
                                         ( props?.error?.proofDocuments?.[index]?.files && props?.touch?.proofDocuments?.[index]?.files)? "is-invalid" : ""
                                       }`}
                                        type="file"
                                        name={`proofDocuments[${index}].files`}
                                        onBlur={props.onBlur}
                                        invalid={props.touch["files"] && props.error["files"] ? true : false}
                                        value={value.files}
                                        onChange={async (event) => {
                                          const files: any = event.target.files;
                                          const fileArray = Array.from(files);
                                          const base64files = handleFilesToBase64(fileArray);
                                          let name = files?.[0]?.name
                                            props.setFieldValue(`proofDocuments[${index}].files`, fileArray);
                                         
                                          props.setFieldValue(`proofDocuments[${index}].fileName`, files?.[0]?.name);
                                          base64files.then((res) => {
                                            
                                            props.setBase64stringForUploadedFiles((prevState: any) => {
                                              const newState = [...prevState];
                                              newState[index] = res?.[0];
                                              return newState;
                                            });
                                          });
                                          props.setProofDocumentFiles((prevState: any) => {
                                            const newState = [...prevState];
                                            newState[index] = fileArray;
                                            return newState;
                                          });
                                          props.handleChange(event);
                                        }}
                                        required={true}
                                      />
                                      <ErrorMessage name={`proofDocuments[${index}].files` || props.error["files"] || ''} component={ErrorText} />
                                    </div>

                                  }

                                  {
                                    (props.values.proofDocuments[`${index}`].files != "" && props.values.proofDocuments[`${index}`].files != null) &&
                                    <div style={{ width: "90%" }}>
                                      <div className='additionalExpenses'>
                                        {
                                          props.values.proofDocuments[`${index}`].files && <>
                                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.values.proofDocuments[`${index}`].fileName}
                                            </div>
                                            <div title={props.values.proofDocuments[`${index}`].files} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                              {
                                                ((proofDocumentDetails?.filter((data: any) => data.isDeleted == false).length > 0) && proofDocumentDetails[index]?.document != null && props.values.proofDocuments[index].document?.id) &&
                                                <div style={{ cursor: "pointer" }}>
                                                  <FeatherIcon size={18} icon="eye" onClick={() => {
                                                    viewProofDocument(props.values.proofDocuments[index].document.id);
                                                  }} />
                                                </div>
                                              }
                                              {
                                                (props.values.proofDocuments[`${index}`].files) && <div style={{ cursor: "pointer" }}>
                                                  <i className="far fa-trash-alt selected-file-delete-icon" onClick={() => {props.setFieldValue(`proofDocuments[${index}].files`, []); 
                                         
                                         props.setFieldValue(`proofDocuments[${index}].fileName`, "");}}>
                                                  </i>

                                                
                                                </div>
                                              }
                                            </div>
                                          </>
                                        }
                                      </div>
                                    </div>
                                  }

                                  {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "proofDocuments") &&
                                    <div className="justify-content-center" style={{ marginLeft: "10px" }}>
                                      <TooltipMEL title="Remove part" position="left">
                                        <i className="fas fa-times-circle pointer color-red" onClick={() => {
                                          ;
                                          if (value.id == 0) {
                                            remove(index);
                                            props.setProofDocumentFiles((prevState: any) => {
                                              const newState = [...prevState];
                                              newState.splice(index, 1);
                                              return newState;
                                            });
                                          } else {
                                            dispatch(deleteProofDocumentLoading(value.id, value.jobId));
                                          }
                                        }}></i>
                                      </TooltipMEL>
                                    </div>}
                                </div>

                              </div>
                            </Col>
                          </Row>
                        </div>;
                      }
                    )

                  }

                  {(true) &&
                    <div className="add-btn-container">
                      {<div className="test" onClick={() => { handleAddProofDocumentDetails(props.values, push, props.setFieldTouched); }}>
                        <TooltipMEL title="Add parts" position='bottom'>
                          <i className="fas fa-plus-circle"></i><span>Add</span>
                        </TooltipMEL>
                      </div>
                      }
                    </div>}

                 

                  <hr className='common-bottom-color' />
                </div>
              )}
            </FieldArray>
          </div>

          {/* franchise faulty upload document */}
          {(hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) &&
            <>
              <div className='dealer-title-header font-size-15 border-bottom'>Franchise Upload document</div>
              {
                (!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "franchise_upload_document_section")) &&
                <Row>
                  <Col lg={12}>
                    {
                      // franchiseDocumet?.length == 0 && (
                      <Col lg={12} className="mt-mb">
                        {
                          // (props.selectedFiles?.length == 0) && 
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles);
                            }}
                            onError={(err: Error) => { console.log("file error", err); }}
                            onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                            maxFiles={10}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} accept="image/*" />
                                  <div className="">
                                    <i className="display-4 text-muted bx bx-cloud-upload" />
                                  </div>
                                  <h4>upload documents.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        }

                        {(props.selectedFiles[0]?.preview) && <JobsheetImagePreview selectedFiles={props.selectedFiles.length > 0 ? props.selectedFiles : []} removeIndex={deleteConfirmationHandler} />}
                      </Col>
                      // )
                    }

                    {/* faulty documents show */}
                    {
                      franchiseDocumet?.filter((data) => data.fileType == "fault")?.length > 0 && (
                        <Row>
                          <Col lg={12} >
                            <div className='franchise_upload_document_header'>Document List</div>
                            {
                              franchiseDocumet?.filter((data) => data.fileType == "fault")?.map((data: any, index: number) => {
                                return (
                                  <div key={index} className='uploaded_franchise_document'>
                                    <div className='uploade_file_name'>{data.name}</div>
                                    <div className='upload_franchise_icon'>
                                      <FeatherIcon size={20} icon="eye" onClick={() => viewFranchiseDocuments(data)} />
                                      <FeatherIcon size={20} icon="trash-2" onClick={() => deleteFranchiseDocument(data)} />
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </Col>
                        </Row>
                      )
                    }
                  </Col>

                  {/* upload correct images */}
                  {/* <Col lg={6}>
                  {
                    (franchiseDocumet?.length > 0 && franchiseDocumet?.filter((data) => data.fileType == "perfection").length == 0) && (
                      <Col lg={12} className="mt-mb">
                        {
                          (props.correctedFiles?.length == 0) &&
                          <Dropzone
                            onDrop={acceptedFiles => handleAcceptedCorrectFiles(acceptedFiles)}
                            onError={(err: Error) => console.log("file error", err)}
                            onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                            maxFiles={10}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className={`dropzone`}>
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} accept="image/*" />
                                  <div className="">
                                    <i className="display-4 text-muted bx bx-cloud-upload" />
                                  </div>
                                  <h4>upload perfection Images</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        }
                        {(props.correctedFiles[0]?.preview) && <JobsheetImagePreview selectedFiles={props.correctedFiles.length > 0 ? props.correctedFiles : []} removeIndex={deleteConfirmationHandlerForPerfectionImage} />}
                      </Col>
                    )
                  }
                  
                  {
                    franchiseDocumet?.filter((data) => data.fileType == "perfection")?.length > 0 && (
                      <Row>
                        <Col lg={12} >
                          <div className='franchise_upload_document_header'>Perfection Images</div>
                          {
                            franchiseDocumet?.filter((data) => data.fileType == "perfection")?.map((data: any, index: number) => {
                              return (
                                <>
                                  <div key={index} className='uploaded_franchise_document'>
                                    <div className='uploade_file_name'>{data.name}</div>
                                    <div className='upload_franchise_icon'>
                                      <FeatherIcon size={20} icon="eye" onClick={() => viewFranchiseDocuments(data)} />
                                      <FeatherIcon size={20} icon="trash-2" onClick={() => deleteFranchiseDocument(data)} />
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }
                        </Col>
                      </Row>
                    )
                  }
                </Col> */}
                </Row>

              }
            </>
          }
        </CardBody >
      </Card >
    </div >
  );
};

export default AdditionalExpense;