import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import DeleteModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { clearPartsMessage, createParts, getAudioBrandsListForParts, getPartsBYID, resetPartsMessage, updateParts } from "src/store/parts/action";
import { parts } from "src/models/pages/partsModel";
import DropdownInput from "src/components/UI/DropdownInput";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const formValue: any = useSelector(
    (state: RootReducerState) => state.partsReducer.parts
  );
  const { error, message, loading, audioBrandList } = useSelector(
    (state: RootReducerState) => state.partsReducer
  );

  

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const [contactDetailFalse, setContactDetailFalse] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [visible, setVisible] = useState(false);

  const AUDIO_BRANDS_OPTION = audioBrandList.length > 0 ? (audioBrandList.map((eachTax: any) => {
    let data: any = {};
    data['value'] = eachTax.id;
    data['label'] = eachTax.brandName;
    return data;
  })).sort((a:any, b:any) => {
    const labelA =a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : []



  useEffect(() => {
    if (id) {
      dispatch(getAudioBrandsListForParts());
      dispatch(getPartsBYID(id));
    } else {
      dispatch(getAudioBrandsListForParts());
    }
    return () => {
      setVisible(false)
      dispatch(resetPartsMessage())
    }
  }, [id]);


  useEffect(() => {
    if (error) {
      setIsSubmitting(false)
      setVisible(true)
      // showToast(error, { type: "error" })
    }
    if (message) {

      showToast(message, { type: "success" });
      setTimeout(() => {
        setIsSubmitting(false)
        navigate(ROUTER.PARTS_GRID);
      }, 500)
      dispatch(clearPartsMessage())

    }
  }, [error, message])

  const hidePopup = () => { setVisible(false); dispatch(clearPartsMessage()) }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  const post = (formValue: parts) => {
    dispatch(createParts(formValue));
  };



  const update = (formValue: parts) => {
    dispatch(updateParts(formValue, id as string));
  };

  const initialvalue = {
    audioBrandId: (formValue?.audioBrandId || ""),
    partNumber: (formValue?.partNumber || ""),
    hsnNumber: (formValue?.hsnNumber || ""),
    description: (formValue?.description || ""),
    // reorderLevel:  (String(formValue?.reorderLevel ?? formValue?.reorderLevel ) || ""),
    reorderLevel: Number.isNaN(formValue?.reorderLevel) ? "" : formValue?.reorderLevel,
    taxPercentage: (formValue?.taxPercentage !== undefined ? Number(formValue?.taxPercentage) : ""),
  };



  const handleSubmit = async (values: any) => {
    if (isSubmitting) return
    const submittedData: parts = {
      "audioBrandId": values.audioBrandId,
      "partNumber": values.partNumber,
      "hsnNumber": values.hsnNumber,
      "description": values.description,
      "reorderLevel": values.reorderLevel,
      "taxPercentage": values.taxPercentage,
    }
    setIsSubmitting(true)
    id ? update(submittedData) : post(submittedData);
  };

  const validationSchema = Yup.object().shape({
    audioBrandId: Yup.string().required(`Audio brand is required`),
    partNumber: Yup.string().required(`Part Number is required`),
    hsnNumber: Yup.string()
      .required(`HSN number is required`)
      .max(8, 'HSN number must be at most 8 characters long'),
    description: Yup.string().required(`Description is required`).max(120, 'Description must be at most 120 characters long'),
    reorderLevel: Yup.number().required(`Reorder level is required`).min(0,"Enter valid Reorder level"),
    taxPercentage: Yup.number().required(`Tax percentage is required`).min(0,"Enter valid Tax Percentage").max(100,"Enter a valid Tax Percentage"),
  });

  const validationSchemaForEdit = Yup.object().shape({
    audioBrandId: Yup.string().required(`Audio brand is required`),
    partNumber: Yup.string().required(`Part Number is required`),
    hsnNumber: Yup.string()
      .required(`HSN number is required`)
      .max(8, 'HSN number must be at most 8 characters long'),
    description: Yup.string().required(`Description is required`).max(120, 'Description must be at most 120 characters long'),
    reorderLevel: Yup.number().required(`Reorder level is required`).min(0,"Enter valid Reorder level"),
    taxPercentage: Yup.number().required(`Tax percentage is required`).min(0,"Enter valid Tax Percentage").max(100,"Enter a valid Tax Percentage"),
  });

  const removeIndex = (i: number) => {
    setselectedFiles((prevFiles: any) => {
      const newFiles = [...prevFiles];
      newFiles.splice(i, 1);
      return newFiles;
    });
  }

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
     * Formats the size
     */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleReset = (resetForm: any) => {
    resetForm();
    if (id) {
      navigate(ROUTER.PARTS_GRID)
    }
  }




  const isContactDetailFilled = (values: any) => {
    const contactDetails = values.contactDetails || [];
    return contactDetails.every((contact: any) => {
      // Check for values in all fields except 'alternativeNumber'
      const { alternativeNumber, ...fieldsExceptAlternativeNumber } = contact;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  return (
    <div id="view-dealer">
      {visible && dialogContainer(error)}
      <DeleteModal
        show={deleteModal || false}
        showIcon={false}
        message="Are you sure want to delete this contact"
        onDeleteClick={() => {
          // deleteContact
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Parts"
            titlePath={ROUTER.PARTS_GRID}
            breadcrumbItem={id ? "Edit Parts" : "Create Parts"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={id ? validationSchemaForEdit : validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Part Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="partNumber"
                                onBlur={handleBlur}
                                value={values.partNumber}
                                onChange={handleChange}
                                disabled={id ? true : false}
                                isRequired={true}
                                invalid={
                                  touched["partNumber"] && errors["partNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Part Description"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="description"
                                onBlur={handleBlur}
                                value={values.description}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["description"] && errors["description"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="HSN Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="hsnNumber"
                                onBlur={handleBlur}
                                value={values.hsnNumber}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["hsnNumber"] && errors["hsnNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Tax Percentage"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="taxPercentage"
                                onBlur={handleBlur}
                                value={values.taxPercentage}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["taxPercentage"] && errors["taxPercentage"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Reorder Level"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="reorderLevel"
                                onBlur={handleBlur}
                                value={values.reorderLevel}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["reorderLevel"] && errors["reorderLevel"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={AUDIO_BRANDS_OPTION}
                                name="audioBrandId"
                                label="Audio Brand"
                                onChange={handleChange}
                                required={true}
                                onBlur={handleBlur}
                                value={values.audioBrandId}
                                invalid={
                                  touched["audioBrandId"] && errors["audioBrandId"]
                                    ? true
                                    : false
                                } />
                            </Col>




                          </Row>


                          <div className="d-flex justify-content-end mt-2">
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label={id ? "Update Parts" : "Create Parts"}
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default EditUser;
