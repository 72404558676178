import React, { useEffect, useMemo, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip, Input } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { audioBrands } from "src/models/pages/audioBrandsModel";
import { RootReducerState } from "src/store/reducers";
import { ROUTER } from "src/constants/routes";
import { formatDateMEL, onlyAdminRoleHandler, saveAsExcelFile } from "src/helpers/common_helpers";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { clearIncomingDispatch, getIncomingDispatchList, getReceivedBYID, resetIncomingDispatch } from "src/store/incomingDispatch/action";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { API } from "src/util/api";
import { getProfile } from "src/store/profile/actions";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
import { employeeDropdownForJobLoading, getTechnicianDropdownLoading } from "src/store/actions";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
const partsListConverson = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue.id;
    data['number'] = eachValue?.number;
    data['jobId'] = eachValue?.jobId ? eachValue?.jobId : "";
    data['courierName'] = eachValue?.courierName ? eachValue?.courierName : eachValue?.companyName ? eachValue?.companyName : "";
    data['ReceivedId'] = eachValue?.receivedByName;
    data['receivedId'] = eachValue?.receivedId;
    data['receivedDate'] = eachValue?.receivedDate;
    data['isDeleted'] = eachValue?.isDeleted;
    data['jobIddetails'] = eachValue?.jobId;
    data['courierId'] = eachValue?.courierId;
    return data;
  });
  return modifeidValue;
};
export default function IncomingDispatchList() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state?.IncomingDispatchReducer?.incomingDispatchList);
  const { showToast } = useToast();
  const modifiedValues: any = partsListConverson(values);
  const { loading, paginatorCount, error, message, ReceivedById } = useSelector((state: RootReducerState) => state?.IncomingDispatchReducer);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const { profile } = useSelector((state: RootReducerState) => state.profilesReducer);
      const { employeeList, technicianList } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<audioBrands | null>(null);
  const filterDetails: DataTableFilterMeta = {
    number: { value: "", matchMode: "contains" },
    jobId: { value: "", matchMode: "contains" },
    ReceivedStartDate: { value: "", matchMode: "contains" },
    ReceivedEndDate: { value: "", matchMode: "contains" },
    receivedDate: { value: "", matchMode: "contains" },
    ReceivedId: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);
  useEffect(() => {
    dispatch(getProfile());
  }, []);
  useEffect(()=>{
    dispatch(getTechnicianDropdownLoading())
    dispatch(employeeDropdownForJobLoading())
  },[])

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.STOCK_ADJUSTMENT_CREATE);
  };

  const viewHandler = (rowData: audioBrands) => {
    navigate(`${ROUTER.VIEW_INCOMING_DISPATCH}/${rowData["id"]}`);
  };
  const movejobPage = (rowData: audioBrands) => {
    navigate(`${ROUTER.VIEW_INCOMING_DISPATCH}/${rowData["jobId"]}`);
  };
  const editHandler = (rowData: audioBrands) => {
    navigate(`${ROUTER.STOCK_ADJUSTMENT_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: audioBrands) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: audioBrands, field: string) => {
    return (
      <ColumnAction
        displayData={rowData[field] as string}
        isEdit={false}
        isDelete={false}
        isView={true}
        onViewClick={() => viewHandler(rowData)}
      />
    );
  };

  const jobTemplate = (rowData: audioBrands, field: string) => {

    return (
      <>
        {

          rowData[field] ? <Link to={`${ROUTER.JOB_SHEETS_VIEW}/${rowData["jobId"]}`}>{rowData[field] as string ? "View job sheet" as string : ""}</Link> : ""
        }
      </>
    );
  };

  const courierTemplate = (rowData: audioBrands, field: string) => {
    return (
      <>
        {
          rowData[field] ? <Link to={`${ROUTER.VIEW_COURIER}/${rowData["courierId"]}`}>{rowData[field] as string ? rowData[field] as string : ""}</Link> : ""
        }
      </>
    );
  };

  useEffect(() => {
    if (ReceivedById) {
      if (ReceivedById.userType == "Employee") {
        navigate(`${ROUTER.USERS_VIEW}/${ReceivedById.melEmployeeId}`);
      }
      if (ReceivedById.userType == "Franchise") {
        navigate(`${ROUTER.FRANCHISEE_VIEW}/${ReceivedById.franchiseId}`);
      }
      if (ReceivedById.userType == "Dealer") {
        navigate(`${ROUTER.DEALERS_VIEW}/${ReceivedById.dealerId}`);
      }
      if (ReceivedById.userType == "Customer") {
        navigate(`${ROUTER.USERS_VIEW}/${ReceivedById.customerId}`);
      }
    }
  }, [ReceivedById]);

  const receivedbyTemplate = (rowData: audioBrands, field: string) => {
    return (
      <>
        {
          (onlyAdminRoleHandler() || profile?.extraProperties?.IsAdmin == true) ? <Link to={``} onClick={() => { dispatch(getReceivedBYID(rowData.receivedId)); }}>{rowData[field] as string ? rowData[field] as string : ""}</Link> : rowData[field]
        }
      </>
    );
  };

  const statusBodyTemplate = (rowData: audioBrands, field: string) => {
    let status = rowData[field] as string;
    return (
      <StatusHghlighter status={status} />
    );

  };
  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date"
        required
        value={options?.value?.startDate || ""}
        max={options?.value?.endDate ? options?.value?.endDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['startDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }}
      />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date"
        required
        value={options?.value?.endDate || ""}
        min={options?.value?.startDate ? options?.value?.startDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['endDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }} />
    </>);
  };

  const deliveredByOptions = useMemo(()=>{
    let data = [...employeeList,...technicianList]
    let options = data?.map((eachEmployee: any) => ({
      label: eachEmployee?.name,
      value: eachEmployee?.userId,
    }))
    return options
  },[employeeList,technicianList])

  const deliveryByFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(deliveredByOptions?.length > 0) ? (deliveredByOptions?.map((eachSupplier: any) => {
          let data: any = {};
          data['label'] = eachSupplier?.label;
          data['value'] = eachSupplier?.value;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>);
  };

  const options: Option[] = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  const columns: Columns[] = [
    // {
    //   field: "select",
    //   sortable: false,
    //   header: "",
    //   filter: false,
    //   showFilterMenu: false,
    //   selectionMode: "multiple",
    //   headerStyle: { width: "3rem", whiteSpace: "nowrap" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "number",
      sortable: true,
      header: "Number",
      filter: true,
      frozen: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "number"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "jobId",
      sortable: false,
      header: "Job Sheet Reference",
      filter: false,
      showFilterMenu: true,
      body: (rowData) => jobTemplate(rowData, "jobId"),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "courierName",
      sortable: true,
      header: "Courier",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => courierTemplate(rowData, "courierName"),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "ReceivedId",
      sortable: false,
      header: "Received By",
      filter: true,
      showFilterMenu: true,
      body: (rowData) => receivedbyTemplate(rowData, "ReceivedId"),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
      filterElement: deliveryByFilterTemplate,
    },
    {
      field: "receivedDate",
      sortable: true,
      header: "Received Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => formatDateMEL(rowData.receivedDate),
      filterElement: dateFilterTemplate,
      isDefault: true,
      isStatic: false,
      // className:"align-right pr-4"
    },

  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  useEffect(()=>{
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
  },[deliveredByOptions])
  
  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      data['Number'] = eachValue?.number;
      // data['jobId'] = eachValue?.jobSheet;
      data['Courier Name'] = eachValue?.courierName;
      data['Received By'] = eachValue?.receivedBy;
      data['Received Date'] = formatDateMEL(eachValue?.receivedDate);
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const fetchData = async (isPdf: boolean) => {
    let lazy: any = {
      ...lazyState,
      rows: totalRecords > 5000 ? 5000 : totalRecords < 1 ? 1 : totalRecords,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true);
      const response = await API.getincomingDispatchList(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['id'] = eachValue.id;
        data['number'] = eachValue?.number;
        data['jobId'] = eachValue?.jobId ? eachValue?.jobId : "";
        data['courierName'] = eachValue?.courierName ? eachValue?.courierName : eachValue?.companyName ? eachValue?.companyName : "";
        data['receivedBy'] = eachValue?.receivedByName;
        data['receivedId'] = eachValue?.receivedId;
        data['receivedDate'] = eachValue?.receivedDate;
        data['isDeleted'] = eachValue?.isDeleted;
        data['jobIddetails'] = eachValue?.jobId;
        data['courierId'] = eachValue?.courierId;
        return data;
      });
      // let resp=await partsListConverson(response?.data?.items)
      if (isPdf) {

        saveDataAsPdf(modifeidValue, columns, "Incoming Dispatch");
      } else {
        saveDataAsExcel(modifeidValue, "Incoming Dispatch");
      }
      setPdfLoading(false);
    } catch (error: any) {
      showToast(error.message, { type: "error" });
      setPdfLoading(false);

    } finally {
      setPdfLoading(false);
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "Incoming Dispatch");
    fetchData(false);
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    let column = columns.filter((item: any) => item.field != "jobId");
    const destructureedData = values.map((data) => {
      let datas = {
        number: data?.number,
        jobSheet: data?.jobSheet,
        jobId: `Jobsheet(${data?.jobId})`,
        courierName: data?.courierName,
        ReceivedId: data?.receivedBy,
        receivedDate: formatDateMEL(data?.receivedDate)
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = column.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        // const doc = new (jsPDF as any).default(0, 0);
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          body: values,
          showFoot: "everyPage",
          tableWidth: "auto",
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "Incoming Dispatch");
    fetchData(true);
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.receivedDate) {
      modifiedLazyState.filters.receivedDate.value = "";
    }
    dispatch(getIncomingDispatchList(lazyState));
  };


  const [selectAll, setSelectAll] = useState(false);
  const [selectedIncomingDispatch, setSelectedIncomingDispatch] = useState([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedIncomingDispatch(value);
    setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      setSelectAll(true);
      setSelectedIncomingDispatch(modifiedValues);
    } else {
      setSelectAll(false);
      setSelectedIncomingDispatch([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['ReceivedStartDate']['value'] = event?.filters?.receivedDate?.value?.startDate ? event?.filters?.receivedDate?.value?.startDate : '';
    event['filters']['ReceivedEndDate']['value'] = event?.filters?.receivedDate?.value?.endDate ? event?.filters?.receivedDate?.value?.endDate : '';
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: formValue["status"],
        }

      },
    }));
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    callbackFunction(lazyState);

    return () => {
      dispatch(clearIncomingDispatch());
    };
  }, [lazyState]);

  const handleDeleteUsers = (rowData: audioBrands) => {
    let payload = {
      id: rowData["id"],
      lazyState,
    };
    // dispatch(deleteStockAdjustmentFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    showToast("Incoming Dispatch Successfully", { type: "success" });
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedIncomingDispatch([]);
    setArchiveModal(false);
  };




  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetIncomingDispatch());
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetIncomingDispatch());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([...orderedSelectedColumns]);
  };
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(null);


  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedIncomingDispatch(modifiedValues);
    }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedIncomingDispatch([]);
  };
  const clearFilter = () => {
    setLazyState(initialLazyState);
  };

  const header =
    <div className="table-header-container">
      <div>Incoming Dispatches List</div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
        {/* <TooltipMEL title="Select Status" position="bottom">
          <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL> */}

        <Button type="button" className="customize-table-button mt-1" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="" />
        </Button>

        {/* <Button type="button" className="btn btn-primary" onClick={onCreateClickHandler}>
        <FeatherIcon icon="plus-circle" style={{ marginRight: "10px" }} />
        Add Stock Register
      </Button> */}

        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (rowData) {
              handleDeleteUsers(rowData);
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these audio brands?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedIncomingDispatch([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          header={header}
          columnDetails={visibleColumns}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No Incoming Dispatch Found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Incoming Dispatch  {last} records of {totalRecords} in total"
          selection={selectedIncomingDispatch}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}

