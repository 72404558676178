import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { PartSkuTypes } from "./actionTypes";
import { getPartSkuList, getPartSkuListSuccess, getPartSkuListError, getPartAutoCompleteSuccess, getPartAutoCompleteError, getPartSkuAutoCompleteSuccess, getPartSkuAutoCompleteError, getLocationListSuccess, getLocationListError, getJobsheetIdSuccess, getJobsheetIdError, getConformationSkuSuccess, getConformationSkuError, getConsumptionSkuSuccess, getConsumptionSkuError } from "./action";



function* getPartSkuListAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.getPartSkuListAPI,{...action.lazyState}
    )
    yield put(getPartSkuListSuccess(response));
  } catch (error) {
    yield put(getPartSkuListError(error));
  }
}

function* getLocationListAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.getStockLocationDropdownApi
    )
    yield put(getLocationListSuccess(response));
  } catch (error) {
    yield put(getLocationListError(error));
  }
}

// function* deleteTaxesAsync(action : any) {
//   try {
//     const response : Promise<any> = yield call(
//         API.deleteTaxesListAPI,{id : action.payload.id ,lazyEvent : JSON.stringify(action)}
//     )
//     yield put(deleteTaxesFromListSuccess(response))  
//     yield put(getTaxesList(action.lazyState))
//   } catch (error) {
//     yield put(deleteTaxesFromListError(error));
//   }
// }
function* getPartsAutocompleteListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getPartsForIntentAutoCompleteAPI, action.params
    )
    yield put(getPartAutoCompleteSuccess(response));
  } catch (error) {
    yield put(getPartAutoCompleteError(error));
  }
}

function* getPartsSkuAutocompleteListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getPartSkuAutoCompleteAPI, action.params
    )
    yield put(getPartSkuAutoCompleteSuccess(response));
  } catch (error) {
    yield put(getPartSkuAutoCompleteError(error));
  }
}

function* getJobsheetIdAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getJobIdAPI, action.number
    )
    yield put(getJobsheetIdSuccess(response));
  } catch (error) {
    yield put(getJobsheetIdError(error));
  }
}

function* getConformationSkuAsync() {
  try {
    const response: Promise<any> = yield call( API.getConformationSkuApi)
    const response2: Promise<any> = yield call( API.getConformationSkuMailApi)

    yield put(getConformationSkuSuccess(response));
  } catch (error) {
    yield put(getConformationSkuError(error));
  }
}

function* getConsumptionSkuAsync() {
  try {
    const response: Promise<any> = yield call( API.getConsumptionSkuApi)
    const response2: Promise<any> = yield call( API.getConsumtionSkuMailApi)

    yield put(getConsumptionSkuSuccess(response));
  } catch (error) {
    yield put(getConsumptionSkuError(error));
  }
}

function* partSkuSaga() {
  yield takeLatest(PartSkuTypes.GET_PART_SKU_LIST, getPartSkuListAsync);
  yield takeLatest(PartSkuTypes.GET_LOCATION_LIST, getLocationListAsync);
  yield takeLatest(PartSkuTypes.GET_PART_AUTOCOMPLETE, getPartsAutocompleteListAsync);
  yield takeLatest(PartSkuTypes.GET_PART_SKU_AUTOCOMPLETE, getPartsSkuAutocompleteListAsync);
  yield takeLatest(PartSkuTypes.GET_JOBSHEET_ID, getJobsheetIdAsync);
  yield takeLatest(PartSkuTypes.GET_CONFORMATION_SKU, getConformationSkuAsync);
  yield takeLatest(PartSkuTypes.GET_CONSUMPTION_SKU, getConsumptionSkuAsync);
}

export default partSkuSaga;