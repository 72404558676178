import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip, Input } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import { audioBrands } from "src/models/pages/audioBrandsModel";
import { RootReducerState } from "src/store/reducers";
import { ROUTER } from "src/constants/routes";
import { CurrencyFormatter, formatDateMEL, saveAsExcelFile } from "src/helpers/common_helpers";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Link } from "react-router-dom";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
import { getPartTransactionList } from "src/store/partTransaction/action";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { getLocationList } from "src/store/actions";
import UIButton from 'src/components/UI/Button'
import { OverlayPanel } from "primereact/overlaypanel";

const partTransferListConversation = (values: any) => {
  const modifeidValue = values?.items?.map((eachValue: any) => {
    let data: any = {};
  data["intentDeliverableId"] =  eachValue?.intentDeliverableId ? eachValue?.intentDeliverableId : "",
  data["intentDetailsId"] =  eachValue?.intentDetailsId ? eachValue?.intentDetailsId : "",
  data["partId"] =  eachValue?.partId ? eachValue?.partId : "",
  data["locationId"] =  eachValue?.locationId ? eachValue?.locationId : "",
  data["partSkuId"] =  eachValue?.partSkuId ? eachValue?.partSkuId : "",
  data["partSkuNo"] =  eachValue?.partSkuNo ? eachValue?.partSkuNo : "",
  data["partNo"] =  eachValue?.partNo ? eachValue?.partNo : "",
  data["intentReceived"] =  eachValue?.intentReceived ? eachValue?.intentReceived : false,
  data["intentReceivedOn"] =  eachValue?.intentReceivedOn ? eachValue?.intentReceivedOn : "",
  data["stockTransferDeliverableId"] =  eachValue?.stockTransferDeliverableId ? eachValue?.stockTransferDeliverableId : "",
  data["stockTransferDetailsId"] =  eachValue?.stockTransferDetailsId ? eachValue?.stockTransferDetailsId : "",
  data["intentID"] =  eachValue?.intentID ? eachValue?.intentID : "",
  data["stockTransferID"] =  eachValue?.stockTransferID ? eachValue?.stockTransferID : "",
  data["locationName"] =  eachValue?.locationName ? eachValue?.locationName : "",
  data["dispatchNumber"] =  eachValue?.dispatchNumber ? eachValue?.dispatchNumber : "",
  data["courierId"] =  eachValue?.courierId ? eachValue?.courierId : "",
  data["courierCompany"] =  eachValue?.courierCompany ? eachValue?.courierCompany : "",
  data["courierAwbNumber"] =  eachValue?.courierAwbNumber ? eachValue?.courierAwbNumber : "",
  data["courierTokenNumber"] =  eachValue?.courierTokenNumber ? eachValue?.courierTokenNumber : "",
  data["deliveryDate"] =  eachValue?.deliveryDate ? eachValue?.deliveryDate : "",
  data["courierCreationTime"] =  eachValue?.courierCreationTime ? eachValue?.courierCreationTime : "",
  data["deliveryType"] =  eachValue?.deliveryType ? eachValue?.deliveryType : ""
    return data;
  });
  return modifeidValue;
};

export default function PartTransactionList() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.partTransactionReducer.partTransactionList);
  const modifiedValues = partTransferListConversation(values)
  const { showToast } = useToast();
  const { loading } = useSelector((state: RootReducerState) => state.stockRegisterReducer);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const { error, message } = useSelector((state: RootReducerState) => state.partTransactionReducer);
  const { locationList } = useSelector(
    (state: RootReducerState) => state.partSkuReducer
  );
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<audioBrands | null>(null);
  const filterDetails: DataTableFilterMeta = {
    partSkuNo: { value: "", matchMode: "contains" },
    partNo: { value: "", matchMode: "contains" },
    locationName: { value: "", matchMode: "contains" },
    intentReceivedOn: { value: "", matchMode: "contains" },
    IntentReceivedOnStartDate: { value: "", matchMode: "contains" },
    IntentReceivedOnEndDate: { value: "", matchMode: "contains" },
    dispatchNumber: { value: "", matchMode: "contains" },
    courierCompany: { value: "", matchMode: "contains" },
    courierAwbNumber: { value: "", matchMode: "contains" },
    courierTokenNumber: { value: "", matchMode: "contains" },
    deliveryDate: { value: "", matchMode: "contains" },
    DeliveryStartDate: { value: "", matchMode: "contains" },
    DeliveryEndDate: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "",
    sortOrder: 0,
    // sortField: "",
    // sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState<any>(initialLazyState);

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.STOCK_ADJUSTMENT_CREATE);
  };

  const viewHandler = (rowData: audioBrands) => {
    navigate(`${ROUTER.STOCK_ADJUSTMENT_VIEW}/${rowData["id"]}`);
  };
  const editHandler = (rowData: audioBrands) => {
    navigate(`${ROUTER.STOCK_ADJUSTMENT_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: audioBrands) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: audioBrands, field: string) => {
    return (
      <ColumnAction
        displayData={rowData[field] as string}
      />
    );
  };

  const options: Option[] = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  useEffect(()=>{
    dispatch(getLocationList())
  },[])

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date" required value={options?.value?.startDate || ""} max={options?.value?.endDate ? options?.value?.endDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['startDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date" required value={options?.value?.endDate || ""} min={options?.value?.startDate ? options?.value?.startDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['endDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
    </>)
  };

  const currencyBodyTemplate = (rowData: any, field: string) => {
    let amount = rowData[field];
    return (<>{CurrencyFormatter({ value: amount })}</>);
  };
  const percentageBodyTemplate = (rowData: any, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };

  const AudioBrandTemplate = (rowData: any, field: any) => {
    
    return (
      <Link to={`${ROUTER.AUDIO_BRANDS_VIEW}/${rowData['audioBrandIds']}`}>{rowData[field]}</Link>
    )
  }
  const ReferenceTemplate = (rowData: any, field: any) => {
    let status = rowData['to']
    if(status == "Used" ){
      return (
        <Link to={`${ROUTER.INVOICE_VIEW_BY_PART + `/${rowData?.partSkuId}`}`}>{"Invoice"}</Link>
      )
    }else if(status == "Scrapped" || status == "Rejected" || status == "Missing"){
      return (
        <Link to={`${ROUTER.STOCK_ADJUSTMENT_PART_VIEW + `/${rowData?.partSkuId}` }`}>{"Stock Adjustment"}</Link>
      )
    }else{
      return(<></>)
    }

    
  }

  const partTemplate = (rowData: any, field: any) => {
    
    return (
      <Link to={`${ROUTER.PARTS_VIEW}/${rowData['partId']}`}>{rowData[field]}</Link>
    )
  }

  const supplierBodyTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.SUPPLIER_VIEW}/${rowData['SupplierId']}`}>{rowData[field]}</Link>
    );
  };

  const PurchaseOrderTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.PURCHASE_ORDER_VIEW}/${rowData['puchaseOrderId']}`}>{rowData[field]}</Link>
    );
  };

  const headerTemplate =(val:any)=>{
    return(
      <div className="d-flex justify-content-center">
        <div className="d-flex justify-content-end"style={{width:"100px"}}  >
        <div className="align-right">
          {val}
        </div>
        </div>
      </div>
    )
    }
    
    const statusBodyTemplate = (rowData: any, field: string) => {
      let status = rowData[field] as string;
      return (
        <StatusHghlighter status={status} />
      );
  
    };

    const LocationFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
      return (<>
        <Dropdown
          options={(locationList?.length > 0) ? (locationList?.map((eachLocation: any) => {
            let data: any = {};
            data['label'] = eachLocation?.locationName;
            data['value'] = eachLocation?.locationName;
            return data;
          })).sort((a:any, b:any) => {
            const labelA =a.label.toUpperCase().trim();
            const labelB = b.label.toUpperCase().trim();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            return 0;
          }) : []}
          name="supplierId"
          optionLabel="label"
          filter
          onChange={(e: DropdownChangeEvent) => {
            options.filterCallback(e.value, options.index)
            return {};
          }}
          required={true}
          virtualScrollerOptions={{ itemSize: 35 }}
          value={options.value}
          itemTemplate={(option: any) => (
            <div
              title={option.label} // Tooltip content comes from the `tooltip` field in the option
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {option.label}
            </div>
          )}
        />
      </>)
    };

  const columns: Columns[] = [
    // {
    //   field: "select",
    //   sortable: false,
    //   header: "",
    //   filter: false,
    //   showFilterMenu: false,
    //   selectionMode: "multiple",
    //   headerStyle: { width: "3rem", whiteSpace: "nowrap" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "partSkuNo",
      sortable: true,
      header: "Part Sku Number",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap" },
      className:"partSku",
      isDefault: true,
      isStatic: true,
    },
    {
      field: "partNo",
      sortable: false,
      header: "Part",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>partTemplate(rowData,'partNo'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "intentReceived",
      sortable: false,
      header: "Stock Received",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => statusBodyTemplate(rowData, 'intentReceived'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "intentReceivedOn",
      sortable: true,
      header: "Stock Received On",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => formatDateMEL(rowData.intentReceivedOn),
      isDefault: true,
      isStatic: false,
      filterElement:dateFilterTemplate
    },
    {
      field: "locationName",
      sortable: false,
      header: "Location",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      className:"partSku",
      isStatic: false,
      filterElement:LocationFilterTemplate
    },
    {
      field: "dispatchNumber",
      sortable: true,
      header: "Dispatch Number",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      isStatic: false,
    },
    {
      field: "courierCompany",
      sortable: true,
      header: "Courier Company",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "courierAwbNumber",
      sortable: true,
      header: "Courier Awb Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "courierTokenNumber",
      sortable: true,
      header: "Courier Token Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "deliveryDate",
      sortable: true,
      header: "Delivery Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => formatDateMEL(rowData.deliveryDate),
      isDefault: true,
      isStatic: false,
      filterElement:dateFilterTemplate
    },
    {
      field: "deliveryType",
      sortable: true,
      header: "Delivery Type",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false
    }
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

useEffect(()=>{
  setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
},[locationList])
  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachPurchase) => {
      const { partSkuNo, partNo, intentReceived, intentReceivedOn, locationName, dispatchNumber, courierCompany, courierTokenNumber,courierAwbNumber, deliveryDate, deliveryType } = eachPurchase;
      let data: any = {};
      data['part sku Number'] = partSkuNo;
      data["part"] = partNo;
      data["Stock Received"] = intentReceived;
      data["Stock Received On"] = formatDateMEL(intentReceivedOn);
      data["Location"] = locationName;
      data["Dispatch Number"] = dispatchNumber;
      data["Courier"] = courierCompany;
      data["Courier Token Number"] = courierTokenNumber;
      data["Courier Awb Number"] = courierAwbNumber;
      data["Delivery Date"] = formatDateMEL(deliveryDate);
      data["Delivery Type"] = deliveryType;
      return data;
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    try {
      setPdfLoading(true)
      const response = await API.getPartTransactionListApi(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data["intentDeliverableId"] =  eachValue?.intentDeliverableId ? eachValue?.intentDeliverableId : "",
        data["intentDetailsId"] =  eachValue?.intentDetailsId ? eachValue?.intentDetailsId : "",
        data["partId"] =  eachValue?.partId ? eachValue?.partId : "",
        data["locationId"] =  eachValue?.locationId ? eachValue?.locationId : "",
        data["partSkuId"] =  eachValue?.partSkuId ? eachValue?.partSkuId : "",
        data["partSkuNo"] =  eachValue?.partSkuNo ? eachValue?.partSkuNo : "",
        data["partNo"] =  eachValue?.partNo ? eachValue?.partNo : "",
        data["intentReceived"] =  eachValue?.intentReceived ? eachValue?.intentReceived : false,
        data["intentReceivedOn"] =  eachValue?.intentReceivedOn ? eachValue?.intentReceivedOn : "",
        data["stockTransferDeliverableId"] =  eachValue?.stockTransferDeliverableId ? eachValue?.stockTransferDeliverableId : "",
        data["stockTransferDetailsId"] =  eachValue?.stockTransferDetailsId ? eachValue?.stockTransferDetailsId : "",
        data["intentID"] =  eachValue?.intentID ? eachValue?.intentID : "",
        data["stockTransferID"] =  eachValue?.stockTransferID ? eachValue?.stockTransferID : "",
        data["locationName"] =  eachValue?.locationName ? eachValue?.locationName : "",
        data["dispatchNumber"] =  eachValue?.dispatchNumber ? eachValue?.dispatchNumber : "",
        data["courierId"] =  eachValue?.courierId ? eachValue?.courierId : "",
        data["courierCompany"] =  eachValue?.courierCompany ? eachValue?.courierCompany : "",
        data["courierAwbNumber"] =  eachValue?.courierAwbNumber ? eachValue?.courierAwbNumber : "",
        data["courierTokenNumber"] =  eachValue?.courierTokenNumber ? eachValue?.courierTokenNumber : "",
        data["deliveryDate"] =  eachValue?.deliveryDate ? eachValue?.deliveryDate : "",
        data["courierCreationTime"] =  eachValue?.courierCreationTime ? eachValue?.courierCreationTime : "",
        data["deliveryType"] =  eachValue?.deliveryType ? eachValue?.deliveryType : ""
        return data;
      });
      
      // let resp=await stockRegisterListConversation(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(modifeidValue, columns, "Parts Transaction");
     }else{
      saveDataAsExcel(modifeidValue, "Parts Transaction");
     }
     setPdfLoading(false)
    } catch (error) {
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "Stock Register");
    fetchData(false)
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((eachValue) => {
      let datas = {
        intentDeliverableId :   eachValue?.intentDeliverableId ? eachValue?.intentDeliverableId : "",
        intentDetailsId :   eachValue?.intentDetailsId ? eachValue?.intentDetailsId : "",
        partId :   eachValue?.partId ? eachValue?.partId : "",
        locationId :   eachValue?.locationId ? eachValue?.locationId : "",
        partSkuId :   eachValue?.partSkuId ? eachValue?.partSkuId : "",
        partSkuNo :   eachValue?.partSkuNo ? eachValue?.partSkuNo : "",
        partNo :   eachValue?.partNo ? eachValue?.partNo : "",
        intentReceived :   eachValue?.intentReceived ? eachValue?.intentReceived : false,
        intentReceivedOn :   eachValue?.intentReceivedOn ? eachValue?.intentReceivedOn : "",
        stockTransferDeliverableId :   eachValue?.stockTransferDeliverableId ? eachValue?.stockTransferDeliverableId : "",
        stockTransferDetailsId :   eachValue?.stockTransferDetailsId ? eachValue?.stockTransferDetailsId : "",
        intentID :   eachValue?.intentID ? eachValue?.intentID : "",
        stockTransferID :   eachValue?.stockTransferID ? eachValue?.stockTransferID : "",
        locationName :   eachValue?.locationName ? eachValue?.locationName : "",
        dispatchNumber :   eachValue?.dispatchNumber ? eachValue?.dispatchNumber : "",
        courierId :   eachValue?.courierId ? eachValue?.courierId : "",
        courierCompany :   eachValue?.courierCompany ? eachValue?.courierCompany : "",
        courierAwbNumber :   eachValue?.courierAwbNumber ? eachValue?.courierAwbNumber : "",
        courierTokenNumber :   eachValue?.courierTokenNumber ? eachValue?.courierTokenNumber : "",
        deliveryDate :   eachValue?.deliveryDate ? eachValue?.deliveryDate : "",
        courierCreationTime :   eachValue?.courierCreationTime ? eachValue?.courierCreationTime : "",
        deliveryType :   eachValue?.deliveryType ? eachValue?.deliveryType : ""
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        // const doc = new (jsPDF as any).default(0, 0);
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          body: modifiedValues,
          showFoot: "everyPage",
          tableWidth: "auto",
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };


  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "Stock Register");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.intentReceivedOn) {
      modifiedLazyState.filters.intentReceivedOn.value = "";
    }
    if (modifiedLazyState.filters && modifiedLazyState.filters.deliveryDate) {
      modifiedLazyState.filters.deliveryDate.value = "";
    }
    dispatch(getPartTransactionList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedStockAdjustment, setSelectedStockAdjustment] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedStockAdjustment(value);
    setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedStockAdjustment(values);
    } else {
      setSelectAll(false);
      setSelectedStockAdjustment([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {

    setLazyState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['IntentReceivedOnStartDate']['value'] = event?.filters?.intentReceivedOn?.value?.startDate ? event?.filters?.intentReceivedOn?.value?.startDate : ''
    event['filters']['IntentReceivedOnEndDate']['value'] = event?.filters?.intentReceivedOn?.value?.endDate ? event?.filters?.intentReceivedOn?.value?.endDate : ''
    event['filters']['DeliveryStartDate']['value'] = event?.filters?.deliveryDate?.value?.startDate ? event?.filters?.deliveryDate?.value?.startDate : ''
    event['filters']['DeliveryEndDate']['value'] = event?.filters?.deliveryDate?.value?.endDate ? event?.filters?.deliveryDate?.value?.endDate : ''
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setLazyState((state: any) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: formValue["status"],
        }

      },
    }));
  };

  const handleReset = (formValue: any) => {
    setLazyState((state: any) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    let data: any = { ...lazyState };
    if (data.sortField == "eventDate") {
      data.sortField = "creationTime"
    }
    callbackFunction(data);
  }, [lazyState]);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      // dispatch(clearPartsMessage());
    }
  }, [error, message]);

  const handleDeleteUsers = (rowData: audioBrands) => {
    let payload = {
      id: rowData["id"],
      lazyState,
    };
    // dispatch(deleteStockAdjustmentFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    showToast("Stock Adjustment Successfully", { type: "success" });
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedStockAdjustment([]);
    setArchiveModal(false);
  };
  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([...orderedSelectedColumns]);
  };
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(null);


  const bulkActionClickHandler = () => {
    // if (values.length > 0) {
    //   setSelectAll(true);
    //   setSelectedStockAdjustment(values);
    // }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedStockAdjustment([]);
  };
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  const clearFilter=()=>{
    setLazyState(initialLazyState)
  }

  const formatDate = (date:any) => {
    return date.toISOString().slice(0, 10);
  };

  const calendarRef = useRef<any>(null);
  const [startDate, setStartDate] = useState<any>()
  const [showStartDate, setShowStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [showEndDate, setShowEndDate] = useState<any>()
  const clearCustomCalendar = (e: any) => {
    calendarRef.current.toggle(e)
    setStartDate(null)
    setEndDate(null)
    setShowStartDate(null)
    setShowEndDate(null)
    setLazyState((state:any) => ({
      ...state,
      filters: {
        ...state.filters,
        IntentReceivedOnStartDate: { value: null, matchMode: "contains" },
        IntentReceivedOnEndDate: { value: null, matchMode: "contains" },
      },
    }))
  }

  const searchCustomCalendar = (event: any) => {
    calendarRef.current.toggle(event)
    setShowStartDate(startDate);
    setShowEndDate(endDate);

    setLazyState((state:any) => ({
      ...state,
      filters: {
        ...state.filters,
        IntentReceivedOnStartDate: { value: startDate, matchMode: "contains" },
        IntentReceivedOnEndDate: { value: endDate, matchMode: "contains" },
      },
    }))
    
  }
  const ThreeDaysFilter = (day:any) =>{
    let today  = new Date()
    let threeDayBefore = new Date(today.getTime() - day * 24 * 60 * 60 * 1000);
    setLazyState((state:any) => ({
      ...state,
      filters: {
        ...state.filters,
        IntentReceivedOnStartDate: { value: formatDate(threeDayBefore), matchMode: "contains" },
        IntentReceivedOnEndDate: { value: formatDate(today), matchMode: "contains" },
      },
    }))
  }
  const header =
    <div className="table-header-container">
      <div>Parts Transaction</div>
      <div className="m-2 d-flex flex-wrap gap-1 align_justify">
        {showStartDate && (
          <div className="job-basic-info">
            <div
              className="title text-center"
              style={{
                background: "#dff0ff",
                color: "#749be0",
                width: "auto",
                whiteSpace: "nowrap",
              }}
            >
              Start Date
            </div>
            <b
              className="m-auto text-center font-size-13"
              style={{ minWidth: "120px", whiteSpace: "nowrap" }}
            >
              {formatDateMEL(showStartDate)}
            </b>
          </div>
        )}
        {showEndDate && (
          <div className="job-basic-info">
            <div
              className="title text-center"
              style={{
                background: "#e0f5f1",
                color: "#2d97a6",
                width: "auto",
                whiteSpace: "nowrap",
              }}
            >
              End Date
            </div>
            <b
              className="m-auto text-center font-size-13"
              style={{ minWidth: "150px", whiteSpace: "nowrap" }}
            >
              {formatDateMEL(showEndDate)}
            </b>
          </div>
        )}
      </div>
      <div className="table-header-container-item">

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div className="d-flex justify-content-center btn-group-tat">
        <div
        id="calendar"
        className="calendar_btns new-calendar"
        style={{ marginLeft: "10px" }}
      >
        <div className="table-header-container-item">
          <div className="btn-group">
            <button
              className="btn btn-primary waves-effect waves-light"
              // onClick={toggleCalendar}
              style={{height:"37px"}}
              onClick={(e) => calendarRef.current.toggle(e)}
            >
              {/* <i className="bx bx-calendar font-size-16 align-middle"></i> */}
              <FeatherIcon icon="search" style={{ Width: "6px" }} />
            </button>
          </div>
        </div>
      </div>
      <OverlayPanel ref={calendarRef}>
        <div className="mb-2">
          <label htmlFor="">Start Date</label>
          {/* <Row>     */}
          <input
            className="form-control"
            type="date"
            name="month"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="">End Date</label>
          {/* <Row>     */}
          <input
            className="form-control"
            type="date"
            name="month"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>

        <div className="p-column-filter-buttonbar">
          <button
            aria-label="Clear"
            className="p-button p-component p-button-outlined p-button-sm"
            type="button"
            onClick={clearCustomCalendar}
          >
            <span className="p-button-label p-c">Clear</span>
          </button>
          <button
            aria-label="Apply"
            className="p-button p-component p-button-sm"
            type="button"
            onClick={searchCustomCalendar}
          >
            <span className="p-button-label p-c">Apply</span>
          </button>
        </div>
      </OverlayPanel>
        <UIButton
          label={"Last 3 Days"}
          className={`btn-primary ms-2`}
          type="button"
          onClick={() => {ThreeDaysFilter(3)}}
          disabled={false}
        ></UIButton>
        <UIButton
          label={"Last 7 Days"}
          className={`btn-primary ms-2`}
          type="button"
          onClick={() => {ThreeDaysFilter(7)}}
          disabled={false}
        ></UIButton>
        <UIButton
          label={"Last 15 Days"}
          className={`btn-primary ms-2`}
          type="button"
          onClick={() => {ThreeDaysFilter(15)}}
          disabled={false}
        ></UIButton>
        <UIButton
          label={"Last 30 Days"}
          className={`btn-primary ms-2`}
          type="button"
          onClick={() => {ThreeDaysFilter(30)}}
          disabled={false}
        ></UIButton>
        <UIButton
          label={"Last 60 Days"}
          className={`btn-primary ms-2`}
          type="button"
          onClick={() => {ThreeDaysFilter(60)}}
          disabled={false}
        ></UIButton>
      </div>
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} TooltipPosition="left" clearFilter={clearFilter} isFilter={true} />
        {/* <TooltipMEL title="Select Status" position="bottom">
          <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL> */}
        <Button type="button" className="customize-table-button mt-1" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}  style={{paddingLeft:"0px"}}>
          <FeatherIcon icon="sliders"/>
        </Button>

        {/* <Button type="button" className="btn btn-primary" onClick={onCreateClickHandler}>
        <FeatherIcon icon="plus-circle" style={{ marginRight: "10px" }} />
        Add Stock Register
      </Button> */}

        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  return (
    <>
      <div className="page-content" id="stock-music-system">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (rowData) {
              handleDeleteUsers(rowData);
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these audio brands?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedStockAdjustment([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          header={header}
          columnDetails={visibleColumns}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          filterIcon={FilterIcon}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No Parts Transaction Found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Parts Transaction {last} records of {totalRecords} in total"
          selection={selectedStockAdjustment}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
