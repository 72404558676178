import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { formatDateMEL, hasFranchiseRoleHandler, saveAsExcelFile } from "src/helpers/common_helpers";
import { parts } from "src/models/pages/partsModel";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import TooltipMEL from "src/components/UI/Tooltip";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { clearValues, deleteStockInspectionFromList, getStockInspectionList, resetStockInspectionMessage } from "src/store/stockInspection/action";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Link } from "react-router-dom";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";

const partsListConverson = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue.id,
    data['initiatedBy'] = eachValue.initiatedByName;
    data['SupplierName'] = eachValue.supplierName;
    data['AudioBrandName'] = eachValue.audioBrandName;
    data['inspectionDate'] = eachValue.creationTime;
    data['totalUnusedParts'] = eachValue.totalUnusedParts;
    data['unusedPartsValue'] = eachValue.unusedPartsValue;
    data['SupplierId']=eachValue?.supplierId;
    data['status'] = eachValue.status;
    data['isDeleted'] = eachValue.isDeleted;
    data['locked'] = eachValue.locked;
    data['audioBrandIds']=eachValue?.audioBrandId;
    return data;
  });
  return modifeidValue;
}

export default function StockInspectionList() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: any) => state.StockInspectionReducer.stockInspectionList);
  const modifiedValues: any[] = partsListConverson(values);
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.StockInspectionReducer.loading);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)
  const { error, message, paginatorCount } = useSelector((state: any) => state.StockInspectionReducer);
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<parts | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const filterDetails: DataTableFilterMeta = {
    SupplierName: { value: "", matchMode: "contains" },
    AudioBrandName: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.STOCK_INSPECTION_CREATE);
  };

  const viewHandler = (rowData: any) => {
    navigate(`${ROUTER.STOCK_INSPECTION_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: any) => {
    navigate(`${ROUTER.STOCK_INSPECTION_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: any) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          (!rowData.isDeleted) ? (
            <ColumnAction
              displayData={rowData[field] as string}
              isEdit={(rowData.locked == true && rowData.status =="Completed") ? false : ((rowData.locked == false && rowData.status =="Completed")) ? true : (rowData.status =="Cancelled") ? false : true}
              isDelete={(rowData.locked == true && rowData.status =="Completed") ? false : ((rowData.locked == false && rowData.status =="Completed")) ? true : (rowData.status =="Cancelled") ? false : true}
              isView={true}
              onViewClick={() => viewHandler(rowData)}
              onEditClick={() => editHandler(rowData)}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          ) : (
            <ColumnAction
              displayData={rowData[field] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )}
      </>
    );
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetStockInspectionMessage());
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetStockInspectionMessage())
  };


  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };


  const percentageBodyTemplate = (rowData: parts, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };

  const statusBodyTemplate = (rowData: parts, field: string) => {
    let status = rowData[field] as string;
    return (
      <StatusHghlighter status={status} />
    );

  };

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const statuslist = [
    { name: "CANCELLED", id: 2 },
    { name: "COMPLETED", id: 1 },
    { name: "IN PROGRESS", id: "0" },
  ]

  const statusFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(statuslist?.length > 0) ? (statuslist?.map((eachStatus: any) => {
          let data: any = {};
          data['label'] = eachStatus?.name;
          data['value'] = eachStatus?.id;
          return data;
        })) : []}
        name="supplierId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>)
  };

  const supplierBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
      {!hasFranchiseRoleHandler ?   <Link to={`${ROUTER.SUPPLIER_VIEW}/${rowData['SupplierId']}`}>{rowData[field]}</Link> : rowData[field]}
     
      </>
    );
  };

  const AudioBrandTemplate = (rowData: any, field: any) => {
    
    return (
      <>
      {!hasFranchiseRoleHandler ?  <Link to={`${ROUTER.AUDIO_BRANDS_VIEW}/${rowData['audioBrandIds']}`}>{rowData[field]}</Link> :rowData[field]}
     
      </>
      
    )
  }

  const headerTemplate =(val:any)=>{
    return(
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end"style={{width:"100px"}}  >
        <div className="align-right">
          {val}
        </div>
        </div>
      </div>
    )
    }

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: " ",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      isDefault: true,
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isStatic: false,
      frozen : true,
    },
    {
      field: "initiatedBy",
      sortable: false,
      header: "Initiated By",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      frozen : true,
      body: (rowData) => actionBodyTemplate(rowData, "initiatedBy"),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isStatic: true,
    },
    {
      field: "SupplierName",
      sortable: true,
      header: "Supplier",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>supplierBodyTemplate(rowData,'SupplierName'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "AudioBrandName",
      sortable: true,
      header: "Audio Brands",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      body:(rowData:any)=>AudioBrandTemplate(rowData,'AudioBrandName'),
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "inspectionDate",
      sortable: false,
      header: "Inspection Date",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => formatDateMEL(rowData.inspectionDate),
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },

    {
      field: "totalUnusedParts",
      sortable: false,
      header: "Total Unused Parts",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      body:(rowData:any)=>headerTemplate(rowData.totalUnusedParts),
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "unusedPartsValue",
      sortable: false,
      header: "Unused Parts Value",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      body:(rowData:any)=>headerTemplate(rowData.unusedPartsValue),
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "locked",
      sortable: false,
      header: "Locked",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'locked'),
      isStatic: false,
    },
    {
      field: "status",
      sortable: false,
      header: "Status",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      filterElement: statusFilterTemplate
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));


  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachDealer) => {
      const { id, initiatedBy, SupplierName, AudioBrandName, creationTime, inspectionDate, totalUnusedParts, unusedPartsValue, status,locked } = eachDealer;
      let data: any = {};
      data['Initiated By'] = initiatedBy;
      data['Supplier'] = SupplierName;
      data['Audio Brand'] = AudioBrandName;
      data['Audio Brandsate'] = formatDateMEL(inspectionDate);
      data['Total Unused Parts'] = totalUnusedParts;
      data['Unused Parts Value'] = unusedPartsValue;
      data['Locked'] = locked;
      data['Status'] = status;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {

    const destructureedData = values.map((data) => {
      let datas = {
        id: data.id,
        initiatedBy: data.initiatedBy,
        SupplierName: data.SupplierName,
        AudioBrandName: data.AudioBrandName,
        inspectionDate: formatDateMEL(data.inspectionDate),
        totalUnusedParts: data.totalUnusedParts,
        unusedPartsValue: data.unusedPartsValue,
        locked: data.locked,
        status: data.status
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default({
          orientation: "landscape",
          format: "a4"
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    try {
      setPdfLoading(true)
      const response = await API.getStockInspectionListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      let modifeidValue = response?.data?.items?.map((eachValue:any) => {
        let data: any = {};
        data['id'] = eachValue.id,
        data['initiatedBy'] = eachValue.initiatedByName;
        data['SupplierName'] = eachValue.supplierName;
        data['AudioBrandName'] = eachValue.audioBrandName;
        data['inspectionDate'] = eachValue.creationTime;
        data['totalUnusedParts'] = eachValue.totalUnusedParts;
        data['unusedPartsValue'] = eachValue.unusedPartsValue;
        data['SupplierId']=eachValue?.supplierId;
        data['status'] = eachValue.status;
        data['isDeleted'] = eachValue.isDeleted;
        data['locked'] = eachValue.locked;
        data['audioBrandIds']=eachValue?.audioBrandId;
        return data;
      });
      // let resp=await partsListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(modifeidValue, columns, "Stock_Inspection");
     }else{
      saveDataAsExcel(modifeidValue, "Stock_Inspection");
     }
     setPdfLoading(false)
    } catch (error:any) {
      showToast(error?.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "Stock_Inspection");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "Stock_Inspection");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getStockInspectionList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedParts, setSelectedParts] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    let unLocked = value.filter((item: any) => item.locked != true);
    setSelectedParts(unLocked);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(unLocked.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      let filtereddata = modifiedValues?.filter((data:any)=>data?.locked != true)
      setSelectAll(filtereddata?.length == modifiedValues?.length);
      setSelectedParts(filtereddata);
    } else {
      setSelectAll(false);
      setSelectedParts([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    // if (event?.sortField === "supplier") {
    //   event.sortField = "SupplierName"
    // } else if (event?.sortField === "audioBrand") {
    //   event.sortField = "AudioBrandName"
    // }
    setLazyState(event);
  };


  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedParts([]);
    callbackFunction(lazyState);

    return ()=>{
      dispatch(clearValues());
    }

  }, [lazyState]);



  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData['isDeleted'] ? true : (rowData[0]?.isDeleted ? true : false),
      lazyState,
    };

    dispatch(deleteStockInspectionFromList(payload));
    setDeleteModal(false);
    setRowData(null);



    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedParts([]);
      // showToast("Stock Inspection Deleted Successfully", { type: "success" });
      setTimeout(() => {
        dispatch(getStockInspectionList(lazyState));
      }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedParts([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      let filtereddata = modifiedValues?.filter((data:any)=>data?.locked != true)
      setSelectAll(filtereddata?.length == modifiedValues?.length);
      setSelectedParts(filtereddata);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedParts([]);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>Stock Inspection List</div>
      {/* <BreadcrumbWithFilter
          title="Admin"
          breadcrumbItem="Parts"
          isShowFilter={false}
          isShowCreate={false}
          selectedCustomers={selectedParts}
          bulkActionSatusTitle="Parts"
          showBorderRight={true}
        />  */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedParts.length == 0&& selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedParts.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
              <FeatherIcon icon="archive" className="mr-1" />
              Archive
            </Button>
          </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 btn_space" onClick={() => { setDeleteModal(true); }}>
                <FeatherIcon icon={selectedStatus == DELETED ? "repeat" : "trash-2"} className="mr-1" />
                  {selectedStatus == DELETED ? (<span className="remove_lables">Restore</span>) : (<span className="remove_lables">Delete</span>)}
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1 ml-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedParts.length > 0 && <BulkActionStatus count={selectedParts.length} title="Stock Inspections"></BulkActionStatus>}
              </motion.div>


            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>

        <Button type="button" className="customize-table-button mt-1" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" />
        </Button>
        {/* <TooltipMEL title="Add Parts" position="bottom"> */}
        <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
          Add Stock Inspection
        </Button>
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;







  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedParts?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedParts([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedParts.length > 0) {
              for (let i = 0; i < selectedParts.length; i++) {
                handleDeleteUsers(selectedParts[i], selectedParts.length, i);
              }
            }
            // if (rowData) {
            //   handleDeleteUsers(rowData);
            // }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these parts?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedParts([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Stock Inspection  {last} records of {totalRecords} in total"
          selection={selectedParts}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
