import { Editor } from 'primereact/editor'
import { BlockUI } from 'primereact/blockui';
import React, { useMemo } from 'react'
import ErrorText from '../UI/ErrorText';
import { ErrorMessage } from 'formik';
import HtmltoPlainText from './HtmltoPlainText';


interface TextEditorProps {
    labelName: string;
    name: string;
    value: any;
    onChange?: (value: any) => void;
    required: boolean;
    invalid?: boolean;
    setFieldValue: any;
    setFieldTouched: any
    onBlur: any;
    readOnly? :boolean 
}

const TextEditor = ({ labelName, name, value, onChange = () => { }, required, invalid, setFieldValue, setFieldTouched, onBlur,readOnly }: TextEditorProps) => {

    const handleChange = (e: any) => {

        setFieldTouched(name, true);
        if (e.htmlValue == null) {
            setFieldValue(name, "")
        } else {
            setFieldValue(name, e.htmlValue);
        }
    };

    const renderHeader = () => {
        return (
          <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button className="ql-list" value="bullet" aria-label="Bullet List"></button>
            <button className="ql-align" value="" aria-label="Left Align"></button>
            <button className="ql-align" value="center" aria-label="Center Align"></button>
            <button className="ql-align" value="right" aria-label="Right Align"></button>
          </span>
        );
      }

      const header = renderHeader();

      const handlePaste = (event:any) => {
        let windows:any=window
        const clipboardData = event.clipboardData || windows.clipboardData;
        const items = clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].kind === 'file' && items[i].type.startsWith('image/')) {
                event.preventDefault();
                return;
            }
        }
    };
    const modules =useMemo(()=>{
return {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // Text formatting
        [{ 'header': [1, 2, false] }],             // Header levels
        [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
        [{ 'script': 'sub' }, { 'script': 'super' }], // Sub/superscript
        [{ 'indent': '-1' }, { 'indent': '+1' }], // Indent
        [{ 'direction': 'rtl' }],                // Text direction
        [{ 'size': ['small', false, 'large', 'huge'] }], // Font sizes
        [{ 'color': [] }, { 'background': [] }], // Text colors
        [{ 'align': [] }],                       // Text alignment
        ['clean']                                // Remove formatting
    ]
};
    },[]) 
    return (
        <React.Fragment>
            <label htmlFor="customerConcern">{labelName} {required && <span className="text-danger">*</span>}</label>
           {!(readOnly ? true : false) ? (<Editor
                    value={value}
                    onTextChange={handleChange}
                    style={{ height: '100px' }}
                    name={name}
                    onBlur={onBlur}
                    className={`${invalid ? 'text_editor_error' : ''}`}
                    readOnly={readOnly ? true : false}
                    onPaste={(e:any)=>handlePaste(e)}
                    modules={modules}
                />)

               : (
            //    <Editor
            //     value={value}
            //     onTextChange={handleChange}
            //     style={{ height: '100px' }}
            //     name={name}
            //     onBlur={onBlur}
            //     headerTemplate={header}
            //     className={`${invalid ? 'text_editor_error' : '' } text_editor_disabled`}
            //     readOnly={true} 
            // />
            <div className='text_editor_disabled'>
                <HtmltoPlainText htmlString={value} />
            </div>
            )}
           
            <ErrorMessage name={name || ''} component={ErrorText} />
        </React.Fragment>
    )
}

export default TextEditor