import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip, Input } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import { audioBrands } from "src/models/pages/audioBrandsModel";
import { RootReducerState } from "src/store/reducers";
import { ROUTER } from "src/constants/routes";
import { CurrencyFormatter, formatDateMEL, saveAsExcelFile } from "src/helpers/common_helpers";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import { getStockRegisterList } from "src/store/stockRegister/action";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Link } from "react-router-dom";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
import { DefectivePartListModal } from "src/models/pages/defectivePartModal";
import { getDefectivePartListLoading } from "src/store/defectivePart/actions";
import { getJobsheetNumberLoading, getLocationList } from "src/store/actions";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";


const defectivePartListConversation = (values: any) => {
  const modifeidValue = values?.map((eachValue: DefectivePartListModal) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['number'] = eachValue?.number;
    data['jobId'] = eachValue?.jobNumber;
    data['JobId'] = eachValue?.jobId;
    data['locationId'] = eachValue?.locationName;
    data['LocationId'] = eachValue?.locationId;
    data['status'] = eachValue?.status;
    return data;
  });
  return modifeidValue;
};

export default function DefectivePartList() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.defectivePartReducer.defectivePartList);
  const { locationList } = useSelector(
    (state: RootReducerState) => state.partSkuReducer
  );
  const { jobsheetNumberFilterData } = useSelector(
    (state: RootReducerState) => state.jobsheetReducer
  );
  const modifiedValues = defectivePartListConversation(values)
  const { showToast } = useToast();
  const { loading } = useSelector((state: RootReducerState) => state.defectivePartReducer);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const { error, message } = useSelector((state: RootReducerState) => state.defectivePartReducer);
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<audioBrands | null>(null);
  const filterDetails: DataTableFilterMeta = {
    number: { value: "", matchMode: "contains" },
    jobId: { value: "", matchMode: "contains" },
    locationId: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "",
    // sortOrder: 0,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState<any>(initialLazyState);

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.STOCK_ADJUSTMENT_CREATE);
  };

  const viewHandler = (rowData: audioBrands) => {
    navigate(`${ROUTER.STOCK_ADJUSTMENT_VIEW}/${rowData["id"]}`);
  };
  const editHandler = (rowData: audioBrands) => {
    navigate(`${ROUTER.STOCK_ADJUSTMENT_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: audioBrands) => {
    setDeleteModal(true);
    setRowData(rowData);
  };
  useEffect(()=>{
    dispatch(getLocationList())
    dispatch(getJobsheetNumberLoading())
  },[])
  const actionBodyTemplate = (rowData: audioBrands, field: string) => {
    return (
      <ColumnAction
        displayData={rowData[field] as string}
      />
    );
  };

  const options: Option[] = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date" required value={options?.value?.startDate || ""} max={options?.value?.endDate ? options?.value?.endDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['startDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date" required value={options?.value?.endDate || ""} min={options?.value?.startDate ? options?.value?.startDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['endDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
    </>)
  };


  const ReferenceTemplateJobsheet = (rowData: any, field: any) => {
    if(rowData[field] != 0){
      return (
        <>
        <Link to={`${ROUTER.JOB_SHEETS_VIEW + `/${rowData['JobId']}`}`}>{rowData[field]}</Link>
        </>
      )
    }else{
      return(<>{rowData[field]}</>)
    }

    
  }

  const ReferenceTemplateLocation = (rowData: any, field: any) => {
    if(rowData[field] != 0){
      return (
        <Link to={`${ROUTER.STOCK_LOCATION_VIEW + `/${rowData['LocationId']}`}`}>{rowData[field]}</Link>
      )
    }else{
      return(<>{rowData[field]}</>)
    }

    
  }

  const LocationFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(locationList?.length > 0) ? (locationList?.map((eachLocation: any) => {
          let data: any = {};
          data['label'] = eachLocation?.locationName;
          data['value'] = eachLocation?.id;
          return data;
        })).sort((a:any, b:any) => {
          const labelA =a.label?.toUpperCase().trim();
          const labelB = b.label?.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="supplierId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        virtualScrollerOptions={{ itemSize: 35 }}
        value={options.value}
        itemTemplate={(option: any) => (
          <div
            title={option.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.label}
          </div>
        )}
      />
    </>)
  };

  const JobsheetFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(jobsheetNumberFilterData?.length > 0) ? (jobsheetNumberFilterData?.filter((data:any)=>(data.number != "" && data.number != null))?.map((eachData: any) => {
          let data: any = {};
          data['label'] = eachData?.number;
          data['value'] = eachData?.id;
          return data;
        })).sort((a:any, b:any) => {
          const labelA =a.label?.toUpperCase().trim();
          const labelB = b.label?.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="supplierId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        virtualScrollerOptions={{ itemSize: 35 }}
        value={options.value}
      />
    </>)
  };

  const columns: Columns[] = [
    {
      field: "number",
      sortable: true,
      header: "Number",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "status",
      sortable: false,
      header: "Status",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "jobId",
      sortable: false,
      header: "Job",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => ReferenceTemplateJobsheet(rowData, 'jobId'),
      isDefault: true,
      isStatic: false,
      filterElement:JobsheetFilterTemplate
    },
    {
      field: "locationId",
      sortable: false,
      header: "Location",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => ReferenceTemplateLocation(rowData, 'locationId'),
      isDefault: true,
      isStatic: false,
      filterElement:LocationFilterTemplate
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  }, [locationList,jobsheetNumberFilterData]);

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachPurchase) => {
      const {number, jobId, locationId, status } = eachPurchase;
      let data: any = {};
      data['Number'] = number;
      data['Jobsheet'] = jobId;
      data['Location'] = locationId;
      data['Status'] = status;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    try {
      setPdfLoading(true)
      const response = await API.getDefectivePartListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['id'] = eachValue?.id;
        data['number'] = eachValue?.number;
        data['jobId'] = eachValue?.jobNumber;
        data['locationId'] = eachValue?.locationName;
        data['status'] = eachValue?.status;
        return data;
      });
      
      // let resp=await stockRegisterListConversation(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(modifeidValue, columns, "Defective Parts");
     }else{
      saveDataAsExcel(modifeidValue, "Defective Parts");
     }
     setPdfLoading(false)
    } catch (error) {
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "Stock Register");
    fetchData(false)
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
            id : data?.id,
            number : data?.number,
            jobId : data?.jobId,
            locationId : data?.locationId,
            status : data?.status,
            };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        // const doc = new (jsPDF as any).default(0, 0);
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          body: modifiedValues,
          showFoot: "everyPage",
          tableWidth: "auto",
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };


  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "Stock Register");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.eventDate) {
      modifiedLazyState.filters.eventDate.value = "";
    }
    dispatch(getDefectivePartListLoading(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedStockAdjustment, setSelectedStockAdjustment] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedStockAdjustment(value);
    setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedStockAdjustment(values);
    } else {
      setSelectAll(false);
      setSelectedStockAdjustment([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {

    setLazyState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setLazyState((state: any) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: formValue["status"],
        }

      },
    }));
  };

  const handleReset = (formValue: any) => {
    setLazyState((state: any) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    let data: any = { ...lazyState };
    if (data.sortField == "eventDate") {
      data.sortField = "creationTime"
    }
    callbackFunction(data);
  }, [lazyState]);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      // dispatch(clearPartsMessage());
    }
  }, [error, message]);

  const handleDeleteUsers = (rowData: audioBrands) => {
    let payload = {
      id: rowData["id"],
      lazyState,
    };
    // dispatch(deleteStockAdjustmentFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    showToast("Stock Adjustment Successfully", { type: "success" });
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedStockAdjustment([]);
    setArchiveModal(false);
  };
  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([...orderedSelectedColumns]);
  };
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(null);


  const bulkActionClickHandler = () => {
    // if (values.length > 0) {
    //   setSelectAll(true);
    //   setSelectedStockAdjustment(values);
    // }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedStockAdjustment([]);
  };
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  const clearFilter=()=>{
    setLazyState(initialLazyState)
  }

  const header =
    <div className="table-header-container">
      <div>Defective Part Sku</div>
      <div className="table-header-container-item">

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} TooltipPosition="left" clearFilter={clearFilter} isFilter={true} />
        <Button type="button" className="customize-table-button mt-1" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}  style={{paddingLeft:"0px"}}>
          <FeatherIcon icon="sliders"/>
        </Button>

        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  return (
    <>
      <div className="page-content" id="stock-music-system">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (rowData) {
              handleDeleteUsers(rowData);
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these audio brands?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedStockAdjustment([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          header={header}
          columnDetails={visibleColumns}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          filterIcon={FilterIcon}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No Defective Part Found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Defective Part  {last} records of {totalRecords} in total"
          selection={selectedStockAdjustment}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
