import React, { useEffect, useRef, useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import {
  formatDateMEL,
  hasCustomerRoleHandler,
  hasDealerRoleHandler,
  saveAsExcelFile,
} from "src/helpers/common_helpers";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";

import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { RootReducerState } from "src/store/reducers";
import { getFeedbackListLoading, getInvoicePageJoblistListLoading, getVehicleBrandFeedBackLoading } from "src/store/actions";
import { purchaseOrderList } from "src/models/pages/purchaseOrders";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link } from "react-router-dom";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";


const feedbackListConverson = (values: any) => {
  const modifeidValue = values?.map((eachValue: any) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['jobId'] = eachValue?.jobNumber;
    data['VehicleBrandId'] = eachValue?.vehicleBrandName;
    data['Vehicle'] = eachValue?.vehicleBrandId;
    data['VehicleModelName'] = eachValue?.vehicleModelName ? eachValue?.vehicleModelName : "-";
    data['commenter'] = eachValue?.commenterName;
    data['adherenceOfTatRating'] = eachValue?.adherenceOfTATRating;
    data['adherenceOfTatcomments'] = eachValue?.adherenceOfTATComments ? eachValue?.adherenceOfTATComments : "-";
    data['ontimeSolutionRating'] = eachValue?.ontimeSolutionRating;
    data['ontimeSolutionComments'] = eachValue?.onTimeSolutionComments ? eachValue?.onTimeSolutionComments : "-";
    data['onTimePartSupportRating'] = eachValue?.onTimePartSupportRating;
    data['onTimePartSupportComments'] = eachValue?.onTimePartSupportComments ? eachValue?.onTimePartSupportComments : "-";
    data['engineerTechnicalSkillRating'] = eachValue?.engineerTechnicalSkillRating;
    data['engineerTechnicalSkillComments'] = eachValue?.engineerTechnicalSkillComments ? eachValue?.engineerTechnicalSkillComments : "-";
    data['engineerBehaviorRating'] = eachValue?.engineerBehaviorRating;
    data['engineerBehaviorComments'] = eachValue?.engineerBehaviorComments ? eachValue?.engineerBehaviorComments : "-";
    data['marks'] = eachValue?.marks;
    data['grade'] = eachValue?.grade;
    data['createdAt'] = eachValue?.creationTime;
    return data;
  });
  return modifeidValue;
};

const ACTIVE = "Active";
const DELETED = "Deleted";
const LOCKED = "Locked";
const UNLOCKED = "Unlocked";


export default function FeedbackList() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { items, totalCount } = useSelector((state: RootReducerState) => state.feedbackReducer.feedbackList);
  const { error, message, vehiclebrandDrop } = useSelector((state: RootReducerState) => state.feedbackReducer);
  const { jobListDropdown } = useSelector((state: RootReducerState) => state.invoiceReducer)
  const modifiedValues: any[] = feedbackListConverson(items);
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.feedbackReducer.loading);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false)

  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<any | null>(null);

  const filterDetails: DataTableFilterMeta = {
    IsDeleted: { value: "false", matchMode: "contains" },
    VehicleModelName: { value: "", matchMode: "contains" },
    VehicleBrandId: { value: "", matchMode: "contains" },
    jobId: { value: "", matchMode: "contains" },
    grade: { value: "", matchMode: "contains" },
    UserId: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const viewHandler = (rowData: any) => {

    navigate(`${ROUTER.FEEDBACK_VIEW}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: any) => {

    setDeleteModal(true);
    setRowData(rowData);
  };


  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      // dispatch(resetPurchaseOrder());

    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const actionBodyTemplate = (rowData: purchaseOrderList, value: string) => {
    return (
      <>
        {
          <ColumnAction
            displayData={rowData[value] as string}
            // isEdit={true}
            isDelete={false}
            isView={true}
            onViewClick={() => viewHandler(rowData)}
            onDeleteClick={() => deleteHandler(rowData)}
          />
        }
      </>
    );
  };


  const vehicleBrandTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={vehiclebrandDrop?.length > 0 ? (vehiclebrandDrop?.map((invoice: any) => {
          let data: any = {};
          data['value'] = invoice.id;
          data['label'] = invoice.name;
          return data;
        })) : []}
        name="buyingCurrency"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
        filter
        itemTemplate={(option: any) => (
          <div
            title={option.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.label}
          </div>
        )}
      />
    </>)
  };

  const VehicleBrandNavigate = (rowData: any, field: any) => {
    if (rowData[field] && (hasCustomerRoleHandler() || hasDealerRoleHandler())) {
      return <>{rowData[field]}</>
    } else {
      return (
        <Link to={`${ROUTER.VEHICLE_BRANDS_VIEW}/${rowData['Vehicle']}`}>{rowData[field]}</Link>
      )
    }
    // return (
    //   <>
    //     {
    //       (rowData[field] && (!hasCustomerRoleHandler() || !hasDealerRoleHandler())) ? <>{rowData[field]} </> : <Link to={`${ROUTER.VEHICLE_BRANDS_VIEW}/${rowData['Vehicle']}`}>{rowData[field]}</Link>
    //     }
    //   </>

    // )
  }

  const columns: Columns[] = [
    {
      field: "jobId",
      sortable: true,
      header: "Job Sheet",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "jobId"),
      isDefault: true,
      frozen : true,
      isStatic: true,
    },
    {
      field: "VehicleBrandId",
      sortable: true,
      header: "Vehicle Brand",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => VehicleBrandNavigate(rowData, 'VehicleBrandId'),
      isDefault: true,
      isStatic: false,
      filterElement: vehicleBrandTemplate
    },
    {
      field: "VehicleModelName",
      sortable: true,
      header: "Vehicle Model",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },

    {
      field: "commenter",
      sortable: false,
      header: "Commenter",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,

    },
    {
      field: "adherenceOfTatRating",
      sortable: true,
      header: "Adherence of TAT rating",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      className: "align-right pr-2",
      isStatic: false,
    },
    {
      field: "adherenceOfTatcomments",
      sortable: true,
      header: "Adherence of TAT comments",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "ontimeSolutionRating",
      sortable: true,
      header: "Ontime Solution Rating",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "ontimeSolutionComments",
      sortable: true,
      header: "Ontime Solution Comments",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "onTimePartSupportRating",
      sortable: true,
      header: "On Time Part Support Rating",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "onTimePartSupportComments",
      sortable: true,
      header: "On Time Part Support Comments",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "engineerTechnicalSkillRating",
      sortable: true,
      header: "Engineer Technical Skill Rating",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "engineerTechnicalSkillComments",
      sortable: true,
      header: "Engineer Technical Skill Comments",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "engineerBehaviorRating",
      sortable: true,
      header: "Engineer Behavior Rating",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "engineerBehaviorComments",
      sortable: true,
      header: "Engineer Behavior Comments",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "marks",
      sortable: true,
      header: "Marks",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "grade",
      sortable: true,
      header: "Grade",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "createdAt",
      sortable: false,
      header: "Created At",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => formatDateMEL(rowData.createdAt),
      isDefault: true,
      isStatic: false,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));


  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      data['jobId'] = eachValue?.jobId;
      data['VehicleBrandId'] = eachValue?.VehicleBrandId;
      data['VehicleModelName'] = eachValue?.vehicleModelName ? eachValue?.vehicleModelName : "-";
      data['commenter'] = eachValue?.commenter;
      data['adherenceOfTatRating'] = eachValue?.adherenceOfTatRating;
      data['adherenceOfTatcomments'] = eachValue?.adherenceOfTatcomments ? eachValue?.adherenceOfTatcomments : "-";
      data['ontimeSolutionRating'] = eachValue?.ontimeSolutionRating;
      data['ontimeSolutionComments'] = eachValue?.ontimeSolutionComments ? eachValue?.ontimeSolutionComments : "-";
      data['onTimePartSupportRating'] = eachValue?.onTimePartSupportRating;
      data['onTimePartSupportComments'] = eachValue?.onTimePartSupportComments ? eachValue?.onTimePartSupportComments : "-";
      data['engineerTechnicalSkillRating'] = eachValue?.engineerTechnicalSkillRating;
      data['engineerTechnicalSkillComments'] = eachValue?.engineerTechnicalSkillComments ? eachValue?.engineerTechnicalSkillComments : "-";
      data['engineerBehaviorRating'] = eachValue?.engineerBehaviorRating;
      data['engineerBehaviorComments'] = eachValue?.engineerBehaviorComments ? eachValue?.engineerBehaviorComments : "-";
      data['marks'] = eachValue?.marks;
      data['grade'] = eachValue?.grade;
      data['createdAt'] = formatDateMEL(eachValue?.createdAt)
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((eachValue) => {
      let data = {
        jobId: eachValue?.jobId ? eachValue?.jobId : "-",
        VehicleBrandId: eachValue?.VehicleBrandId ? eachValue?.VehicleBrandId : "-",
        VehicleModelName: eachValue?.vehicleModelName ? eachValue?.vehicleModelName : "-",
        commenter: eachValue?.commenter ? eachValue?.commenter : "-",
        adherenceOfTatRating: eachValue?.adherenceOfTatRating ? eachValue?.adherenceOfTatRating : "-",
        adherenceOfTatcomments: eachValue?.adherenceOfTatcomments ? eachValue?.adherenceOfTatcomments : "-",
        ontimeSolutionRating: eachValue?.ontimeSolutionRating ? eachValue?.ontimeSolutionRating : "-",
        ontimeSolutionComments: eachValue?.ontimeSolutionComments ? eachValue?.ontimeSolutionComments : "-",
        onTimePartSupportRating: eachValue?.onTimePartSupportRating ? eachValue?.onTimePartSupportRating : "-",
        onTimePartSupportComments: eachValue?.onTimePartSupportComments ? eachValue?.onTimePartSupportComments : "-",
        engineerTechnicalSkillRating: eachValue?.engineerTechnicalSkillRating ? eachValue?.engineerTechnicalSkillRating : "-",
        engineerTechnicalSkillComments: eachValue?.engineerTechnicalSkillComments ? eachValue?.engineerTechnicalSkillComments : "-",
        engineerBehaviorRating: eachValue?.engineerBehaviorRating ? eachValue?.engineerBehaviorRating : "-",
        engineerBehaviorComments: eachValue?.engineerBehaviorComments ? eachValue?.engineerBehaviorComments : "-",
        marks: eachValue?.marks ? eachValue?.marks : "-",
        grade: eachValue?.grade ? eachValue?.grade : "-",
        createdAt: formatDateMEL(eachValue?.createdAt)
      };
      return data;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf: boolean) => {
    let lazy: any = {
      ...lazyState,
      rows: totalCount > 5000 ? 5000 : totalCount < 1 ? 1 : totalCount,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true)
      const response = await API.getFeedbackListApi(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['id'] = eachValue?.id;
        data['jobId'] = eachValue?.jobNumber;
        data['VehicleBrandId'] = eachValue?.vehicleBrandName;
        data['Vehicle'] = eachValue?.vehicleBrandId;
        data['VehicleModelName'] = eachValue?.vehicleModelName ? eachValue?.vehicleModelName : "-";
        data['commenter'] = eachValue?.commenterName;
        data['adherenceOfTatRating'] = eachValue?.adherenceOfTATRating;
        data['adherenceOfTatcomments'] = eachValue?.adherenceOfTATComments ? eachValue?.adherenceOfTATComments : "-";
        data['ontimeSolutionRating'] = eachValue?.ontimeSolutionRating;
        data['ontimeSolutionComments'] = eachValue?.onTimeSolutionComments ? eachValue?.onTimeSolutionComments : "-";
        data['onTimePartSupportRating'] = eachValue?.onTimePartSupportRating;
        data['onTimePartSupportComments'] = eachValue?.onTimePartSupportComments ? eachValue?.onTimePartSupportComments : "-";
        data['engineerTechnicalSkillRating'] = eachValue?.engineerTechnicalSkillRating;
        data['engineerTechnicalSkillComments'] = eachValue?.engineerTechnicalSkillComments ? eachValue?.engineerTechnicalSkillComments : "-";
        data['engineerBehaviorRating'] = eachValue?.engineerBehaviorRating;
        data['engineerBehaviorComments'] = eachValue?.engineerBehaviorComments ? eachValue?.engineerBehaviorComments : "-";
        data['marks'] = eachValue?.marks;
        data['grade'] = eachValue?.grade;
        data['createdAt'] = eachValue?.creationTime;
        return data;
      });
      // let resp=await feedbackListConverson(response?.data?.items)
      if (isPdf) {

        saveDataAsPdf(modifeidValue, columns, "feedback");
      } else {
        saveDataAsExcel(modifeidValue, "feedback");
      }
      setPdfLoading(false)
    } catch (error: any) {
      showToast(error.message, { type: "error" })
      setPdfLoading(false)

    } finally {
      setPdfLoading(false)
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "feedback");
    fetchData(false)

  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "feedback");
    fetchData(true)

  };



  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.vehicleBrand) {
      modifiedLazyState.filters.vehicleBrand.value = "";
    }

    if (hasDealerRoleHandler() || hasCustomerRoleHandler()) {
      modifiedLazyState.filters.UserId = { value: dealerId, matchMode: "contains" }
    }
    dispatch(getFeedbackListLoading(modifiedLazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedDealers, setSelectedDealers] = useState<any[]>([]);


  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedDealers(value);
    // setSelectAll(value.length === totalRecords);
    // setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedDealers(modifiedValues);
    } else {
      setSelectAll(false);
      setSelectedDealers([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    // event['filters']['enquiryDateStart']['value'] = event?.filters?.vehicleBrand?.value?.startDate ? event?.filters?.vehicleBrand?.value?.startDate : ''
    // event['filters']['enquiryDateEnd']['value'] = event?.filters?.vehicleBrand?.value?.endDate ? event?.filters?.vehicleBrand?.value?.endDate : ''

    setLazyState(event);
  };



  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },

        },
      }));
    }
    else if (formValue == LOCKED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          Locked: { value: "true", matchMode: "contains" },

        },
      }));
    } else if (formValue == UNLOCKED) {

      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          Locked: { value: "false", matchMode: "contains" },

        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },
        },
      }));
    }
  };

  const [dealerId, setDealerId] = useState<any>("")
  useEffect(() => {
    if (hasDealerRoleHandler() || hasCustomerRoleHandler()) {
      let dealerUser = JSON.parse(localStorage.getItem('authUser') ? localStorage.getItem('authUser') as string : "")
      let dealerUserId = dealerUser.uid
      if (dealerId != dealerUserId) {
        setDealerId(dealerUserId)
      }
    }
  }, [])

  useEffect(() => {
    dispatch(getVehicleBrandFeedBackLoading())
    dispatch(getInvoicePageJoblistListLoading())
  }, [])

  useEffect(() => {
    setSelectAll(false);
    setSelectedDealers([]);
    callbackFunction(lazyState);

    return () => {
      // dispatch(resetPurchaseOrder());
    };
  }, [lazyState, dealerId]);

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
  }, [vehiclebrandDrop])

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedDealers([]);
    setArchiveModal(false);
  };


  const defaultColumns = {
    ...columns[0]
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedDealers(modifiedValues);
    }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedDealers([]);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.FEEDBACK_CREATE);
  };
  const clearFilter = () => {
    setLazyState(initialLazyState)
  }

  const header =
    <div className="table-header-container">
      <div>
        Feedback List
      </div>
      <div className="table-header-container-item table-header-container-item-special">
        <AnimatePresence>
          {selectedDealers.length > 0 &&
            <>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1" onClick={() => { setDeleteModal(true); }}>
                  <FeatherIcon icon="trash-2" className="mr-1" />
                  Delete
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button mr-1 ml-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> Cancel selection</Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedDealers.length > 0 && <BulkActionStatus count={selectedDealers.length} title="Purchase Order "></BulkActionStatus>}
              </motion.div>
            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
        <Button type="button" className="customize-table-button mt-2" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" />
        </Button>
        {/* <Button type="button" className="btn btn-primary" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" style={{ marginRight: "10px" }} />
          New Feedback
        </Button> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;


  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    const data = defaultColumns
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));
    const areEqual = JSON.stringify(orderedSelectedColumns) === JSON.stringify([data]);
    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);



  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these feedback?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedDealers([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={modifiedValues}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalCount}
          loading={loading}
          emptyMessage="No feedback found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying feedback  {last} records of {totalRecords} in total"
          selection={selectedDealers}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        >
        </DataTableWrapper>
      </div>
    </>
  );
}
