import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router'
import { Col, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { RootReducerState } from 'src/store/reducers'
import "../../SubUserManagement/subUserstyle.scss"
import { ClearPartsInboundMessage, getPartsInboundById, partsinboundSkugeneratedLoading } from 'src/store/partsInbound/action'
import { formatDateMEL } from 'src/helpers/common_helpers';
import { Form, Formik } from "formik";
import './PartsInbound.scss'
import FeatherIcon from "feather-icons-react";
import Button from 'src/components/UI/Button';
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from 'src/components/Common/ReactToaster'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import Scanner from 'src/components/Common/Scanner/Scanner'

const SkuGeneratorComponent = () => {
  const { id, sub_id } = useParams();
  const Location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const PartsInboundDetails: any = useSelector((state: RootReducerState) => state.PartsInboundReducer.editInbound);
  const [partDetails, setPartDetails] = useState<any>([])
  const [scanParts, setScanParts] = useState<any>([])
  const inputRef = useRef<HTMLInputElement>(null);
  const [visible, setVisible] = useState(false);
  const [userError, setUserError] = useState<string>("")
  const [isQuantity, setIsQuantity] = useState<boolean>(false);
  const [systemMessage, setSystemMessage] = useState("")
  const [generateSKUModal, setGenerateSKUModal] = useState<boolean>(false)
  const { loading, dealerTaxList } = useSelector(
    (state: RootReducerState) => state.dealer
  );
  const { error, message } = useSelector((state: RootReducerState) => state.PartsInboundReducer);
  const { showToast } = useToast();


  useEffect(() => {
    if (id) {
      dispatch(getPartsInboundById(id));
    }
  }, [id])

  useEffect(() => {
    const partId = parseInt(Location.pathname.split('/').pop() || '', 10);
    const partsDetails: any = PartsInboundDetails?.partsInboundDetailDtos?.filter((item: any) => (item.id === partId))
    if (partsDetails?.length > 0) {
      setPartDetails(partsDetails)
    }
  }, [PartsInboundDetails])



  const userView = {
    generalDetails: [
      {
        name: "Shipping Document Date",
        value: formatDateMEL(PartsInboundDetails?.shippingDocumentDate)
      },
      {
        name: "Received Date",
        value: formatDateMEL(PartsInboundDetails?.receivedDate)
      },
      {
        name: "Invoice Number",
        value: PartsInboundDetails?.invoiceNumber
      },
      {
        name: "Airway Bill Number",
        value: PartsInboundDetails?.airwayBillNumber
      },
      {
        name: "Courier Name",
        value: PartsInboundDetails?.courierName
      },
      {
        name: "Buying Currency",
        value: PartsInboundDetails?.buyingCurrency
      },
      {
        name: "Currency Conversion Rate",
        value: PartsInboundDetails?.currencyConversionRate
      },
      {
        name: "Purchase Order",
        value: PartsInboundDetails?.purchaseOrderId
      },
      {
        name: "Supplier",
        value: PartsInboundDetails?.supplierName
      },
    ],
    partDetails: [
      { name: "Part", value: partDetails[0]?.partNo },
      { name: "Quantity", value: partDetails[0]?.quantity },
      { name: "Buying Price", value: partDetails[0]?.buyingPrice },
      { name: "Profit Percentage", value: partDetails[0]?.profitPercentage },
      { name: "Selling Price", value: partDetails[0]?.sellingPrice },
    ]
  }

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    const inputElement = document.getElementById('ScanField');
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  useEffect(() => {
    if (message && message != "" && message != undefined) {
      showToast(message, { type: "success" });
      dispatch(ClearPartsInboundMessage())
      navigate(ROUTER.PARTS_INBOUND_EDIT + "/" + id)
    }
  }, [message])

  useEffect(() => {
    if (error && error != "" && error != undefined) {
      setVisible(true)
    }
  }, [error])


  const handleSubmit = async (values: any, { resetForm }: { resetForm: () => void }) => {
    setIsQuantity(false);
    if (values.scanParts !== "") {
      if (!scanParts.includes(values.scanParts)) {
        if (scanParts.length < partDetails[0]?.quantity) {
          setScanParts([...scanParts, values.scanParts]);
          resetForm();
        } else {
          setIsQuantity(true)
          setUserError('Scanned quantity cannot be more then Quantity')
        }
      } else {
        setUserError("This part has been already scanned")
        setIsQuantity(true)
      }
    }
  }


  //ClearPartsInboundMessage

  const hidePopup = () => {
    setVisible(false);
    dispatch(ClearPartsInboundMessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    );
  };

  const initialvalue = {
    scanParts: ""
  }

  const handleRemoveParts = (id: number) => {
    const RemoveParts = scanParts.filter((item: any, index: number) => index !== id);
    setScanParts(RemoveParts)
    setIsQuantity(false)
  }

  const handleClearParts = () => {
    setScanParts([])
    if (scanParts.length < partDetails[0]?.quantity) {
      setIsQuantity(false)
    }
  }

  const handleGenerateSKU = () => {
    if (scanParts.length > 0) {
      if (scanParts.length == partDetails[0]?.quantity) {
        setUserError("");
        setIsQuantity(false);
        setGenerateSKUModal(true)
        setSystemMessage("The system will generate SKUs of this parts")
      } else if (scanParts.length < partDetails[0]?.quantity) {
        setUserError("");
        setIsQuantity(false);
        setGenerateSKUModal(true)
        setSystemMessage(`Manual SKU generation completed for ${scanParts.length} part. The system will automatically generate the remaining parts.`)
      }
    } else {
      setUserError("");
      setIsQuantity(false);
      setGenerateSKUModal(true)
      setSystemMessage(`The system will automatically generate SKU for all parts.`)
    }
  }

  const partsSkuGenerated = () => {
    let partinboundparseID: any = id;
    let partInboudDetailIdparse: any = sub_id
    const scanPart = scanParts.map((item: any) => ({ barCode: item }));
    let modifiedData = {
      partInboudId: parseInt(partinboundparseID),
      partInboudDetailId: parseInt(partInboudDetailIdparse),
      barCodes: scanPart
    }
    dispatch(partsinboundSkugeneratedLoading(modifiedData));
  }

  const handleGenerateSKUCallback = () => {

    setGenerateSKUModal(false);
    partsSkuGenerated();
  }
  const [openScanner, setOpenScanner] = useState<boolean>(false);
  const openScannerHandler = () => {
    setOpenScanner(true)
  }

  const scannerContainer = () => {
    return <Scanner visible={openScanner} onHide={() => setOpenScanner(false)} scannedSkus={[]} setScanParts={setScanParts} isFloatingPage={false} scanParts={scanParts} isSkuGeneratorPage={true}/>
  }

  return (
    <div className="pagescontent " id="view-dealer">
      {visible && dialogContainer(userError ? userError : error)}
      {openScanner && scannerContainer()}
      <ArchiveModal
        show={generateSKUModal}
        message={systemMessage}
        deleteLabel="Yes"
        closeLabel="No"
        // onDeleteClick={() => {
        //   setGenerateSKUModal(false);
        // }}     
        onDeleteClick={handleGenerateSKUCallback}
        onCloseClick={() => {
          setGenerateSKUModal(false);
        }}
      />
      <BreadcrumbWithFilter
        title="Parts Inbound"
        titlePath={ROUTER.PARTS_INBOUND_GRID}
        breadcrumbItem="Generate SKU"
        isShowCreate={false}
        isShowFilter={false}
        filter={false}
        isShowFirstButton={false}
      />
      <Row>
        <div className="view">
          <div className="general-details">
            <b>Parts Inbound Details</b>
          </div>
          <div className="subview">
            <Row>
              {
                userView.generalDetails.map((data, index) => {
                  return (
                    <Col lg={3} key={index} className={`${index >= 4 ? "mt-4" : "mt-1"}`}>
                      <div className='mb-2'>{data.name}</div>
                      <b>{data.value ? data.value : <div className="none-text">-</div>}</b>
                    </Col>
                  )
                })
              }
            </Row>
          </div>
        </div>
        <div className="view">
          <div className="general-details">
            <b>Parts Details</b>
          </div>
          <div className="subview">
            <Row>
              {
                userView.partDetails.map((data, index) => {
                  return (
                    <Col lg={3} key={index} className={`${index >= 4 ? "mt-4" : "mt-1"}`}>
                      <div className='mb-2'>{data.name}</div>
                      <b>{data.value ? data.value : <div className="none-text">-</div>}</b>
                    </Col>
                  )
                })
              }
            </Row>
          </div>
        </div>
        <div className="view">
          <div className="general-details d-flex justify-content-between align-items-center">
            <b>Generate SKU </b>
            <Formik
                    onSubmit={(values, { resetForm }) => handleSubmit(values, { resetForm })}
                    initialValues={initialvalue}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      resetForm,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <div className="custom-scan-input" style={{width:"300px"}}>
                            <div className="inp-icon scan-here">
                              <FeatherIcon icon="search" className="icon-lg left-icon" />
                              <input
                                className="icon-input"
                                ref={inputRef}
                                type="text"
                                name="scanParts"
                                placeholder='Scan here'
                                value={values.scanParts}
                                onChange={handleChange}
                                // readOnly={true}
                                id="ScanField"
                                autoFocus={true}
                              />
                              <FeatherIcon icon="camera" className="icon-lg right-icon pointer" onClick={openScannerHandler} />
                            </div>
                          </div>
                        
                          {isQuantity &&
                            <div className="text-danger"><small>{userError}</small></div>}
                        </Form>
                      );
                    }}
                  </Formik>
          </div>
          <div className="subview">
            <Row>
             
              <Col lg={12} className='mb-2'>
                <div className="mt-3">
                  <div className="custom-label scanpart">
                    <b>Scanned Parts Details <span> {scanParts?.length > 0 ? ` - ${scanParts?.length}` : ''}</span></b>
                  </div>
                  <div className="p-chips p-component p-inputwrapper p-inputwrapper-filled custom-chip">
                    <ul className="p-inputtext p-chips-multiple-container p-variant-filled">
                      {scanParts.length > 0 ? (
                        scanParts.map((item: any, index: number) => (
                          <li key={index} className="p-chips-token">
                            <span className="p-chips-token-label">{item}</span>
                            <FeatherIcon icon="x-circle" className="p-icon p-chips-token-icon" onClick={() => handleRemoveParts(index)} />
                          </li>
                        ))
                      ) :
                        (
                          <div className="p-chips-input-token">
                            <input type="text" readOnly /></div>
                        )}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-2">
              <Button
                className="secondary-btn me-2"
                label="Clear"
                type="button"
                onClick={() => handleClearParts()}
              />
              <Button
                className="btn-primary me-2"
                label="Save & Generate"
                type="button"
                onClick={handleGenerateSKU}
              />
            </div>
          </div>
        </div>
      </Row>
    </div>
  )
}

export default SkuGeneratorComponent