
export enum InvoiceTypes {
    //grid
    GET_INVOICES_LOADING = '@@invoices/GET_INVOICES_LOADING',
    GET_INVOICES_SUCCESS = '@@invoices/GET_INVOICES_SUCCESS',
    GET_INVOICES_FAIL = '@@invoices/GET_INVOICES_FAIL',

    //view
    GET_INVOICE_DETAIL_LOADING = '@@invoices/GET_INVOICE_DETAIL_LOADING',
    GET_INVOICE_DETAIL_SUCCESS = '@@invoices/GET_INVOICE_DETAIL_SUCCESS',
    GET_INVOICE_DETAIL_FAIL = '@@invoices/GET_INVOICE_DETAIL_FAIL',

    GET_INVOICE_DETAIL_BY_PART_LOADING = '@@invoices/GET_INVOICE_DETAIL_BY_PART_LOADING',
    GET_INVOICE_DETAIL_BY_PART_SUCCESS = '@@invoices/GET_INVOICE_DETAIL_BY_PART_SUCCESS',
    GET_INVOICE_DETAIL_BY_PART_FAIL = '@@invoices/GET_INVOICE_DETAIL_BY_PART_FAIL',

    //add
    ADD_INVOICE_DETAIL_LOADING = "@@invoices/ADD_INVOICE_DETAIL_LOADING",
    ADD_INVOICE_DETAIL_SUCCESS = "@@invoices/ADD_INVOICE_DETAIL_SUCCESS",
    ADD_INVOICE_DETAIL_FAIL = "@@invoices/ADD_INVOICE_DETAIL_FAIL",

    //delete
    DELETE_INVOICE_DETAIL_LOADING = "@@invoices/DELETE_INVOICE_DETAIL_LOADING",
    DELETE_INVOICE_DETAIL_SUCCESS = "@@invoices/DELETE_INVOICE_DETAIL_SUCCESS",
    DELETE_INVOICE_DETAIL_FAIL = "@@invoices/DELETE_INVOICE_DETAIL_FAIL",

    //update
    UPDATE_INVOICE_DETAIL_LOADING = "@@invoices/UPDATE_INVOICE_DETAIL_LOADING",
    UPDATE_INVOICE_DETAIL_SUCCESS = "@@invoices/UPDATE_INVOICE_DETAIL_SUCCESS",
    UPDATE_INVOICE_DETAIL_FAIL = "@@invoices/UPDATE_INVOICE_DETAIL_FAIL",

    // create 
    POST_INVOICE_DETAIL_LOADING = "@@invoices/POST_INVOICE_DETAIL_LOADING",
    POST_INVOICE_DETAIL_SUCCESS = "@@invoices/POST_INVOICE_DETAIL_SUCCESS",
    POST_INVOICE_DETAIL_FAIL = "@@invoices/POST_INVOICE_DETAIL_FAIL",

    // get CUSTOMER DROP DOWN 

    GET_CUSTOMER_DROP_INVOICE_LOADING = "@@invoices/GET_CUSTOMER_DROP_INVOICE_LOADING",
    GET_CUSTOMER_DROP_INVOICE_SUCCESS = "@@invoices/GET_CUSTOMER_DROP_INVOICE_SUCCESS",
    GET_CUSTOMER_DROP_INVOICE_FAIL = "@@invoices/GET_CUSTOMER_DROP_INVOICE_FAIL",

    // get DEALER DROP DOWN 

    GET_DEALER_DROP_INVOICE_LOADING = "@@invoices/GET_DEALER_DROP_INVOICE_LOADING",
    GET_DEALER_DROP_INVOICE_SUCCESS = "@@invoices/GET_DEALER_DROP_INVOICE_SUCCESS",
    GET_DEALER_DROP_INVOICE_FAIL = "@@invoices/GET_DEALER_DROP_INVOICE_FAIL",

    // get PARTS SKU LIST 

    GET_PARTS_SKU_LIST_LOADING = "@@invoices/GET_PARTS_SKU_LIST_LOADING",
    GET_PARTS_SKU_LIST_SUCCESS = "@@invoices/GET_PARTS_SKU_LIST_SUCCESS",
    GET_PARTS_SKU_LIST_FAIL = "@@invoices/GET_PARTS_SKU_LIST_FAIL",

    //  get INVOICE TAXES

    GET_TAXES_LIST_FOR_INVOICE_LOADING = "@@invoices/GET_TAXES_LIST_FOR_INVOICE_LOADING",
    GET_TAXES_LIST_FOR_INVOICE_SUCCESS = "@@invoices/GET_TAXES_LIST_FOR_INVOICE_SUCCESS",
    GET_TAXES_LIST_FOR_INVOICE_FAIL = "@@invoices/GET_TAXES_LIST_FOR_INVOICE_FAIL",

    //  get INVOICE DEATILS TAXES

    GET_TAXES_DEATILS_FOR_INVOICE_LOADING = "@@invoices/GET_TAXES_DEATILS_FOR_INVOICE_LOADING",
    GET_TAXES_DEATILS_FOR_INVOICE_SUCCESS = "@@invoices/GET_TAXES_DEATILS_FOR_INVOICE_SUCCESS",
    GET_TAXES_DEATILS_FOR_INVOICE_FAIL = "@@invoices/GET_TAXES_DEATILS_FOR_INVOICE_FAIL",


    //reset
    RESET_INVOICE_DETAILS = "@@invoices/RESET_INVOICE_DETAILS",
    RESET_PARTS_SKU_INDEX = "@@invoices/RESET_PARTS_SKU_INDEX",
    RESET_INVOICE_MESSAGE = "@@invoices/RESET_INVOICE_MESSAGE",


    //call all tax id to get taxes details

    GET_INVOICEPAGE_TAXESBYID_DETAILS_LOADING = "@@invoices/GET_INVOICEPAGE_TAXESBYID_DETAILS_LOADING",
    GET_INVOICEPAGE_TAXESBYID_DETAILS_SUCCESS = "@@invoices/GET_INVOICEPAGE_TAXESBYID_DETAILS_SUCCESS",
    GET_INVOICEPAGE_TAXESBYID_DETAILS_ERROR = "@@invoices/GET_INVOICEPAGE_TAXESBYID_DETAILS_ERROR",


    //get invoice type 

    GET_INVOICEPAGE_INVOICETYPE_LOADING = "@@invoices/GET_INVOICEPAGE_INVOICETYPE_LOADING",
    GET_INVOICEPAGE_INVOICETYPE_SUCCESS = "@@invoices/GET_INVOICEPAGE_INVOICETYPE_SUCCESS",
    GET_INVOICEPAGE_INVOICETYPE_ERROR = "@@invoices/GET_INVOICEPAGE_INVOICETYPE_ERROR",

    //get joblist dropdown type     
    GET_INVOICEPAGE_JOBLIST_DROPDOWN_LOADING = "@@invoices/GET_INVOICEPAGE_JOBLIST_DROPDOWN_LOADING",
    GET_INVOICEPAGE_JOBLIST_DROPDOWN_SUCCESS = "@@invoices/GET_INVOICEPAGE_JOBLIST_DROPDOWN_SUCCESS",
    GET_INVOICEPAGE_JOBLIST_DROPDOWN_ERROR = "@@invoices/GET_INVOICEPAGE_JOBLIST_DROPDOWN_ERROR",

    //get jobsheetid related details
    GET_INVOICEPAGE_JOBSHEET_ID_RELATED_LOADING = "@@invoices/GET_INVOICEPAGE_JOBSHEET_ID_RELATED_LOADING",
    GET_INVOICEPAGE_JOBSHEET_ID_RELATED_SUCCESS = "@@invoices/GET_INVOICEPAGE_JOBSHEET_ID_RELATED_SUCCESS",
    GET_INVOICEPAGE_JOBSHEET_ID_RELATED_ERROR = "@@invoices/GET_INVOICEPAGE_JOBSHEET_ID_RELATED_ERROR",


    //edit jobid related jobestimateDto

    GET_JOB_ESTIMATE_DTO_BY_JOB_ID_LOADING = "@@invoices/GET_JOB_ESTIMATE_DTO_BY_JOB_ID_LOADING",
    GET_JOB_ESTIMATE_DTO_BY_JOB_ID_SUCCESS = "@@invoices/GET_JOB_ESTIMATE_DTO_BY_JOB_ID_SUCCESS",
    GET_JOB_ESTIMATE_DTO_BY_JOB_ID_ERROR = "@@invoices/GET_JOB_ESTIMATE_DTO_BY_JOB_ID_ERROR",



    //tax details onchnage
    GET_INVOICE_DETAILS_ONCHANGE_LOADING = "@@invoices/GET_INVOICE_DETAILS_ONCHANGE_LOADING",
    GET_INVOICE_DETAILS_ONCHANGE_SUCCESS = "@@invoices/GET_INVOICE_DETAILS_ONCHANGE_SUCCESS",
    GET_INVOICE_DETAILS_ONCHANGE_ERROR = "@@invoices/GET_INVOICE_DETAILS_ONCHANGE_ERROR",


    //reset onchangestaxes

    RESET_TAXES_ONCHANGES_DETAILS = "@@invoices/RESET_TAXES_ONCHANGES_DETAILS",
    RECEIVED_PAYMENT_LOADING = "@@invoices/RECEIVED_PAYMENT_LOADING",
    RECEIVED_PAYMENT_SUCCESS = "@@invoices/RECEIVED_PAYMENT_SUCCESS",
    RECEIVED_PAYMENT_ERROR = "@@invoices/RECEIVED_PAYMENT_ERROR",

    //get jobsheet return jobid related details

    GET_JOBRETURN_INVOICE_DETAILS_LOADING = "@@invoices/GET_JOBRETURN_INVOICE_DETAILS_LOADING",
    GET_JOBRETURN_INVOICE_DETAILS_SUCCESS = "@@invoices/GET_JOBRETURN_INVOICE_DETAILS_SUCCESS",
    GET_JOBRETURN_INVOICE_DETAILS_ERROR = "@@invoices/GET_JOBRETURN_INVOICE_DETAILS_ERROR",


    //invoice download pdf
    INVOICE_PAGE_DOWNLOAD_PDF_LOADING = "@@invoices/INVOICE_PAGE_DOWNLOAD_PDF_LOADING",
    INVOICE_PAGE_DOWNLOAD_PDF_SUCCESS = "@@invoices/INVOICE_PAGE_DOWNLOAD_PDF_SUCCESS",
    INVOICE_PAGE_DOWNLOAD_PDF_ERROR = "@@invoices/INVOICE_PAGE_DOWNLOAD_PDF_ERROR",

    //invoice send email 
    INVOICE_PAGE_SEND_EMAIL_LOADING = "@@invoices/INVOICE_PAGE_SEND_EMAIL_LOADING",
    INVOICE_PAGE_SEND_EMAIL_SUCCESS = "@@invoices/INVOICE_PAGE_SEND_EMAIL_SUCCESS",
    INVOICE_PAGE_SEND_EMAIL_ERROR = "@@invoices/INVOICE_PAGE_SEND_EMAIL_ERROR",

    //invoice send whatsapp 
    INVOICE_PAGE_SEND_WHATSAPP_LOADING = "@@invoices/INVOICE_PAGE_SEND_WHATSAPP_LOADING",
    INVOICE_PAGE_SEND_WHATSAPP_SUCCESS = "@@invoices/INVOICE_PAGE_SEND_WHATSAPP_SUCCESS",
    INVOICE_PAGE_SEND_WHATSAPP_ERROR = "@@invoices/INVOICE_PAGE_SEND_WHATSAPP_ERROR",

    //get taxes types dropdown
    GET_TAXES_TYPES_INVOICE_PAGE_LOADING = "@@invoices/GET_TAXES_TYPES_INVOICE_PAGE_LOADING",
    GET_TAXES_TYPES_INVOICE_PAGE_SUCCESS = "@@invoices/GET_TAXES_TYPES_INVOICE_PAGE_SUCCESS",
    GET_TAXES_TYPES_INVOICE_PAGE_ERROR = "@@invoices/GET_TAXES_TYPES_INVOICE_PAGE_ERROR",


    //UNDO_PARTS_SKU

    UNDO_PARTSKU_LIST_LOADING = "@@invoices/UNDO_PARTSKU_LIST_LOADING",
    UNDO_PARTSKU_LIST_SUCCESS = "@@invoices/UNDO_PARTSKU_LIST_SUCCESS",
    UNDO_PARTSKU_LIST_ERROR = "@@invoices/UNDO_PARTSKU_LIST_ERROR",

    //get taxes types dropdown
    GET_UPDATED_BY_NAME_LOADING = "@@invoices/GET_UPDATED_BY_NAME_LOADING",
    GET_UPDATED_BY_NAME_SUCCESS = "@@invoices/GET_UPDATED_BY_NAME_SUCCESS",
    GET_UPDATED_BY_NAME_ERROR = "@@invoices/GET_UPDATED_BY_NAME_ERROR",
    //reset undo part

    RESET_UNDO_PART = "@@invoices/RESET_UNDO_PART",

    REMOVE_PARTS_SKU_DETAILS ="@@invoices/REMOVE_PARTS_SKU_DETAILS",

    GENERATE_PAYMENT_LINK="@@invoices/GENERATE_PAYMENT_LINK",
    GENERATE_PAYMENT_LINK_SUCCESS="@@invoices/GENERATE_PAYMENT_LINK_SUCCESS",
    GENERATE_PAYMENT_LINK_ERROR="@@invoices/GENERATE_PAYMENT_LINK_ERROR",
    GENERATE_PAYMENT_LINK_RESET="@@invoices/GENERATE_PAYMENT_LINK_RESET",

    LOAD_FULL_JOB_SHEET_LOADING = "@@invoices/LOAD_FULL_JOB_SHEET_LOADING",
    LOAD_FULL_JOB_SHEET_SUCCESS= "@@invoices/LOAD_FULL_JOB_SHEET_SUCCESS",
    LOAD_FULL_JOB_SHEET_ERROR = "@@invoices/LOAD_FULL_JOB_SHEET_ERROR",


    LOAD_ALL_TYPE_INVOICE_LOADING = "@@invoices/LOAD_ALL_TYPE_INVOICE_LOADING",
    LOAD_ALL_TYPE_INVOICE_SUCCESS = "@@invoices/LOAD_ALL_TYPE_INVOICE_SUCCESS",
    LOAD_ALL_TYPE_INVOICE_ERROR = "@@invoices/LOAD_ALL_TYPE_INVOICE_ERROR",

    FRANCHISE_DROPDOWN_LOADING = "@@invoices/FRANCHISE_DROPDOWN_LOADING",
    FRANCHISE_DROPDOWN_SUCCESS = "@@invoices/FRANCHISE_DROPDOWN_SUCCESS",
    FRANCHISE_DROPDOWN_ERROR = "@@invoices/FRANCHISE_DROPDOWN_ERROR",
}
