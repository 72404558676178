import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Router, useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { ROUTER } from 'src/constants/routes';
import { UserDetails } from 'src/models/components/ViewUserDetails';
import { Fieldset } from 'primereact/fieldset';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { formatDateMEL, formatDateTOYYYYMMDD, hasAdminOrEmployeeRoleHandler, hasCustomerRoleHandler, hasDealerRoleHandler, hasDealerSubuserRoleHandler, hasFranchiseRoleHandler, hasFranchiseSubuserRoleHandler, hasManufacturerRoleHandler, onDropRejectedFileHandler, onlyAdminRoleHandler } from 'src/helpers/common_helpers';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import { deleteEmployeeImageLoading, getAudioBrandsListForParts, getCustomerImageLoading, getEmployeeImageLoading, getPartsBYID, resetPartsMessage, uploadEmployeeFileLoading } from 'src/store/actions';
import { createSelector } from 'reselect';
import { clearProfileMessage, deleteProfilePictureLoading, getProfile, getProfilePictureLoading, resendOtpProfile, resetOtpProfileMessage, sendProfileotpLoading, updateProfile, uploadProfileMobileNumberLoading, uploadProfilePictureLoading } from 'src/store/profile/actions';
import { useToast } from "src/components/Common/ReactToaster";
import FeatherIcon from "feather-icons-react";
import { Input } from 'react-select/animated';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputCom from 'src/components/Common/Input';
import CheckboxInput from 'src/components/UI/CheckboxInput';
import Button from 'src/components/UI/Button';
import { FieldModal } from 'src/models/components/inputWrapperModel';
import './profile.scss';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import { getDealerImageLoading } from 'src/store/dealer/actions';
import { getEmployeeImageProfileLoading } from 'src/store/EmployeeProfile/action';
import Dropzone from 'react-dropzone';
import { FILE_UPLOAD_SIZE, FILE_UPLOAD_SIZE_2mb } from 'src/constants/commonConstants';
import ImagePreviewer from 'src/components/Common/ImagePreviewer/ImagePreviewer';
import DeleteModal from '../Calendar/DeleteModal';
import ProfileImageViewer from './ProfileImageViewer';
import OtpScreen from '../Customers/OtpScreen';
import { Dialog } from 'primereact/dialog';
const Profile = () => {
  const navigate = useNavigate();
  const [id, setId] = useState<any>();
  const [user, setUser] = useState<any>();


  const editUserClick = () => {
    // navigate(`${ROUTER.PROFILE_EDIT}/${"1"}`)
    setEditMode(!EditMode);
  };
  const profiledata = createSelector(

    (state: any) => state.profile,
    (state) => ({
      success: state.success,
    })
  );
  // const{employeeImage }=useSelector( (state: RootReducerState) => state.employeeProfileReducer)
  // const { franchiseImage } = useSelector((state: RootReducerState) => state.franchiseeReducer);
  // const { dealerImage } = useSelector((state: RootReducerState) => state.dealer);
  // const { customerImage } = useSelector((state: RootReducerState) => state.customerReducer);
  // // Inside your component
  const { success } = useSelector(profiledata);
  const { error, loading, message, profile, profilePicture, phoneMessage, editedNumber, otpMessage } = useSelector(
    (state: RootReducerState) => state.profilesReducer
  );

  const role: any = localStorage.getItem('authUser');
  const isAdmin: any = role ? JSON.parse(role).role : "";
  const userId: any = JSON.parse(role)?.uid;
  const isFranchiseLogin = hasFranchiseRoleHandler();
  const isEmployeeLogin = (hasAdminOrEmployeeRoleHandler() && isAdmin != "admin");
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  

  const [username, setusername] = useState("Admin");
  const [email, setemail] = useState("");

  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    if (getAuthUser) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {

        const obj = JSON.parse(getAuthUser);
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(getAuthUser);
        setusername(obj.username);
        setemail(obj.email);
      }
    }
  }, [success]);

  useEffect(() => {
    const getAuthUser: any = localStorage.getItem("authUser");
    const obj = JSON.parse(getAuthUser);

    if (hasAdminOrEmployeeRoleHandler()) {
      setUser("Employee");
      setId(profile?.extraProperties?.MelEmployeeId);
    }
    if (onlyAdminRoleHandler()) {
      setUser("Employee");
      setId(obj?.uid);
    }
    if (hasFranchiseRoleHandler()) {
      setUser("Franchise");
      setId(profile?.extraProperties?.FranchiseId);
    }
    if (hasDealerRoleHandler()) {
      setUser("Dealer");
      setId(profile?.extraProperties?.DealerId);
    }
    if (hasCustomerRoleHandler()) {
      setUser("Customer");
      setId(profile?.extraProperties?.CustomerId);
    }
    if (hasDealerSubuserRoleHandler()) {
      setUser("DealerSubUser");
      setId(obj?.uid);
    }
    if (hasFranchiseSubuserRoleHandler()) {
      setUser("FranchiseSubUser");
      setId(obj?.uid);
    }
    if (hasManufacturerRoleHandler()) {
      setUser("Manufacturer");
      setId(profile?.extraProperties?.ManufacturerId);
    }
  }, [profile]);

  const { showToast } = useToast();
  const [visible, setVisible] = useState<any>();

  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);
  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    console.count(`${value}`);
    return (
      <StatusHghlighter status={status} />
    );
  };

  // const statusBodyTemplate =useCallback ((value: boolean) => {
  //   console.count("count")
  //   let status = value;
  //   return (
  //     <StatusHghlighter status={status} />
  //   )

  // },[profile])

  const [EditMode, setEditMode] = useState(false);
  useEffect(() => {
    dispatch(getProfile());

  }, []);
  const [generaldetails, setGeneralDetail] = useState<any>([]);
  const userView = useMemo(() => {
    setGeneralDetail([
      {
        name: "User Name",
        value: profile?.userName,
        hasEdit: true,
      },

      {
        name: "Email",
        value: profile?.email,
        hasEdit: false,
      },
      {
        name: "Name",
        value: profile?.name,
        hasEdit: true,
      },
      {
        name: "Surname",
        value: profile?.surname,
        hasEdit: true,
      },
      {
        name: "Mobile",
        value: profile?.phoneNumber,
        hasEdit: true,
      },
      !hasManufacturerRoleHandler() ? {
        name: "Address",
        value: profile?.extraProperties?.Address,
        hasEdit: true,
      } : null,
      (hasFranchiseRoleHandler() || hasDealerRoleHandler()) ? {
        name: "City",
        value: profile?.extraProperties?.City,
        hasEdit: true,
      } : null,
      (hasFranchiseRoleHandler() || hasDealerRoleHandler()) ? {
        name: "Region",
        value: profile?.extraProperties?.Region,
        hasEdit: true,
      } : null,
      (hasFranchiseRoleHandler() || hasDealerRoleHandler()) ? {
        name: "Pincode",
        value: profile?.extraProperties?.Pincode,
        hasEdit: true,
      } : null,
      !hasManufacturerRoleHandler() ?{
        name: "Send Sms",
        value: statusBodyTemplate(profile?.extraProperties?.SendSms),
        hasEdit: true,
      } : null,
      !hasManufacturerRoleHandler() ?{
        name: "Send Email",
        value: statusBodyTemplate(profile?.extraProperties?.SendEmail),
        hasEdit: true,
      }:null,
      !hasManufacturerRoleHandler() ?{
        name: "Send Whatsapp",
        value: statusBodyTemplate(profile?.extraProperties?.SendWhatsapp),
        hasEdit: true,
      }:null
    ]);
  }, [profile]);

  const initialvalue = {
    userName: profile?.userName ? profile?.userName : "",
    name: profile?.name ? profile?.name : "",
    surname: profile?.surname ? profile?.surname : "",
    email: profile?.email ? profile?.email : "",
    phoneNumber: profile?.phoneNumber ? profile?.phoneNumber : "",
    address: profile?.extraProperties ? profile?.extraProperties.Address : "",
    city: profile?.extraProperties ? profile?.extraProperties.City : "",
    region: profile?.extraProperties ? profile?.extraProperties.Region : "",
    pincode: profile?.extraProperties ? profile?.extraProperties.Pincode : "",
    SendSms: profile?.extraProperties ? profile.extraProperties.SendSms : null,
    SendEmail: profile?.extraProperties ? profile.extraProperties.SendEmail : null,
    SendWhatsapp: profile?.extraProperties ? profile.extraProperties.SendWhatsapp : null,
  };

  const handleSubmit = async (values: any) => {
    const submitValue = profile;
    submitValue['userName'] = values.userName;
    submitValue['name'] = values.name;
    submitValue['surname'] = values.surname;
    submitValue['phoneNumber'] = String(values.phoneNumber);
    submitValue['extraProperties']['Address'] = values?.address;
    submitValue['extraProperties']['City'] = values?.city;
    submitValue['extraProperties']['Region'] = values?.region;
    submitValue['extraProperties']['Pincode'] = String(values?.pincode);
    submitValue['extraProperties']['SendSms'] = values?.SendSms;
    submitValue['extraProperties']['SendEmail'] = values?.SendEmail;
    submitValue['extraProperties']['SendWhatsapp'] = values?.SendWhatsapp;
    delete submitValue.concurrencyStamp;
    dispatch(updateProfile(submitValue));
    if (selectedFiles.length > 0) {
      dispatch(uploadProfilePictureLoading(selectedFiles[0], id, user));
    }
    

  };

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required('User Name is required'),
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required')
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    address: Yup.string().required('Address is required'),
    // .matches(/[\s\S]*\b\d{6}\b$/, 'Enter with valid pincode Ex:-(chennai 600028)'),
  });

  const validationSchemanew = Yup.object().shape({
    userName: Yup.string().required('User Name is required'),
    name: Yup.string().required('Name is required'),

    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),

  });

  const handleReset = (resetForm: any) => {
    setselectedFiles([]);
    setEditMode(false);
  };
  useEffect(() => {
    if (error) {
      setVisible(true);
    }

    if (message && error == "") {
      showToast(message, { type: "success" });
      setselectedFiles([])
      setEditMode(false)
      dispatch(getProfilePictureLoading(id, user))
      dispatch(clearProfileMessage())
      dispatch(getProfile())
    }
  }, [error, message]);

  useEffect(()=>{

    return () =>{
      dispatch(clearProfileMessage());
    }
  },[])
  useEffect(() => {
    if (otpMessage) {
      showToast(otpMessage, { type: "success" });
      setselectedFiles([]);
      setEditMode(false);
      dispatch(getProfilePictureLoading(id, user));
      dispatch(clearProfileMessage());
      dispatch(getProfile());
    }
  }, [otpMessage]);

  useEffect(() => {
    if (phoneMessage && error == "") {
      showToast(phoneMessage, { type: "success" });
      // dispatch(getProfile())
      setOpenOtp(true);
    }
  }, [phoneMessage]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearProfileMessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const navigateMoreDetails = () => {
    navigate(ROUTER.FRANCHISEE_PROFILE_VIEW);
  };
  const navigateEmployeeDetails = () => {
    navigate(ROUTER.PROFILE_EMPLOYEE);
  };


  useEffect(() => {
    if (id && user) {
      

      dispatch(getProfilePictureLoading(id, user));
    }

  }, [id, user]);

  const [imageLink, setImageLink] = useState<any>("");
  useEffect(() => {
    setImageLink(profilePicture);

  }, [profilePicture]);

  const [selectedFiles, setselectedFiles] = useState<any>([]);

  function handleAcceptedFiles(files: any) {
    
    let filesModified = files.map((file: any) => {
      
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith('image/')
      });
    }
    );

    
    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE_2mb) {
        setselectedFiles(files);
      } else {
        showToast("File size exceeds 2MB", { type: "error" });
        setselectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" });
      setselectedFiles([]);
    }

  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  };
  const removeIndex = () => {
    setDeleteModal(false);
    

    if (profilePicture) {
      dispatch(deleteProfilePictureLoading(id, user));
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };
  const [emailEdit, setEmailEdit] = useState<boolean>(false);
  const [phoneEdit, setPhoneEdit] = useState<boolean>(false);
  const preventSpecialChar = (event: any) => {
    if ((event.which >= 106 && event.which <= 111) || (event.which >= 187 && event.which <= 191) || event.which === 69) {
      event.preventDefault();
    }
  };

  const [otpValue, setOtpValue] = useState<any>(null);
  const [openOtp, setOpenOtp] = useState<any>(false);
  const handleOtpSubmit = () => {

    dispatch(sendProfileotpLoading(otpValue, userId, editedNumber));

    setOpenOtp(false);
  };
  const hideOtpPopup = () => {
    setOpenOtp(false);
    dispatch(resetOtpProfileMessage());
    setOtpValue("");
  };
  const openOtpScreen = () => {
    return (
      <>
        <OtpScreen visible={openOtp} onHide={hideOtpPopup} onSubmit={handleOtpSubmit} otpValue={otpValue} setOtpValue={setOtpValue} />
      </>
    );
  };
  const handleResendOtp = (number: any) => {
    dispatch(resendOtpProfile(userId, number));
  };


  const [Number, setNumber] = useState<any>("");
  const onHide = () => {
    setPhoneEdit(false);
  };
  const handleNumberSubmit = (values: any) => {
    
    setPhoneEdit(false);
    dispatch(uploadProfileMobileNumberLoading(values.phoneNumber, userId, user));

  };
  const EditValidationNumber = Yup.object().shape({

    phoneNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),

  });
  const PhoneNumber = () => {
    return (
      <>
        <Dialog
          header="Edit Mobile Number"
          visible={phoneEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHide}
        >
          <div >
            <Formik
              onSubmit={handleNumberSubmit}
              validationSchema={EditValidationNumber}
              initialValues={{
                phoneNumber: Number
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Mobile"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="number"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onKeyDown={preventSpecialChar}
                        disabled={id && !phoneEdit ? true : false}
                        isRequired={true}
                        invalid={
                          touched["phoneNumber"] &&
                            errors["phoneNumber"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setPhoneEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  console.log(generaldetails);

  
  return (
    <React.Fragment>
      <div className='userpage' id="profilebody">
        {error && dialogContainer(error)}
        {openOtp && openOtpScreen()}
        {openOtp && <div className='balckscreen'></div>}
        {phoneEdit && PhoneNumber()}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            removeIndex();
          }}
          onCloseClick={() => {
            setDeleteModal(false);
          }}
        />

        <Container fluid>
          {/* <BreadcrumbWithFilter
            // title="Profile" breadcrumbItem='User Details'
            // isShowCreate={false}
            // isShowFilter={false}
            filter={false}
            isShowFirstButton={!EditMode}
            firstButtonLabel='Edit Profile'
            onFirstButtonClick={editUserClick}
          /> */}
          {!EditMode ?
            <Row>
              {/* <div className="view"> */}
              <Card style={{ borderRadius: "20px" }}>
                <CardBody>
                  <div className="" id="profile" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "3px 7px" }}>
                    <h5>Profile Details</h5>
                    <div>

                      <Button
                        label={"Edit"}
                        icon={'edit-2'}
                        className={`btn-primary `}
                        type="button"
                        onClick={() => setEditMode(!EditMode)}
                      ></Button>


                      {
                        isFranchiseLogin && <Button
                          label={"More details"}
                          className={`btn-primary `}
                          type="button"
                          onClick={navigateMoreDetails}
                        ></Button>
                      }
                      {
                        isEmployeeLogin && <Button
                          label={"More details"}
                          className={`btn-primary `}
                          type="button"
                          onClick={navigateEmployeeDetails}
                        ></Button>
                      }


                    </div>
                  </div>


                  {/* <div className="general-details" style={{ display: "flex", justifyContent: "space-between", alignItems: "center",padding:"3px 7px" }}>
                  <b>Personal Details</b>
                  <Button
                    label={"Edit Profile"}
                    className={`btn-primary `}
                    type="button"
                    onClick={() => setEditMode(!EditMode)}
                  ></Button>
                </div> */}
                  <div className="subview">
                    {imageLink &&
                      <div className="image-container">
                        <div className="image-child">
                          <img src={imageLink} alt="" />
                          <div className="text-center">
                            <b>Image</b>
                          </div>
                        </div>
                      </div>
                    }
                    <Row className='mt-3'>
                      {
                        generaldetails?.filter((val:any)=>val !=null)?.map((data: any, index: number) => {
                          return (
                            <Col lg={3} key={index} className={`mb-4 `} >
                              <h6 className='mb-1'>{data.name}</h6>
                              <b>{data.value ? data.value : <div className='view-none-text'>-</div>}</b>
                            </Col>
                          );
                        })
                      }
                    </Row>
                  </div>
                </CardBody>
              </Card>
              {/* </div> */}
            </Row>
            :
            (
              <Card style={{ borderRadius: "20px" }} id="profile">
                <CardBody>
                  <h5 className='mb-4'>Edit Profile</h5>
                  {(isAdmin != "admin") &&
                    <React.Fragment>
                      <div className="mt-1">
                        <Row>
                          <Col sm={12} lg={12} md={12} xs={12}>
                            <div>
                              {(selectedFiles.length == 0 && !profilePicture) &&
                                <div className='mb-4'>
                                  <h5>Image</h5>
                                  <Dropzone
                                    onDrop={acceptedFiles => {
                                      handleAcceptedFiles(acceptedFiles);
                                    }}
                                    onError={(err: Error) => { console.log("file error", err); }}
                                    onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                                    maxFiles={1}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} accept="image/*" />
                                          <div className="">
                                            <i className="display-4 text-muted bx bx-cloud-upload" />
                                          </div>
                                          <h4>Drop files here or click to upload.</h4>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone></div>}

                              {/* {(selectedFiles[0]?.preview || profilePicture) && <ImagePreviewer selectedFile={selectedFiles[0]?.preview ? selectedFiles[0]?.preview : profilePicture} removeIndex={deleteConfirmationHandler} />} */}
                              {(selectedFiles[0]?.preview || profilePicture) && <ProfileImageViewer selectedFile={selectedFiles[0]?.preview ? selectedFiles[0]?.preview : profilePicture} removeIndex={deleteConfirmationHandler} />}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </React.Fragment>
                  }
                  <React.Fragment>
                    <Formik
                      onSubmit={handleSubmit}
                      initialValues={initialvalue}
                      validationSchema={hasManufacturerRoleHandler() ?validationSchemanew : validationSchema}
                      enableReinitialize>
                      {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, ...rest }: any) => {
                        return (
                          <Form onChange={handleChange}>
                            <Row className=''>
                              <Col lg={3} className='mb-2'>
                                <InputCom labelName="User Name"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="userName"
                                  onBlur={handleBlur}
                                  value={values.userName}
                                  onChange={handleChange}
                                  isRequired={true}
                                  invalid={touched["userName"] && errors["userName"] ? true : false}
                                />

                              </Col>



                              <Col lg={3} className='mb-2'>
                                <InputCom labelName="Name"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="name"
                                  onBlur={handleBlur}
                                  value={values.name}
                                  onChange={handleChange}
                                  isRequired={true}
                                  invalid={touched["name"] && errors["name"] ? true : false}
                                />

                              </Col>
                              <Col lg={3} className='mb-2'>
                                <InputCom labelName="Surname"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="surname"
                                  onBlur={handleBlur}
                                  value={values.surname}
                                  onChange={handleChange}
                                  isRequired={false}
                                  invalid={touched["surname"] && errors["surname"] ? true : false}
                                />

                              </Col>
                              <Col lg={3}>
                                <div className="phoneField" style={{ position: "relative" }}>
                                  <InputCom
                                    labelName="Mobile"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="number"
                                    name="phoneNumber"
                                    onBlur={handleBlur}
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    onKeyDown={preventSpecialChar}
                                    disabled={!phoneEdit ? true : false}
                                    isRequired={true}
                                    invalid={
                                      touched["phoneNumber"] &&
                                        errors["phoneNumber"]
                                        ? true
                                        : false
                                    }
                                  />
                                  {!phoneEdit && id && (<FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                    onClick={() => { setNumber(values.phoneNumber); setPhoneEdit(true); }}
                                  />
                                  )}

                                </div>
                              </Col>
                             {!hasManufacturerRoleHandler() &&<> <Col lg={12} className='mb-2'>
                                <InputCom labelName="Address"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="address"
                                  onBlur={handleBlur}
                                  value={values.address}
                                  onChange={handleChange}
                                  isRequired={true}
                                  invalid={touched["address"] && errors["address"] ? true : false}
                                />

                              </Col></>}
                              {
                              (hasFranchiseRoleHandler() || hasDealerRoleHandler()) && <>
                              <Col lg={3} className='mb-2'>
                                <InputCom labelName="City"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="city"
                                  onBlur={handleBlur}
                                  value={values.city}
                                  onChange={handleChange}
                                  isRequired={true}
                                  invalid={touched["city"] && errors["city"] ? true : false}
                                />

                              </Col>
                              <Col lg={3} className='mb-2'>
                                <InputCom labelName="Region"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="region"
                                  onBlur={handleBlur}
                                  value={values.region}
                                  onChange={handleChange}
                                  isRequired={true}
                                  invalid={touched["region"] && errors["region"] ? true : false}
                                />

                              </Col>
                              <Col lg={3} className='mb-2'>
                                <InputCom labelName="Pincode"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="number"
                                  name="pincode"
                                  onBlur={handleBlur}
                                  value={values.pincode}
                                  onChange={handleChange}
                                  isRequired={true}
                                  invalid={touched["pincode"] && errors["pincode"] ? true : false}
                                />

                              </Col></>}
                              {!hasManufacturerRoleHandler() &&<>
                              <Col lg={4} className='d-flex align-items-center'>
                                <Col lg={6} className='mb-2'>
                                  <CheckboxInput
                                    name="SendSms"
                                    label="Send SMS"
                                    value={values["SendSms"]}
                                    onChange={handleChange}
                                    {...rest}
                                  />
                                </Col>
                                <Col lg={6} className='mb-2'>
                                  <CheckboxInput
                                    name="SendEmail"
                                    label="Send Email"
                                    value={values["SendEmail"]}
                                    onChange={handleChange}
                                    {...rest}
                                  />
                                </Col>
                                <Col lg={6} className='mb-2'>
                                  <CheckboxInput
                                    name="SendWhatsapp"
                                    label="Whatsapp"
                                    value={values["SendWhatsapp"]}
                                    onChange={handleChange}
                                    {...rest}
                                  />
                                </Col>
                              </Col></>
                      }
                              <div className="d-flex justify-content-end mt-2">
                                
                                <Button
                                  className="secondary-btn me-2"
                                  label="Cancel"
                                  type="button"
                                  onClick={() => handleReset(resetForm)}
                                />
                                <Button
                                  className="btn-primary me-2"
                                  label="Update Profile"
                                  type="submit"
                                />
                              </div>

                            </Row>


                          </Form>
                        );
                      }}
                    </Formik>
                  </React.Fragment>
                </CardBody>
              </Card>
            )}
        </Container>
      </div >
    </React.Fragment >
  );
};

export default Profile;