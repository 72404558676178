import React, { useEffect, useRef, useState } from 'react';
import DeleteModal from '../Calendar/DeleteModal';
import ArchiveModal from "../Calendar/DeleteModal";
// import { franchisee } from 'src/models/pages/franchiseeModel';
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Form, Formik } from 'formik';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import Select from 'src/components/Common/Select';
import { Option } from 'src/models/components/inputWrapperModel';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { ROUTER } from 'src/constants/routes';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ClearFranchiseeImage, deleteFranchiseeLoading, getFranchiseeListLoading, resetFranchisee } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { useToast } from 'src/components/Common/ReactToaster';
import CustomizeTable from 'src/components/Common/CustomizeTable/CustomizeTable';
import { Dropdown } from 'primereact/dropdown';
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from 'src/constants/bulkActionVariant';
import EmailBodyTemplate from 'src/components/Common/EmailBodyTemplate/EmailBodyTemplate';
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import { saveAsExcelFile } from 'src/helpers/common_helpers';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import BulkActionStatus from 'src/components/Common/BulkActionStatus/BulkActionStatus';
import TooltipMEL from 'src/components/UI/Tooltip';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import { API } from 'src/util/api';
import { ALL } from 'src/constants/commonConstants';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import { customizeTableColumns } from 'src/models/components/CustomizeTableColumns';

const ACTIVE = "Active";
const DELETED = "Deleted";
const EMAIL_VERIFIED = "Email verified";

const FranchiseeList: React.FC = () => {
  const filterDetails: DataTableFilterMeta = {
    Name: { value: "", matchMode: "contains" },
    PhoneNumber: { value: "", matchMode: "contains" },
    Email: { value: "", matchMode: "contains" },
    companyName: { value: "", matchMode: "contains" },
    IsVerfied: { value: null, matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "Name",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  useEffect(() => {
    dispatch(ClearFranchiseeImage())
  }, [])
  const emailBodyTemplate = (rowData: any, field: string) => {
    let data = rowData[field] as string;
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const phoneVerifiedTemplate =(rowData:any) => {
    return(
      <div className="d-flex gap-1 align-items-center">
      <b>{rowData.PhoneNumber}</b>
      {rowData.phoneNumberConfirmed ? <FeatherIcon icon="check-circle" className={`icon-lg right-icon`} style={{ color:"green",width:"20px"}}/>:null}
      </div>
    )
  }
  const hidePopup = () => {
    setVisible(false);
    dispatch(resetFranchisee());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
        {/* <Dialog header="Validation Error" visible={visible} maximizable className='vw30' onHide={() => { setVisible(false); dispatch(resetFranchisee()); }}>
          <div className="m-0 validation-text">
            {error}
          </div>
        </Dialog> */}
      </>
    );
  };

  const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHghlighter status={status} />
    );
  };

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      frozen : true,
      isStatic: true,
    },
    {
      field: "Name",
      sortable: true,
      header: "Name",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "Name"),
      isDefault: true,
      isStatic: true,
    },
    // {
    //   field: "isActive",
    //   sortable: true,
    //   header: "Is Active",
    //   filter: false,
    //   showFilterMenu: true,
    //   headerStyle: { whiteSpace: "nowrap", minWidth: "200px" },
    //   body: (rowData) => statusBodyTemplate(rowData, 'isActive'),
    //   isDefault: true,
    //   isStatic: true,
    // },
    {
      field: "Email",
      sortable: true,
      header: "Email",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
      body: (rowData) => emailBodyTemplate(rowData, "Email"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "PhoneNumber",
      sortable: true,
      header: "Mobile",
      filter: true,
      showFilterMenu: true,
      body:(rowData:any)=>phoneVerifiedTemplate(rowData),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "alternateNumber",
      sortable: false,
      header: "Alternate Mobile Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "gstNumber",
      sortable: false,
      header: "Gst Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "companyName",
      sortable: false,
      header: "Company Name",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "address",
      sortable: false,
      header: "Address",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "isActive",
      sortable: false,
      header: "Is Active",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'isActive'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "sendSms",
      sortable: false,
      header: "Send Sms",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendSms'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "sendEmail",
      sortable: false,
      header: "Send Email",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendEmail'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "sendWhatsapp",
      sortable: false,
      header: "Send Whatsapp",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendWhatsapp'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
  ];

  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedFranchisee, setSelectedFranchisee] = useState<any>([]);
  const [rowData, setRowData] = useState<any | null>(null);
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState(false);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const [franchiseeData, setFranchiseeData] = useState<any>([]);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  const initialValues = {
    status: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const dt = useRef<DataTable<any>>(null);
  const { franchiseeList, paginatorCount } = useSelector((state: RootReducerState) => state.franchiseeReducer);
  const { totalCount } = useSelector((state: RootReducerState) => state.franchiseeReducer.franchiseeList);
  const { error, message } = useSelector((state: any) => state.franchiseeReducer);



  const loading = useSelector((state: RootReducerState) => state.franchiseeReducer.loading);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false)

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);



  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          rowData.isDeleted == false ?
            (
              <ColumnAction
                displayData={rowData[field] as string}
                isEdit={true}
                isDelete={true}
                isView={true}
                franchiseSubUser={true}
                onViewClick={() => viewHandler(rowData)}
                onEditClick={() => editHandler(rowData)}
                onDeleteClick={() => deleteHandler(rowData)}
                onFranchiseSubuserClick={() => franchiseSubUSer(rowData)}
              />
            ) : (
              <ColumnAction
                displayData={rowData[field] as string}
                undo={rowData.isDeleted ? true : false}
                onDeleteClick={() => deleteHandler(rowData)}
              />
            )

        }
      </>

    );
  };

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
    { label: "Email verified", value: "Email verified" },
  ];
  // const handleDeleteUsers = (rowData: franchisee) => {
  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      lazyState,
      undo: rowData['isDeleted'] ? true : (rowData[0]?.isDeleted ? true : false),
    };
    dispatch(deleteFranchiseeLoading(payload));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setTimeout(() => {
        dispatch(getFranchiseeListLoading(lazyState));
      }, 500);
      setSelectAll(false);
      setSelectedFranchisee([]);
    }

  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedFranchisee([]);
    setArchiveModal(false);
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == ALL) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == EMAIL_VERIFIED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: "true", matchMode: "contains" },
        },
      }));
    } else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  // const viewHandler = (rowData: franchisee) => {
  const viewHandler = (rowData: any) => {
    navigate(`${ROUTER.FRANCHISEE_VIEW}/${rowData["id"]}`);
  };

  // const editHandler = (rowData: franchisee) => {
  const editHandler = (rowData: any) => {
    navigate(`${ROUTER.FRANCHISEE_EDIT}/${rowData["id"]}`);
  };

  // const deleteHandler = (rowData: franchisee) => {
  const deleteHandler = (rowData: any) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const franchiseSubUSer = (rowData: any) => {
    navigate(ROUTER.SUB_USER_CREATE_ID + '/' + rowData['id'], { state: rowData })
  }

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedFranchisee(value);
    // setSelectAll(value.length === totalCount);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      setSelectAll(true);
      setSelectedFranchisee(franchiseeData);
    } else {
      setSelectAll(false);
      setSelectedFranchisee([]);
    }
  };
  const defaultColumns = {
    ...columns[0]
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedFranchisee([]);
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {

    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetFranchisee());
      dispatch(getFranchiseeListLoading(lazyState));
    }
  }, [error, message]);

  const onPage = (event: any) => {
    setLazyState(event);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.FRANCHISEE_CREATE);
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getFranchiseeListLoading(lazyState));
  };

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const bulkActionClickHandler = () => {
    if (franchiseeData.length > 0) {
      setSelectAll(true);
      setSelectedFranchisee(franchiseeData);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedFranchisee([]);
  };
  const clearFilter = () => {
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  useEffect(() => {
    setSelectAll(false);
    setSelectedFranchisee([]);
    callbackFunction(lazyState);
  }, [lazyState]);

  useEffect(() => {
    if (franchiseeList?.items?.length > 0) {
      const franchiseStructuredData = franchiseeList?.items?.map((data) => {
        let modified = {
          id: data?.id,
          Name: data?.user?.name,
          userName: data?.user?.userName,
          Email: data?.user?.email,
          PhoneNumber: data?.user?.phoneNumber,
          alternateNumber: data?.user?.extraProperties?.AlternateNumber,
          companyName: data?.companyName,
          phoneNumberConfirmed : data?.user?.phoneNumberConfirmed,
          gstNumber: data?.user?.extraProperties.GstNumber,
          address: data?.user?.extraProperties.Address,
          isActive : data?.user?.isActive,
          sendEmail: data?.user?.extraProperties?.SendEmail,
          sendSms: data?.user?.extraProperties?.SendSms,
          sendWhatsapp: data?.user?.extraProperties?.SendWhatsapp,
          isDeleted: data?.isDeleted
        };
        return modified;
      });

      setFranchiseeData(franchiseStructuredData);
    } else {
      setFranchiseeData([])
    }
  }, [franchiseeList]);
  const fetchData = async (isPdf: boolean) => {
    let state: any = {
      ...lazyState,
      rows: totalCount > 5000 ? 5000 : totalCount < 1 ? 1 : totalCount,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };
   

    try {
      setPdfLoading(true)
      const response = await API.getFranchiseeListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const franchiseStructuredData = response?.data?.items?.map((data:any) => {
        let modified = {
          id: data?.id,
          Name: data?.user?.name,
          userName: data?.user?.userName,
          Email: data?.user?.email,
          phoneNumber: data?.user?.phoneNumber,
          alternateNumber: data?.user?.extraProperties?.AlternateNumber,
          companyName: data?.companyName,
          phoneNumberConfirmed : data?.user?.phoneNumberConfirmed,
          gstNumber: data?.user?.extraProperties.GstNumber,
          address: data?.user?.extraProperties.Address,
          isActive : data?.user?.isActive,
          sendEmail: data?.user?.extraProperties?.SendEmail,
          sendSms: data?.user?.extraProperties?.SendSms,
          sendWhatsapp: data?.user?.extraProperties?.SendWhatsapp,
          isDeleted: data?.isDeleted
        };
        return modified;
      });
      if (isPdf) {

        saveDataAsPdf(franchiseStructuredData, columns, "Franchise");
      } else {
        saveDataAsExcel(franchiseStructuredData, "Franchise");
      }
      setPdfLoading(false)
    } catch (error:any) {
      // console.log(error);
      showToast(error?.message,{type:"error"})
      setPdfLoading(false)

    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(franchiseeData, "franchise");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(franchiseeData, columns, "franchise");
    fetchData(true)
  };


  const header =
    <div className="table-header-container">
      <div>
        Franchise List
      </div>
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedFranchisee.length == 0&& selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedFranchisee.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
          <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
            <FeatherIcon icon="archive" className="mr-1" />
            Archive
          </Button>
        </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                  {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></>}
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedFranchisee.length > 0 && <BulkActionStatus count={selectedFranchisee.length} title="Franchise"></BulkActionStatus>}
              </motion.div>


            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
          <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
            <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
              filter className="w-full md:w-14rem" />
          </TooltipMEL>
          <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
            <FeatherIcon icon="sliders" className="" />
          </Button>
        </div>
        <div className="reponsive_position">
          {/* <TooltipMEL title="Add Franchisee" position="bottom"> */}
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Franchise
          </Button>
        </div>
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((a) => {
      const { id: Id, Name, Email, PhoneNumber: Mobile, gstNumber, companyName, address: Address,isActive,phoneNumberConfirmed,sendEmail,sendSms,sendWhatsapp , alternateNumber} = a;
      let data: any = {};
      data['Mobile'] = Mobile;
      data['Alternate Mobile Number'] = alternateNumber;
      data['GST number'] = gstNumber;
      data['Company name'] = companyName;
      data['Address'] = Address;
      data['Is Active'] = isActive;
      data['Send Sms'] = sendSms;
      data['Send Email'] = sendEmail;
      data['Send Whatsapp'] = sendWhatsapp;
      data['Mobile Number Verified']=phoneNumberConfirmed;
      return { ...{ Name, Email, }, ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = async (values: any[], fileName: string) => {
    let modifiedColumnDetails = await changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        id: data.id,
        Name: data.Name,
        Email: data.Email,
        PhoneNumber: data?.PhoneNumber,
        alternateNumber: data?.alternateNumber,
        gstNumber: data?.gstNumber,
        companyName: data?.companyName,
        address: data?.address,
        isActive: data?.isActive,
        sendSms:data?.sendSms,
        sendEmail:data?.sendEmail,
        sendWhatsapp:data?.sendWhatsapp,
        phoneNumberConfirmed:data?.phoneNumberConfirmed
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        let columnsWithVerified=[...exportColumns,{
          title:"Mobile Number Verified",
          dataKey:"phoneNumberConfirmed",
         }]
        const doc:any = new jsPDF.default({ orientation: "landscape", format: "a3" });
        // const doc = new (jsPDF as any).default({or : "a4"});
        doc.autoTable(columnsWithVerified, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (selectedFranchisee?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedFranchisee([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (rowData || selectedFranchisee.length == 1) {
              handleDeleteUsers(rowData || selectedFranchisee);
            } else if (selectedFranchisee.length > 1) {
              for (let i = 0; i < selectedFranchisee.length; i++) {
                handleDeleteUsers(selectedFranchisee[i], selectedFranchisee.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these administrators?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedFranchisee([]);
          }}
        />

        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          filterIcon={FilterIcon}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          value={franchiseeData}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalCount}
          // loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Franchise  {last} records of {totalRecords} in total"
          selection={selectedFranchisee}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </React.Fragment>
  );
};

export default FranchiseeList;