import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHighlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import {
  CurrencyFormatter,
  hasFranchiseRoleHandler,
  saveAsExcelFile,
} from "src/helpers/common_helpers";
import { deleteTaxesFromList } from "src/store/taxes/action";
import { partSku } from "src/models/pages/partSkusModel";
import {
  getConformationSku,
  getConsumptionSku,
  getJobsheetId,
  getLocationList,
  getPartAutoCompleteList,
  getPartSkuAutoComplete,
  getPartSkuList,
  resetPartskumessage,
} from "src/store/partSku/action";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import TooltipMEL from "src/components/UI/Tooltip";
import Barcode from "react-barcode";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { RootReducerState } from "src/store/reducers";
import { Link } from "react-router-dom";
import { ROUTER } from "src/constants/routes";
import ReactToPrint from "react-to-print";
import BarcodeLabel from "./BarcodeLabel";
import { Dialog } from "primereact/dialog";
import DropdownInput from "src/components/UI/DropdownInput";
import InputCom from "src/components/Common/Input";
import { API } from "src/util/api";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { AutoComplete } from "primereact/autocomplete";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { title } from "process";
import { co } from "@fullcalendar/core/internal-common";

const partsSkuListConversion = (values: any[]) => {
  const modifeidValue = values.map((eachValue: any) => {
    let data: any = {};
    data["id"] = eachValue.id;
    data["Number"] = eachValue?.number;
    data["Status"] =
      eachValue?.status == "Floating" ? "Transit" : eachValue?.status;
    data["PartNumber"] = eachValue?.partNumber;
    data["PartDescription"] = eachValue?.partDescription;
    data["PartInBoundNumber"] = eachValue?.partInBoundNumber;
    data["PurchaseOrder"] = eachValue?.purchaseOrder;
    data["AudioBrandName"] = eachValue?.audioBrandName;
    data["InvoiceNumber"] = eachValue?.invoiceNumber;
    data["stockAdjustment"] = eachValue?.stockAdjustment;
    data["Supplier"] = eachValue?.supplier;
    data["barcode"] = eachValue?.number;
    data["labelGenerated"] = eachValue?.labelGenerated;
    data["partIds"] = eachValue?.partId;
    data["partInBoundId"] = eachValue?.partInBoundId;
    data["purchaseOrderId"] = eachValue?.purchaseOrderId;
    data["supplierId"] = eachValue?.supplierId;
    data["audioBrandId"] = eachValue?.audioBrandId;
    data["stockAdjustmentId"] = eachValue?.stockAdjustmentId;
    data["partPrice"] = eachValue?.partPrice;
    data["StockLocationId"] = eachValue?.locationName;
    data["jobPrefix"] = eachValue?.jobPrefix;
    data["transist"] = eachValue?.transist;
    data["pincode"] = eachValue?.pincode;
    data["city"] = eachValue?.city;
    data["region"] = eachValue?.region;
    data["companyName"] = eachValue?.companyName;
    data["jobNumber"] = eachValue?.jobNumber;
    return data;
  });
  return modifeidValue;
};

export default function PartSKUs() {
  const navigate = useNavigate();
  const { sku_id } = useParams();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector(
    (state: RootReducerState) => state.partSkuReducer.partSkuList
  );
  const { error, partList, partSkuNumberList, locationList,jobId } = useSelector(
    (state: RootReducerState) => state.partSkuReducer
  );
  const modifiedValues: any[] = partsSkuListConversion(values);

  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.partSkuReducer.loading);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<partSku | null>(null);
  const [visible, setVisible] = useState(false);
  const filterDetails: DataTableFilterMeta = {
    Number: { value: "", matchMode: "contains" },
    Status: { value: "", matchMode: "contains" },
    PartNumber: { value: "", matchMode: "contains" },
    PartInBoundNumber: { value: "", matchMode: "contains" },
    StockLocationId: { value: "", matchMode: "contains" },
    PartDescription: { value: "", matchMode: "contains" },
    PurchaseOrder: { value: "", matchMode: "contains" },
    Supplier: { value: "", matchMode: "contains" },
    AudioBrandName: { value: "", matchMode: "contains" },
    InvoiceNumber: { value: "", matchMode: "contains" },
    TransistForAdjusment: { value: "", matchMode: "contains" },
    partPrice: { value: "", matchMode: "contains" },
    UnlockedInvoicePartSku: { value: "", matchMode: "contains" },
    FranchiseId: { value: sku_id ? sku_id : "", matchMode: "contains" },
    transist: { value: "", matchMode: "contains" },
    region: { value: "", matchMode: "contains" },
    city: { value: "", matchMode: "contains" },
    pincode: { value: "", matchMode: "contains" },
    companyName: { value: "", matchMode: "contains" },
    jobNumber: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "",
    // sortOrder: 0,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  useEffect(() => {
    dispatch(getLocationList());
  }, []);

  const numElements = 100;
  const columnToPrintIndex = "Number";
  const elementRefs: any = useRef(null);
  const [labels, setLabels] = useState<any>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  useEffect(() => {
    const elements = (
      <div ref={elementRefs}>
        {modifiedValues.map((row: any, index: number) => (
          <div key={index} style={{ textAlign: "center" }}>
            {row[columnToPrintIndex] && (
              <Barcode value={row[columnToPrintIndex]} format="CODE128" />
            )}
          </div>
        ))}
      </div>
    );
    setLabels(elements);
  }, [values]);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
  }, [error]);
  useEffect(() => {
    if (jobId) {
      navigate(`${ROUTER.JOB_SHEETS_VIEW}/${jobId}`);
      dispatch(resetPartskumessage());
    }
  }, [jobId]);

  const onPrintRefHandler = async () => {
    setPrintView(true);
    // navigate(ROUTER.PARTS_SKU_PRINT)
    // const printableElement = elementRefs?.current?.innerHTML
    // const printableData = printableElement;
    // console.log("Print Data", printableData)
    // printHTMLContent(printableData)
  };

  const onCreateClickHandler = () => {
    navigate("/createUser");
  };

  const viewHandler = (rowData: partSku) => {
    navigate(`/editCustomer/${rowData["id"]}`);
  };
  const editHandler = (rowData: partSku) => {
    navigate(`/editCustomer/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: partSku) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);

  const actionBodyTemplate = (rowData: partSku) => {
    return (
      <ColumnAction
        isEdit={true}
        isDelete={true}
        isView={true}
        onViewClick={() => viewHandler(rowData)}
        onEditClick={() => editHandler(rowData)}
        onDeleteClick={() => deleteHandler(rowData)}
      />
    );
  };

  const statusBodyTemplate = (rowData: partSku, field: string) => {
    let status = rowData[field] as string;
    return <StatusHighlighter status={status} />;
  };

  const options: Option[] = [
    { label: "Used", value: "Used" },
    { label: "Unused", value: "Unused" },
    { label: "Unlocked", value: "Unlocked" },
    { label: "Rejected", value: "Rejected" },
    { label: "Scrapped", value: "Scrapped" },
    { label: "Missing", value: "Missing" },
    { label: "Transit", value: "Floating" },
    { label: "In Transit For Adjustment", value: "TransistForAdjusment" },
    { label: "Auto Label Generated", value: "IsLabelGenerated" },
    { label: "Manual Label Generated", value: "IsNotLabelGenerated" },
    // { label: "In Transit For Invoice", value: "InTransitForInvoice" },
    // { label: "In Transit For Adjustment", value: "In Transit For Adjustment" },
  ];

  const currencyBodyTemplate = (rowData: partSku, field: string) => {
    let amount = rowData[field];
    return <>{CurrencyFormatter({ value: amount })}</>;
  };

  const barcodeTemplate = (rowData: partSku, field: string) => {
    let data = rowData[field] as string;
    return (
      <div id="barcode">
        {data && <Barcode value={data} format="CODE128" width={4} />}
        <div className="text-center max-100-barcode">
          {rowData.PartDescription}
        </div>
      </div>
    );
  };

  const partTemplate = (rowData: any, field: any) => {
    return (
      <>
        {!hasFranchiseRoleHandler() ? (
          <Link to={`${ROUTER.PARTS_VIEW}/${rowData["partIds"]}`}>
            {rowData[field]}
          </Link>
        ) : (
          rowData[field]
        )}
      </>
    );
  };
  const partInboundTemplate = (rowData: any, field: any) => {
    return (
      <>
        {!hasFranchiseRoleHandler() ? (
          <Link to={`${ROUTER.PARTS_INBOUND_VIEW}/${rowData["partInBoundId"]}`}>
            {rowData[field]}
          </Link>
        ) : (
          rowData[field]
        )}
      </>
    );
  };

  const PurchaseOrderTemplate = (rowData: any, field: string) => {
    return (
      <>
        {!hasFranchiseRoleHandler() ? (
          <Link
            to={`${ROUTER.PURCHASE_ORDER_VIEW}/${rowData["purchaseOrderId"]}`}
          >
            {rowData[field]}
          </Link>
        ) : (
          rowData[field]
        )}
      </>
    );
  };

  const supplierBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {!hasFranchiseRoleHandler() ? (
          <Link to={`${ROUTER.SUPPLIER_VIEW}/${rowData["supplierId"]}`}>
            {rowData[field]}
          </Link>
        ) : (
          rowData[field]
        )}
      </>
    );
  };

  const AudioBrandTemplate = (rowData: any, field: any) => {
    return (
      <>
        {!hasFranchiseRoleHandler() ? (
          <Link to={`${ROUTER.AUDIO_BRANDS_VIEW}/${rowData["audioBrandId"]}`}>
            {`${rowData[field]} (${rowData?.jobPrefix})`}
          </Link>
        ) : (
          rowData[field]
        )}
      </>
    );
  };

  const StockAdjustmentTemplate = (rowData: any, field: any) => {
    return (
      <Link
        to={
          !hasFranchiseRoleHandler()
            ? `${ROUTER.STOCK_ADJUSTMENT_VIEW}/${rowData["stockAdjustmentId"]}`
            : ""
        }
      >
        {rowData[field] ? `Stock Adjustment(${rowData[field]})` : ""}
      </Link>
    );
  };
  const JobsheetTemplate = (rowData: any, field: any) => {
    return (
      <>
        {rowData[field] ? (
          <Link to={'#'} onClick={() => {dispatch(getJobsheetId(rowData[field]))}}>
            {" "}
            {rowData[field]}{" "}
          </Link>
        ) : (
          ""
        )}
      </>
    );
  };
  const InvoiceTemplate = (rowData: any, field: any) => {
    return (
      <>
        {rowData[field] ? (
          <Link to={`${ROUTER.JOB_SHEETS_VIEW}/${rowData["invoiceId"]}`}>
            {" "}
            {rowData[field]}{" "}
          </Link>
        ) : (
          ""
        )}
      </>
    );
  };
  const numberTemplate = (rowData: any) => {
    let isAutoGenerated = rowData?.labelGenerated;
    return (
      <>
        {isAutoGenerated ? (
          <div className="partSkuNumber">
            {rowData?.Number} <span></span>{" "}
          </div>
        ) : (
          <div>
            <span
              style={{
                maxWidth: "200px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {rowData?.Number}
            </span>
          </div>
        )}
      </>
    );
  };
  const searchParts = (event: any) => {
    dispatch(getPartAutoCompleteList(event.query));
  };
  const searchPartsPartSku = (event: any) => {
    dispatch(getPartSkuAutoComplete(event.query));
  };
  const [partsList, setPartList] = useState([]);
  const [partsSkuList, setPartSkuList] = useState([]);

  const partsTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <div className="autoCompleteFilter">
        <AutoComplete
          value={options.value}
          suggestions={partsList}
          completeMethod={searchParts}
          onChange={(e: DropdownChangeEvent) => {
            options.filterCallback(e.value, options.index);
            return {};
          }}
          className="w-full md:w-20rem padding-none"
        />
      </div>
    );
  };

  const partsSkuFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <div className="autoCompleteFilter">
        <AutoComplete
          value={options.value}
          suggestions={partsSkuList}
          completeMethod={searchPartsPartSku}
          onChange={(e: DropdownChangeEvent) => {
            options.filterCallback(e.value, options.index);
            return {};
          }}
          className="w-full md:w-20rem padding-none"
        />
      </div>
    );
  };

  const LocationFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <>
        <Dropdown
          options={
            locationList?.length > 0
              ? locationList
                  ?.map((eachLocation: any) => {
                    let data: any = {};
                    data["label"] = eachLocation?.locationName;
                    data["value"] = eachLocation?.id;
                    return data;
                  })
                  .sort((a: any, b: any) => {
                    const labelA = a.label.toUpperCase().trim();
                    const labelB = b.label.toUpperCase().trim();
                    if (labelA < labelB) {
                      return -1;
                    }
                    if (labelA > labelB) {
                      return 1;
                    }
                    return 0;
                  })
              : []
          }
          name="supplierId"
          optionLabel="label"
          filter
          onChange={(e: DropdownChangeEvent) => {
            options.filterCallback(e.value, options.index);
            return {};
          }}
          required={true}
          virtualScrollerOptions={{ itemSize: 35 }}
          value={options.value}
          itemTemplate={(option: any) => (
            <div
              title={option.label}
              style={{ display: "flex", alignItems: "center" }}
            >
              {option.label}
            </div>
          )}
        />
      </>
    );
  };

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
      frozen: true,
    },
    {
      field: "Number",
      sortable: true,
      header: "Number",
      filter: true,
      showFilterMenu: true,
      frozen: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "auto" },
      className: "partSku",
      body: (rowData: any) => numberTemplate(rowData),
      isDefault: true,
      isStatic: true,
      filterElement: partsSkuFilterTemplate,
    },
    {
      field: "Status",
      sortable: true,
      header: "Status",
      filter: true,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "PartNumber",
      sortable: true,
      header: "Part Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => partTemplate(rowData, "PartNumber"),
      isDefault: true,
      isStatic: false,
      filterElement: partsTemplate,
    },
    {
      field: "StockLocationId",
      sortable: false,
      header: "Location",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      className: "partSku",
      isDefault: true,
      isStatic: false,
      filterElement: LocationFilterTemplate,
    },
    {
      field: "transist",
      sortable: true,
      header: "Transit",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      className: "partSku",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "region",
      sortable: true,
      header: "Region",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      className: "partSku",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "city",
      sortable: true,
      header: "City",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      className: "partSku",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "pincode",
      sortable: true,
      header: "Pincode",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      className: "partSku",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "jobNumber",
      sortable: true,
      header: "Job Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => JobsheetTemplate(rowData, "jobNumber"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "companyName",
      sortable: true,
      header: "Company Name",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      className: "partSku",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "partPrice",
      sortable: true,
      header: "Price",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "PartDescription",
      sortable: true,
      header: "Part Description",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "400px" },
      isDefault: true,
      isStatic: false,
    },

    {
      field: "PartInBoundNumber",
      sortable: false,
      header: "Part Inbound Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => partInboundTemplate(rowData, "PartInBoundNumber"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "PurchaseOrder",
      sortable: true,
      header: "Purchase Order",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => PurchaseOrderTemplate(rowData, "PurchaseOrder"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "Supplier",
      sortable: true,
      header: "Supplier",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "200px" },
      body: (rowData: any) => supplierBodyTemplate(rowData, "Supplier"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "AudioBrandName",
      sortable: true,
      header: "Audio Brand",
      filter: true,
      showFilterMenu: true, //need filter from backend
      headerStyle: { whiteSpace: "nowrap", minWidth: "150px" },
      body: (rowData: any) => AudioBrandTemplate(rowData, "AudioBrandName"),
      isDefault: true,
      isStatic: false,
    },
    // {
    //   field: "InvoiceNumber",
    //   sortable: false,
    //   header: "Invoice",
    //   filter: false,
    //   showFilterMenu: false,
    //   headerStyle: { whiteSpace: "nowrap", minWidth: "150px" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "stockAdjustment",
      sortable: true,
      header: "References",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) =>
        StockAdjustmentTemplate(rowData, "stockAdjustment"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "barcode",
      sortable: false,
      header: "Barcode",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => barcodeTemplate(rowData, "Number"),
      headerStyle: { whiteSpace: "nowrap", minWidth: "250px" },
      isDefault: false,
      isStatic: false,
    },

    // {
    //   field: "action",
    //   sortable: false,
    //   header: "Action",
    //   filter: false,
    //   showFilterMenu: false,
    //   body: actionBodyTemplate,
    //   headerStyle: { whiteSpace: "nowrap" },
    // },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.filter((eachColumn: Columns) => eachColumn.isDefault)
  );

  useEffect(() => {
    if (partList) {
      setPartList(partList?.map((item: any) => item.partNumber));
    }
  }, [partList]);

  useEffect(() => {
    if (partSkuNumberList) {
      setPartSkuList(partSkuNumberList?.map((item: any) => item.number));
    }
  }, [partSkuNumberList]);

  useEffect(() => {
    setVisibleColumns(
      columns.filter((eachColumn: Columns) => eachColumn.isDefault)
    );
  }, [partsList, partsSkuList, locationList]);

  const transformDataForExcel = (apiData: any[]) => {
    const allPartNumbers = new Set<string>();

    // Step 1: Extract all unique part numbers
    apiData.forEach((location) => {
      location.parts.forEach((part: any) => {
        allPartNumbers.add(part.partNumber);
      });
    });

    const partNumbers = Array.from(allPartNumbers); // Convert set to array
    const headers = [
      "Region",
      "Company Name",
      "Location Name",
      ...partNumbers.flatMap((partNumber: any) => [
        `${partNumber} - Unused`,
        `${partNumber} - Transit`,
      ]),
    ]; // Excel headers with sub-columns for Unused and Transit

    // Step 2: Build rows with location names and quantities
    const rows = apiData.map((location) => {
      const row: { [key: string]: any } = {
        Region: location.region,
        "Company Name": location.companyName || "",
        "Location Name": location.city,
      };

      // Initialize part quantities for both Unused and Transit sub-columns
      partNumbers.forEach((part) => {
        row[`${part} - Unused`] = 0;
        row[`${part} - Transit`] = 0;
      });

      // Update quantities where part exists and categorize by status
      location.parts.forEach((part: any) => {
        row[`${part.partNumber} - Unused`] = part.quantity;

        row[`${part.partNumber} - Transit`] = part.transit;
      });

      return row;
    });

    // Step 3: Return headers and rows
    return { headers, rows };
  };

  const saveDataAsExcel = (values: any, sheet2Value: any, fileName: any) => {
    const modifiedColumnDetails = changeColumnDetails(values); // Sheet 1 data
    const { headers, rows } = transformDataForExcel(sheet2Value); // Sheet 2 data

    import("exceljs").then((ExcelJS) => {
      const workbook = new ExcelJS.Workbook();

      // Sheet 1
      const sheet1 = workbook.addWorksheet("Parts List");
      sheet1.columns = Object.keys(modifiedColumnDetails[0]).map((key) => ({
        header: key,
        key: key,
        width: 20,
      }));
      sheet1.addRows(modifiedColumnDetails);

      // Sheet 2
      const sheet2 = workbook.addWorksheet("Location Based Quantity");

      // Step 1: Prepare multi-level headers
      const partNumbers = Array.from(
        headers.slice(3).reduce((set, header) => {
          set.add(header.split(" - ")[0]);
          return set;
        }, new Set())
      );

      // First row (part numbers)
      const firstRow = ["Region", "Company Name", "Location Name"];
      partNumbers.forEach(() => firstRow.push("", ""));
      firstRow.push("Total");

      // Second row (Unused and Transit) - It will be merged with the first row for Region, Company Name, and Location Name
      const secondRow = ["", "", ""];
      partNumbers.forEach(() => secondRow.push("Unused", "Transit"));
      secondRow.push("Total");

      // Add headers to the sheet
      sheet2.addRow(firstRow);
      sheet2.addRow(secondRow);
      sheet2.mergeCells(1, 1, 2, 1); // Merge Region
      sheet2.mergeCells(1, 2, 2, 2); // Merge Company Name
      sheet2.mergeCells(1, 3, 2, 3);
      // Merge cells for part numbers in the first row
      let startColumn: any = 4;
      partNumbers.forEach((partNumber: any) => {
        sheet2.mergeCells(1, startColumn, 1, startColumn + 1); // Merge Unused and Transit under part number
        sheet2.getCell(1, startColumn).value = partNumber; // Add part number text
        sheet2.getCell(1, startColumn).alignment = { horizontal: "center" };
        startColumn += 2;
      });
      sheet2.mergeCells(1, startColumn, 2, startColumn); // Merge "Total" header
      sheet2.getCell(1, startColumn).value = "Total";

      // Step 2: Add data rows
      const updatedRows = rows.map((row) => {
        const rowData = [row.Region, row["Company Name"], row["Location Name"]];
        let rowTotal = 0;
      
        partNumbers.forEach((partNumber) => {
          // Check if the value is a valid number before adding it to the total
          const unused = row[`${partNumber} - Unused`];
          const transit = row[`${partNumber} - Transit`];
      
          // Only add to rowData and rowTotal if the value is not empty and is a valid number
          if (unused && !isNaN(unused)) {
            rowData.push(parseFloat(unused));
            rowTotal += parseFloat(unused);
          } else {
            rowData.push(""); // Push empty value if not a valid number
          }
      
          if (transit && !isNaN(transit)) {
            rowData.push(parseFloat(transit));
            rowTotal += parseFloat(transit);
          } else {
            rowData.push(""); // Push empty value if not a valid number
          }
        });
      
        rowData.push(rowTotal); // Push total value for the row
        return rowData;
      });
      
      sheet2.addRows(updatedRows);
      

      // Step 3: Add grand total row
      const grandTotalRow = ["", "", "Grand Total"];
      let grandTotal: any = 0;

      let columnIndex = 3; // Start at the first relevant column (index 4 corresponds to the first part number's "Unused" value)

      partNumbers.forEach(() => {
        // Calculate the sum for "Unused" and "Transit" for each part number
        const unusedTotal: any = updatedRows.reduce(
          (sum, row) => sum + (row[columnIndex] || 0),
          0
        ); // Sum for the "Unused" column
        const transitTotal: any = updatedRows.reduce(
          (sum, row) => sum + (row[columnIndex + 1] || 0),
          0
        ); // Sum for the "Transit" column

        // Add the totals for "Unused" and "Transit" to the grand total row
        grandTotalRow.push(unusedTotal, transitTotal);

        // Add the "Unused" and "Transit" totals to the grand total
        grandTotal += unusedTotal + transitTotal;

        // Move to the next part number columns (i.e., skip 2 columns for each part)
        columnIndex += 2;
      });

      // Add the grand total to the end of the row
      grandTotalRow.push(grandTotal);

      // Add the grand total row to the sheet
      sheet2.addRow(grandTotalRow);

      // Step 4: Style headers and rows
      [sheet2.getRow(1), sheet2.getRow(2)].forEach((headerRow) => {
        headerRow.eachCell((cell) => {
          cell.font = { bold: true, color: { argb: "000000" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffff00" },
          }; // Green background
          cell.alignment = { horizontal: "center" };
        });
      });
      sheet2.eachRow((row) => {
        row.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });
      sheet2.getColumn(1).width = 15;
      sheet2.getColumn(2).width = 30;
      sheet2.getColumn(3).width = 20;
      // Style "Total" and "Grand Total"
      const lastColumn = sheet2.columns.length;
      sheet2.getColumn(lastColumn).eachCell((cell, rowIndex) => {
        if (rowIndex > 1) {
          cell.font = { bold: true };
          cell.alignment = { horizontal: "center" };
        }
      });

      const lastRowIndex = sheet2.rowCount; // Get the last row index
      const grandTotalRowStyle = sheet2.getRow(lastRowIndex);
      grandTotalRowStyle.eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { horizontal: "center" };
      });

      // Save workbook
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(
          blob,
          fileName.endsWith(".xlsx") ? fileName : `${fileName}.xlsx`
        );
      });
    });
  };

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachDealer) => {
      const {
        Number,
        PartNumber,
        StockLocationId,
        Status,
        PartDescription,
        PartInBoundNumber,
        PurchaseOrder,
        Supplier,
        AudioBrandName,
        partPrice,
        InvoiceNumber,
        stockAdjustment,
        transist,
        city,
        pincode,
        region,
        jobNumber,
        companyName,
      } = eachDealer;
      let data: any = {};
      data["Number"] = Number;
      data["Status"] = Status == "Floating" ? "Transit" : Status;
      data["Part Number"] = PartNumber;
      data["Location"] = StockLocationId;
      data["Transit"] = transist;
      data["City"] = city;
      data["Pincode"] = pincode;
      data["Region"] = region;
      data["Job Number"] = jobNumber;
      data["Company Name"] = companyName;
      data["Part Price"] = partPrice;
      data["Part Description"] = PartDescription;
      data["Part Inbound Number"] = PartInBoundNumber;
      data["Purchase Order"] = PurchaseOrder;
      data["Supplier"] = Supplier;
      data["Audio Brand"] = AudioBrandName;
      data["Invoice"] = InvoiceNumber;
      data["stockAdjustment"] = stockAdjustment;
      return { ...data };
    });

    return modifiedColumnDetails;
  };

  const changeColumnDetails2 = (values: any[]) => {
    let modifiedColumnDetails = values.map((val) => {
      const {
        partNumber,
        audioBrandName,
        location,
        jobPrefix,
        quantity,
        cityWithPincode,
        region,
        companyName,
      } = val;
      let data: any = {};
      data["Company Name"] = companyName;
      data["Audio Brand Name"] = audioBrandName;
      data["Part number"] = partNumber;
      data["Location"] = cityWithPincode;
      data["Region"] = region;
      data["Quantity"] = quantity;
      return { ...data };
    });

    return modifiedColumnDetails;
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {

    debugger
      const destructureedData = values.map((eachValue) => {
        let datas = {
          Number : eachValue.Number,
          Status : eachValue.Status == "Floating" ? "Transit" : eachValue.Status,
          PartNumber : eachValue.PartNumber,
          StockLocationId : eachValue.StockLocationId,
          transist : eachValue.transist,
          city : eachValue.city,
          pincode : eachValue.pincode,
          region : eachValue.region,
          jobNumber : eachValue.jobNumber,
          companyName : eachValue.companyName,
          partPrice : eachValue.partPrice,
          PartDescription : eachValue.PartDescription,
          PartInBoundNumber : eachValue.PartInBoundNumber,
          PurchaseOrder : eachValue.PurchaseOrder,
          Supplier : eachValue.Supplier,
          AudioBrandName : eachValue.AudioBrandName,
          stockAdjustment : eachValue.stockAdjustment,
         
        };
        return datas;
      });
      import("jspdf").then((jsPDF) => {
        import("jspdf-autotable").then(() => {
          const doc = new (jsPDF as any).default({
            orientation: 'landscape',
            format: 'a3',
          });
          const columnStyles = {
            0: { cellWidth: 40 },
            1: { cellWidth: 20 },
            2: { cellWidth: 25 },
            3: { cellWidth: 45 },
            4: { cellWidth: 30 },
            5: { cellWidth: 20 },
            6: { cellWidth: 15 },
            7: { cellWidth: 15 },
            8: { cellWidth: 20 },
            9: { cellWidth: 25 },
            10: { cellWidth: 20 },
            11:{ cellWidth: 25 },
            12:{ cellWidth: 20 },
            13:{ cellWidth: 20 },
            14:{ cellWidth: 20 },
            15:{ cellWidth: 20 },
            16:{ cellWidth: 20 },
          };
    
          doc.autoTable({
            columns: columns.filter((data:any)=>data.field!="select" && data.field!="barcode").map((val:any)=>({title:val.header,dataKey:val.field})),
            body: destructureedData,
            columnStyles: columnStyles,
            headStyles:{
              fillColor: [42, 125, 182],
              textColor: [255, 255, 255],
            },
            styles: {
              fontSize: 8, 
              overflow: 'linebreak',
              halign: 'center',
              valign: 'middle',
            },
            theme: 'grid',
            startY: 20,
          });
          doc.save(`${fileName}.pdf`);
        });
      });
    };

  const fetchData = async (isPdf: boolean) => {
    let state: any = {
      ...lazyState,
      rows: totalRecords > 5000 ? 5000 : totalRecords < 1 ? 1 : totalRecords,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true);
      const response = await API.getPartSkuListAPI(state);
      const { sortField, sortOrder, ...rest } = state;
      const response2 = await API.getPartSkuListSheet2API(rest);
      if (!response || !response2) {
        throw new Error("Network response was not ok");
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data["id"] = eachValue.id;
        data["Number"] = eachValue?.number;
        data["Status"] = eachValue?.status;
        data["PartNumber"] = eachValue?.partNumber;
        data["PartDescription"] = eachValue?.partDescription;
        data["PartInBoundNumber"] = eachValue?.partInBoundNumber;
        data["PurchaseOrder"] = eachValue?.purchaseOrder;
        data["AudioBrandName"] = eachValue?.audioBrandName;
        data["InvoiceNumber"] = eachValue?.invoiceNumber;
        data["stockAdjustment"] = eachValue?.stockAdjustment;
        data["Supplier"] = eachValue?.supplier;
        data["barcode"] = eachValue?.number;
        data["labelGenerated"] = eachValue?.labelGenerated;
        data["partIds"] = eachValue?.partId;
        data["partInBoundId"] = eachValue?.partInBoundId;
        data["purchaseOrderId"] = eachValue?.purchaseOrderId;
        data["supplierId"] = eachValue?.supplierId;
        data["audioBrandId"] = eachValue?.audioBrandId;
        data["stockAdjustmentId"] = eachValue?.stockAdjustmentId;
        data["partPrice"] = eachValue?.partPrice;
        data["StockLocationId"] = eachValue?.locationName;
        data["jobNumber"] = eachValue?.jobNumber;
        data["jobId"] = eachValue?.jobId;
        data["invoiceId"] = eachValue?.invoiceId;
        data["transist"] = eachValue?.transist;
        data["city"] = eachValue?.city;
        data["pincode"] = eachValue?.pincode;
        data["region"] = eachValue?.region;
        data["companyName"] = eachValue?.companyName;
        return data;
      });
      let sheet2Data = response2?.data?.items?.map((data: any) => {
        let datas: any = {};
        datas["id"] = data?.id;
        datas["partNumber"] = data?.partNumber;
        datas["audioBrandName"] = data?.audioBrandName;
        datas["location"] = data?.locationName;
        datas["stockLocationId"] = data?.stockLocationId;
        datas["jobPrefix"] = data?.jobPrefix;
        datas["audioBrandId"] = data?.audioBrandId;
        datas["partId"] = data?.partId;
        datas["quantity"] = data?.quantity;
        datas["cityWithPincode"] = data?.cityWithPincode;
        datas["region"] = data?.region;
        datas["companyName"] = data?.companyName;
        return datas;
      });
      // let resp=await partsSkuListConversion(response?.data?.items)
      if (isPdf) {
        saveDataAsPdf(modifeidValue, columns, "partSkus");
      } else {
        saveDataAsExcel(modifeidValue, response2?.data, "partSkus");
      }
      setPdfLoading(false);
    } catch (error: any) {
      // console.log(error);
      showToast(error.message, { type: "error" });
      setPdfLoading(false);
    } finally {
      setPdfLoading(false);
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "partSkus");
    fetchData(false);
  };

  const pdfClickHandler = () => {
    fetchData(true)
    // saveDataAsPdf(modifiedValues, columns, "partSkus");
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getPartSkuList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedPartSku, setSelectedPartSku] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedPartSku(value);
    setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedPartSku(modifiedValues);
    } else {
      setSelectAll(false);
      setSelectedPartSku([]);
    }
  };

  const handleStatusBlur = () => {};

  const onPage = (event: any) => {
    setLazyState(event);
    setSelectedPartSku([]);
    setSelectAll(false);
  };

  const onSort = (event: any) => {
    let filterObject: any = { ...event };
    filterObject["first"] = 0;
    setLazyState(filterObject);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    const partDescriptionValue = JSON.parse(
      JSON.stringify(event.filters.PartDescription)
    );
    let obj = event;
    obj.filters["partDescriptionNumber"] = partDescriptionValue;
    setLazyState(obj);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == "TransistForAdjusment") {
      setLazyState((state) => ({
        ...state,

        filters: {
          ...state.filters,
          UnlockedInvoicePartSku: { value: "true", matchMode: "contains" },
          IsLabelGenerated: { value: "", matchMode: "contains" },
          TransistForAdjusment: { value: "true", matchMode: "contains" },
          Status: {
            ...state.filters.Status,
            value: "",
          },
        },
      }));
    } else if (formValue == "Unlocked") {
      setLazyState((state) => ({
        ...state,

        filters: {
          ...state.filters,
          TransistForAdjusment: { value: "", matchMode: "contains" },
          IsLabelGenerated: { value: "", matchMode: "contains" },
          UnlockedInvoicePartSku: { value: "true", matchMode: "contains" },
          Status: {
            ...state.filters.Status,
            value: "",
          },
        },
      }));
    }else if (formValue == "IsLabelGenerated" || formValue == "IsNotLabelGenerated") {
      let val = formValue == "IsLabelGenerated" ? "true" : "false";
      setLazyState((state) => ({
        ...state,

        filters: {
          ...state.filters,
          TransistForAdjusment: { value: "", matchMode: "contains" },
          UnlockedInvoicePartSku: { value: "", matchMode: "contains" },
          IsLabelGenerated: { value: val, matchMode: "contains" },
          Status: {
            ...state.filters.Status,
            value: "",
          },
        },
      }));
    } else {
      setLazyState((state) => ({
        ...state,

        filters: {
          ...state.filters,
          TransistForAdjusment: { value: "", matchMode: "contains" },
          Status: {
            ...state.filters.Status,
            value: formValue,
          },
        },
      }));
    }
    setIsTooltipVisible(false);
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        },
      },
    }));
  };

  useEffect(() => {
    callbackFunction(lazyState);
  }, [lazyState]);

  const handleDeleteUsers = (rowData: partSku) => {
    let payload = {
      id: rowData["id"],
      lazyState,
    };
    dispatch(deleteTaxesFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    showToast("Part SKUs Deleted Successfully", { type: "success" });
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedPartSku([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0],
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol: any) => sCol.field === col.field)
    );

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(null);

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedPartSku(modifiedValues);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedPartSku([]);
  };
  const printStyle: any = {
    display: "flex",
    flexWrap: "wrap",
    width: "100%", // Adjust based on sticker sheet width
    height: "auto", // Let the height adjust based on content
    padding: "10px",
  };
  const componentRef: any = useRef();
  const [partSkuNumbers, setPartSkuNumbers] = useState<any>([]);

  useEffect(() => {
    let numbers = values.map((val: any) => ({
      number: val.number,
      description: val.partDescription,
      partNumber: val.partNumber,
    }));
    setPartSkuNumbers(numbers);
  }, [values]);

  useEffect(() => {
    let numbers = selectedPartSku.map((val: any) => ({
      number: val.Number,
      description: val.PartDescription,
      partNumber: val.PartNumber,
    }));
    setPartSkuNumbers(numbers);
  }, [selectedPartSku]);

  const [printView, setPrintView] = useState<any>(false);
  const onHide = () => setPrintView(false);
  const printOption = [
    { label: "1", value: "90%" },
    { label: "2", value: "49%" },
  ];
  const [col, setCol] = useState("90%");
  const [height, setHeight] = useState(65);
  const [width, setWidth] = useState(4);
  const [gap, setGap] = useState(1);
  const [margin, setMargin] = useState(0);
  const printyModal = () => {
    return (
      <Dialog
        header="Print Barcode"
        visible={printView}
        maximizable
        className="vw100"
        onHide={onHide}
      >
        <div style={printStyle}>
          <Card style={{ width: "100%" }}>
            <CardBody>
              <Formik
                onSubmit={() => {}}
                initialValues={{
                  col: col,
                  width: width,
                  height: height,
                  gap: gap,
                  margin: margin,
                }}
                enableReinitialize
              >
                {({
                  values,
                  handleChange,
                  touched,
                  errors,
                  handleBlur,
                  resetForm,
                  reset,
                  ...rest
                }: any) => {
                  return (
                    <>
                      <Row>
                        <Col lg={2} className="mb-2">
                          <DropdownInput
                            options={printOption}
                            name="vehicleBrandId"
                            label="No of Column"
                            labelClassName={"d-none"}
                            onChange={(e: any) => {
                              setCol(e.target.value);
                            }}
                            required={false}
                            value={col}
                            onBlur={() => {}}
                            invalid={false}
                          />
                        </Col>
                        <Col lg={2} className="mb-2">
                          <InputCom
                            labelName="Width"
                            inputclassName="form-control"
                            labelClassName="form-Label"
                            type="number"
                            name="width"
                            onBlur={handleBlur}
                            value={values.width}
                            onChange={(e: any) => setWidth(e.target.value)}
                            isRequired={false}
                            invalid={
                              touched["width"] && errors["width"] ? true : false
                            }
                          />
                        </Col>
                        <Col lg={2} className="mb-2">
                          <InputCom
                            labelName="Height"
                            inputclassName="form-control"
                            labelClassName="form-Label"
                            type="number"
                            name="height"
                            onBlur={handleBlur}
                            value={values.height}
                            onChange={(e: any) => setHeight(e.target.value)}
                            isRequired={false}
                            invalid={
                              touched["height"] && errors["height"]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col lg={2} className="mb-2">
                          <InputCom
                            labelName="Horizontal Gap"
                            inputclassName="form-control"
                            labelClassName="form-Label"
                            type="number"
                            name="gap"
                            onBlur={handleBlur}
                            value={values.gap}
                            onChange={(e: any) => setGap(e.target.value)}
                            isRequired={false}
                            invalid={
                              touched["gap"] && errors["gap"] ? true : false
                            }
                          />
                        </Col>
                        <Col lg={2} className="mb-2">
                          <InputCom
                            labelName="Vertical Gap"
                            inputclassName="form-control"
                            labelClassName="form-Label"
                            type="number"
                            name="margin"
                            onBlur={handleBlur}
                            value={values.margin}
                            onChange={(e: any) => setMargin(e.target.value)}
                            isRequired={false}
                            invalid={
                              touched["margin"] && errors["margin"]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col lg={2} className="mb-2 d-flex align-items-center">
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                type="button"
                                className="customize-table-button mr-1 ml-1"
                              >
                                <FeatherIcon icon="printer" className="mr-1" />
                                Print Labels
                              </Button>
                            )}
                            content={() => componentRef.current}
                          />
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>

          <div ref={componentRef}>
            <Row className="d-flex" style={{ gap: `${gap}px` }}>
              {partSkuNumbers?.map((number: any, index: any) => (
                <Col
                  lg={4}
                  key={index}
                  style={{
                    width: col,
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: margin > 0 ? `${margin}px` : "10px",
                  }}
                >
                  <BarcodeLabel
                    number={number.number}
                    description={number.description}
                    partNumber={number.partNumber}
                    Col={col}
                    width={width}
                    height={height}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Dialog>
    );
  };
  const customizeTableColumns: customizeTableColumns[] = columns
    .map((eachColumns: Columns) => {
      let data: any = {};
      data["field"] = eachColumns.field;
      data["value"] = visibleColumns.some(
        (sCol: any) => sCol.field === eachColumns.field
      );
      data["isDisable"] = eachColumns.isStatic;
      data["header"] = eachColumns.header;
      return data;
    })
    .filter(
      (eachColumns: customizeTableColumns) => eachColumns.field != "select"
    );
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) =>
      customizeColumnObject.some((sCol: any) => sCol === col.field)
    );

    // setVisibleColumns([...orderedSelectedColumns]);
    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const clearFilter = () => {
    setLazyState(initialLazyState);
    setselectedStatus(null);
  };

  const conmformationSku = () =>{
    dispatch(getConformationSku())
  }
  const consumptionSku = () =>{
    dispatch(getConsumptionSku())
  }
  const header = (
    <div className="table-header-container">
      <div>Part SKU List</div>

      {selectedPartSku.length > 0 && (
        <Button
          type="button"
          className="customize-table-button"
          onClick={onPrintRefHandler}
          style={{
            whiteSpace: "nowrap",
            paddingLeft: "0px",
            marginLeft: "20px",
          }}
        >
          <FeatherIcon icon="printer" className="mr-1" />
          <span className="remove_lables">Print Labels</span>
        </Button>
      )}
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <div className="" style={{paddingTop:"6px"}}>
            <Button
              type="button"
              className="btn btn-primary"
              onClick={conmformationSku}
            >Confirmation Sku</Button>
            <Button
              type="button"
              className="btn btn-primary"
              onClick={consumptionSku}
            >Consumption Sku</Button>
              </div>
          <ExportFile
            exportExcel={csvClickHandler}
            isShowPdf={true}
            exportPdf={pdfClickHandler}
            clearFilter={clearFilter}
            isFilter={true}
          />
          <TooltipMEL
            title="Select Status"
            position="bottom"
            tooltipVisible={isTooltipVisible}
            setTooltipVisible={setIsTooltipVisible}
          >
            <Dropdown
              value={selectedStatus}
              onChange={(e) => handleSubmit(e.value)}
              options={options}
              optionLabel="label"
              showClear={true}
              placeholder="Select status .."
              filter
              className="search_width w-full md:w-14rem"
            />
          </TooltipMEL>

          <TooltipMEL
            title="Choose fields you want to see in the table"
            position="bottom"
          >
            <Button
              type="button"
              className="customize-table-button"
              id="ScheduleUpdateTooltip"
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
              data-btn="btn"
              onClick={customizeTableClickHandler}
            >
              <FeatherIcon icon="sliders" className="" />
            </Button>
          </TooltipMEL>
        </div>

        {/* 

        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip> */}
      </div>
    </div>
  );

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetPartskumessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const paginatorLeft = (
    <div className="d-flex align-items-center gap-2">
      {" "}
      <div className="partSkuNumber d-flex align-items-center">
        <span style={{ padding: "4px" }}></span>
      </div>{" "}
      <div>
        <b> Internal</b>{" "}
      </div>
    </div>
  );
  const paginatorRight = <></>;
  return (
    <>
      <div className="page-content" id="stock-music-system">
        {visible && dialogContainer(error)}
        {printView && printyModal()}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (rowData) {
              handleDeleteUsers(rowData);
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these part sku?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedPartSku([]);
          }}
        />
        {showCustomizeTable && (
          <CustomizeTable
            onCancelHandler={customizeTableCancelHandler}
            onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler}
            columns={customizeTableColumns}
          />
        )}
        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear"></Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button
                            className="btn btn-primary ms-2"
                            color="primary"
                            type="reset"
                          >
                            Clear{" "}
                          </Button>
                          <Button
                            className="btn btn-primary ms-2"
                            color="primary"
                            type="submit"
                          >
                            Search{" "}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <div style={{ display: "none" }}>{labels}</div>
        <DataTableWrapper
          ref={dt}
          header={header}
          columnDetails={visibleColumns}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          filterIcon={FilterIcon}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Part SKUs  {last} records of {totalRecords} in total"
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight}
          selection={selectedPartSku}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
