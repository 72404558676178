import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router';
import { Col, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { ROUTER } from 'src/constants/routes';
import { RootReducerState } from 'src/store/reducers';
import "../../SubUserManagement/subUserstyle.scss";
import { hasFranchiseRoleHandler } from 'src/helpers/common_helpers';
import { Form, Formik } from "formik";
import FeatherIcon from "feather-icons-react";
import Button from 'src/components/UI/Button';
import ArchiveModal from "../../Calendar/DeleteModal";
import { clearIntentMessageData, dispatchIntent, franchiseListforIntent, getIntentDetail, partSkuValidate, receiveIntent, resetIntentValue } from 'src/store/actions';
import { IntentDetailById } from 'src/models/pages/intentModel';
import { useToast } from "src/components/Common/ReactToaster";
import { INTENT_STATUS } from 'src/constants/commonConstants';
import Scanner from 'src/components/Common/Scanner/Scanner';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';

const StockScannerComponent = () => {
  const { id, sub_id } = useParams();
  const Location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { intentDetail, franchiseList, intentError, intentMessage, loading,partSkuValue,error } = useSelector((state: RootReducerState) => state.intentReducer);
  const [intentDetails, setIntentDetails] = useState<any>([]);
  const [scanParts, setScanParts] = useState<any>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [visible, setVisible] = useState(false);
  const [errvisible, setErrVisible] = useState(false);
  const [userError, setUserError] = useState<string>("");
  const [isQuantity, setIsQuantity] = useState<boolean>(false);
  const [generateSKUModal, setGenerateSKUModal] = useState<boolean>(false);
  const { showToast } = useToast();
  const [openScanner, setOpenScanner] = useState<boolean>(false);

  const franchiseLogin = hasFranchiseRoleHandler()

  useEffect(() => {
    if (id) {
      dispatch(franchiseListforIntent());
      dispatch(getIntentDetail(id));
    }

    return () => {
      dispatch(resetIntentValue());
    };
  }, [id]);
  useEffect(() => {
    if (error) {
      setErrVisible(true);
    }    
  }, [error]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (intentError) {
      setVisible(true);
      setIsSubmitting(false);
      // showToast(error, { type: "error" })
    }
    if (intentMessage) {
      setIsSubmitting(false);
      showToast(intentMessage, { type: "success" });
      dispatch(clearIntentMessageData());
      setIsSubmitting(false);
      navigate(`${ROUTER.INTENT_EDIT}/${id}`);

    }
  }, [intentError, intentMessage]);

  useEffect(() => {
    const intentDetails = (intentDetail as IntentDetailById)?.intentDetails?.filter((item: any) => (item.id == sub_id));
    if (intentDetails?.length > 0) {
      setIntentDetails(intentDetails);
    }
    // console.log(partDetails)
  }, [intentDetail]);

  const openScannerHandler = () => {
    setOpenScanner(true)
  }

  const scannerContainer = () => {
    return <Scanner visible={openScanner} onHide={() => setOpenScanner(false)} scannedSkus={[]} setScanParts={setScanParts} isFloatingPage={true} scanParts={scanParts} intentDetail={intentDetails[0]}/>
  }

  const userView = {
    generalDetails: [
      {
        name: "Franchise",
        value: franchiseList?.find((eachValue: any) => eachValue.id == intentDetail?.franchiseId)?.name
      },
      { name: "Part", value: intentDetails[0]?.partNo },
      { name: "Quantity", value: intentDetails[0]?.partQuantity },

    ],

  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    const inputElement = document.getElementById('ScanField');
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const handleKeyPress = (event: any, values: any,setFieldValue:any) => {
    if (event.which == 13 || event.which == 32) {
      event.preventDefault();
    }

    if (!event?.target?.value) return;

    let toScan = event.target.value;
    if (event.key === 'Enter' || event.key === "Tab") {
      if(!hasFranchiseRoleHandler()){
        dispatch(partSkuValidate({partId:intentDetails[0]?.partId,skuNum:toScan}))
      }else{
        setScanParts([...scanParts,{number:toScan}])
      }

      if ( toScan && !scanParts.includes(toScan)) {
        // setScanParts((prevScanParts: any) => [...prevScanParts, toScan]);

        setFieldValue('scanSku','')
      }
     
    }
  };
useEffect(()=>{
  if(partSkuValue){
    
    setScanParts((prevScanParts: any) => [...prevScanParts, ...partSkuValue])
  }
},[partSkuValue])

  const handleSubmit = async (values: any, { resetForm }: { resetForm: () => void; }) => {
    setIsQuantity(false);
    if (values.scanParts !== "") {
      if (!scanParts.includes(values.scanParts)) {
        if (scanParts.length < intentDetails[0]?.partQuantity) {
          setScanParts([...scanParts, values.scanParts]);
          resetForm();
        } else {
          setIsQuantity(true);
          setUserError('Scanned quantity cannot be less than Requested quantity');
        }
      } else {
        setUserError("This part has been already scanned");
        setIsQuantity(true);
      }
    }
  };

  const hidePopup = () => {
    setVisible(false);

    dispatch(clearIntentMessageData())
  };
  const hidePopupError = () => {
    setErrVisible(false);

    dispatch(clearIntentMessageData())
  };

  const dialogContainer = (error: any) => {
    return (
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    );
  };
  const dialogContainerError = (error: any) => {
    return (
      <ValidationPopup error={error} visible={errvisible} onHide={hidePopupError} />
    );
  };

  const initialvalue = {
    scanParts: ""
  };

  const handleRemoveParts = (id: number) => {
    const RemoveParts = scanParts.filter((item: any, index: number) => index !== id);
    setScanParts(RemoveParts);
    setIsQuantity(false);
  };

  const handleClearParts = () => {
    setScanParts([]);
    setUserError("")
    if (scanParts.length < intentDetails[0]?.quantity) {
      setIsQuantity(false);
    }
  };

  const handleGenerateSKU = () => {
    if (scanParts.length > 0) {
      // if (scanParts.length != intentDetails[0]?.partQuantity) {
      if (scanParts.length > intentDetails[0]?.partQuantity) {
        setIsQuantity(true);
        setUserError('Scanned quantity cannot be greater than Requested quantity');
      } else if(scanParts.length < intentDetails[0]?.partQuantity){
        setIsQuantity(true);
        setUserError('Scanned quantity cannot be less than Requested quantity');
      }
      else {
        setIsQuantity(false);
        let data: any = {};
          data["intentId"] = Number(id),
          data["intentDetailId"] = Number(sub_id),
          data["barCodes"] = scanParts.map((eachSku: any) => {
            return {
              "barCode": eachSku.number
            };
          }
          );
        if (intentDetail?.status == INTENT_STATUS.COURIERED || franchiseLogin ) {
          dispatch(receiveIntent(data));
        } else if(!franchiseLogin) {
          dispatch(dispatchIntent(data));
        }
      }
    } else {
      setIsQuantity(true);
      setUserError('Scanned parts Cannot be empty');
    }
  };

  return (
    <div className="pagescontent " id="view-dealer">
      {visible && dialogContainer(intentError)}
      {error && dialogContainerError(error)}
      {openScanner && scannerContainer()}
      <ArchiveModal
        show={generateSKUModal}
        message="The system will automatically generate SKU. when parts cannot be scanned."
        deleteLabel="Yes"
        closeLabel="No"
        onDeleteClick={() => {
          setGenerateSKUModal(false);
        }}
        onCloseClick={() => {
          setGenerateSKUModal(false);
        }}
      />
      <BreadcrumbWithFilter
        title="Indent"
        titlePath={`${ROUTER.INTENT_EDIT}/${id}`}
        breadcrumbItem="Scan parts"
        isShowCreate={false}
        isShowFilter={false}
        filter={false}
        isShowFirstButton={false}
      />
      <Row>
        <div className="view">
          <div className="general-details">
            <div className="dis-flex-btw">

              <b>Indent Details</b>

              <div className={`intent-status ${intentDetail?.status == INTENT_STATUS.APPROVED ? 'grn-color' : ""} ${intentDetail?.status == INTENT_STATUS.REJECTED ? 'red-color' : ""}`}>{intentDetail?.status}</div>
            </div>
          </div>

          <div className="subview">
            <Row>
              {
                userView.generalDetails.map((data, index) => {
                  return (
                    <Col lg={3} key={index} className={`${index >= 4 ? "mt-4" : "mt-1"}`}>
                      <div className='mb-2'>{data.name}</div>
                      <b>{data.value ? data.value : <div className="none-text">-</div>}</b>
                    </Col>
                  );
                })
              }
            </Row>
          </div>
        </div>
        {/* <div className="view">
          <div className="general-details">
            <b>Parts Details</b>
          </div>
          <div className="subview">
            <Row>
              {
                userView.intentDetails.map((data, index) => {
                  return (
                    <Col lg={3} key={index} className={`${index >= 4 ? "mt-4" : "mt-1"}`}>
                      <div className='mb-2'>{data.name}</div>
                      <b>{data.value ? data.value : <div className="none-text">-</div>}</b>
                    </Col>
                  )
                })
              }
            </Row>
          </div>
        </div> */}
        <div className="view">
          <div className="general-details d-flex justify-content-between align-items-center">
            <b>Scan SKU</b>
            <Formik
                    onSubmit={(values, { resetForm }) => handleSubmit(values, { resetForm })}
                    initialValues={initialvalue}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      resetForm,
                      setFieldValue,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <div className="custom-scan-input" style={{width:"300px"}}>
                            <div className="inp-icon scan-here">
                              <FeatherIcon icon="search" className="icon-lg left-icon" />
                              <input
                                className="icon-input"
                                type="text"
                                placeholder="Scan SKU here"
                                // onBlur={handleBlur}
                                disabled={false}
                                name="scanSku"
                                value={values.scanSku}
                                required={false}
                              onKeyDown={(e) => handleKeyPress(e, values,setFieldValue)}
                              />
                              <FeatherIcon icon="camera" className="icon-lg right-icon pointer" onClick={openScannerHandler}/>
                            </div>
                          </div>
                          
                        </Form>
                      );
                    }}
                  </Formik>
          </div>
          <div className="subview">
            <Row>
              {/* <Col lg={6}>
                <div className="mt-3">
                  <div className="custom-label">
                    <b>Scan Parts</b>
                  </div>
          
                </div>
              </Col> */}
              <Col lg={12} className='mb-2'>
                <div className="mt-3">
                  <div className="custom-label scanpart">
                    <b>Scanned Parts Details <span> {scanParts?.length > 0 ? ` - ${scanParts?.length}` : ''}</span></b>
                  </div>
                  <div className="p-chips p-component p-inputwrapper p-inputwrapper-filled custom-chip">
                    <ul className="p-inputtext p-chips-multiple-container p-variant-filled">
                      {scanParts.length > 0 ? (
                        scanParts.map((item: any, index: number) => (
                          <li key={index} className="p-chips-token">
                            <span className="p-chips-token-label">{hasFranchiseRoleHandler() ? item?.number : `${item?.number} :- ${item?.partDescription}`}</span>
                            <FeatherIcon icon="x-circle" className="p-icon p-chips-token-icon" onClick={() => handleRemoveParts(index)} />
                          </li>
                        ))
                      ) :
                        (
                          <div className="p-chips-input-token">
                            <input type="text" readOnly /></div>
                        )}
                    </ul>
                  </div>
                  {isQuantity &&
                            <div className="text-danger"><small>{userError}</small></div>}
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-2">
              <Button
                className="secondary-btn me-2"
                label="Clear"
                type="button"
                onClick={() => handleClearParts()}
              />
              <Button
                className="btn-primary me-2 btn-primary-shadow"
                label={(intentDetail?.status == INTENT_STATUS.APPROVED || intentDetail?.status == INTENT_STATUS.PENDING_DISPATCH) ? "Save & Deliver" : "Save & Receive"}
                type="button"
                onClick={() => handleGenerateSKU()}
              />
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default StockScannerComponent;