import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip, Input } from "reactstrap";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { CurrencyFormatter, formatDateMEL, saveAsExcelFile } from "src/helpers/common_helpers";

import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import EmailBodyTemplate from "src/components/Common/EmailBodyTemplate/EmailBodyTemplate";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import TooltipMEL from "src/components/UI/Tooltip";
import { RootReducerState } from "src/store/reducers";
import { clearPurchaseOrder, deletePurchaseOrderFromList, getDropdownForPurchase, getPurchaseOrderList, resetPurchaseOrder } from "src/store/actions";
import { purchaseOrderList } from "src/models/pages/purchaseOrders";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { currency } from "src/constants/purchaseOrderFormFields";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { Link } from "react-router-dom";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";


const purchaseListConverson = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['PoNumber'] = eachValue?.poNumber;
    data['generatedDate'] = eachValue?.generatedDate;
    data['BuyingCurrency'] = eachValue?.buyingCurrency;
    data['SupplierId'] = eachValue?.supplierId;
    data['noOfItems'] = eachValue?.noOfItems;
    data['totalPrice'] = eachValue?.totalPrice;
    data['locked'] = eachValue?.locked;
    data['isDeleted'] = eachValue?.isDeleted;
    data['supplierName'] = eachValue?.supplierName;
    return data;
  });
  return modifeidValue;
};

const ACTIVE = "Active";
const DELETED = "Deleted";
const EMAIL_VERIFIED = "Email verified";
const LOCKED = "Locked";
const UNLOCKED = "Unlocked";


export default function PurchaseOrders() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.purchaseOrderReducer.purchaseList);
  const { error, message, supplierList, paginatorCount } = useSelector(
    (state: RootReducerState) => state.purchaseOrderReducer
  ) || [];
  const modifiedValues: any[] = purchaseListConverson(values);
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.purchaseOrderReducer.loading);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<any | null>(null);

  const filterDetails: DataTableFilterMeta = {
    SupplierId: { value: "", matchMode: "contains" },
    PoNumber: { value: "", matchMode: "contains" },
    generatedDate: { value: "", matchMode: "contains" },
    GeneratedStartDate: { value: "", matchMode: "contains" },
    GeneratedEndDate: { value: "", matchMode: "contains" },
    BuyingCurrency: { value: "", matchMode: "contains" },
    Locked: { value: null, matchMode: "contains" },

    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "PoNumber",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.PURCHASE_ORDER_CREATE);
  };

  const viewHandler = (rowData: any) => {

    navigate(`${ROUTER.PURCHASE_ORDER_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: any) => {

    navigate(`${ROUTER.PURCHASE_ORDER_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: any) => {

    setDeleteModal(true);
    setRowData(rowData);
  };


  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetPurchaseOrder());
      dispatch(getDropdownForPurchase())
      dispatch(getPurchaseOrderList(lazyState));

    }
  }, [error, message]);

  const emailBodyTemplate = (rowData: any, field: string) => {

    let data = rowData[field] as string;
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const supplierBodyTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.SUPPLIER_VIEW}/${rowData['SupplierId']}`}>{rowData['supplierName']}</Link>
    );
  };
  
  const hidePopup = () => {
		setVisible(false);
    dispatch(resetPurchaseOrder())
	};

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    </>
    );
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);

  const actionBodyTemplate = (rowData: purchaseOrderList, value: string) => {
    return (
      <>
        {
          (!rowData.isDeleted && !rowData.locked) ? (
            <ColumnAction
              displayData={rowData[value] as string}

              isEdit={true}
              isDelete={true}
              isView={true}
              onViewClick={() => viewHandler(rowData)}
              onEditClick={() => editHandler(rowData)}
              onDeleteClick={() => deleteHandler(rowData)}
            />
            ) :  (  (rowData.locked && !rowData.isDeleted) ?
            
            (
              <ColumnAction
                displayData={rowData[value] as string}
                undo={rowData.isDeleted ? true : false}
                isView={true}
                onViewClick={() => viewHandler(rowData)}
              />
            )
            :
                  (
                    <ColumnAction
                      displayData={rowData[value] as string}
                      undo={rowData.isDeleted ? true : false}
                      onDeleteClick={() => deleteHandler(rowData)}
                    />
                  ))
        }
      </>
    );
  };



  const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHghlighter status={status} />
    );

  };
  const currencyBodyTemplate = (rowData: any, field: string, code: string) => {
    let amount = rowData[field];
    let currencyCode = rowData[code];
    return (<div >{CurrencyFormatter({ value: amount, currencyCode: currencyCode })}</div>);
  };


  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date" required value={options?.value?.startDate || ""} max={options?.value?.endDate ? options?.value?.endDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['startDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date" required value={options?.value?.endDate || ""} min={options?.value?.startDate ? options?.value?.startDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['endDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
    </>)
  };

  // const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
  //   return <InputNumber value={options.value} onChange={(e: any) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
  // };

  const currencyFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={currency}
        name="buyingCurrency"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
        filter
      />
    </>)
  };







  const supplierFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(supplierList?.length > 0) ? (supplierList?.map((eachSupplier: any) => {
          let data: any = {};
          data['label'] = eachSupplier?.name;
          data['value'] = eachSupplier?.id;
          return data;
        })).sort((a, b) => {
          const labelA =a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="supplierId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>)
  };






  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
    { label: "Locked", value: "Locked" },
    { label: "Unlocked", value: "Unlocked" },
  ];

  const headerTemplate =(val:any)=>{
    return(
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end"style={{width:"100px"}}  >
        <div className="align-right">
          {val}
        </div>
        </div>
      </div>
    )
    }

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      frozen : true,
    },
    {
      field: "PoNumber",
      sortable: true,
      header: "Number",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "PoNumber"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "generatedDate",
      sortable: true,
      header: "Generated Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData) => formatDateMEL(rowData.generatedDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "BuyingCurrency",
      sortable: true,
      header: "Buying Currency",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      filterElement: currencyFilterTemplate
    },

    {
      field: "SupplierId",
      sortable: true,
      header: "Supplier",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData) => supplierBodyTemplate(rowData, "SupplierId"),
      filterElement: supplierFilterTemplate
    },
    {
      field: "noOfItems",
      sortable: false,
      header: "Items",
      filter: false,
      showFilterMenu: false,
      // headerStyle: { whiteSpace: "nowrap",display:"flex",justifyContent:"end",height:"40px" },
      // className: "align-right pr-2",
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
      // body:(rowData:any)=>headerTemplate(rowData.noOfItems),
      tooltip:true,
      tooltipTitle:"No of Items",
    },
    {
      field: "totalPrice",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap",display:"flex",justifyContent:"end",height:"40px" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
      body: (rowData) => currencyBodyTemplate(rowData, 'totalPrice', 'BuyingCurrency'),
    },
    {
      field: "locked",
      sortable: false,
      header: "Locked",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'locked'),
      isDefault: false,
      isStatic: false,
    }
    // {
    //   field: "action",
    //   sortable: false,
    //   header: "Action",
    //   filter: false,
    //   showFilterMenu: false,
    //   body: actionBodyTemplate,
    //   headerStyle: { whiteSpace: "nowrap" },
    // },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
  }, [supplierList])

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachPurchase) => {
      const { id, BuyingCurrency, PoNumber, SupplierId, generatedDate, isDeleted, locked, noOfItems, supplierName, totalPrice } = eachPurchase;
      let data: any = {};
      data['Number'] = PoNumber;
      data['Generated Date'] = generatedDate;
      data['Buying Currency'] = BuyingCurrency;
      data['Supplier'] = supplierName;
      data['No of Items'] = noOfItems;
      data['Total'] = totalPrice;
      data['Locked'] = locked;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        id: data.id,
        PoNumber: data.PoNumber,
        BuyingCurrency: data.BuyingCurrency,
        SupplierId: data?.supplierName,
        generatedDate: formatDateMEL(data?.generatedDate),
        locked: data?.locked ? "YES" : "NO",
        noOfItems: data?.noOfItems,
        totalPrice: data?.totalPrice,
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default(0, 0);
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    try {
      setPdfLoading(true)
      const response = await API.getPurchaseOrderListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      let resp=await purchaseListConverson(response?.data?.items)
      
     if(isPdf){

       saveDataAsPdf(resp, columns, "PurchaseOrders");
     }else{
      saveDataAsExcel(resp, "PurchaseOrders");
     }
     setPdfLoading(false)
    } catch (error:any) {
      showToast(error?.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "purchaseOrders");
    fetchData(false)

  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "purchaseOrders");
    fetchData(true)

  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.generatedDate) {
      modifiedLazyState.filters.generatedDate.value = "";
    }
    dispatch(getDropdownForPurchase())
    dispatch(getPurchaseOrderList(modifiedLazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedDealers, setSelectedDealers] = useState<any[]>([]);


  const onSelectionChange = (event: any) => {
    const value = event.value.filter((eachValue: any) => !eachValue.locked);
    setSelectedDealers(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      if (selectedStatus == LOCKED) {
        setSelectAll(false);
        setSelectedDealers([]);
      } else if (selectedStatus == ACTIVE) {
        let len = modifiedValues?.length;
        let filterLen = modifiedValues?.filter((eachValue: any) => !eachValue.locked).length;
        if (len === filterLen) {
          setSelectAll(true);
          setSelectedDealers(modifiedValues);
        } else {
          setSelectAll(false);
          setSelectedDealers(modifiedValues?.filter((eachValue: any) => !eachValue.locked));
        }
      }
      else {
        setSelectAll(true);
        setSelectedDealers(modifiedValues);
      }
    } else {
      setSelectAll(false);
      setSelectedDealers([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['GeneratedStartDate']['value'] = event?.filters?.generatedDate?.value?.startDate ? event?.filters?.generatedDate?.value?.startDate : ''
    event['filters']['GeneratedEndDate']['value'] = event?.filters?.generatedDate?.value?.endDate ? event?.filters?.generatedDate?.value?.endDate : ''

    setLazyState(event);
  };



  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },

        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },

        },
      }));
    }
    else if (formValue == LOCKED) {

      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: "true", matchMode: "contains" },

        },
      }));
    } else if (formValue == UNLOCKED) {

      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: "false", matchMode: "contains" },

        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedDealers([]);
    callbackFunction(lazyState);
    return () => {
      dispatch(clearPurchaseOrder());
    };
  }, [lazyState]);



  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"],
      undo: rowData['isDeleted'] ?? false,
      lazyState,
    };
    dispatch(deletePurchaseOrderFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedDealers([]);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedDealers([]);
    setArchiveModal(false);
  };


  const defaultColumns = {
    ...columns[0]
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedDealers(modifiedValues);
    }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedDealers([]);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>
        Purchase orders List
      </div>
      {/* <BreadcrumbWithFilter
        title="Admin"
        breadcrumbItem="Dealers"
        isShowFilter={false}
        isShowCreate={false}
        selectedCustomers={selectedDealers}
        bulkActionSatusTitle="Dealers"
        showBorderRight={true}
      /> */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedDealers.length == 0 && selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedDealers.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
              <FeatherIcon icon="archive" className="mr-1" />
              Archive
            </Button>
          </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                  {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedDealers.length > 0 && <BulkActionStatus count={selectedDealers.length} title="Purchase Order "></BulkActionStatus>}
              </motion.div>
            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
      <div style={{ display: "flex" }}>
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown showClear={false} value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label"
            placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>
        <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="" />
        </Button>
        </div>
        <div className="reponsive_position">
        <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
          Add Purchase Order
        </Button>
        </div>
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;


  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  // const isSelectable = (data: any) => {
  //   return !data?.locked;
  // }

  // const rowClassName = (data: any) => (isSelectable(data) ? '' : 'p-disabled');



  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedDealers?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedDealers([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedDealers.length > 0) {
              for (let i = 0; i < selectedDealers.length; i++) {
                handleDeleteUsers(selectedDealers[i], selectedDealers.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these purchase order?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedDealers([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row id="export-search-clear">
                        <Col xs={12} lg={7} >

                        </Col>
                        <Col xs={12} lg={5} className="select-clear-search-container d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>

                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}

        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          value={modifiedValues}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying purchase orders  {last} records of {totalRecords} in total"
          selection={selectedDealers}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
          selectionMode="checkbox"
          // rowClassName={rowClassName}
        >
        </DataTableWrapper>
      </div>
    </>
  );
}
