import React, { useRef, useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { useToast } from "src/components/Common/ReactToaster";
import { Button, Input, UncontrolledTooltip } from "reactstrap";
import { AnimatePresence, motion } from "framer-motion";
import FeatherIcon from "feather-icons-react";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import { Option } from "src/models/components/inputWrapperModel";
import { Columns, LazyTableState } from "src/models/components/dataTableWrapperModel";
import { RootReducerState } from "src/store/reducers";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { formatDateMEL, CurrencyFormatter, isJobsheetCreateAllow, saveAsExcelFile, hasDealerRoleHandler, hasFranchiseRoleHandler, hasCustomerRoleHandler, hasAdminOrEmployeeRoleHandler, hasFranchiseSubuserRoleHandler, hasDealerSubuserRoleHandler, jobLatModifiedResponse, hasManufacturerRoleHandler } from "src/helpers/common_helpers";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { ROUTER } from "src/constants/routes";
import DeleteModal from "../../Calendar/DeleteModal";
import StatusHighlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import {
  checkDealerHaveFeedbackPendingLoading,
  clearJobsheet,
  deleteJobsheetsFromList,
  employeeDropdownForJobLoading,
  getAudioModelLoading,
  getcustomerdropdownListLoading,
  getJobsheetNumberLoading,
  getJobsheetsList,
  getTechnicianDropdownLoading,
  getVehiclebrandDropdownLoading,
  jobDealerdropdownListLoading,
  JobsheetDetailsReset,
  setJobsheetsStatus,
} from "src/store/actions";
import * as Yup from "yup";
import TooltipMEL from "src/components/UI/Tooltip";
import {
  ACTIVE,
  NEW_JOB,
  EXCHANGE_GENERATED,
  JOB_SHEET_CREATED,
  REPLACEMENT_SYSTEM_DISPATCHED,
  ASSIGNED_TO_ESTIMATOR,
  JOB_ESTIMATED_AWAITING_APPROVAL,
  JOB_ASSIGNABLE_TO_TECHNICIAN,
  ASSIGNED_TO_TECHNICIAN,
  TECHNICIAN_COMPLETED_JOB,
  READY_TO_INVOICE,
  READY_FOR_INSPECTION,
  INVOICED_JOB,
  JOB_CLOSED,
  JOB_CANCELLED,
  IN_PENDING,
  IS_EXCHANGE,
  FACULTY_SYATEM_AVAILABLE,
  PERMIT_FORM_PENDING,
  FEEDBACK_PENDING,
  SERVICE_REQUEST_GENERATED,
  FRANCHISE_JOB_CLOSED,
  ESTIMATION_REJECTED,
  IS_REMOTE_SERIVICE,
} from "src/constants/JobSheetStatus";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { DELETED } from "src/constants/commonConstants";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import { isCreateFeedback, isShowAdditionalExpense, isShowDelete, isShowEdit } from "src/helpers/jobsheet_validation";
import { Link } from "react-router-dom";
import FeedBackPendingStatus from "src/components/UI/JobSheetTabs/FeedBackPendingStatus";
import { API } from "src/util/api";
import { APPENV_URL } from "src/util/appUrlEnv";
import { MultiSelect } from "primereact/multiselect";
import { Chips } from "primereact/chips";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
import HtmltoPlainText from "src/components/atoms/HtmltoPlainText";


const jobSheetListConversion = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['Number'] = eachValue?.number;
    data['AudioModel'] = eachValue?.audioModel;
    data['DealerId'] = eachValue?.dealerName;
    data['CustomerId'] = eachValue?.customerName;
    data['EstimatorId'] = eachValue?.estimatorName;
    data['TechnicianId'] = eachValue?.technicianName;
    data['status'] = eachValue?.status;
    data['isFeedbackPending'] = eachValue?.isFeedbackPending;
    data['WarrantyStatus'] = eachValue?.warrantyStatus;
    data['VinNumber'] = eachValue?.vinNumber;
    data['VehicleRegistrationNumber'] = eachValue?.vehicleRegistrationNumber;
    data['VehicleBrandId'] = eachValue?.vehicleBrandName;
    data['vehicleModel'] = eachValue?.vehicleModel;
    data['ExchangeAudioSerialNumber'] = eachValue?.exchangeAudioSerialNumber;
    data['AudioSerialNumber'] = eachValue?.audioSerialNumber;
    data['AudioPartNumber'] = eachValue?.audioPartNumber;
    data['creationTime'] = eachValue?.creationTime;
    data['requestDate'] = eachValue?.requestDate;
    data['dateOfSale'] = eachValue?.dateOfSale;
    data['manufacturedDate'] = eachValue?.manufacturedDate;
    data['failedDate'] = eachValue?.failedDate;
    data['lastRepairDate'] = eachValue?.lastRepairDate;
    data['isExchange'] = eachValue?.isExchange;
    data['isRemoteService'] = eachValue?.isRemoteService;
    data['isFaultySystemAvailable'] = eachValue?.isFaultySystemAvailable;
    data['serviceCharge'] = eachValue?.serviceCharge;
    data['totalEstimate'] = eachValue?.totalEstimate;
    data['ServiceRequestNumber'] = eachValue?.serviceRequestNumber;
    data['tat'] = eachValue?.tat;
    data['IsDeleted'] = eachValue?.isDeleted;
    data['audioModelId'] = eachValue?.audioModelId;
    data['dealerId'] = eachValue?.dealerId;
    data['customerId'] = eachValue?.customerId;
    data['vehicleBrandId'] = eachValue?.vehicleBrandId;
    data['serviceRequestNumber'] = eachValue?.serviceRequestNumber;
    data['serviceRequestGeneratedDate'] = eachValue?.serviceRequestGeneratedDate;
    data['technicianUserType'] = eachValue?.technicianUserType;
    data['jobPrefix'] = eachValue?.jobPrefix ? eachValue?.jobPrefix : "-";
    data['lastModificationTime'] = eachValue?.lastModificationTime ? eachValue?.lastModificationTime : "";
    data['totalTat'] = eachValue?.totalTat ? eachValue?.totalTat : "";
    data['totalTatDuration'] = eachValue?.totalTat ? eachValue?.totalTat : 0;
    data['melTat'] = eachValue?.melTat ? eachValue?.melTat : 0;
    data['otherTat'] = eachValue?.otherTat ? eachValue?.otherTat : 0;
    data['supplierReferenceNumber'] = eachValue?.supplierReferenceNumber ? eachValue?.supplierReferenceNumber : "";
    data['priority'] = eachValue?.priority ? eachValue?.priority : false;
    data['customerConcern'] = eachValue?.customerConcern ?  eachValue?.customerConcern : "";
    return data;
  });
  return modifeidValue;
};

export default function JobSheets() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedJobSheets, setSelectedJobSheets] = useState<any>([]);
  const isInitialRender = useRef(true);
  const [checked, setChecked] = useState(false);
  const dt = useRef<DataTable<any>>(null);
  const { error, loading, message, jobListLoading, customerList, dealerList, paginationCount, technicianList, estimatorList, employeeList, vehicleBrandDropdownListPage, feedbackPendingOrNot, jobsheetNumberFilterData, audioModelFilterData } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const { values, totalRecords } = useSelector((state: any) => state.jobsheetReducer.jobsheetList);
  const modifiedValues: any[] = jobSheetListConversion(values ? values : []);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowData, setRowData] = useState<any | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [feedbackPendingWarning, setFeedbackPendingWarning] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackDetails, setFeedbackDetails] = useState([]);
  const isFranshieLogin = hasFranchiseRoleHandler();
  const isCustomerLogin = hasCustomerRoleHandler();
  let currentUser = JSON.parse(localStorage.getItem('authUser') ? localStorage.getItem('authUser') as string : "");
  let currentUserUid = currentUser?.uid;



  const [selectedStatus, setselectedStatus] = useState((status == "Feedback_Pending") ? FEEDBACK_PENDING : ACTIVE);

  const DealerId: any = useMemo(() => {
    if (hasDealerRoleHandler()) {
      let dealerUser = JSON.parse(localStorage.getItem('authUser') ? localStorage.getItem('authUser') as string : "");
      let dealerUserId = dealerUser.uid;
      let DealerId = dealerList.filter((eachDealer: any) => eachDealer.userId == dealerUserId)?.[0]?.id;
      return DealerId;
    }
    return "";
  }, [dealerList]);

  const CustomerId: any = useMemo(() => {
    if (hasDealerRoleHandler()) {
      let customerUser = JSON.parse(localStorage.getItem('authUser') ? localStorage.getItem('authUser') as string : "");
      let customerUserId = customerUser.uid;
      let DealerId = customerList.filter((eachDealer: any) => eachDealer.userId == customerUserId)?.[0]?.id;
      return DealerId;
    }
    return "";
  }, [customerList]);


  const [technicianId, setTechnicianId] = useState<any>("");

  const [customerId, setCustomerId] = useState<any>("");


  useEffect(() => {
    if (hasFranchiseRoleHandler()) {
      let user = JSON.parse(localStorage.getItem('authUser') || "null");
      let userId = user?.uid ? user?.uid : 0;
      let techinicianListLength = technicianList.filter((eachTechnician: any) => eachTechnician.userId == userId).length;
      let employeeListLength = employeeList.filter((eachTechnician: any) => eachTechnician.userId == userId).length;
      if (techinicianListLength) {
        let id = technicianList.filter((eachTechnician: any) => eachTechnician.userId == userId)?.[0]?.id;
        if (id >= 0) {
          setTechnicianId(id);
        }
      }
      if (employeeListLength) {
        let id = employeeList.filter((eachTechnician: any) => eachTechnician.userId == userId)?.[0]?.id;
        if (id >= 0) {
          setTechnicianId(id);
        }
      }
    }
  }, [technicianList, employeeList]);

  useEffect(() => {
    if (hasCustomerRoleHandler()) {
      let user = JSON.parse(localStorage.getItem('authUser') || "null");
      let userId = user?.uid ? user?.uid : 0;
      let customerListLength = customerList.filter((eachTechnician: any) => eachTechnician.userId == userId).length;
      if (customerListLength) {
        let id = customerList.filter((eachTechnician: any) => eachTechnician.userId == userId)?.[0]?.id;
        if (id >= 0) {
          setCustomerId(id);
        }
      }
    }
  }, [customerList]);




  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || jobListLoading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, jobListLoading, pdfLoading]);


  // useEffect(() => {
  //   if (jobsheetFeedbackList?.length > 0) {
  //     if (jobsheetFeedbackList?.length > 0 && jobsheetFeedbackList[0]?.isFeedbackPending == true && hasDealerRoleHandler()) {
  //       setFeedbackPendingWarning(true)
  //     } else if (jobsheetFeedbackList?.length > 0 && jobsheetFeedbackList[0]?.isFeedbackPending == true && hasCustomerRoleHandler()) {
  //       setFeedbackPendingWarning(true)
  //       // showToast("Awaiting feedback for one or more previous job sheets", { type: "warning" });
  //     } else {

  //     }
  //   }
  // }, [jobsheetFeedbackList])


  const filterDetails: DataTableFilterMeta = {
    Number: { value: "", matchMode: "contains" },
    VinNumber: { value: "", matchMode: "contains" },
    VehicleRegistrationNumber: { value: "", matchMode: "contains" },
    afcsStartDate: { value: "", matchMode: "contains" },
    AudioPartNumber: { value: "", matchMode: "contains" },
    AudioSerialNumber: { value: "", matchMode: "contains" },
    AudioModel: { value: "", matchMode: "contains" },
    AudioModelId: { value: "", matchMode: "contains" },
    VehicleBrandId: { value: "", matchMode: "contains" },
    WarrantyStatus: { value: "", matchMode: "contains" },
    serviceRequestNumber: { value: "", matchMode: "contains" },
    ExchangeAudioSerialNumber: { value: "", matchMode: "contains" },
    DealerId: { value: hasDealerRoleHandler() ? (DealerId ? DealerId : "") : "", matchMode: "contains" },
    CustomerId: { value: "", matchMode: "contains" },
    EstimatorId: { value: "", matchMode: "contains" },
    TechnicianId: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
    isActive: { value: "true", matchMode: "contains" },
    RequestStartDate: { value: "", matchMode: "contains" },
    RequestEndDate: { value: "", matchMode: "contains" },
    DateOfSaleStart: { value: "", matchMode: "contains" },
    DateOfSaleEnd: { value: "", matchMode: "contains" },
    ManufacturedStartDate: { value: "", matchMode: "contains" },
    ManufacturedEndDate: { value: "", matchMode: "contains" },
    FailedStartDate: { value: "", matchMode: "contains" },
    FailedEndDate: { value: "", matchMode: "contains" },
    LastRepairStartDate: { value: "", matchMode: "contains" },
    LastRepairEndDate: { value: "", matchMode: "contains" },
    Status: { value: "", matchMode: "contains" },
    requestDate: { value: "", matchMode: "contains" },
    dateOfSale: { value: "", matchMode: "contains" },
    manufacturedDate: { value: "", matchMode: "contains" },
    failedDate: { value: "", matchMode: "contains" },
    lastRepairDate: { value: "", matchMode: "contains" },
    FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
    IsExchange: { value: "", matchMode: "contains" },
    IsRemoteService: {value: "", matchMode: "contains"},
    InPending: { value: "", matchMode: "contains" },
    IsFaultySystemAvailable: { value: "", matchMode: "contains" },
    PermitFormPending: { value: "", matchMode: "contains" },
    FeedBackPending: { value: (status == "Feedback_Pending") ? "true" : "", matchMode: "contains" },
    isExchangeGenerated: { value: "", matchMode: "contains" },
    TechnicianGUId: { value: '', matchMode: "contains" },
    TechnicianUserType: { value: "", matchMode: "contains" },
    jobPrefix: { value: "", matchMode: "contains" },
    vehicleModel: { value: "", matchMode: "contains" },
    serviceCharge: { value: "", matchMode: "contains" },

  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };

  const [lazyState, setLazyState] = useState<any>(initialLazyState);

  useEffect(() => {
    callbackFunction(lazyState);
  }, [DealerId, technicianId, customerId]);


  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          // isActive: { value: "true", matchMode: "contains" },
          // IsDeleted: { value: "false", matchMode: "contains" },
          // Status: { value: "", matchMode: "contains" },
          // FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          // IsExchange: { value: null, matchMode: "contains" },
          // IsRemoteService: {value: null, matchMode: "contains"},
          // IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          // InPending: { value: null, matchMode: "contains" },
          // PermitFormPending: { value: null, matchMode: "contains" },
          // FeedBackPending: { value: null, matchMode: "contains" },
          // isExchangeGenerated: { value: null, matchMode: "contains" },
          TechnicianUserType: { value: checked ? "Franchise" : "", matchMode: "contains" },
        },
      }));
    }
  }, [checked]);



  const initialValues = {
    status: "",
  };


  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date"
        required
        name={options?.value?.startDate}
        value={options?.value?.startDate || ""}
        max={options?.value?.endDate ? options?.value?.endDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['startDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }}
      />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date"
        required
        name={options?.value?.endDate}
        value={options?.value?.endDate || ""}
        min={options?.value?.startDate ? options?.value?.startDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['endDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }}
      />
    </>);
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.requestDate.value != "") {
      modifiedLazyState.filters.requestDate.value = "";
    } else if (modifiedLazyState.filters && modifiedLazyState.filters.dateOfSale.value != "" && modifiedLazyState.filters.dateOfSale.value != null) {
      modifiedLazyState.filters.dateOfSale.value = "";
    } else if (modifiedLazyState.filters && modifiedLazyState.filters.manufacturedDate.value != "" && modifiedLazyState.filters.manufacturedDate.value != null) {
      modifiedLazyState.filters.manufacturedDate.value = "";
    } else if (modifiedLazyState.filters && modifiedLazyState.filters.failedDate.value != "" && modifiedLazyState.filters.failedDate.value != null) {
      modifiedLazyState.filters.failedDate.value = "";
    } else if (modifiedLazyState.filters && modifiedLazyState.filters.lastRepairDate.value != "" && modifiedLazyState.filters.lastRepairDate.value != null) {
      modifiedLazyState.filters.lastRepairDate.value = "";
    }
    if (hasDealerRoleHandler()) {
      modifiedLazyState.filters.DealerId = +DealerId >= 0 ? { value: DealerId, matchMode: 'contains' } : { value: "", matchMode: 'contains' };
    }

    if (hasFranchiseRoleHandler()) {
      modifiedLazyState.filters.TechnicianId = +technicianId >= 0 ? { value: technicianId, matchMode: 'contains' } : { value: "", matchMode: 'contains' };
    }

    if (hasCustomerRoleHandler()) {
      modifiedLazyState.filters.CustomerId = +customerId >= 0 ? { value: customerId, matchMode: 'contains' } : { value: "", matchMode: 'contains' };
    }
    if (hasManufacturerRoleHandler()) {
      modifiedLazyState.filters.WarrantyStatus =  { value: "In Warranty", matchMode: 'contains' } ;
    }

    dispatch(getJobsheetsList(lazyState));
  };

  const employeeDropList = employeeList?.length > 0 ? (employeeList.map((eachEmployee: any) => {
    let data: any = {};
    data['value'] = eachEmployee.userId;
    data['label'] = eachEmployee.name;
    data['id'] = eachEmployee.id;
    return data;
  })) : [];

  const userOptions: Option[] = [
    { label: "Employee", value: "Employee" },
    { label: "Franchise", value: "Franchise" },
  ];

  const onCreateClickHandler = async () => {
    const loginToken = localStorage.getItem('token');
    if (hasDealerRoleHandler() && feedbackPendingOrNot == true) {
      try {
        const response = await fetch(APPENV_URL.jobServiceUrl + "/" + `job/paged?DealerId=${DealerId}&Status=${JOB_CLOSED}&FeedBackPending=${true}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${loginToken}`
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.items.length > 0 && data.items[0]?.isFeedbackPending == true) {
            setFeedbackDetails(data.items);
            setFeedbackText("dealer");
            setFeedbackPendingWarning(true);
          } else {
            navigate(ROUTER.JOB_SHEETS_CREATE);
          }
        } else {
          navigate(ROUTER.JOB_SHEETS_CREATE);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else if (hasCustomerRoleHandler() && feedbackPendingOrNot == true) {
      try {
        const response = await fetch(APPENV_URL.jobServiceUrl + "/" + `job/paged?CustomerId=${CustomerId}&Status=${JOB_CLOSED}&FeedBackPending=${true}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${loginToken}`
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.items.length > 0 && data.items[0]?.isFeedbackPending == true) {
            setFeedbackDetails(data.items);
            setFeedbackText("customer");
            setFeedbackPendingWarning(true);
          } else {
            navigate(ROUTER.JOB_SHEETS_CREATE);
          }
        } else {
          navigate(ROUTER.JOB_SHEETS_CREATE);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      navigate(ROUTER.JOB_SHEETS_CREATE);
    }
  };

  const viewHandler = (rowData: any) => {
    navigate(`${ROUTER.JOB_SHEETS_VIEW}/${rowData["id"]}`);
  };

  const AdditionalExpense = (rowData: any) => {
    navigate(`${ROUTER.ADDITIONAL_EXPENSE_GRID}/${rowData["id"]}`);
  };

  const editHandler = (rowData: any) => {
    navigate(`${ROUTER.JOB_SHEETS_EDIT}/${rowData["id"]}`);
  };

  const createFeedback = (rowData: any) => {
    navigate(`${ROUTER.FEEDBACK_CREATE}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: any) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          rowData.IsDeleted == true ? (
            <>
              <ColumnAction
                displayData={rowData[field] as string ? rowData[field] as string : <div className="empty-number-jobeheet"><span></span><span></span><span></span></div>}
                undo={(rowData.IsDeleted && hasAdminOrEmployeeRoleHandler()) ? true : false}
                onDeleteClick={() => deleteHandler(rowData)}
              />
            </>
          ) : (
            <>
              <ColumnAction
                displayData={rowData[field] as string ? rowData[field] as string : <div className="empty-number-jobeheet"><span></span><span></span><span></span></div>}
                isEdit={isShowEdit(rowData["status"], rowData["isFeedbackPending"]) ? true : false}
                isDelete={isShowDelete() ? true : false}
                isView={true}
                additionalExpense={(isShowAdditionalExpense(rowData["status"], rowData["technicianUserType"])) ? true : false}
                additionalExpenseClick={() => AdditionalExpense(rowData)}
                onViewClick={() => viewHandler(rowData)}
                onEditClick={() => {
                  if (isCreateFeedback(rowData["status"])) {
                    createFeedback(rowData);
                  } else {
                    editHandler(rowData);
                  }
                }}
                onDeleteClick={() => deleteHandler(rowData)}
              />
            </>
          )
        }
      </>
    );
  };

  const modificationTemplate = (rowData: any, field: string) => {
    return (
      <>
        {jobLatModifiedResponse(rowData[field])}
      </>
    );
  };

  const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );
  };

  const currencyBodyTemplate = (rowData: any, field: string, code: string) => {
    let amount = rowData[field];
    let currencyCode = 'INR';
    return (<div className="align-right">{CurrencyFormatter({ value: amount, currencyCode: currencyCode })}</div>);
  };



  let options: Option[] =
    hasDealerRoleHandler() ? [
      { label: "All", value: "All" },
      { label: "Active", value: "Active" },
      { label: "Deleted", value: "Deleted" },
      { label: "New Job", value: "New Job" },
      { label: "Service Request Generated", value: "Service Request Generated" },
      { label: "Exchange Generated", value: "Exchange Generated" },
      { label: "Feedback Pending", value: "Feedback Pending" },
    ] : (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) ? [
      { label: "All", value: "All" },
      { label: "Active", value: "Active" },
      { label: "Deleted", value: "Deleted" },
      { label: "Assigned To Technician", value: "Assigned To Technician" },
      { label: "Franchise Job Closed", value: "Franchise Job Closed" },
      { label: "In Pending", value: "In Pending" }
    ]
      : [
        { label: "All", value: "All" },
        { label: "Active", value: "Active" },
        { label: "Deleted", value: "Deleted" },
        { label: "New Job", value: "New Job" },
        { label: "Service Request Generated", value: "Service Request Generated" },
        { label: "Exchange Generated", value: "Exchange Generated" },
        { label: "Replacement System Dispatched", value: "Replacement System Dispatched" },
        { label: "Job Sheet Created", value: "Job Sheet Created" },
        { label: "Assigned To Estimator", value: "Assigned To Estimator" },
        { label: "Job Estimated Awaiting Approval", value: "Job Estimated Awaiting Approval" },
        { label: "Estimation Rejected", value: "Estimation Rejected" },
        { label: "Job Assignable To Technician", value: "Job Assignable To Technician" },
        { label: "Assigned To Technician", value: "Assigned To Technician" },
        { label: "Ready To Invoice", value: "Ready To Invoice" },
        { label: "Invoiced Job", value: "Invoiced Job" },
        { label: "Job Closed", value: "Job Closed" },
        { label: "Job Cancelled", value: "Job Cancelled" },
        { label: "Franchise Job Closed", value: "Franchise Job Closed" },
        { label: "In Pending", value: "In Pending" },
        { label: "Is Exchange", value: "Is Exchange" },
        { label: "Is Remote Service", value: "Is Remote Service" },
        { label: "Faulty System Available", value: "Faulty System Available" },
        { label: "Permit Form Pending", value: "Permit Form Pending" },
        { label: "Feedback Pending", value: "Feedback Pending" },
      ];

  if (hasCustomerRoleHandler()) {
    options = options.filter((eachStatus: any) => {
      return eachStatus.label != "Deleted";
    });
  }




  const customerFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={customerList?.length > 0 ? (customerList.map((eachCustomer: any) => {
          let data: any = {};
          data['value'] = eachCustomer.id;
          data['label'] = eachCustomer.name;
          return data;
        })).sort((a: any, b: any) => {
          const labelA = a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="buyingCurrency"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
        filter
        itemTemplate={(option: any) => (
          <div
            title={option.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.label}
          </div>
        )}
      />
    </>);
  };

  const technicianFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <>
        {/* <div className="mb-2">
          <label htmlFor="">Select User Type</label>
          <Dropdown
            placeholder="Select UserType"
            options={userOptions?.length > 0 ? (userOptions.map((eachEmployee: any) => {
              let data: any = {};
              data['value'] = eachEmployee.value;
              data['label'] = eachEmployee.label;
              return data;
            })).sort((a: any, b: any) => {
              const labelA = a.label.toUpperCase().trim();
              const labelB = b.label.toUpperCase().trim();
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            }) : []}
            name="buyingCurrency"
            optionLabel="label"
            onChange={(e: DropdownChangeEvent) => {
              options.filterCallback(e.value, options.index)
              return {};
            }}
            required={true}
            value={options.value}
            filter
          />
        </div> */}
        <div className="mb-2">
          {/* <label htmlFor="">Select User</label> */}
          <Dropdown
            placeholder="Select User"
            options={technicianList?.length > 0 ? (technicianList.map((eachCustomer: any) => {
              let data: any = {};
              data['value'] = eachCustomer.franchiseId;
              data['label'] = eachCustomer.name;
              return data;
            })).sort((a: any, b: any) => {
              const labelA = a.label.toUpperCase().trim();
              const labelB = b.label.toUpperCase().trim();
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            }) : []}
            name="buyingCurrency"
            optionLabel="label"
            onChange={(e: DropdownChangeEvent) => {
              options.filterCallback(e.value, options.index);
              return {};
            }}
            required={true}
            value={options.value}
            filter
            itemTemplate={(option: any) => (
              <div
                title={option.label}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {option.label}
              </div>
            )}
          />
        </div>
      </>);
  };
  const estimatorFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={employeeList?.length > 0 ? (employeeList.map((eachCustomer: any) => {
          let data: any = {};
          data['value'] = eachCustomer.id;
          data['label'] = eachCustomer.name;
          return data;
        })).sort((a: any, b: any) => {
          const labelA = a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="buyingCurrency"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
        filter        itemTemplate={(option: any) => (
          <div
            title={option.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.label}
          </div>
        )}
      />
    </>);
  };
  const dealerFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {


    return (<>
      <Dropdown
        options={dealerList?.length > 0 ? (dealerList.map((eachCustomer: any) => {
          let data: any = {};
          data['value'] = eachCustomer.id;
          data['label'] = eachCustomer.name;
          return data;
        })).sort((a: any, b: any) => {
          const labelA = a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="buyingCurrency"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
        filter
        itemTemplate={(option: any) => (
          <div
            title={option.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.label}
          </div>
        )}
      />
    </>);
  };
  const vehiclebrandFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={vehicleBrandDropdownListPage?.vehicleBrands?.length > 0 ? (vehicleBrandDropdownListPage?.vehicleBrands?.map((eachCustomer: any) => {
          let data: any = {};
          data['value'] = eachCustomer.id;
          data['label'] = eachCustomer.name;
          return data;
        })).sort((a: any, b: any) => {
          const labelA = a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="buyingCurrency"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value || ""}
        filter
        itemTemplate={(option: any) => (
          <div
            title={option.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.label}
          </div>
        )}
      />
    </>);
  };
  const warrantyStatusFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={[
          {label:"In Warranty",value:"In Warranty"},
          {label:"Out Warranty",value:"Out Warranty"},
          {label:"Warranty Unknown",value:"Warranty Unknown"},
          {label:"Manufacturer Warranty",value:"Manufacturer Warranty"},
        ]}
        name="buyingCurrency"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value || ""}
        filter
        itemTemplate={(option: any) => (
          <div
            title={option.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.label}
          </div>
        )}
      />
    </>);
  };

  const AudioModelTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          (isFranshieLogin || isCustomerLogin || hasDealerRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) ? rowData[field] : <Link to={`${ROUTER.AUDIO_MODELS_VIEW}/${rowData['audioModelId']}`}>{rowData[field]}</Link>
        }
      </>

    );
  };

  const JobsheetBodyTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.JOB_SHEETS_VIEW}/${rowData['jobId']}`}>{rowData[field]}</Link>
    );
  };

  const CustomerBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {/* {
          (isFranshieLogin || isCustomerLogin || hasDealerRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler()) ? rowData[field] : <Link to={`${ROUTER.CUSTOMERS_VIEW}/${rowData['customerId']}`}>{rowData[field]}</Link>
        } */}
        {rowData[field]}
      </>

    );
  };

  const DealerBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          (isFranshieLogin || isCustomerLogin || hasDealerRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) ? rowData[field] : <Link to={`${ROUTER.DEALERS_VIEW}/${rowData['dealerId']}`}>{rowData[field]}</Link>
        }
      </>
    );
  };

  const AudioBrandTemplate = (rowData: any, field: any) => {
    return (
      <>
        {
          (isFranshieLogin || hasFranchiseSubuserRoleHandler() || isCustomerLogin || hasDealerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) ? rowData[field] : <Link to={`${ROUTER.VEHICLE_BRANDS_VIEW}/${rowData['vehicleBrandId']}`}>{rowData[field]}</Link>
        }
      </>

    );
  };

  const convertTimesToMinutes = (minutes: any) => {
    let years = Math.floor(minutes / (60 * 24 * 365));
    minutes %= (60 * 24 * 365);
    let months = Math.floor(minutes / (60 * 24 * 30));
    minutes %= (60 * 24 * 30);
    let days = Math.floor(minutes / (60 * 24));
    minutes %= (60 * 24);
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let output = [];
    if (years > 0) {
      output.push(`${years} year${years > 1 ? 's' : ''}`);
    }
    if (months > 0) {
      output.push(`${months} month${months > 1 ? 's' : ''}`);
    }
    if (days > 0) {
      output.push(`${days} day${days > 1 ? 's' : ''}`);
    }
    if (hours > 0) {
      output.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    }
    if (remainingMinutes > 0) {
      output.push(`${remainingMinutes.toFixed(1)} minute${remainingMinutes > 1 ? 's' : ''}`);
    }
    if (output.length === 0) {
      return "less than a minute";
    } else {
      return (output.join(", "));
    }
  };
  const convertTimesToSeconds = (seconds: any) => {
    let years = Math.floor(seconds / (60 * 60 * 24 * 365));
    seconds %= (60 * 60 * 24 * 365);
    let months = Math.floor(seconds / (60 * 60 * 24 * 30));
    seconds %= (60 * 60 * 24 * 30);
    let days = Math.floor(seconds / (60 * 60 * 24));
    seconds %= (60 * 60 * 24);
    let hours = Math.floor(seconds / (60 * 60));
    seconds %= (60 * 60);
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    let output = [];
    if (years > 0) {
      output.push(`${years} year${years > 1 ? 's' : ''}`);
    }
    if (months > 0) {
      output.push(`${months} month${months > 1 ? 's' : ''}`);
    }
    if (days > 0) {
      output.push(`${days} day${days > 1 ? 's' : ''}`);
    }
    if (hours > 0) {
      output.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    }
    if (minutes > 0) {
      output.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
    }
    if (remainingSeconds > 0) {
      output.push(`${remainingSeconds.toFixed(1)} second${remainingSeconds > 1 ? 's' : ''}`);
    }
    if (output.length === 0) {
      return "less than a second";
    } else {
      return output.join(", ");
    }
  };
  
  const headerTemplate = (val: any): any => {
    return (
      convertTimesToMinutes(val)
    );
  };


  const currentHrsCalculate = (minutes: any) => {
    // const creationTime = minutes;
    // const currentTime : any = new Date();
    // const creationDate : any = new Date(creationTime);
    // const timeDifference = (currentTime - creationDate);
    // const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    // const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    // return (<div className="hours_to_creation">{hoursDifference}:{minutesDifference}hrs</div>);
    return (<div className="hours_to_creation">No action taken</div>);
  };

  const convertHours = (seconds: any, creationTime: any) => {

    if (seconds == 0) {
      return currentHrsCalculate(creationTime);
    }

    let minutes = ((seconds ? seconds : 0) + 1) / 60;
    let years = Math.floor(minutes / (60 * 24 * 365));
    minutes %= (60 * 24 * 365);
    let months = Math.floor(minutes / (60 * 24 * 30));
    minutes %= (60 * 24 * 30);
    let days = Math.floor(minutes / (60 * 24));
    minutes %= (60 * 24);
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let output = [];
    if (years > 0) {
      output.push(`${years}y`);
    }
    if (months > 0) {
      output.push(`${months}m`);
    }
    if (days > 0) {
      output.push(`${days}d`);
    }
    if (hours > 0) {
      output.push(`${hours}h`);
    }
    if (remainingMinutes > 0) {
      output.push(`${remainingMinutes.toFixed(1)}m`);
    }
    if (output.length === 0) {
      return "less than a minute";
    } else {
      return (output.join(", "));
    }


    // const hours = Math.floor(minutes / 60);
    // const remainingMinutes = minutes % 60;

    // let duration = '';
    // if (hours > 0) {
    //   // duration += `${hours} hour${hours !== 1 ? 's' : ''}`;
    //   duration += `${hours}h`;
    // }
    // // else {
    // //   duration += `${hours}Hr`;
    // // }
    // if (remainingMinutes > 0) {
    //   if (hours > 0) {
    //     duration += ' : ';
    //   }
    //   // duration += `${remainingMinutes} m${remainingMinutes !== 1 ? 's' : ''}`;
    //   duration += `${remainingMinutes}m`;
    // }

    // return duration;
  };

  const convertMinutes = (minutes: any) => {
    let years = Math.floor(minutes / (60 * 24 * 365));
    minutes %= (60 * 24 * 365);
    let months = Math.floor(minutes / (60 * 24 * 30));
    minutes %= (60 * 24 * 30);
    let days = Math.floor(minutes / (60 * 24));
    minutes %= (60 * 24);
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let output = [];
    if (years > 0) {
      output.push(`${years}y`);
    }
    if (months > 0) {
      output.push(`${months}m`);
    }
    if (days > 0) {
      output.push(`${days}d`);
    }
    if (hours > 0) {
      output.push(`${hours}h`);
    }
    if (remainingMinutes > 0) {
      output.push(`${remainingMinutes.toFixed(1)}m`);
    }
    if (output.length === 0) {
      return "less than a minute";
    } else {
      return (output.join(", "));
    }
  };

  const newAddedColumn = [
    {
      field: "serviceRequestGeneratedDate",
      sortable: true,
      header: "Service Request Created On",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => formatDateMEL(rowData.serviceRequestGeneratedDate),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
  ];

  const columns: any[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      frozen: true,
      isStatic: false,
    },
    {
      field: "Number",
      sortable: true,
      header: "Number",
      filter: true,
      showFilterMenu: true,
      frozen: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData: any) => actionBodyTemplate(rowData, "Number"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "jobPrefix",
      sortable: true,
      header: "Job Prefix",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "serviceRequestNumber",
      sortable: true,
      header: "Service Request Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "lastModificationTime",
      sortable: true,
      header: "Last Modified",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => modificationTemplate(rowData, "lastModificationTime"),
      isDefault: true,
      isStatic: false,
    },
    // {
    //   field: "totalTat",
    //   sortable: false,
    //   header: "Tat",
    //   filter: false,
    //   showFilterMenu: true,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   // body: (rowData: any) => headerTemplate(rowData.totalTat),
    //   body: (rowData: any) => headerTemplate(((rowData.totalTat ? rowData.totalTat : 0) + 1) / 60),
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "melTat",
      sortable: true,
      header: "Mel Tat",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => convertMinutes(((rowData?.melTat ? rowData?.melTat : 0) + 1) / 60),
      // body: (rowData: any) => convertHours(rowData.melTat, rowData.creationTime),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "otherTat",
      sortable: true,
      header: "Other Tat",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => convertMinutes(((rowData.otherTat ? rowData.otherTat : 0) + 1) / 60),
      // body: (rowData: any) => convertHours(rowData.otherTat, rowData.creationTime),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },

    {
      field: "totalTatDuration",
      sortable: false,
      header: "Tat (Duration)",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => convertHours(rowData.totalTatDuration, rowData.creationTime),
      isDefault: true,
      isStatic: false,
      className: "align-right",
    },
    ...((lazyState?.filters?.Status?.value == "Service Request Generated" || hasCustomerRoleHandler()) ? newAddedColumn : []),
    {
      field: "AudioModel",
      sortable: true,
      header: "Audio Model",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => AudioModelTemplate(rowData, 'AudioModel'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "DealerId",
      sortable: hasDealerRoleHandler() ? false : true,
      header: "Dealer",
      filter: hasDealerRoleHandler() ? false : true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => DealerBodyTemplate(rowData, 'DealerId'),
      isDefault: true,
      isStatic: false,
      filterElement: dealerFilterTemplate
    },
    {
      field: "CustomerId",
      sortable: hasCustomerRoleHandler() ? false : true,
      header: "Customer",
      filter: hasCustomerRoleHandler() ? false : true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => CustomerBodyTemplate(rowData, 'CustomerId'),
      isDefault: true,
      isStatic: false,
      filterElement: customerFilterTemplate
    },
    !hasDealerRoleHandler() && ({
      field: "EstimatorId",
      sortable: true,
      header: "Estimator",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      filterElement: estimatorFilterTemplate
    }),

    !hasDealerRoleHandler() && ({
      field: "TechnicianId",
      sortable: hasFranchiseRoleHandler() ? false : true,
      header: "Technician",
      filter: hasFranchiseRoleHandler() ? false : true,
      // filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      filterElement: technicianFilterTemplate
    }),
    {
      field: "status",
      sortable: true,
      header: "Status",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "WarrantyStatus",
      sortable:  hasManufacturerRoleHandler() ? false : true,
      header: "Warranty Status",
      filter: hasManufacturerRoleHandler() ? false : true,
      showFilterMenu: hasManufacturerRoleHandler() ? false : true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      filterElement:warrantyStatusFilterTemplate
    },
    {
      field: "supplierReferenceNumber",
      sortable: false,
      header: "Supplier Reference Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "VinNumber",
      sortable: true,
      header: "Vin Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "VehicleRegistrationNumber",
      sortable: true,
      header: "Vehicle Registration Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "VehicleBrandId",
      sortable: true,
      header: "Vehicle Brand",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => AudioBrandTemplate(rowData, 'VehicleBrandId'),
      isDefault: true,
      isStatic: false,
      filterElement: vehiclebrandFilterTemplate
    },
    {
      field: "vehicleModel",
      sortable: true,
      header: "Vehicle Model",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "ExchangeAudioSerialNumber",
      sortable: true,
      header: "New Audio Serial Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "AudioSerialNumber",
      sortable: true,
      header: "Failed Audio Serial Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "customerConcern",
      sortable: false,
      header: "Customer Concern",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=><HtmltoPlainText htmlString={rowData?.customerConcern} />,
      className:"hover-class",
      isDefault: true,
      isStatic: false,
    },

    !hasDealerRoleHandler() && ({
      field: "AudioPartNumber",
      sortable: true,
      header: "Audio Part Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    }),
    {
      field: "creationTime",
      sortable: true,
      header: "Created At",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData: any) => formatDateMEL(rowData.requestDate),
    },
    {
      field: "requestDate",
      sortable: true,
      header: "Request Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData: any) => formatDateMEL(rowData.requestDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "dateOfSale",
      sortable: true,
      header: "Vehicle Date Of Sale",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData: any) => formatDateMEL(rowData.dateOfSale),
      filterElement: dateFilterTemplate,
    },
    {
      field: "manufacturedDate",
      sortable: true,
      header: "Failed Audio Manufactured Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData: any) => formatDateMEL(rowData.manufacturedDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "failedDate",
      sortable: true,
      header: "Failed Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData: any) => formatDateMEL(rowData.failedDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "lastRepairDate",
      sortable: true,
      header: "Last Repair Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData: any) => formatDateMEL(rowData.lastRepairDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "priority",
      sortable: false,
      header: "Priority",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => statusBodyTemplate(rowData, 'priority'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "isExchange",
      sortable: false,
      header: "Is Exchange",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => statusBodyTemplate(rowData, 'isExchange'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "isRemoteService",
      sortable: false,
      header: "Is Remote Service",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => statusBodyTemplate(rowData, 'isRemoteService'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "isFaultySystemAvailable",
      sortable: false,
      header: "Is Faulty System Available",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => statusBodyTemplate(rowData, 'isFaultySystemAvailable'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    !hasDealerRoleHandler() && ({
      field: "serviceCharge",
      sortable: true,
      header: "Service Charge",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData: any) => currencyBodyTemplate(rowData, 'serviceCharge', 'INR'),

    }),
    {
      field: "totalEstimate",
      sortable: true,
      header: "Total Estimate",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData: any) => currencyBodyTemplate(rowData, 'totalEstimate', 'INR'),
    }

  ];



  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  const defaultColumns = {
    ...columns[0]
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col: any) => customizeColumnObject.some((sCol: any) => sCol === col.field));
    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns | any) => {
    if (typeof eachColumns !== 'boolean') { // Check if eachColumns is not a boolean
      let data: any = {};
      data['field'] = eachColumns.field;
      data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
      data['isDisable'] = eachColumns.isStatic;
      data['header'] = eachColumns.header;
      return data;
    }
  }).filter((eachColumns: customizeTableColumns | undefined) => eachColumns !== undefined && eachColumns.field !== 'select');

  const validationSchema = Yup.object({});

  const onSelectionChange = (event: any) => {
    if (selectedStatus == "All") {
      const value = event.value?.filter((item: any) => item.IsDeleted != true);
      setSelectedJobSheets(value);
      setSelectAll(value.length === paginationCount);
    } else {
      if (isShowDelete()) {
        const value = event.value;
        setSelectedJobSheets(value);
        setSelectAll(value.length === paginationCount);
      }
    }
  };

  const onSelectAllChange = (event: any) => {
    if (selectedStatus == "All") {
      const value = modifiedValues.filter((item: any) => item.IsDeleted != true);
      setSelectedJobSheets(value);
      setSelectAll(value.length === modifiedValues?.length);
    } else {
      if (isShowDelete()) {
        const selectAll = event.checked;
        if (selectAll) {
          setSelectAll(true);
          setSelectedJobSheets(modifiedValues);
        } else {
          setSelectAll(false);
          setSelectedJobSheets([]);
        }
      }
    }
  };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['RequestStartDate']['value'] = event?.filters?.requestDate?.value?.startDate ? event?.filters?.requestDate?.value?.startDate : '';
    event['filters']['RequestEndDate']['value'] = event?.filters?.requestDate?.value?.endDate ? event?.filters?.requestDate?.value?.endDate : '';
    event['filters']['DateOfSaleStart']['value'] = event?.filters?.dateOfSale?.value?.startDate ? event?.filters?.dateOfSale?.value?.startDate : '';
    event['filters']['DateOfSaleEnd']['value'] = event?.filters?.dateOfSale?.value?.endDate ? event?.filters?.dateOfSale?.value?.endDate : '';
    event['filters']['ManufacturedStartDate']['value'] = event?.filters?.manufacturedDate?.value?.startDate ? event?.filters?.manufacturedDate?.value?.startDate : '';
    event['filters']['ManufacturedEndDate']['value'] = event?.filters?.manufacturedDate?.value?.endDate ? event?.filters?.manufacturedDate?.value?.endDate : '';
    event['filters']['FailedStartDate']['value'] = event?.filters?.failedDate?.value?.startDate ? event?.filters?.failedDate?.value?.startDate : '';
    event['filters']['FailedEndDate']['value'] = event?.filters?.failedDate?.value?.endDate ? event?.filters?.failedDate?.value?.endDate : '';
    event['filters']['LastRepairStartDate']['value'] = event?.filters?.lastRepairDate?.value?.startDate ? event?.filters?.lastRepairDate?.value?.startDate : '';
    event['filters']['LastRepairEndDate']['value'] = event?.filters?.lastRepairDate?.value?.endDate ? event?.filters?.lastRepairDate?.value?.endDate : '';
    setLazyState(event);
  };



  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    dispatch(setJobsheetsStatus(formValue));
    if (formValue == ACTIVE) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive: { value: "true", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: "", matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "true", matchMode: "contains" },
          Status: { value: null, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        }
      }));
    }
    else if (formValue == SERVICE_REQUEST_GENERATED) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: SERVICE_REQUEST_GENERATED, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == EXCHANGE_GENERATED) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          Status: { value: null, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: "isExchangeGenerated", matchMode: "contains" },
        },
      }));
    } else if (formValue == JOB_SHEET_CREATED) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: JOB_SHEET_CREATED, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == NEW_JOB) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: NEW_JOB, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == ESTIMATION_REJECTED) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: ESTIMATION_REJECTED, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == REPLACEMENT_SYSTEM_DISPATCHED) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: REPLACEMENT_SYSTEM_DISPATCHED, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == ASSIGNED_TO_ESTIMATOR) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: ASSIGNED_TO_ESTIMATOR, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == JOB_ESTIMATED_AWAITING_APPROVAL) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: JOB_ESTIMATED_AWAITING_APPROVAL, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == JOB_ASSIGNABLE_TO_TECHNICIAN) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: JOB_ASSIGNABLE_TO_TECHNICIAN, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == ASSIGNED_TO_TECHNICIAN) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: ASSIGNED_TO_TECHNICIAN, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == TECHNICIAN_COMPLETED_JOB) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: TECHNICIAN_COMPLETED_JOB, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == READY_TO_INVOICE) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: READY_TO_INVOICE, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == READY_FOR_INSPECTION) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: READY_FOR_INSPECTION, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == INVOICED_JOB) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: INVOICED_JOB, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == JOB_CLOSED) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "", matchMode: "contains" },
          Status: { value: JOB_CLOSED, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == FRANCHISE_JOB_CLOSED) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: "", matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: true, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == JOB_CANCELLED) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: JOB_CANCELLED, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == IN_PENDING) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: null, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          InPending: { value: "true", matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },

        },
      }));
    }
     else if (formValue == IS_EXCHANGE) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: null, matchMode: "contains" },
          IsExchange: { value: "true", matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } 
     else if (formValue == IS_REMOTE_SERIVICE) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: null, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: "true", matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } 
    
    else if (formValue == FACULTY_SYATEM_AVAILABLE) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: null, matchMode: "contains" },
          IsFaultySystemAvailable: { value: "true", matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == PERMIT_FORM_PENDING) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: null, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: "true", matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == FEEDBACK_PENDING) {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: "false", matchMode: "contains" },
          Status: { value: null, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: "true", matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state: any) => ({
        ...state,
        filters: {
          ...state.filters,
          isActive:{value: "", matchMode: "contains" },
          IsDeleted: { value: null, matchMode: "contains" },
          Status: { value: null, matchMode: "contains" },
          IsExchange: { value: null, matchMode: "contains" },
          IsRemoteService: {value: null, matchMode: "contains"},
          IsFaultySystemAvailable: { value: null, matchMode: "contains" },
          FranchiseJobCompletedStatus: { value: "", matchMode: "contains" },
          InPending: { value: null, matchMode: "contains" },
          PermitFormPending: { value: null, matchMode: "contains" },
          FeedBackPending: { value: null, matchMode: "contains" },
          isExchangeGenerated: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false);
  };

  useEffect(() => {
    dispatch(getcustomerdropdownListLoading());
    dispatch(jobDealerdropdownListLoading());
    dispatch(getTechnicianDropdownLoading());
    dispatch(employeeDropdownForJobLoading());
    dispatch(getVehiclebrandDropdownLoading());
    return () => {
      dispatch(JobsheetDetailsReset());
      dispatch(clearJobsheet());
    };
  }, []);

  useEffect(() => {
    setSelectAll(false);
    setSelectedJobSheets([]);
    callbackFunction(lazyState);
  }, [lazyState]);

  useEffect(() => {
    if (currentUserUid) {
      dispatch(checkDealerHaveFeedbackPendingLoading(currentUserUid));
    }
  }, [currentUserUid]);

  useEffect(() => {
    if (message) {
      showToast(message, { type: "success" });
      setTimeout(() => {
        dispatch(getJobsheetsList(lazyState));
      }, 500);
    }
    if (error) {
      setVisible(true);
    }
  }, [message, error]);

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  }, [customerList, dealerList, technicianList, estimatorList, vehicleBrandDropdownListPage, lazyState]);

  const bulkActionClickHandler = () => {
    if (selectedStatus == "All") {
      const value = modifiedValues.filter((item: any) => item.IsDeleted != true);
      setSelectedJobSheets(value);
      setSelectAll(value.length === modifiedValues?.length);
    } else {
      if (isShowDelete()) {
        if (modifiedValues.length > 0) {
          setSelectAll(true);
          setSelectedJobSheets(modifiedValues);
        }
      }
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedJobSheets([]);
  };

  const handleDeleteJobsheets = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData?.IsDeleted == true ? true : rowData[0]?.IsDeleted == true ? true : false,
      lazyState,
    };
    dispatch(deleteJobsheetsFromList(payload));
    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedJobSheets([]);
    }

  };

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      data['Number'] = eachValue?.Number ? eachValue?.Number : "...";
      // data['AudioModel'] = eachValue?.AudioModel;
      // data['jobPrefix'] = eachValue?.jobPrefix;
      data['Dealer'] = eachValue?.DealerId;
      // data['Customer'] = eachValue?.CustomerId;
      // data['Estimator'] = eachValue?.EstimatorId;
      data['Technician'] = eachValue?.TechnicianId;
      data['Status'] = eachValue?.status;
      data['Warranty Status'] = eachValue?.WarrantyStatus;
      data['Vin Number'] = eachValue?.VinNumber;
      // data['VehicleRegistrationNumber'] = eachValue?.VehicleRegistrationNumber;
      data['Vehicle Brand'] = eachValue?.VehicleBrandId;
      data['vehicle Model'] = eachValue?.vehicleModel;
      data['Customer Concern'] = eachValue?.customerConcern;
      // data['ExchangeAudioSerialNumber'] = eachValue?.ExchangeAudioSerialNumber;
      // data['AudioSerialNumber'] = eachValue?.AudioSerialNumber;
      data['Audio Part Number'] = eachValue?.AudioPartNumber;
      data['Job Created On'] = formatDateMEL(eachValue?.creationTime);
      data['Assigned Date'] = formatDateMEL(eachValue?.assignedFranchiseTime);
      // data['dateOfSale'] = formatDateMEL(eachValue?.dateOfSale);
      // data['manufacturedDate'] = formatDateMEL(eachValue?.manufacturedDate);
      // data['failedDate'] = formatDateMEL(eachValue?.failedDate);
      // data['lastRepairDate'] = formatDateMEL(eachValue?.lastRepairDate);
      // data['Priority'] = eachValue?.priority;
      // data['isExchange'] = eachValue?.isExchange;
      // data['isFaultySystemAvailable'] = eachValue?.isFaultySystemAvailable;
      // data['serviceCharge'] = eachValue?.serviceCharge;
      // data['ServiceRequestNumber'] = eachValue?.serviceRequestNumber;
      // data['totalEstimate'] = eachValue?.totalEstimate;
      // data['serviceRequestGeneratedDate'] = formatDateMEL(eachValue?.serviceRequestGeneratedDate);
      // data['Job Prefix'] = eachValue?.jobPrefix;
      data['Franchise Job Completed Date'] = formatDateMEL(eachValue?.franchiseJobCompletedDate);
      data['Tat'] = convertTimesToSeconds(eachValue?.totalTat);
      // data['Other Tat'] = convertTimesToMinutes(eachValue?.otherTat);
      // data['Mel Tat'] = convertTimesToMinutes(eachValue?.melTat);
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf: boolean) => {
    let lazy: any = {
      ...lazyState,
      rows: totalRecords > 5000 ? 5000 : totalRecords < 1 ? 1 : totalRecords,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true);
      const response = await API.getJobsheetPDFAPI(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['id'] = eachValue?.id;
        data['Number'] = eachValue?.number;
        data['AudioModel'] = eachValue?.audioModel;
        data['DealerId'] = eachValue?.dealerName;
        data['CustomerId'] = eachValue?.customerName;
        data['EstimatorId'] = eachValue?.estimatorName;
        data['TechnicianId'] = eachValue?.technicianName;
        data['status'] = eachValue?.status;
        data['isFeedbackPending'] = eachValue?.isFeedbackPending;
        data['WarrantyStatus'] = eachValue?.warrantyStatus;
        data['VinNumber'] = eachValue?.vinNumber;
        data['VehicleRegistrationNumber'] = eachValue?.vehicleRegistrationNumber;
        data['VehicleBrandId'] = eachValue?.vehicleBrandName;
        data['vehicleModel'] = eachValue?.vehicleModel;
        data['ExchangeAudioSerialNumber'] = eachValue?.exchangeAudioSerialNumber;
        data['AudioSerialNumber'] = eachValue?.audioSerialNumber;
        data['AudioPartNumber'] = eachValue?.audioPartNumber;
        data['creationTime'] = eachValue?.creationTime;
        data['requestDate'] = eachValue?.requestDate;
        data['dateOfSale'] = eachValue?.dateOfSale;
        data['manufacturedDate'] = eachValue?.manufacturedDate;
        data['failedDate'] = eachValue?.failedDate;
        data['lastRepairDate'] = eachValue?.lastRepairDate;
        data['isExchange'] = eachValue?.isExchange;
        data['isFaultySystemAvailable'] = eachValue?.isFaultySystemAvailable;
        data['serviceCharge'] = eachValue?.serviceCharge;
        data['totalEstimate'] = eachValue?.totalEstimate;
        data['ServiceRequestNumber'] = eachValue?.serviceRequestNumber;
        data['totalTatDuration'] = eachValue?.tat;
        data['IsDeleted'] = eachValue?.isDeleted;
        data['audioModelId'] = eachValue?.audioModelId;
        data['dealerId'] = eachValue?.dealerId;
        data['customerId'] = eachValue?.customerId;
        data['vehicleBrandId'] = eachValue?.vehicleBrandId;
        data['serviceRequestNumber'] = eachValue?.serviceRequestNumber;
        data['serviceRequestGeneratedDate'] = eachValue?.serviceRequestGeneratedDate;
        data['technicianUserType'] = eachValue?.technicianUserType;
        data['jobPrefix'] = eachValue?.jobPrefix;
        data['totalTat'] = eachValue?.totalTat;
        data['otherTat'] = eachValue?.otherTat;
        data['melTat'] = eachValue?.melTat;
        data['priority'] = eachValue?.priority;
        data['assignedFranchiseTime'] = eachValue?.assignedFranchiseTime;
        data['franchiseJobCompletedDate'] = eachValue?.franchiseJobCompletedDate;
        data['customerConcern'] = eachValue?.customerConcern;
        return data;
      });
      // let resp=await jobSheetListConversion(response?.data?.items)
      if (isPdf) {

        saveDataAsPdf(modifeidValue, columns, "jobsheet");
      } else {
        saveDataAsExcel(modifeidValue, "jobsheet");
      }
      setPdfLoading(false);
    } catch (error: any) {
      showToast(error.message, { type: "error" });
      setPdfLoading(false);

    } finally {
      setPdfLoading(false);
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "jobsheet");
    fetchData(false);
  };


  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((eachValue) => {
      let datas = {
        Number: eachValue?.Number ? eachValue?.Number : "...",
        AudioModel: eachValue?.AudioModel,
        DealerId: eachValue?.DealerId,
        CustomerId: eachValue?.CustomerId,
        EstimatorId: eachValue?.EstimatorId,
        TechnicianId: eachValue?.TechnicianId,
        status: eachValue?.status,
        WarrantyStatus: eachValue?.WarrantyStatus,
        VinNumber: eachValue?.VinNumber,
        VehicleRegistrationNumber: eachValue?.VehicleRegistrationNumber,
        VehicleBrandId: eachValue?.VehicleBrandId,
        vehicleModel: eachValue?.vehicleModel,
        ExchangeAudioSerialNumber: eachValue?.ExchangeAudioSerialNumber,
        AudioSerialNumber: eachValue?.AudioSerialNumber,
        AudioPartNumber: eachValue?.AudioPartNumber,
        creationTime: formatDateMEL(eachValue?.creationTime),
        requestDate: formatDateMEL(eachValue?.assignedFranchiseTime),
        dateOfSale: formatDateMEL(eachValue?.dateOfSale),
        manufacturedDate: formatDateMEL(eachValue?.manufacturedDate),
        failedDate: formatDateMEL(eachValue?.failedDate),
        lastRepairDate: formatDateMEL(eachValue?.lastRepairDate),
        isExchange: eachValue?.isExchange,
        isFaultySystemAvailable: eachValue?.isFaultySystemAvailable,
        serviceCharge: eachValue?.serviceCharge,
        serviceRequestNumber: eachValue?.ServiceRequestNumber,
        totalEstimate: eachValue?.totalEstimate,
        serviceRequestGeneratedDate: formatDateMEL(eachValue?.serviceRequestGeneratedDate),
        tat: eachValue?.tat,
        jobPrefix: eachValue?.jobPrefix,
        totalTat: convertTimesToSeconds(eachValue?.totalTat),
        otherTat: convertTimesToMinutes(eachValue?.otherTat),
        melTat: convertTimesToMinutes(eachValue?.melTat),
        priority: eachValue?.priority,
        franchiseJobCompletedDate: formatDateMEL(eachValue?.franchiseJobCompletedDate),
        customerConcern: eachValue?.customerConcern,
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        let headers=['Number','Dealer',"Technician","Status","Warranty Status","Vin Number","Vehicle Brand","Vehicle Model","Audio Part Number","Created At","Request Date","Tat (Duration)","Customer Concern"]
        let filteredColumns = columns.filter((val:any)=>headers.includes(val.header))
        let exportColumns:any = filteredColumns.map((col) => ({
          title: col.header == "Request Date" ? "Assigned Date" : col.header == "Created At" ? "Job Created On" : col.header,
          dataKey: col.field,
        }))?.filter((data:any)=>data.dataKey != "totalTatDuration");
        console.log(exportColumns)
        exportColumns = [...exportColumns,{title:"Franchise Job Completed Date",dataKey:"franchiseJobCompletedDate"},{title:"Tat",dataKey:"totalTat"}]
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a2',
        });
        const columnStyles = {
          0: { cellWidth: 30 },
          1: { cellWidth: 50 },
          2: { cellWidth: 30 },
          3: { cellWidth: 55 },
          4: { cellWidth: 35 },
          5: { cellWidth: 35 },
          6: { cellWidth: 35 },
          7: { cellWidth: 35 },
          8: { cellWidth: 60 },
          9: { cellWidth: 35 },
          10: { cellWidth: 30 },
          11:{ cellWidth: 30 },
          12:{ cellWidth: 30 },
          13:{ cellWidth: 50 },
        };
  
        doc.autoTable({
          columns: exportColumns,
          body: destructureedData,
          columnStyles: columnStyles,
          headStyles:{
            fillColor: [42, 125, 182],
            textColor: [255, 255, 255],
          },
          styles: {
            fontSize: 8, 
            overflow: 'linebreak',
            halign: 'center',
            valign: 'middle',
          },
          theme: 'grid',
          startY: 20,
        });
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "jobsheet");
    fetchData(true);
  };

  const hidePopup = () => {
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const hideFeedbackContanier = () => {
    setFeedbackPendingWarning(false);
  };

  const feedbackDialogContainer = () => {
    return (
      <>
        <FeedBackPendingStatus text={feedbackText} feedbackList={feedbackDetails} onHide={hideFeedbackContanier} />
      </>
    );
  };
  const clearFilter = () => {
    setVisibleRight(false);
    setJobsheetNumber(null);
    setAudioModel(null);
    setVehicleModel(null);
    setVehicleNumber(null);
    setLazyState(initialLazyState);
    setselectedStatus((status == "Feedback_Pending") ? FEEDBACK_PENDING : ACTIVE);
  };

  const calendarRef = useRef<any>(null);
  const [jobsheetNumber, setJobsheetNumber] = useState<any>();
  const [audioModel, setAudioModel] = useState<any>();
  const [vehicleModel, setVehicleModel] = useState<any>();
  const [vehicleNumber, setVehicleNumber] = useState<any>();
  const searchCustomCalendar = (event: any) => {
    setVisibleRight(false);
    setLazyState((state: any) => ({
      ...state,
      filters: {
        ...state.filters,
        JobNumbersList: { value: jobsheetNumber, matchMode: "contains" },
        AudioModelIds: { value: audioModel, matchMode: "contains" },
        VehicleModelList: { value: vehicleModel, matchMode: "contains" },
        VehicleRegistrationNumberList: { value: vehicleNumber, matchMode: "contains" },
      }
    }));

  };
  const clearCustomCalendar = (event: any) => {
    setVisibleRight(false)
    setJobsheetNumber(null)
    setAudioModel(null)
    setVehicleModel(null)
    setVehicleNumber(null)
    setLazyState(initialLazyState)
    setselectedStatus((status == "Feedback_Pending") ? FEEDBACK_PENDING : ACTIVE);
  }
useEffect(()=>{
  dispatch(getJobsheetNumberLoading());
  dispatch(getAudioModelLoading());
},[])



  const [optionsList, setOptionsList] = useState();
  const [audioModelOptionsList, setAudioModelOptionsList] = useState();
  useEffect(() => {
    const val = jobsheetNumberFilterData?.length > 0 ? jobsheetNumberFilterData?.filter((data: any) => data.number != null)?.map((val: any) => ({ label: val.number, value: val.number })) : [];
    setOptionsList(val.reverse());
  }, [jobsheetNumberFilterData]);
  useEffect(() => {
    const val = audioModelFilterData?.length > 0 ? audioModelFilterData?.filter((data: any) => data.number != null)?.map((val: any) => ({ label: val.number, value: val.id })) : [];
    setAudioModelOptionsList(val.reverse());
  }, [audioModelFilterData]);
  const header =
    <div className="table-header-container">

      <div>{hasCustomerRoleHandler() ? "Service Request List" : hasDealerRoleHandler() ? "Service Request List" : "Job Sheet List"}</div>
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedJobSheets.length == 0 && selectedStatus != "All" && hasAdminOrEmployeeRoleHandler()) &&
          <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
            <FeatherIcon icon="layers" className="mr-1" />
            Bulk actions
          </Button>
        }
        <AnimatePresence>
          {selectedJobSheets.length > 0 &&
            <>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                  {/* <FeatherIcon icon="trash-2" className="mr-1" /> */}
                  <FeatherIcon icon={selectedStatus == DELETED ? "repeat" : "trash-2"} className="mr-1" />
                  <span className="remove_lables"> {selectedStatus == DELETED ? "Restore" : "Delete"} </span>
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1 " onClick={cancelBulkActionHadler}> <FeatherIcon icon="x" className="" /> <span className="remove_lables"> Cancel selection </span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedJobSheets.length > 0 && <BulkActionStatus count={selectedJobSheets.length} title="JobSheets"></BulkActionStatus>}
              </motion.div>
            </>
          }
        </AnimatePresence>

      </div>
      {/* <div id="calendar" className="calendar_btns" style={{ marginLeft: "10px" }}>
        <div className="table-header-container-item">
          <div className="btn-group">
            <button
              className="btn btn-soft-primary waves-effect waves-light"
              // onClick={toggleCalendar}
              onClick={(e) => calendarRef.current.toggle(e)}
            > 
              <FeatherIcon icon="search" style={{ Width: "6px" }} />
            </button>
          </div>
        </div>
      </div> */}
      {/* <OverlayPanel ref={calendarRef}>

        <div className="mb-2" style={{display:"flex",flexDirection:"column"}}>
            <label htmlFor="">Select Jobsheet</label>
            <MultiSelect
            value={jobsheetNumber}
            options={optionsList}
            onChange={(e) => {
                setJobsheetNumber(e.value);
            }}
            filter={true}
            selectAll={false}
            virtualScrollerOptions={{ itemSize: 15 }}
            maxLength={5}
            maxSelectedLabels={2}
            placeholder="Select Items"
            className="w-full md:w-20rem"
        />
          </div>
        
        <div className="p-column-filter-buttonbar">
          <button aria-label="Clear" className="p-button p-component p-button-outlined p-button-sm" type="button" onClick={clearCustomCalendar}>
            <span className="p-button-label p-c">Clear</span>
          </button>
          <button aria-label="Apply" className="p-button p-component p-button-sm" type="button" onClick={searchCustomCalendar}>
            <span className="p-button-label p-c">Apply</span>
          </button>
        </div>
      </OverlayPanel> */}
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} toggle={(hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()  || hasManufacturerRoleHandler()) ? false : true} checkedValue={checked} setChecked={setChecked} />
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown showClear={false} value={selectedStatus} onChange={(e) => handleSubmit(e.value)}
            options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>
        <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" />
        </Button>
        {isJobsheetCreateAllow() && <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" style={{ marginRight: "10px" }} />
          {(isCustomerLogin || hasDealerRoleHandler()) ? "Service Request Generate" : "Add Service Request"}
        </Button>}

        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          style={{
            backgroundColor: '#324f6b',
          }}
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
        {
                <div className="reponsive_position-jobsheet">
          <FeatherIcon size={20} onClick={() => setVisibleRight(!visibleRight)} icon="filter" className={"feathet-icon"} />
        </div>
}
      </div>
    </div>;
  const [visibleRight, setVisibleRight] = useState<boolean>(false);
  const containerRef: any = useRef<any>();

  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        {feedbackPendingWarning && feedbackDialogContainer()}
        {feedbackPendingWarning && <div className='balckscreen'></div>}
        {/* <Sidebar style={{width:"20%"}} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
          <h4>Filter Details</h4>
          
        </Sidebar> */}

        {visibleRight && <div className="jobsheet-filter-parent" ref={containerRef}>
          <div className="header-jobsheet">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Filters</h5>
              </div>
              <div className="cancel">
                <FeatherIcon icon={"x-square"} onClick={() => setVisibleRight(false)} />
              </div>
            </div>
          </div>
          <hr />
          <div className="job-filter-container mt-2" style={{ position: 'relative' }}>
            <div className="filter-content mt-4">
              <div className="mb-2" style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="">Jobsheet</label>
                <MultiSelect
                  value={jobsheetNumber}
                  options={optionsList}
                  onChange={(e) => {
                    setJobsheetNumber(e.value);
                  }}
                  filter={true}
                  selectAll={false}
                  showSelectAll={false}
                  virtualScrollerOptions={{ itemSize: 15 }}
                  maxLength={5}
                  maxSelectedLabels={2}
                  placeholder="Select Items"
                  className="w-full md:w-20rem"
                />
              </div>
              <div className="mb-2" style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="">Audio Model</label>
                <MultiSelect
                  value={audioModel}
                  options={audioModelOptionsList}
                  onChange={(e) => {
                    setAudioModel(e.value);
                  }}
                  filter={true}
                  selectAll={false}
                  showSelectAll={false}
                  virtualScrollerOptions={{ itemSize: 15 }}
                  maxLength={5}
                  maxSelectedLabels={2}
                  placeholder="Select Items"
                  className="w-full md:w-20rem"
                />
              </div>
              <div className="mb-2" style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="">Vehicle Model</label>
                <Chips value={vehicleModel} onChange={(e) => setVehicleModel(e.value)} />
                <p style={{ fontSize: "12px" }}>Please press the Enter key after entering the vehicle model.</p>
              </div>
              <div className="mb-2" style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="">Vehicle Registration Number</label>
                <Chips value={vehicleNumber} onChange={(e) => setVehicleNumber(e.value)} />
                <p style={{ fontSize: "12px" }}>Please press the Enter key after entering vehicle registration number .</p>
              </div>
              <div className="d-flex justify-content-end gap-2">
                <button aria-label="Clear" className="p-button p-component p-button-outlined p-button-sm" type="button" onClick={clearCustomCalendar}>
                  <span className="p-button-label p-c">Clear</span>
                </button>
                <button aria-label="Apply" className="p-button p-component p-button-sm" type="button" onClick={searchCustomCalendar}>
                  <span className="p-button-label p-c">Apply</span>
                </button>
              </div>
            </div>
          </div>
        </div>}

        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteJobsheets(rowData);
            }

            if (selectedJobSheets?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedJobSheets([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }
            if (selectedJobSheets.length > 0) {
              for (let i = 0; i < selectedJobSheets.length; i++) {
                handleDeleteJobsheets(selectedJobSheets[i], selectedJobSheets.length, i);
              }
            }

          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />

        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}
        <DataTableWrapper
          // style={{ width: visibleRight ? "calc(80% - 10px)" : "100%" }}
          ref={dt}
          header={header}
          columnDetails={(selectedStatus == "All" || !hasAdminOrEmployeeRoleHandler()) ? [...visibleColumns]?.filter((items: any) => items.field != "select") : visibleColumns}
          value={modifiedValues}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          // loading={loading}
          loading={jobListLoading}
          emptyMessage={(hasCustomerRoleHandler() || hasDealerRoleHandler() ? "No service request found " : "No job sheets found.")}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          // currentPageReportTemplate="Displaying Job Sheets  {last} records of {totalRecords} in total"
          currentPageReportTemplate={`Displaying ${hasCustomerRoleHandler() ? "Service Request" : hasDealerRoleHandler() ? "Service Request" : "Job Sheets"}  {last} records of {totalRecords} in total`}
          selection={selectedJobSheets}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </React.Fragment>
  );
}