import React, { ReactNode, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import ErrorText from "../UI/ErrorText";
import { ErrorMessage } from "formik";
import './FloatInput.scss'
import { Button } from 'primereact/button';
import JobTooltipMEL from '../UI/JobTooltip';
import CheckboxInput from '../UI/CheckboxInput';

interface FloatLabelProps {
  type: 'text' | 'number' | 'date' | 'dropdown' | 'textarea' | 'datetime' | 'split';
  label: string;
  value?: any;
  onChange?: (value: any) => void;
  options?: any[];
  invalid?: boolean;
  onBlur?: () => void;
  name: string;
  disabled: boolean;
  required: boolean;
  error?: string;
  today?: any;
  className?: any;
  onClick?:any;
  placeholder?:any;
  children:ReactNode
}

const FloatInputGroup: React.FC<FloatLabelProps>  = ({
  type,
  label,
  value,
  onChange,
  options,
  invalid = false,
  onBlur,
  name,
  disabled,
  required = false,
  error,
  today,
  className = "",
  onClick= ()=>{},
  placeholder,
  children
}) => {
  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };


  return (
    <>
    <div title={type=="dropdown" ? options?.find((data:any) => data.value === value)?.label || "" : ""}>
 <div className='dis-flex' id="technician-drp">
 <div className={`p-float-label ${value ? 'p-input-filled' : ''} ${className} ${disabled ? 'float-disabled' : ''}`}>
        {(type === 'text' || type === 'number') && (
              <InputText
                name={name}
                type={type}
                id={label}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                className={`${invalid ? 'form-control is-invalid' : ''} ${className}`}
                disabled={disabled}
              />
        )}


        {(type === 'date' || type === "datetime") && (
          <Calendar
            name={name}
            inputId={label}
            id={label}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={`p-inputfield ${invalid ? 'p-invalid' : ''} ${disabled ? 'float-disabled' : ''}`}
            disabled={disabled}
            showIcon={true}
            dateFormat="dd-mm-yy"
            showTime={type === 'datetime'}
            hourFormat="24"
            showButtonBar 
            clearButtonClassName="calendar_button"
            todayButtonClassName="calendar_button"
            // unstyled={true}
          />
        )}

        {type === 'dropdown' && (
          <Dropdown
            filter
            name={name}
            id={label}
            value={value}
            title={options?.find((data:any)=>data.value == value)?.label}
            options={options}
            virtualScrollerOptions={{ itemSize: 35 }}
            onChange={onChange}
            onBlur={onBlur}
            className={`${invalid ? 'form-control is-invalid' : ''} ${disabled ? 'float-disabled' : ''}`}
            disabled={disabled}
            showClear={value ? true : false}
            itemTemplate={(option: any) => (
              <div
                title={option.label} // Tooltip content comes from the `tooltip` field in the option
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {option.label}
              </div>
            )}
          />
        )}
        {type === 'textarea' && (
          <InputTextarea
            name={name}
            id={label}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={`${invalid ? 'form-control is-invalid' : ''} ${disabled ? 'float-disabled' : ''}`}
            disabled={disabled} />
        )}
        <label htmlFor={label}>
          {label} {required && <span className="text-danger">*</span>}
        </label>

      </div>
    <div className='drop-grp-brd'>
        {children}
    </div>
 </div>
     
      
      <ErrorMessage name={name || error || ''} component={ErrorText} />
      </div>
    </>
  );
};

export default FloatInputGroup;