import React, { useEffect, useRef, useState } from 'react';
import DeleteModal from '../Calendar/DeleteModal';
import ArchiveModal from "../Calendar/DeleteModal";
import { useToast } from 'src/components/Common/ReactToaster';
import { useDispatch, useSelector } from 'react-redux';
import CustomizeTable from 'src/components/Common/CustomizeTable/CustomizeTable';
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Form, Formik } from 'formik';
import { Button, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import Select from 'src/components/Common/Select';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import { ACTIVE, DELETED, LOCKED, PAYMENTPENDING, UNLOCKED } from 'src/constants/commonConstants';
import { Option } from 'src/models/components/inputWrapperModel';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from 'framer-motion';
import { BUTTON_VARIANT } from 'src/constants/bulkActionVariant';
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import TooltipMEL from 'src/components/UI/Tooltip';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import BulkActionStatus from 'src/components/Common/BulkActionStatus/BulkActionStatus';
import { useNavigate } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import ColumnAction from 'src/components/Common/ColumnAction/ColumnAction';
import { RootReducerState } from 'src/store/reducers';
import {
  generatePaymentLinkLoading,
  getCustomerDropForInvoiceLoading,
  getDealerDropForInvoiceLoading,
  getInvoicePageInvoiceListLoading,
  getInvoicesListLoading,
  invoiceDeleteLoading,
  LoadFullInvoiceLoading,
  LoadFullJobSheetLoading,
  resetInvoicePageMessages,
  resetPaymentLink,
} from 'src/store/actions';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import StatusHighlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { formatDateMEL, hasAdminOrEmployeeRoleHandler, hasCustomerRoleHandler, hasDealerRoleHandler, saveAsExcelFile } from 'src/helpers/common_helpers';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { Link } from 'react-router-dom';
import { API } from 'src/util/api';
import { MultiSelect } from 'primereact/multiselect';
import { customizeTableColumns } from 'src/models/components/CustomizeTableColumns';

const invoiceListConversion = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data["id"] = eachValue?.id;
    data['number'] = eachValue?.number ? eachValue?.number : "WIP";
    data['lockedDate'] = eachValue?.lockedDate;
    data['paidState'] = eachValue?.paidState;
    data['jobId'] = eachValue?.jobId;
    data['JobNumber'] = eachValue?.jobSheetNumber;
    data['InvoiceTypeId'] = eachValue?.invoiceTypeName;
    data['serviceCharge'] = eachValue?.serviceCharge;
    data['discount'] = eachValue?.discount;
    data['total'] = eachValue?.total;
    data['locked'] = eachValue?.locked;
    data['isDeleted'] = eachValue?.isDeleted;
    data['totalAfterDiscount'] = eachValue?.totalAfterDiscount;
    data['totalPriceofpart'] = eachValue?.totalPriceOfParts;
    data['totalTaxes'] = eachValue?.totalTaxes;
    data['DealerId'] = eachValue?.dealerName ? eachValue?.dealerName : "";
    data['CustomerId'] = eachValue?.customerName ? eachValue?.customerName : "";
    data['billToName'] = eachValue?.billToName;
    data['invoiceDate'] = eachValue?.lockedDate;
    data['dealerIdtoNavigate'] = eachValue?.dealerId;
    data['customerId'] = eachValue?.customerId;
    return data;
  });
  return modifeidValue;
};


const InvoiceList = () => {

  const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );
  };


  const invoiceTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={invoiceDropdown?.length > 0 ? (invoiceDropdown?.map((invoice: any) => {
          let data: any = {};
          data['value'] = invoice.id;
          data['label'] = invoice.name;
          return data;
        })).sort((a: any, b: any) => {
          const labelA = a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="buyingCurrency"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
        filter
        itemTemplate={(option: any) => (
          <div
            title={option.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.label}
          </div>
        )}
      />
    </>);
  };

  const dealerTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={dealerList?.length > 0 ? (dealerList?.map((dealer: any) => {
          let data: any = {};
          data['value'] = dealer.id;
          data['label'] = dealer.name;
          return data;
        })).sort((a: any, b: any) => {
          const labelA = a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="buyingCurrency"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        virtualScrollerOptions={{ itemSize: 35 }}
        required={true}
        value={options.value}
        filter
        itemTemplate={(option: any) => (
          <div
            title={option.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.label}
          </div>
        )}
      />
    </>);
  };
  const customerTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={customerList?.length > 0 ? (customerList?.map((customer: any) => {
          let data: any = {};
          data['value'] = customer.id;
          data['label'] = customer.name;
          return data;
        })).sort((a: any, b: any) => {
          const labelA = a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="buyingCurrency"
        title={options.value}
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        virtualScrollerOptions={{ itemSize: 35 }}
        required={true}
        value={options.value}
        filter
        itemTemplate={(option: any) => (
          <div
            title={option.label}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.label}
          </div>
        )}
      />
    </>);
  };

  const DealerBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          (hasAdminOrEmployeeRoleHandler()) ? <Link to={`${ROUTER.DEALERS_VIEW}/${rowData['dealerIdtoNavigate']}`}>{rowData[field]}</Link> : rowData[field]
        }
      </>

    );
  };

  const JobsheetBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          rowData[field] ? <Link to={`${ROUTER.JOB_SHEETS_VIEW}/${rowData['jobId']}`}>{rowData[field]}</Link> : ""
        }
      </>

    );
  };

  const CustomerBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          hasAdminOrEmployeeRoleHandler() ? <Link to={`${ROUTER.CUSTOMERS_VIEW}/${rowData['customerId']}`}>{rowData[field]}</Link> : rowData[field]
        }
      </>

    );
  };

  const PayButton = (rowData: any) => {
    let isAdmin = hasAdminOrEmployeeRoleHandler();
    if (rowData.paidState != true && rowData.locked == true) {

      return (
        <button className='payment-button' onClick={() => dispatch(generatePaymentLinkLoading(rowData.id, isAdmin))}><i className="fa fa-paper-plane mr-1" aria-hidden="true"></i>{hasAdminOrEmployeeRoleHandler() ? "Generate Payment link" : "Pay Now"}</button>
      );
    } else {
      return (
        <></>
      );
    }
  };

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date"
        required
        name={options?.value?.startDate}
        value={options?.value?.startDate || ""}
        max={options?.value?.endDate ? options?.value?.endDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['startDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }}
      />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date"
        required
        name={options?.value?.endDate}
        value={options?.value?.endDate || ""}
        min={options?.value?.startDate ? options?.value?.startDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['endDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }}
      />
    </>);
  };

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
      frozen: true,
    },
    {
      field: "number",
      sortable: true,
      header: "Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "number"),
      isDefault: true,
      frozen: true,
      isStatic: true,
    },
    {
      field: "JobNumber",
      sortable: false,
      header: "Job Sheet",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "200px" },
      body: (rowData: any) => JobsheetBodyTemplate(rowData, 'JobNumber'),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "InvoiceTypeId",
      sortable: false,
      header: "Invoice Type",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
      isDefault: true,
      isStatic: false,
      filterElement: invoiceTemplate

    },
    {
      field: "DealerId",
      sortable: hasDealerRoleHandler() ? false : true,
      header: "Dealer",
      filter: hasDealerRoleHandler() ? false : true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => DealerBodyTemplate(rowData, 'DealerId'),
      isDefault: true,
      isStatic: false,
      filterElement: dealerTemplate
    },
    {
      field: "CustomerId",
      sortable: hasCustomerRoleHandler() ? false : true,
      header: "Customer",
      filter: hasCustomerRoleHandler() ? false : true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => CustomerBodyTemplate(rowData, 'CustomerId'),
      isDefault: true,
      isStatic: false,
      filterElement: customerTemplate
    },
    {
      field: "billToName",
      sortable: false,
      header: "Bill To Name",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "totalPriceofpart",
      sortable: false,
      header: "Total Price of parts",
      filter: false,
      showFilterMenu: false,
      className: "align-right pr-2",
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "serviceCharge",
      sortable: true,
      header: "Service Charge",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "discount",
      sortable: true,
      header: "Discount",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "totalTaxes",
      sortable: true,
      header: "Total Taxes",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "total",
      sortable: true,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "totalAfterDiscount",
      sortable: true,
      header: "Total After Discount",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "locked",
      sortable: true,
      header: "Locked",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'locked'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "paidState",
      sortable: true,
      header: "Paid State",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'paidState'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "invoiceDate",
      sortable: false,
      header: "Invoice Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => formatDateMEL(rowData.invoiceDate),
      isDefault: true,
      isStatic: false,
      filterElement:dateFilterTemplate
    },
    {
      field: "Payment",
      sortable: false,
      header: "Payment",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => PayButton(rowData),
      isDefault: true,
      isStatic: false,
    },
  ];



  //filter details
  const filterDetails: DataTableFilterMeta = {
    Name: { value: "", matchMode: "contains" },
    PhoneNumber: { value: "", matchMode: "contains" },
    Email: { value: "", matchMode: "contains" },
    IsVerfied: { value: null, matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
    IsLocked: { value: "", matchMode: "contains" },
    paymentPending: { value: "", matchMode: "contains" },
    JobNumber: { value: "", matchMode: "contains" },
    number: { value: "", matchMode: "contains" },
    InvoiceTypeId: { value: "", matchMode: "contains" },
    CustomerId: { value: "", matchMode: "contains" },
    DealerId: { value: "", matchMode: "contains" },
    invoiceDate: { value: "", matchMode: "contains" },
    LockedFrom: { value: "", matchMode: "contains" },
    LockedTo: { value: "", matchMode: "contains" },

  };

  //initial lazystate
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "creationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [visible, setVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [rowData, setRowData] = useState<any | null>(null);
  const [archiveModal, setArchiveModal] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [filter, setFilter] = useState(false);
  const [selectedStatus, setselectedStatus] = useState("OnlyActive");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, message, loading, paginatorCount, invoiceDropdown, dealerList, customerList, paymentLink, LoadFullJobsheet,LoadAllTypeInvoice } = useSelector((state: RootReducerState) => state.invoiceReducer);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const { items, totalCount } = useSelector((state: RootReducerState) => state.invoiceReducer.invoiceList);
   


  const jobListOption = LoadFullJobsheet?.length > 0 ? LoadFullJobsheet?.filter((data: any) => data.status === "Assigned To Technician")?.map((eachDealer: any) => {
    let data: any = {};
    data['label'] = eachDealer?.number ? eachDealer?.number : "WIP";
    data['value'] = eachDealer?.id;
    return data;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : [];

  const invoiceListOptions = LoadAllTypeInvoice?.length > 0 ? LoadAllTypeInvoice?.filter((data: any) => data.number != "" && data.number != null)?.map((eachInvoice: any) => {
    let data: any = {};
    data['label'] = eachInvoice?.number;
    data['value'] = eachInvoice?.number;
    return data;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : [];

  useEffect(() => {
    if (paymentLink) {
      try {
        const newWindow = window.open(paymentLink, "", "width=600,height=600");
        if (newWindow === null || typeof newWindow === 'undefined') {
          alert("Enable Popup to open");
        } else {
          newWindow.focus();
        }
      }
      finally {
        dispatch(resetPaymentLink());
      }
    }
  }, [paymentLink]);


  const modifiedValues: any[] = invoiceListConversion(items.length > 0 ? items : []);

  const dt = useRef<DataTable<any>>(null);
  const initialValues = {
    status: "",
  };
  const defaultColumns = {
    ...columns[0]
  };
  const options: Option[] = [
    { label: "All Invoice", value: "Active" },
    { label: "Active", value: "OnlyActive" },
    { label: "Deleted", value: "Deleted" },
    { label: "Locked", value: "Locked" },
    { label: "Un Locked", value: "Unlocked" },
    { label: "Payment Pending", value: "paymentPending" },
  ];
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      let unLocked = modifiedValues.filter((item: any) => item.locked != true && item.isDeleted != true);
      setSelectedInvoices(unLocked);
      setSelectAll(unLocked.length == paginatorCount);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedInvoices([]);
  };

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const onCreateClickHandler = () => {
    navigate(ROUTER.INVOICE_CREATE);
  };

  const changeColumnDetails = (values: any[]) => {

    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      data['Number'] = eachValue?.number ? eachValue?.number : "WIP";
      data['Job Sheet'] = eachValue?.JobId;
      data['Invoice Type'] = eachValue?.InvoiceTypeId;
      data['Dealer'] = eachValue?.DealerId;
      data['Customer'] = eachValue?.CustomerId;
      data['Bill To Name'] = eachValue?.billToName;
      data['Total Price Of Part'] = eachValue?.totalPriceofpart;
      data['Service Charge'] = eachValue?.serviceCharge;
      data['Discount'] = eachValue?.discount;
      data['Total Taxes'] = eachValue?.totalTaxes;
      data['Total After Discount'] = eachValue?.totalAfterDiscount;
      data['Total'] = eachValue?.total;
      data['Locked'] = eachValue?.locked ? "Yes" : "No";
      data['Paid State'] = eachValue?.paidState ? "Yes" : "No";
      data['Invoice Date'] = formatDateMEL(eachValue?.invoiceDate);
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf: boolean) => {
    let lazy: any = {
      ...lazyState,
      rows: totalCount > 5000 ? 5000 : totalCount < 1 ? 1 : totalCount,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true);
      const response = await API.getInvoicesList(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data["id"] = eachValue?.id;
        data['number'] = eachValue?.number ? eachValue?.number : "WIP";
        data['lockedDate'] = eachValue?.lockedDate;
        data['paidState'] = eachValue?.paidState;
        data['jobId'] = eachValue?.jobId;
        data['JobId'] = eachValue?.jobSheetNumber;
        data['InvoiceTypeId'] = eachValue?.invoiceTypeName;
        data['serviceCharge'] = eachValue?.serviceCharge;
        data['discount'] = eachValue?.discount;
        data['total'] = eachValue?.total;
        data['locked'] = eachValue?.locked;
        data['isDeleted'] = eachValue?.isDeleted;
        data['totalAfterDiscount'] = eachValue?.totalAfterDiscount;
        data['totalPriceofpart'] = eachValue?.totalPriceOfParts;
        data['totalTaxes'] = eachValue?.totalTaxes;
        data['DealerId'] = eachValue?.dealerName ? eachValue?.dealerName : "";
        data['CustomerId'] = eachValue?.customerName ? eachValue?.customerName : "";
        data['billToName'] = eachValue?.billToName;
        data['invoiceDate'] = eachValue?.lockedDate;
        data['dealerIdtoNavigate'] = eachValue?.dealerId;
        data['customerId'] = eachValue?.customerId;
        return data;
      });
      if (isPdf) {

        saveDataAsPdf(modifeidValue, columns, "invoices");
      } else {
        saveDataAsExcel(modifeidValue, "invoices");
      }
      setPdfLoading(false);
    } catch (error: any) {
      showToast(error.message, { type: "error" });
      setPdfLoading(false);

    } finally {
      setPdfLoading(false);
    }
  };
  const csvClickHandler = () => {
    fetchData(false);
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((eachValue) => {
      let datas = {
        number: eachValue?.number ? eachValue?.number : "WIP",
        lockedDate: eachValue?.lockedDate,
        paidState: eachValue?.paidState,
        JobId: eachValue?.JobId,
        InvoiceTypeId: eachValue?.InvoiceTypeId,
        DealerId: eachValue?.DealerId,
        CustomerId: eachValue?.CustomerId,
        serviceCharge: eachValue?.serviceCharge,
        discount: eachValue?.discount,
        total: eachValue?.total,
        locked: eachValue?.locked,
        totalAfterDiscount: eachValue?.totalAfterDiscount,
        totalPriceofpart: eachValue?.totalPriceofpart ? eachValue?.totalPriceofpart : "-",
        totalTaxes: eachValue?.totalTaxes,
        billToName: eachValue?.billToName ? eachValue?.billToName : "-",
        dealerName: eachValue?.dealerName ? eachValue?.dealerName : "_",
        customerName: eachValue?.customerName ? eachValue?.customerName : "_",
        invoiceDate: formatDateMEL(eachValue?.invoiceDate)
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a2',
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const pdfClickHandler = () => {
    fetchData(true);
  };

  const viewHandler = (rowData: any) => {
    navigate(`${ROUTER.INVOICE_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: any) => {
    navigate(`${ROUTER.INVOICE_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: any) => {
    setDeleteModal(true);
    setRowData(rowData);
  };


  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {((rowData.locked == true || rowData.isDeleted == true) || !hasAdminOrEmployeeRoleHandler()) ? <ColumnAction
          displayData={rowData[field] as string}
          isEdit={false}
          isDelete={false}
          isView={true}
          onViewClick={() => viewHandler(rowData)}
        /> : <ColumnAction
          displayData={rowData[field] as string}
          isEdit={true}
          isDelete={true}
          isView={true}
          onViewClick={() => viewHandler(rowData)}
          onEditClick={() => editHandler(rowData)}
          onDeleteClick={() => deleteHandler(rowData)}
        />}

      </>

    );
  };
  const clearFilter = () => {
    setVisibleRight(false);
    setJobsheetNumber(null);
    setCustomer(null);
    setDealer(null);
    setLazyState(initialLazyState);
    setselectedStatus("OnlyActive");
    setJobsheetNumber(null);
    setCustomer(null);
    setInvoiceNumber(null)
    setDealer(null);
  };

  const header =
    <div className="table-header-container">
      <div>Invoices List</div>
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedInvoices.length == 0 && selectedStatus != "Active" && selectedStatus != "Locked" && selectedStatus != "Deleted" && hasAdminOrEmployeeRoleHandler()) && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {(selectedInvoices.length > 0 && hasAdminOrEmployeeRoleHandler()) &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
          <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
            <FeatherIcon icon="archive" className="mr-1" />
            Archive
          </Button>
        </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn delete-space btn_space mr-1" onClick={() => { setDeleteModal(true); }}>
                  <FeatherIcon icon="trash-2" className="mr-1" />
                  <span className="remove_lables">Delete</span>
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedInvoices.length > 0 && <BulkActionStatus count={selectedInvoices.length} title="Invoice"></BulkActionStatus>}
              </motion.div>
            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>
        <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" />
        </Button>
        {hasAdminOrEmployeeRoleHandler() &&
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Invoice
          </Button>
        }

        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
        {hasAdminOrEmployeeRoleHandler() &&
        <div className="reponsive_position-jobsheet">
          <FeatherIcon size={20} onClick={() => setVisibleRight(!visibleRight)} icon="filter" className={"feathet-icon"} />
        </div>
}
      </div>

    </div>;

  //hide error dialog tab
  const hidePopup = () => {
    setVisible(false);
    dispatch(resetPaymentLink());
    dispatch(resetInvoicePageMessages());
  };

  //error dialog
  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };


  //delete invoicepage
  const handleDeleteInvoice = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      lazyState,
    };
    dispatch(invoiceDeleteLoading(payload));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      // setTimeout(() => {
      // dispatch(getFranchiseeListLoading(lazyState));
      // }, 500);
      setSelectAll(false);
      // showToast("Invoices Deleted Successfully", { type: "success" });
      setSelectedInvoices([]);
    }
  };

  //deleteModal action
  const deleteModalClick = () => {

    if (selectedInvoices?.length > 10) {
      showToast("Cannot delete more than 10 items at once", { type: "error" });
      setSelectedInvoices([]);
      setDeleteModal(false);
      setSelectAll(false);
      return setRowData(null);
    }

    if (rowData || selectedInvoices.length == 1) {
      handleDeleteInvoice(rowData || selectedInvoices);
    } else if (selectedInvoices.length > 1) {
      for (let i = 0; i < selectedInvoices.length; i++) {
        handleDeleteInvoice(selectedInvoices[i], selectedInvoices.length, i);
      }
    }
  };

  //archive handler
  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedInvoices([]);
    setArchiveModal(false);
  };

  //customize table action

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');


  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);

    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "", matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
          IsLocked: { value: "", matchMode: "contains" },
          paymentPending: { value: "", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == "OnlyActive") {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
          IsLocked: { value: "", matchMode: "contains" },
          paymentPending: { value: "", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
          IsLocked: { value: "false", matchMode: "contains" },
          paymentPending: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == LOCKED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsLocked: { value: "true", matchMode: "contains" },
          paymentPending: { value: "false", matchMode: "contains" },
          Unlocked: { value: "false", matchMode: "contains" },
        },
      }));
    } else if (formValue == UNLOCKED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsLocked: { value: "false", matchMode: "contains" },
          paymentPending: { value: "false", matchMode: "contains" },
        },
      }));
    } else if (formValue == PAYMENTPENDING) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsLocked: { value: "", matchMode: "contains" },
          paymentPending: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
          IsLocked: { value: null, matchMode: "contains" },
          paymentPending: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false);
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  const onSelectionChange = (event: any) => {
    const value = event.value;
    let unLocked = value.filter((item: any) => (item.locked != true && item.isDeleted != true));
    setSelectedInvoices(unLocked);
    setSelectAll(unLocked.length == paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      let unLocked = modifiedValues.filter((item: any) => item.locked != true && item.isDeleted != true);
      setSelectedInvoices(unLocked);
      setSelectAll(unLocked.length == paginatorCount);
    } else {
      setSelectAll(false);
      setSelectedInvoices([]);
    }
  };


  const onSort = (event: any) => {
    setLazyState(event);
    setSelectedInvoices([]);
    setSelectAll(false);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['LockedFrom']['value'] = event?.filters?.invoiceDate?.value?.startDate ? event?.filters?.invoiceDate?.value?.startDate : '';
    event['filters']['LockedTo']['value'] = event?.filters?.invoiceDate?.value?.endDate ? event?.filters?.invoiceDate?.value?.endDate : '';
    setLazyState(event);
  };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.invoiceDate.value != "") {
      modifiedLazyState.filters.invoiceDate.value = "";
    }
    dispatch(getInvoicesListLoading(lazyState));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedInvoices([]);
    callbackFunction(lazyState);
    dispatch(getInvoicePageInvoiceListLoading());
    dispatch(getCustomerDropForInvoiceLoading());
    dispatch(getDealerDropForInvoiceLoading());
    dispatch(LoadFullJobSheetLoading());
    dispatch(LoadFullInvoiceLoading());
  }, [lazyState]);

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  }, [invoiceDropdown, customerList, dealerList]);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetInvoicePageMessages());
      dispatch(getInvoicesListLoading(lazyState));
      dispatch(resetPaymentLink());
    }
  }, [error, message]);
  const [visibleRight, setVisibleRight] = useState<boolean>(false);
  const [jobsheetNumber, setJobsheetNumber] = useState<any>();
  const [invoiceNumber, setInvoiceNumber] = useState<any>();
  const [customer, setCustomer] = useState<any>();
  const [dealer, setDealer] = useState<any>();
  const searchCustomCalendar = (event: any) => {
    setVisibleRight(false);
    setLazyState((state: any) => ({
      ...state,
      filters: {
        ...state.filters,
        JobSheetList: { value: jobsheetNumber, matchMode: "contains" },
        CustomerIds: { value: customer, matchMode: "contains" },
        DealerIds: { value: dealer, matchMode: "contains" },
        InvoiceNumberList: { value: invoiceNumber, matchMode: "contains" }
      }
    }));

  };
  const clearCustomCalendar = (event: any) => {
    setVisibleRight(false);
    setJobsheetNumber(null);
    setCustomer(null);
    setInvoiceNumber(null)
    setDealer(null);
    setLazyState(initialLazyState);
    setselectedStatus("OnlyActive");
  };
  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={deleteModalClick}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these administrators?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedInvoices([]);
          }}
        />

        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {visibleRight && <div className="jobsheet-filter-parent">
          <div className="header-jobsheet">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Filters</h5>
              </div>
              <div className="cancel">
                <FeatherIcon icon={"x-square"} onClick={() => setVisibleRight(false)} />
              </div>
            </div>
          </div>
          <hr />
          <div className="job-filter-container mt-2" style={{ position: 'relative' }}>
            <div className="filter-content mt-4">
              <div className="mb-2" style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="">Jobsheet</label>
                <MultiSelect
                  value={jobsheetNumber}
                  options={jobListOption}
                  onChange={(e) => {
                    setJobsheetNumber(e.value);
                  }}
                  filter={true}
                  selectAll={false}
                  showSelectAll={false}
                  virtualScrollerOptions={{ itemSize: 15 }}
                  maxLength={5}
                  maxSelectedLabels={2}
                  placeholder="Select Items"
                  className="w-full md:w-20rem"
                />
              </div>
              <div className="mb-2" style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="">Invoice Number</label>
                <MultiSelect
                  value={invoiceNumber}
                  options={invoiceListOptions}
                  onChange={(e) => {
                    setInvoiceNumber(e.value);
                  }}
                  filter={true}
                  selectAll={false}
                  showSelectAll={false}
                  virtualScrollerOptions={{ itemSize: 15 }}
                  maxLength={5}
                  maxSelectedLabels={2}
                  placeholder="Select Items"
                  className="w-full md:w-20rem"
                />
              </div>
              <div className="mb-2" style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="">Customer</label>
                <MultiSelect
                  value={customer}
                  options={customerList?.length > 0 ? (customerList?.map((customer: any) => {
                    let data: any = {};
                    data['value'] = customer.id;
                    data['label'] = customer.name;
                    return data;
                  })).sort((a: any, b: any) => {
                    const labelA = a.label.toUpperCase().trim();
                    const labelB = b.label.toUpperCase().trim();
                    if (labelA < labelB) {
                      return -1;
                    }
                    if (labelA > labelB) {
                      return 1;
                    }
                    return 0;
                  }) : []}
                  onChange={(e) => {
                    setCustomer(e.value);
                  }}
                  filter={true}
                  selectAll={false}
                  showSelectAll={false}
                  virtualScrollerOptions={{ itemSize: 15 }}
                  maxLength={5}
                  maxSelectedLabels={2}
                  placeholder="Select Items"
                  className="w-full md:w-20rem"
                />
              </div>
              <div className="mb-2" style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="">Dealer</label>
                <MultiSelect
                  value={dealer}
                  options={dealerList?.length > 0 ? (dealerList?.map((dealer: any) => {
                    let data: any = {};
                    data['value'] = dealer.id;
                    data['label'] = dealer.name;
                    return data;
                  })).sort((a: any, b: any) => {
                    const labelA = a.label.toUpperCase().trim();
                    const labelB = b.label.toUpperCase().trim();
                    if (labelA < labelB) {
                      return -1;
                    }
                    if (labelA > labelB) {
                      return 1;
                    }
                    return 0;
                  }) : []}
                  onChange={(e) => {
                    setDealer(e.value);
                  }}
                  filter={true}
                  selectAll={false}
                  showSelectAll={false}
                  virtualScrollerOptions={{ itemSize: 15 }}
                  maxLength={5}
                  maxSelectedLabels={2}
                  placeholder="Select Items"
                  className="w-full md:w-20rem"
                />
              </div>
              <div className="d-flex justify-content-end gap-2">
                <button aria-label="Clear" className="p-button p-component p-button-outlined p-button-sm" type="button" onClick={clearCustomCalendar}>
                  <span className="p-button-label p-c">Clear</span>
                </button>
                <button aria-label="Apply" className="p-button p-component p-button-sm" type="button" onClick={searchCustomCalendar}>
                  <span className="p-button-label p-c">Apply</span>
                </button>
              </div>
            </div>
          </div>
        </div>}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={((selectedStatus == "Active" || selectedStatus == "Deleted" || selectedStatus == "Locked") || !hasAdminOrEmployeeRoleHandler()) ? [...visibleColumns]?.filter((items: any) => items.field != "select") : visibleColumns}
          header={header}
          filterIcon={FilterIcon}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalCount}
          loading={loading}
          emptyMessage="No Invoices found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Invoice  {last} records of {totalRecords} in total"
          selection={selectedInvoices}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </React.Fragment>
  );
};

export default InvoiceList;