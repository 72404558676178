import React, { useEffect, useRef, useState } from "react";
import { Button, Input, UncontrolledTooltip } from "reactstrap";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import { Columns, LazyTableState, } from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { formatDateMEL, saveAsExcelFile } from "src/helpers/common_helpers";
import { vehicleBrands } from "src/models/pages/vehicleBrandsModel";
import { ClearVehicleBrandImage, clearVehicleBrandsMessage, deleteVehicleBrandsFromList, getVehicleBrandsList } from "src/store/vehicleBrands/action";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import FeatherIcon from "feather-icons-react";
import TooltipMEL from "src/components/UI/Tooltip";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
import { deleteVoucherLoading, getCustomerDropdownList, getDealerDropdownList, getEmployeeDropdownList, getFranchiseDropdownList, getPaymentType, getSupplierDropdownList, getVoucherList, getVoucherType, resetVoucher } from "src/store/voucher/action";
import { RootReducerState } from "src/store/reducers";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Link } from "react-router-dom";


export default function VoucherList() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.VoucherReducer.voucherList);
  const [voucherValues,setVoucherValues]=useState<any>([])
  const { error, message, paginatorCount, loading, voucherTypes, paymentTypeList,employeeList,franchiseList,dealerList,customerList,supplierList} = useSelector((state: RootReducerState) => state.VoucherReducer);
  const { showToast } = useToast();
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<vehicleBrands | null>(null);
  const filterDetails: DataTableFilterMeta = {
    voucherNumber: { value: "", matchMode: "contains" },
    voucherName: { value: "", matchMode: "contains" },
    voucherCategorieName: { value: "", matchMode: "contains" },
    vocherDate: { value: "", matchMode: "contains" },
    voucherStartDate: { value: "", matchMode: "contains" },
    voucherEndDate: { value: "", matchMode: "contains" },
    employeeId: { value: "", matchMode: "contains" },
    dealerId: { value: "", matchMode: "contains" },
    franchiseId: { value: "", matchMode: "contains" },
    customerId: { value: "", matchMode: "contains" },
    supplierId: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "Name",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const paymentPaidTemplate = (data:any)=>{
    return paymentTypeList?.find((val:any)=>val?.id == data)?.name
  }
useEffect(()=>{
let val = VoucherData(values)
setVoucherValues(val)
},[values])
  const VoucherData = (values:any)=>{
    const destructureedData = values.map((eachValue:any) => {
      let data: any = {};
      data['id'] = eachValue.id;
      data['voucherNumber'] = eachValue.voucherNumber;
      data['voucherCategorieName'] = eachValue.voucherCategorieName;
      data['voucherName'] = eachValue.voucherName;
      data['vocherDate'] = formatDateMEL(eachValue.vocherDate);
      data['attachedBills'] = eachValue.attachedBills;
      data['total'] = eachValue.total;
      data['lessTDS'] = eachValue.lessTDS;
      data['roundOff'] = eachValue.roundOff;
      data['grandTotal'] = eachValue.grandTotal;
      data['remittanceDetails'] = eachValue.remittanceDetails;
      data['paymentPaidBy'] =paymentPaidTemplate(eachValue.paymentPaidBy);
      data['payoutDate'] = formatDateMEL(eachValue.payoutDate);
      data['dealerId'] = eachValue.dealerName;
      data['dealerUserId'] = eachValue.dealerId;
      data['customerId'] = eachValue.customerName;
      data['customerUSerId'] = eachValue.customerId;
      data['employeeId'] = eachValue.employeeName;
      data['employeeUserId'] = eachValue.employeeId;
      data['franchiseId'] = eachValue.franchiseName;
      data['franchiseUserId'] = eachValue.franchiseId;
      data['supplierId'] = eachValue.supplierName;
      data['supplierUserId'] = eachValue.supplierId;
      data['isDeleted'] = eachValue.isDeleted;
      return data
    });
    return destructureedData
  }

  useEffect(() => {
    dispatch(getVoucherType())
    dispatch(getPaymentType())
  }, [])

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.VOUCHER_CREATE);
  };

  const viewHandler = (rowData: vehicleBrands) => {
    navigate(`${ROUTER.VOUCHER_VIEW}/${rowData["id"]}`);
  };
  const editHandler = (rowData: vehicleBrands) => {
    navigate(`${ROUTER.VOUCHER_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: vehicleBrands) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          rowData.isDeleted == false ? (<ColumnAction
            displayData={rowData[field] as string}
            isEdit={true}
            isDelete={true}
            isView={true}
            onViewClick={() => viewHandler(rowData)}
            onEditClick={() => editHandler(rowData)}
            onDeleteClick={() => deleteHandler(rowData)}
          />
          ) : (
            <ColumnAction
              displayData={rowData[field] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }
      </>
    );
  };

  const statusBodyTemplate = (rowData: vehicleBrands, field: string) => {
    let status = rowData[field] as string
    return (
      <StatusHghlighter status={status} />
    )

  }

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
    // {
    //   label: "Awaiting Login Approval",
    //   value: "Awaiting Login Approval",
    // },
    // {
    //   label: "Awaiting Verification",
    //   value: "Awaiting Verification",
    // },
    // {
    //   label: "Awaiting Confirmation",
    //   value: "Awaiting Confirmation",
    // },
    // {
    //   label: "Active Selected",
    //   value: "Active Selected",
    // },
  ];

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);
  

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetVoucher());
      setTimeout(() => {
        dispatch(getVoucherList(lazyState));
      }, 1000);
    }
  }, [error, message]);

	const hidePopup = () => {
		setVisible(false);
    dispatch(resetVoucher())
	};

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    </>
    );
  };

  const voucherNameFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(voucherTypes?.length > 0) ? (voucherTypes?.map((val:any) => {
          let data: any = {};
          data['label'] = val?.name;
          data['value'] = val?.name;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>);
  };

  const VoucherTypeList = [
    {label:"Expense",value:"0"},
    {label:"Purchase",value:1}
]

  const voucherCategoryNameFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(VoucherTypeList?.length > 0) ? (VoucherTypeList?.map((val:any) => {
          let data: any = {};
          data['label'] = val?.label;
          data['value'] = val?.label;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>);
  };

  const customerFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(customerList?.length > 0) ? (customerList?.map((val:any) => {
          let data: any = {};
          data['label'] = val?.label;
          data['value'] = val?.value;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>);
  };

  const dealerFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(dealerList?.length > 0) ? (dealerList?.map((val:any) => {
          let data: any = {};
          data['label'] = val?.label;
          data['value'] = val?.value;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          debugger
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>);
  };

  const employeeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(employeeList?.length > 0) ? (employeeList?.map((val:any) => {
          let data: any = {};
          data['label'] = val?.label;
          data['value'] = val?.value;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>);
  };

  const franchiseFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(franchiseList?.length > 0) ? (franchiseList?.map((val:any) => {
          let data: any = {};
          data['label'] = val?.label;
          data['value'] = val?.value;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>);
  };

  const supplierFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(supplierList?.length > 0) ? (supplierList?.map((val:any) => {
          let data: any = {};
          data['label'] = val?.label;
          data['value'] = val?.value;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>);
  };

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input
        type="date"
        required
        value={options?.value?.startDate || ""}
        max={options?.value?.endDate ? options?.value?.endDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['startDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date"
        required
        value={options?.value?.endDate || ""}
        min={options?.value?.startDate ? options?.value?.startDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['endDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }} />
    </>);
  };

  

const CustomerTemplate = (rowData:any)=>{
  return(
    <Link to={`${ROUTER.CUSTOMERS_VIEW}/${rowData.customerUserId}`}>{rowData.customerId}</Link>
  )
}
const EmployeeTemplate = (rowData:any)=>{
  return(
    <Link to={`${ROUTER.USERS_VIEW}/${rowData.employeeUserId}`}>{rowData.employeeId}</Link>
  )
}
const DealerTemplate = (rowData:any)=>{
  return(
    <Link to={`${ROUTER.DEALERS_VIEW}/${rowData.dealerUserId}`}>{rowData.dealerId}</Link>
  )
}
const FranchiseTemplate = (rowData:any)=>{
  return(
    <Link to={`${ROUTER.FRANCHISEE_VIEW}/${rowData.franchiseUserId}`}>{rowData.franchiseId}</Link>
  )
}
const SupplierTemplate = (rowData:any)=>{
  return(
    <Link to={`${ROUTER.SUPPLIER_VIEW}/${rowData.supplierUserId}`}>{rowData.supplierId}</Link>
  )
}
  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      frozen : true,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "voucherNumber",
      sortable: true,
      header: "Voucher Number",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "voucherNumber"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "voucherCategorieName",
      sortable: true,
      header: "Voucher Type",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style",
      filterElement:voucherCategoryNameFilterTemplate,
    },
    {
      field: "voucherName",
      sortable: true,
      header: "Voucher Sub Type",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style",
      filterElement:voucherNameFilterTemplate
    },

    {
      field: "vocherDate",
      sortable: true,
      header: "Voucher Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
      isDefault: true,
      body: (rowData) => formatDateMEL(rowData.vocherDate),
      isStatic: false,
      filterElement:dateFilterTemplate
    },

    {
      field: "attachedBills",
      sortable: true,
      header: "Bills Attached",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData,'attachedBills'),
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    // {
    //   field: "total",
    //   sortable: true,
    //   header: "Total",
    //   filter: false,
    //   showFilterMenu: false,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isDefault: true,
    //   isStatic: false,
    //   className: "table-cell-style"
    // },
    {
      field: "lessTDS",
      sortable: false,
      header: "Less TDS",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap"},
      className: "table-cell-style align-right",
      isDefault: true,
      isStatic: false,

    },
    // {
    //   field: "roundOff",
    //   sortable: false,
    //   header: "Round Off",
    //   filter: false,
    //   showFilterMenu: false,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isDefault: false,
    //   isStatic: false,
    //   className: "table-cell-style"
    // },
    {
      field: "grandTotal",
      sortable: true,
      header: "Grand Total",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap"},
      body:(rowData:any)=>rowData.grandTotal.toFixed(2,0),
      className: "table-cell-style align-right",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "remittanceDetails",
      sortable: true,
      header: "Remittance Details",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "paymentPaidBy",
      sortable: true,
      header: "Payment Type",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", },
      body:(rowData:any)=>paymentPaidTemplate(rowData.paymentPaidBy),
      isDefault: false,
      isStatic: false,
      className: "table-cell-style",
    },
    {
        field: "payoutDate",
        sortable: true,
        header: "Payout Date",
        filter: false,
        showFilterMenu: false,
        headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
        isDefault: true,
        body: (rowData) => formatDateMEL(rowData.payoutDate),
        isStatic: false,
      },
      {
        field: "dealerId",
        sortable: false,
        header: "Dealer",
        filter: true,
        showFilterMenu: true,
        headerStyle: { whiteSpace: "nowrap", },
        body:(rowData:any)=>DealerTemplate(rowData),
        isDefault: true,
        isStatic: false,
        className: "table-cell-style",
        filterElement:dealerFilterTemplate
      },
      {
        field: "customerId",
        sortable: false,
        header: "Customer",
        filter: true,
        showFilterMenu: true,
        headerStyle: { whiteSpace: "nowrap", },
        body:(rowData:any)=>CustomerTemplate(rowData),
        isDefault: true,
        isStatic: false,
        className: "table-cell-style",
        filterElement:customerFilterTemplate
      },
      {
        field: "employeeId",
        sortable: false,
        header: "Employee",
        filter: true,
        showFilterMenu: true,
        headerStyle: { whiteSpace: "nowrap", },
        body:(rowData:any)=>EmployeeTemplate(rowData),
        isDefault: true,
        isStatic: false,
        className: "table-cell-style",
        filterElement:employeeFilterTemplate
      },
      {
        field: "franchiseId",
        sortable: false,
        header: "Franchise",
        filter: true,
        showFilterMenu: true,
        headerStyle: { whiteSpace: "nowrap", },
        body:(rowData:any)=>FranchiseTemplate(rowData),
        isDefault: true,
        isStatic: false,
        className: "table-cell-style",
        filterElement:franchiseFilterTemplate
      },
      {
        field: "supplierId",
        sortable: false,
        header: "Supplier",
        filter: true,
        showFilterMenu: true,
        headerStyle: { whiteSpace: "nowrap", },
        body:(rowData:any)=>SupplierTemplate(rowData),
        isDefault: true,
        isStatic: false,
        className: "table-cell-style",
        filterElement:supplierFilterTemplate
      },
      
  ];

  useEffect(()=>{
    dispatch(getEmployeeDropdownList())
    dispatch(getCustomerDropdownList())
    dispatch(getFranchiseDropdownList())
    dispatch(getDealerDropdownList())
    dispatch(getSupplierDropdownList())
    return()=>{
        dispatch(resetVoucher())
    }
  },[])

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  useEffect(()=>{
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
  },[voucherTypes,employeeList,franchiseList,dealerList,customerList,supplierList])

  const changeColumnDetails = (values: any[]) => {
    let data:any = {}
    let modifiedColumnDetails = values.map((eachValue:any) => {
      data['Voucher Number'] = eachValue.voucherNumber;
      data['Voucher Type'] = eachValue.voucherCategorieName;
      data['Voucher Sub Type'] = eachValue.voucherName;
      data['Voucher Date'] = formatDateMEL(eachValue.vocherDate);
      data['Attached Bills'] = eachValue.attachedBills ?  'Yes' : 'No';
      data['Total'] = eachValue.total;
      data['Less TDS'] = eachValue.lessTDS;
      data['Round Off'] = eachValue.roundOff;
      data['Grand Total'] = eachValue.grandTotal;
      data['Remittance Details'] = eachValue.remittanceDetails;
      data['Payment Paid By'] =paymentPaidTemplate(eachValue.paymentPaidBy);
      data['Payout Date'] = formatDateMEL(eachValue.payoutDate);
      data['Dealer Name'] = eachValue.dealerName;
      data['Customer Name'] = eachValue.customerName;
      data['Employee Name'] = eachValue.employeeName;
      data['Franchise Name'] = eachValue.franchiseName;
      data['Supplier Name'] = eachValue.supplierName;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let lazy:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
	
    try {
      setPdfLoading(true)
      const response = await API.getVoucherListAPI(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      
     if(isPdf){

       saveDataAsPdf(response?.data?.items, columns, "voucher_list");
     }else{
      saveDataAsExcel(response?.data?.items, "voucher_list");
     }
     setPdfLoading(false)
    } catch (error:any) {
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(values, "vehicle_brands");
    fetchData(false)
  };


  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((eachValue) => {
      let data: any = {};
      data['voucherNumber'] = eachValue.voucherNumber;
      data['voucherCategorieName'] = eachValue.voucherCategorieName;
      data['voucherName'] = eachValue.voucherName;
      data['vocherDate'] = formatDateMEL(eachValue.vocherDate);
      data['attachedBills'] = eachValue.attachedBills ? 'Yes' : 'No';
      data['total'] = eachValue.total;
      data['lessTDS'] = eachValue.lessTDS;
      data['roundOff'] = eachValue.roundOff;
      data['grandTotal'] = eachValue.grandTotal;
      data['remittanceDetails'] = eachValue.remittanceDetails;
      data['paymentPaidBy'] =paymentPaidTemplate(eachValue.paymentPaidBy);
      data['payoutDate'] = formatDateMEL(eachValue.payoutDate);
      data['dealerName'] = eachValue.dealerName;
      data['customerName'] = eachValue.customerName;
      data['employeeName'] = eachValue.employeeName;
      data['franchiseName'] = eachValue.franchiseName;
      data['supplierName'] = eachValue.supplierName;
      return data
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        
        
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });
        
        doc.autoTable({
          columns: exportColumns,
          body: destructureedData,
          headStyles:{
            fillColor: [42, 125, 182],
            textColor: [255, 255, 255],
          },
          styles: {
            fontSize: 8, 
            overflow: 'linebreak',
            halign: 'center',
            valign: 'middle',
          },
          theme: 'grid',
          startY: 20,
        });
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(values, columns, "vehicle_brands");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };

    if (modifiedLazyState.filters && modifiedLazyState.filters.vocherDate) {
      modifiedLazyState.filters.vocherDate.value = "";
    }
    dispatch(getVoucherList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedVoucherList, setSelectedVoucherList] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;

    setSelectedVoucherList(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedVoucherList(voucherValues);
    } else {
      setSelectAll(false);
      setSelectedVoucherList([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedVoucherList([]);
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['voucherStartDate']['value'] = event?.filters?.vocherDate?.value?.startDate ? event?.filters?.vocherDate?.value?.startDate : '';
    event['filters']['voucherEndDate']['value'] = event?.filters?.vocherDate?.value?.endDate ? event?.filters?.vocherDate?.value?.endDate : '';
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedVoucherList([]);
    callbackFunction(lazyState);
  }, [lazyState]);

  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData?.isDeleted == true ? true : rowData[0]?.isDeleted == true ? true : false,
      lazyState,
    };
    dispatch(deleteVoucherLoading(payload));
    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      // showToast("Vehicle Brand Deleted Successfully", { type: "success" });
      setSelectedVoucherList([]);
      // setTimeout(() => {
      //   dispatch(getVehicleBrandsList(lazyState));
      // }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false)
    setSelectedVoucherList([])
    setArchiveModal(false)
  }

  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([ {...defaultColumns},...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if (voucherValues.length > 0) {
      setSelectAll(true);
      setSelectedVoucherList(voucherValues);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedVoucherList([]);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{...defaultColumns}, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>
        Vouchers List
      </div>
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedVoucherList.length == 0&& selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedVoucherList.length > 0 &&
            <>
            
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT} >
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon    icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedVoucherList.length > 0 && <BulkActionStatus count={selectedVoucherList.length} title="Vouchers"></BulkActionStatus>}
              </motion.div>


            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile isShowCsv={true} isShowPdf={true} exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
          <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
            <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
              filter className="w-full md:w-14rem mr-1" />
          </TooltipMEL>
        </div>
        <Button type="button" className="customize-table-button mr-1 ml-1" id="ScheduleUpdateTooltip"
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
        <FeatherIcon icon="sliders" className="" />
      </Button>
        {/* <TooltipMEL title="Add Vehicle Brand" position="bottom"> */}
        <div className="reponsive_position">
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Voucher
          </Button>
        </div>
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
        placement="bottom"
        target="ScheduleUpdateTooltip"
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        style={{
          backgroundColor: '#324f6b', // Change tooltip background color

        }}
      >
        Choose fields you want to see in the table
      </UncontrolledTooltip>
      </div>

    </div>;


  return (
    <>
      <div className="page-content" id="view-brand">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedVoucherList?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedVoucherList([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (rowData || selectedVoucherList.length == 1) {
              handleDeleteUsers(rowData || selectedVoucherList);
            } else if (selectedVoucherList.length > 1) {
              for (let i = 0; i < selectedVoucherList.length; i++) {
                handleDeleteUsers(selectedVoucherList[i], selectedVoucherList.length, i);
              }
            }

          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these vehicle brands?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedVoucherList([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          value={voucherValues}
          lazy
          paginator={true}
          filterIcon={FilterIcon}
          header={header}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Vouchers  {last} records of {totalRecords} in total"
          selection={selectedVoucherList}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
