import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router'
import { Col, Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { ROUTER } from 'src/constants/routes';
import { generatedocumentLinkLoading, getFranchiseeByIdLoading, getFranchiseeImageLoading, getSubuserListLoading, getuploadedeDocumentinFranchiseLoading, resetSubuser } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import './franchiseStyle.scss'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import { DataTableFilterMeta } from 'primereact/datatable';
import EmailBodyTemplate from 'src/components/Common/EmailBodyTemplate/EmailBodyTemplate';
import StatusHighlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel';
import { Image } from 'primereact/image';
import { formatDateMEL, hasFranchiseRoleHandler, hasFranchiseSubuserRoleHandler } from 'src/helpers/common_helpers';
import FeatherIcon from "feather-icons-react";

const ViewFranchisee = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState("");
  const { loading, franchiseeDetails, franchiseImage, getUploadedList, documentLink } = useSelector((state: RootReducerState) => state.franchiseeReducer);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.subUserReducer.subUserList);
  const [franchiseDetails, setFranchiseDetails] = useState<any>([])
  
  const layoutType = localStorage.getItem('layout')
  useEffect(() => {
    if (id) {
      dispatch(getFranchiseeByIdLoading(id));
      dispatch(getFranchiseeImageLoading(id))
      dispatch(getuploadedeDocumentinFranchiseLoading(id));
    }
  }, [id])

  useEffect(() => {
    return () => {
      dispatch(resetSubuser())
    }
  }, [])

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);



  const editFranchisee = () => {
    navigate(`${ROUTER.FRANCHISEE_EDIT}/${id}`)
  }

  const checkScreenSize = () => {
    const width = window.innerWidth;
    if (width >= 992) {
      setScreenSize('lg');
    } else if (width >= 768 && width < 992) {
      setScreenSize('md');
    } else {
      setScreenSize('sm');
    }
  };



  useEffect(() => {
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [])

  const getMarginTopClassName = (screenSize: any) => {
    switch (screenSize) {
      case "lg":
        return "mt-1";
      case "md":
        return "mt-4";
      default:
        return "mt-4";
    }
  };
  const getMarginTopanotherClassName = (screenSize: any) => {
    switch (screenSize) {
      case "lg":
        return "mt-4";
      case "md":
        return "mt-4";
      default:
        return "mt-4";
    }
  };


  const statusBodyForViewTemplate = (value: any) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )
  }

  const filterDetails: DataTableFilterMeta = {
    userName: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    email: { value: "", matchMode: "contains" },
    phoneNumber: { value: "", matchMode: "contains" },
    FranchiseId: { value: id ? id : "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };

  let initialLazyState: any = {
    // first: 0,
    // rows: null,
    // page: 1,
    // sortField: "userName",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };

  const [lazyState, setLazyState] = useState(initialLazyState);

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getSubuserListLoading(lazyState));
  };

  useEffect(() => {
    callbackFunction(lazyState);
  }, [lazyState]);


  const emailBodyTemplate = (rowData: any, field: string) => {
    let data = rowData[field];
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );

  };

  const columns: Columns[] = [

    {
      field: "userName",
      sortable: false,
      header: "User Name",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },

    {
      field: "name",
      sortable: false,
      header: "Name",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "200px", marginLeft: "20px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "email",
      sortable: false,
      header: "Email",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
      isDefault: true,
      body: (rowData) => emailBodyTemplate(rowData, "email"),
      isStatic: false,
    },
    {
      field: "phoneNumber",
      sortable: false,
      header: "Mobile",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "isActive",
      sortable: false,
      header: "Is Active",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'isActive'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "lockoutEnabled",
      sortable: false,
      header: "Lockout Enabled",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'lockoutEnabled'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "address",
      sortable: false,
      header: "Address",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
  ];

  useEffect(() => {
    debugger
    if (documentLink != "" && documentLink != undefined) {
      const newWindow = window.open(documentLink)
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
    }
  }, [documentLink])

  const handleDownload = (data: any) => {
    dispatch(generatedocumentLinkLoading(data?.id))
  }



  return (
    <React.Fragment>
      <div className='franchiseePage' id={layoutType == "horizontal" ? 'franchiseePageLand' : 'franchiseePage'}>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Franchise" titlePath={(hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) ? '' : ROUTER.FRANCHISEE_GRID} breadcrumbItem='Franchise Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel='Edit Franchise'
            onFirstButtonClick={editFranchisee}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
                <Row>
                  <Col lg={6}>
                    <div className="parentCls2">

                      <div className={` pt-1 childCls`}>
                        <div>Name</div>
                        <b>{franchiseeDetails?.user?.name ? franchiseeDetails?.user?.name : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>User Name</div>
                        <b>{franchiseeDetails?.user?.userName ? franchiseeDetails?.user?.userName : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>Email</div>
                        <b>{franchiseeDetails?.user?.email ? franchiseeDetails?.user?.email : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>Address</div>
                        <b>{franchiseeDetails?.user?.extraProperties?.Address ? franchiseeDetails?.user?.extraProperties?.Address : <div className='none-text'>-</div>}</b>
                      </div>
                      {/* <div className={` pt-1 childCls`}>
                        <div>Alternative Address</div>
                        <b>{franchiseeDetails?.alternateAddress ? franchiseeDetails?.alternateAddress : <div className='none-text'>-</div>}</b>
                      </div> */}
                      <div className={` pt-1 childCls`}>
                        <div>Date Of Birth</div>
                        <b>{franchiseeDetails?.user?.extraProperties?.DateOfBirth ? formatDateMEL(franchiseeDetails?.user?.extraProperties?.DateOfBirth) : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>Wedding Date</div>
                        <b>{franchiseeDetails?.user?.extraProperties?.WeddingDate ? formatDateMEL(franchiseeDetails?.user?.extraProperties?.WeddingDate) : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>City</div>
                        <b>{franchiseeDetails?.city ? franchiseeDetails?.city : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>Region</div>
                        <b>{franchiseeDetails?.region ? franchiseeDetails?.region : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>Pincode</div>
                        <b>{franchiseeDetails?.pincode ? franchiseeDetails?.pincode : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>isActive</div>
                        <b>{statusBodyForViewTemplate(franchiseeDetails?.user?.isActive)}</b>
                      </div>

                    </div>
                  </Col>
                  <Col lg={6}>  
                    <Row>
                      <Col lg={4}>
                        <div className='mb-2'>Image</div>
                        {franchiseImage ?
                          // <img src={franchiseImage} className='view-image' />
                          <Image src={franchiseImage} alt="Image" className='view-image' width='150' height='150' preview={true} />
                          :
                          <div className='none-text'>-</div>
                        }
                      </Col>
                      {
                        getUploadedList?.length > 0 && (
                          getUploadedList?.filter((data: any) => data.fileType === "GstNumber").map((res: any) => (
                            <Col lg={4} key={res.id}>
                              <div style={{ fontSize: "16px", fontWeight: 700 }}>GST Document</div>
                              <div style={{ width: "200px", height: "100px", padding: "10px" }}>
                                <FeatherIcon style={{ width: "100%", height: "100%" }} onClick={() => handleDownload(res)} icon="file-text" className={`icon-sm right-icon pointer`} />
                              </div>
                            </Col>
                          ))
                        )
                      }
                      {
                        getUploadedList?.length > 0 && (
                          getUploadedList?.filter((data: any) => data.fileType === "pancard").map((res: any) => (
                            <Col lg={4} key={res.id}>
                              {/* <div style={{ fontSize: "16px", fontWeight: 700 }}>{res.name}</div> */}
                              <div style={{ fontSize: "16px", fontWeight: 700 }}>PAN Document</div>
                              <div style={{ width: "200px", height: "100px", padding: "10px" }}>
                                <FeatherIcon style={{ width: "100%", height: "100%" }} onClick={() => handleDownload(res)} icon="file" className={`icon-sm right-icon pointer`} />
                              </div>
                            </Col>
                          ))
                        )
                      }
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>


          {/* more details */}
          <Row className='mt-3'>
            <div className="view">
              <div className="general-details">
                <b>More Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  <div className={` pt-1 childCls`}>
                    <div>Gst Number</div>
                    <div>{franchiseeDetails?.user?.extraProperties?.GstNumber ? franchiseeDetails?.user?.extraProperties?.GstNumber : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>PAN Number</div>
                    <div>{franchiseeDetails?.user?.extraProperties?.PANCardNo ? franchiseeDetails?.user?.extraProperties?.PANCardNo : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Mobile</div>
                    <div>{franchiseeDetails?.user?.phoneNumber ? franchiseeDetails?.user?.phoneNumber : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Alternate Number</div>
                    <div>{franchiseeDetails?.user?.extraProperties?.AlternateNumber ? franchiseeDetails?.user?.extraProperties?.AlternateNumber : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Send Email</div>
                    <div>{statusBodyForViewTemplate(franchiseeDetails?.user?.extraProperties?.SendEmail == true ? true : false)}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Send Sms</div>
                    <div>{statusBodyForViewTemplate(franchiseeDetails?.user?.extraProperties?.SendSms == true ? true : false)}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Send Whatsapp</div>
                    <div>{statusBodyForViewTemplate(franchiseeDetails?.user?.extraProperties?.SendWhatsapp == true ? true : false)}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Mobile Number Verified</div>
                    <div>{statusBodyForViewTemplate(franchiseeDetails?.user?.phoneNumberConfirmed == true ? true : false)}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Company Name</div>
                    <div>{franchiseeDetails?.companyName ? franchiseeDetails?.companyName : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Home Address</div>
                    <b>{franchiseeDetails?.companyAddress ? franchiseeDetails?.companyAddress : <div className='none-text'>-</div>}</b>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Alternate Address</div>
                    <b>{franchiseeDetails?.alternateAddress ? franchiseeDetails?.alternateAddress : <div className='none-text'>-</div>}</b>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Proprietor Name</div>
                    <b>{franchiseeDetails?.proprietorName ? franchiseeDetails?.proprietorName + " " : <div className='none-text'>-</div>}</b>
                  </div>

                </div>
              </div>
            </div>
          </Row>

          {/* user details */}
          <Row className='mt-3'>
            <div className="view">
              <div className="general-details">
                <b>Sub-user Details</b>
              </div>
              <div className="subview">
                <DataTableWrapper
                  columnDetails={columns}
                  value={values}
                  stripedRows={true}
                  rowHover={true}
                  loading={loading}
                  emptyMessage="No users found."
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Displaying Users  {last} records of {totalRecords} in total"
                ></DataTableWrapper>
              </div>
            </div>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewFranchisee