import React, { useEffect, useRef, useState } from 'react'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import { Columns, LazyTableState } from "src/models/components/dataTableWrapperModel";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Formik } from 'formik';
import * as Yup from "yup";
import { ACTIVE, ALL, LOCKED, PACKAGENOTSENT, PACKAGESENT } from 'src/constants/commonConstants';
import UIButton from 'src/components/UI/Button'
import {  Button as ReactStrapButton , Col, Form, Row, UncontrolledTooltip } from 'reactstrap';
import Select from 'src/components/Common/Select';
import { Option } from "src/models/components/inputWrapperModel";
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import FeatherIcon from "feather-icons-react";
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getJobsheetTatReportsTatLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { formatDateMEL, saveAsExcelFile } from 'src/helpers/common_helpers';
import { useToast } from 'src/components/Common/ReactToaster';
import { API } from 'src/util/api';
import { customizeTableColumns } from 'src/models/components/CustomizeTableColumns';
import StatusHighlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { getPendingJobsheetTatReportsLoading } from 'src/store/PendingJobsheetTatReports/action';
import { Link } from 'react-router-dom';
import { ROUTER } from 'src/constants/routes';
import { OverlayPanel } from 'primereact/overlaypanel';



const pendingjobsheetTatreportListConverson = (values: any) => {
  const modifeidValue = values?.items?.map((eachValue: any) => {
    let data: any = {};
    data['number'] = eachValue?.number;
    data['totalTat'] = eachValue?.totalTat ? eachValue?.totalTat : "-";
    data['serviceRequestNumber'] = eachValue?.serviceRequestNumber ? eachValue?.serviceRequestNumber : "-";
    data['vehicleBrandName'] = eachValue?.vehicleBrandName ? eachValue?.vehicleBrandName : "-";
    data['vehicleBrandId'] = eachValue?.vehicleBrandId ? eachValue?.vehicleBrandId : "-";
    data['dealerName'] = eachValue?.dealerName ? eachValue?.dealerName : "-";
    data['dealerId'] = eachValue?.dealerId ? eachValue?.dealerId : null;
    data['customerName'] = eachValue?.customerName ? eachValue?.customerName : "-";
    data['customerId'] = eachValue?.customerId ? eachValue?.customerId : null;
    data['status'] = eachValue?.status ? eachValue?.status : "-";
    data['inPending'] = eachValue?.inPending ? eachValue?.inPending : false;
    data['creationTime'] = eachValue?.creationTime ? eachValue?.creationTime : "";
    return data;
  });
  return modifeidValue;
};

const convertTimesToMinutes = (minutes: any) => {
  let years = Math.floor(minutes / (60 * 24 * 365));
  minutes %= (60 * 24 * 365);
  let months = Math.floor(minutes / (60 * 24 * 30));
  minutes %= (60 * 24 * 30);
  let days = Math.floor(minutes / (60 * 24));
  minutes %= (60 * 24);
  let hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;
  let output = [];
  if (years > 0) {
    output.push(`${years} year${years > 1 ? 's' : ''}`);
  }
  if (months > 0) {
    output.push(`${months} month${months > 1 ? 's' : ''}`);
  }
  if (days > 0) {
    output.push(`${days} day${days > 1 ? 's' : ''}`);
  }
  if (hours > 0) {
    output.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }
  if (remainingMinutes > 0) {
    output.push(`${remainingMinutes.toFixed(1)} minute${remainingMinutes > 1 ? 's' : ''}`);
  }
  if (output.length === 0) {
    return "less than a minute";
  } else {
    return (output.join(", "));
  }
}

const headerTemplate = (val: any) : any => {
  return (
    convertTimesToMinutes(val)
  )
}

const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );
  };
const dealerBodyTemplate=(rowData: any)=>{
  return(
    <>
    {(rowData.dealerName && rowData.dealerId) ? <Link to={ROUTER.DEALERS_VIEW+"/"+rowData.dealerId}>{rowData.dealerName}</Link> : rowData.dealerName}
    </>
  )
}
const customerBodyTemplate=(rowData: any)=>{
  return(
    <>
    {(rowData.customerName && rowData.customerId) ? <Link to={ROUTER.CUSTOMERS_VIEW+"/"+rowData.customerId}>{rowData.customerName}</Link> : rowData.customerName}
    </>
  )
}
const vehicleBrandBodyTemplate=(rowData: any)=>{
  return(
    <>
    {(rowData.vehicleBrandName && rowData.vehicleBrandId) ? <Link to={ROUTER.CUSTOMERS_VIEW+"/"+rowData.vehicleBrandId}>{rowData.vehicleBrandName}</Link> : rowData.vehicleBrandName}
    </>
  )
}

const PendingJobsheetTatReports = () => {
  const columns: Columns[] = [
    // {
    //   field: "id",
    //   sortable: false,
    //   header: "#",
    //   filter: true,
    //   showFilterMenu: true,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "number",
      sortable: false,
      header: "Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "totalTat",
      sortable: true,
      header: "Total Tat",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      // body: (rowData: any) => headerTemplate(rowData.totalTat)
      body: (rowData: any) => headerTemplate(((rowData.totalTat ? rowData.totalTat : 0) + 1) / 60)
    },
    {
        field: "serviceRequestNumber",
        sortable: false,
        header: "Service Request Number",
        filter: false,
        showFilterMenu: false,
        headerStyle: { whiteSpace: "nowrap" },
        isDefault: true,
        isStatic: false,
    },
    {
        field: "vehicleBrandName",
        sortable: false,
        header: "Vehicle Brand",
        filter: false,
        showFilterMenu: false,
        headerStyle: { whiteSpace: "nowrap" },
        body:(rowData:any)=>vehicleBrandBodyTemplate(rowData),
        isDefault: true,
        isStatic: false,
    },
    {
        field: "dealerName",
        sortable: false,
        header: "Dealer",
        filter: false,
        showFilterMenu: false,
        headerStyle: { whiteSpace: "nowrap" },
        body:(rowData:any)=>dealerBodyTemplate(rowData),
        isDefault: true,
        isStatic: false,
    },
    {
        field: "customerName",
        sortable: false,
        header: "Customer",
        filter: false,
        showFilterMenu: false,
        headerStyle: { whiteSpace: "nowrap" },
        body:(rowData:any)=>customerBodyTemplate(rowData),
        isDefault: true,
        isStatic: false,
    },
    {
        field: "status",
        sortable: false,
        header: "Status",
        filter: false,
        showFilterMenu: false,
        headerStyle: { whiteSpace: "nowrap" },
        isDefault: true,
        isStatic: false,
    },
    {
        field: "inPending",
        sortable: false,
        header: "Pending",
        filter: false,
        showFilterMenu: false,
        headerStyle: { whiteSpace: "nowrap" },
        body: (rowData: any) => statusBodyTemplate(rowData,'inPending'),
        isDefault: true,
        isStatic: false,
    },
    {
        field: "creationTime",
        sortable: true,
        header: "Created At",
        filter: false,
        showFilterMenu: false,
        headerStyle: { whiteSpace: "nowrap" },
        body: (rowData: any) => formatDateMEL(rowData.creationTime),
        isDefault: true,
        isStatic: false,
    },
  ];

  const filterDetails: DataTableFilterMeta = {
    CreationTimeTimeStartDate: { value: "", matchMode: "contains" },
    CreationTimeTimeEndDate: { value: "", matchMode: "contains" },
    
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "creationTime",  //CreationTime
    sortOrder: -1,
    filters: filterDetails,
  };
  const { showToast } = useToast()
  const [visible, setVisible] = useState(false);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  const [filter, setFilter] = useState(false);
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const [selectedJobTatReport, setSelectedJobTatReport] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const dt = useRef<DataTable<any>>(null);
  const dispatch = useDispatch();

  const { error, message, pendingJobsheetTatReportList, loading } = useSelector((state: RootReducerState) => state.pendingJobsheetTatReportReducer)
  const [pdfLoading, setPdfLoading] = useState<boolean>(false)

  const { totalCount } = useSelector((state: RootReducerState) => state.pendingJobsheetTatReportReducer.pendingJobsheetTatReportList)

  
  const modifiedValues: any[] = pendingjobsheetTatreportListConverson(pendingJobsheetTatReportList);
  const options: Option[] = [
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const hidePopup = () => {
    setVisible(false);
    // dispatch(); 
  }

  const defaultColumns = {
    ...columns[0]
  };

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const initialValues = {
    status: "",
  };
  const validationSchema = Yup.object({});

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));
    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const dialogContainer = (error: any) => {
    return (<ValidationPopup error={error} visible={visible} onHide={hidePopup} />);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          packageSent: { value: null, matchMode: "contains" },
          packageNotSent: { value: null, matchMode: "contains" },

        },
      }));
    }
    else if (formValue == PACKAGESENT) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          packageSent: { value: "true", matchMode: "contains" },
          packageNotSent: { value: "false", matchMode: "contains" },

        },
      }));
    } else if (formValue == PACKAGENOTSENT) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: "false", matchMode: "contains" },
          packageNotSent: { value: "true", matchMode: "contains" },
          packageSent: { value: "false", matchMode: "contains" },
        },
      }));
    } else if (formValue == ALL) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: "false", matchMode: "contains" },
          packageNotSent: { value: "false", matchMode: "contains" },
          packageSent: { value: "false", matchMode: "contains" },
          all: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          packageSent: { value: null, matchMode: "contains" },
          packageNotSent: { value: null, matchMode: "contains" },
        },
      }));
    }
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  const handleStatusBlur = () => { };

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedJobTatReport(value);
    // setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      if (selectedStatus == LOCKED) {
        setSelectAll(false);
        setSelectedJobTatReport([]);
      } else if (selectedStatus == ACTIVE) {
        // let len = modifiedValues?.length;
        // let filterLen = modifiedValues?.filter((eachValue: any) => !eachValue.locked).length;
        // if (len === filterLen) {
        //   setSelectAll(true);
        //   setSelectedJobReport(modifiedValues);
        // } 
        // else {
        //   setSelectAll(false);
        //   setSelectedJobReport(modifiedValues?.filter((eachValue: any) => !eachValue.locked));
        // }
      }
      else {
        setSelectAll(true);

      }
    } else {
      setSelectAll(false);
      setSelectedJobTatReport([]);
    }
  };

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
        data['Number'] = eachValue?.number;
        data['Total Tat'] = eachValue?.totalTat ? eachValue?.totalTat : "-";
        data['Service Request Number'] = eachValue?.serviceRequestNumber ? eachValue?.serviceRequestNumber : "-";
        data['Vehicle Brand Name'] = eachValue?.vehicleBrandName ? eachValue?.vehicleBrandName : "-";
        data['Dealer Name'] = eachValue?.dealerName ? eachValue?.dealerName : "-";
        data['Customer Name'] = eachValue?.customerName ? eachValue?.customerName : "-";
        data['Status'] = eachValue?.status ? eachValue?.status : "-";
        data['Pending'] = eachValue?.inPending ? eachValue?.inPending : false;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf: boolean) => {
    let state: any = {
      ...lazyState,
      rows: totalCount > 5000 ? 5000 : totalCount < 1 ? 1 : totalCount,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true)
      const response = await API.getPendingJobSheetTatReportsApi(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      let modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['number'] = eachValue?.number;
        data['totalTat'] = eachValue?.totalTat ? eachValue?.totalTat : "-";
        data['serviceRequestNumber'] = eachValue?.serviceRequestNumber ? eachValue?.serviceRequestNumber : "-";
        data['vehicleBrandName'] = eachValue?.vehicleBrandName ? eachValue?.vehicleBrandName : "-";
        data['dealerName'] = eachValue?.dealerName ? eachValue?.dealerName : "-";
        data['customerName'] = eachValue?.customerName ? eachValue?.customerName : "-";
        data['status'] = eachValue?.status ? eachValue?.status : "-";
        data['inPending'] = eachValue?.inPending ? eachValue?.inPending : false;
        return data;
      });
      if (isPdf) {

        saveDataAsPdf(modifeidValue, columns, "pending jobsheet Tat Reports");
      } else {
        saveDataAsExcel(modifeidValue, "pending jobsheet Tat Reports");
      }
      setPdfLoading(false)
    } catch (error) {
      // console.log(error);
      setPdfLoading(false)

    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "jobsheet Tat Reports");
    fetchData(false)
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        number : data?.number,
        totalTat : data?.totalTat ? data?.totalTat : "-",
        serviceRequestNumber : data?.serviceRequestNumber ? data?.serviceRequestNumber : "-",
        vehicleBrandName : data?.vehicleBrandName ? data?.vehicleBrandName : "-",
        dealerName : data?.dealerName ? data?.dealerName : "-",
        customerName : data?.customerName ? data?.customerName : "-",
        status : data?.status ? data?.status : "-",
        inPending : data?.inPending ? data?.inPending : false,

      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        // const doc = new (jsPDF as any).default(0, 0);
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          body: modifiedValues,
          showFoot: "everyPage",
          tableWidth: "auto",
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "jobsheet Tat Reports");
    fetchData(true)
  };
  const calendarRef = useRef<any>(null);
  const [startDate, setStartDate] = useState<any>()
  const [showStartDate, setShowStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [showEndDate, setShowEndDate] = useState<any>()

  const clearCustomCalendar = (e: any) => {
    calendarRef.current.toggle(e)
    setStartDate(null)
    setEndDate(null)
    setShowStartDate(null)
    setShowEndDate(null)
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        CreationTimeTimeStartDate: { value: null, matchMode: "contains" },
        CreationTimeTimeEndDate: { value: null, matchMode: "contains" },
      },
    }))
  }

  const searchCustomCalendar = (event: any) => {
    calendarRef.current.toggle(event)
    setShowStartDate(startDate);
    setShowEndDate(endDate);

    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        CreationTimeTimeStartDate: { value: startDate, matchMode: "contains" },
        CreationTimeTimeEndDate: { value: endDate, matchMode: "contains" },
      },
    }))
    
  }

  const formatDate = (date:any) => {
    return date.toISOString().slice(0, 10);
  };

const ThreeDaysFilter = () =>{
  let today  = new Date()
  let threeDayBefore = new Date(today.getTime() - 3 * 24 * 60 * 60 * 1000);
  setLazyState((state) => ({
    ...state,
    filters: {
      ...state.filters,
      CreationTimeTimeStartDate: { value: formatDate(threeDayBefore), matchMode: "contains" },
      CreationTimeTimeEndDate: { value: formatDate(today), matchMode: "contains" },
    },
  }))
}

const SevenDaysFilter = () =>{
  let today  = new Date()
  let sevenDayBefore = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  setLazyState((state) => ({
    ...state,
    filters: {
      ...state.filters,
      CreationTimeTimeStartDate: { value: formatDate(sevenDayBefore), matchMode: "contains" },
      CreationTimeTimeEndDate: { value: formatDate(today), matchMode: "contains" },
    },
  }))
}

const FifteenDaysFilter = () =>{
  let today  = new Date()
  let fifteenDayBefore = new Date(today.getTime() - 15 * 24 * 60 * 60 * 1000);
  setLazyState((state) => ({
    ...state,
    filters: {
      ...state.filters,
      CreationTimeTimeStartDate: { value: formatDate(fifteenDayBefore), matchMode: "contains" },
      CreationTimeTimeEndDate: { value: formatDate(today), matchMode: "contains" },
    },
  }))
}

const ThirtyDaysFilter = () =>{
  let today  = new Date()
  let thirtyDayBefore = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
  setLazyState((state) => ({
    ...state,
    filters: {
      ...state.filters,
      CreationTimeTimeStartDate: { value: formatDate(thirtyDayBefore), matchMode: "contains" },
      CreationTimeTimeEndDate: { value: formatDate(today), matchMode: "contains" },
    },
  }))
}

const SixtyDaysFilter = () =>{
  let today  = new Date()
  let sixtyDayBefore = new Date(today.getTime() - 60 * 24 * 60 * 60 * 1000);
  setLazyState((state) => ({
    ...state,
    filters: {
      ...state.filters,
      CreationTimeTimeStartDate: { value: formatDate(sixtyDayBefore), matchMode: "contains" },
      CreationTimeTimeEndDate: { value: formatDate(today), matchMode: "contains" },
    },
  }))
}

const clearFilter = () => {
  setStartDate(null)
    setEndDate(null)
    setShowStartDate(null)
    setShowEndDate(null)
    setLazyState(initialLazyState)
}
  const header = (
    <div className="table-header-container">
      <div>Pending Job Sheet TAT Reports</div>

      <div className="m-2 d-flex flex-wrap gap-1 align_justify">
        {showStartDate && (
          <div className="job-basic-info">
            <div
              className="title text-center"
              style={{
                background: "#dff0ff",
                color: "#749be0",
                width: "auto",
                whiteSpace: "nowrap",
              }}
            >
              Start Date
            </div>
            <b
              className="m-auto text-center font-size-13"
              style={{ minWidth: "120px", whiteSpace: "nowrap" }}
            >
              {formatDateMEL(showStartDate)}
            </b>
          </div>
        )}
        {showEndDate && (
          <div className="job-basic-info">
            <div
              className="title text-center"
              style={{
                background: "#e0f5f1",
                color: "#2d97a6",
                width: "auto",
                whiteSpace: "nowrap",
              }}
            >
              End Date
            </div>
            <b
              className="m-auto text-center font-size-13"
              style={{ minWidth: "150px", whiteSpace: "nowrap" }}
            >
              {formatDateMEL(showEndDate)}
            </b>
          </div>
        )}
      </div>

      <div className="flex-grow-1"></div>
      <div className="d-flex justify-content-center btn-group-tat">
      <div
        id="calendar"
        className="calendar_btns new-calendar"
        style={{ marginLeft: "10px" }}
      >
        <div className="table-header-container-item">
          <div className="btn-group">
            <button
              className="btn btn-primary waves-effect waves-light"
              // onClick={toggleCalendar}
              style={{height:"37px"}}
              onClick={(e) => calendarRef.current.toggle(e)}
            >
              {/* <i className="bx bx-calendar font-size-16 align-middle"></i> */}
              <FeatherIcon icon="search" style={{ Width: "6px" }} />
            </button>
          </div>
        </div>
      </div>
      <OverlayPanel ref={calendarRef}>
        <div className="mb-2">
          <label htmlFor="">Start Date</label>
          {/* <Row>     */}
          <input
            className="form-control"
            type="date"
            name="month"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="">End Date</label>
          {/* <Row>     */}
          <input
            className="form-control"
            type="date"
            name="month"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>

        <div className="p-column-filter-buttonbar">
          <button
            aria-label="Clear"
            className="p-button p-component p-button-outlined p-button-sm"
            type="button"
            onClick={clearCustomCalendar}
          >
            <span className="p-button-label p-c">Clear</span>
          </button>
          <button
            aria-label="Apply"
            className="p-button p-component p-button-sm"
            type="button"
            onClick={searchCustomCalendar}
          >
            <span className="p-button-label p-c">Apply</span>
          </button>
        </div>
      </OverlayPanel>
        <UIButton
          label={"Last 3 Days"}
          className={`btn-primary ms-2`}
          type="button"
          onClick={() => {ThreeDaysFilter()}}
          disabled={false}
        ></UIButton>
        <UIButton
          label={"Last 7 Days"}
          className={`btn-primary ms-2`}
          type="button"
          onClick={() => {SevenDaysFilter()}}
          disabled={false}
        ></UIButton>
        <UIButton
          label={"Last 15 Days"}
          className={`btn-primary ms-2`}
          type="button"
          onClick={() => {FifteenDaysFilter()}}
          disabled={false}
        ></UIButton>
        <UIButton
          label={"Last 30 Days"}
          className={`btn-primary ms-2`}
          type="button"
          onClick={() => {ThirtyDaysFilter()}}
          disabled={false}
        ></UIButton>
        <UIButton
          label={"Last 60 Days"}
          className={`btn-primary ms-2`}
          type="button"
          onClick={() => {SixtyDaysFilter()}}
          disabled={false}
        ></UIButton>
      </div>
      <div className="table-header-container-item align_width">
        <div style={{ display: "flex" }}>
          <ExportFile
            exportExcel={csvClickHandler}
            exportPdf={pdfClickHandler}
            isFilter={true}
            clearFilter={clearFilter}
          />
          {/* <TooltipMEL title="Select Status" position="bottom">
            <Dropdown showClear={false} value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label"
              placeholder="Select status .."
              filter className="w-full md:w-14rem" />
          </TooltipMEL> */}
          <ReactStrapButton
            type="button"
            className="customize-table-button mt-1"
            id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            data-btn="btn"
            onClick={customizeTableClickHandler}
            style={{ paddingLeft: "0px" }}
          >
            <FeatherIcon icon="sliders" />
          </ReactStrapButton>
        </div>
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>
    </div>
  );

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    dispatch(getPendingJobsheetTatReportsLoading(modifiedLazyState));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedJobTatReport([]);
    callbackFunction(lazyState);
    return () => {
      // dispatch(clearCourierMessage());
    };
  }, [lazyState]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
    }
  }, [error, message]);

  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}
        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row id="export-search-clear">
                        <Col xs={12} lg={7} >

                        </Col>
                        <Col xs={12} lg={5} className="select-clear-search-container d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <ReactStrapButton className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </ReactStrapButton>
                          <ReactStrapButton className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </ReactStrapButton>
                        </Col>

                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}

        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={modifiedValues}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalCount}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Peniding Job Sheet TAT Reports  {last} records of {totalRecords} in total"
          selection={selectedJobTatReport}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
          selectionMode="checkbox"
        >
        </DataTableWrapper>
      </div>
    </React.Fragment>
  )
}

export default PendingJobsheetTatReports