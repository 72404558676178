import { InvoiceTypes } from "./actionTypes"

//grid

export const getInvoicesListLoading = (lazyState: any) => {
  return {
    type: InvoiceTypes.GET_INVOICES_LOADING,
    lazyState
  }
}

export const getInvoicesListSuccess = (invoice: any) => {
  return {
    type: InvoiceTypes.GET_INVOICES_SUCCESS,
    payload: invoice
  }
}

export const getInvoicesListError = (error: any) => {
  return {
    type: InvoiceTypes.GET_INVOICES_FAIL,
    payload: error
  }
}

//delete
export const invoiceDeleteLoading = (payload: any) => {
  return {
    type: InvoiceTypes.DELETE_INVOICE_DETAIL_LOADING,
    payload: payload
  }
}

export const invoiceDeleteSucess = (response: any) => {
  return {
    type: InvoiceTypes.DELETE_INVOICE_DETAIL_SUCCESS,
    payload: response
  }
}

export const invoiceDeleteError = (error: any) => {
  return {
    type: InvoiceTypes.DELETE_INVOICE_DETAIL_FAIL,
    payload: error
  }
}

//create invoice

export const addInvoiceDetailLoading = (invoice: any) => {
  return {
    type: InvoiceTypes.ADD_INVOICE_DETAIL_LOADING,
    payload: invoice
  }
}

export const addInvoiceDetailSucess = (invoice: any) => {
  return {
    type: InvoiceTypes.ADD_INVOICE_DETAIL_SUCCESS,
    payload: invoice
  }
}

export const addInvoiceDetailError = (error: any) => {
  return {
    type: InvoiceTypes.ADD_INVOICE_DETAIL_FAIL,
    payload: error
  }
}


//update invoice
export const invoiceUpdateLoading = (invoice: any, id: any) => {
  return {
    type: InvoiceTypes.UPDATE_INVOICE_DETAIL_LOADING,
    payload: invoice,
    id: id
  }
}

export const invoiceUpdateSuccess = (invoice: any) => {
  return {
    type: InvoiceTypes.UPDATE_INVOICE_DETAIL_SUCCESS,
    payload: invoice,
  }
}

export const invoiceUpdateError = (error: any) => {
  return {
    type: InvoiceTypes.UPDATE_INVOICE_DETAIL_FAIL,
    payload: error
  }
}


//getinvoicedetail by id

export const getInvoicesIdLoading = (id: string) => {
  return {
    type: InvoiceTypes.GET_INVOICE_DETAIL_LOADING,
    payload: id
  }
}

export const getInvoicesIdSuccess = (userDetails: any) => {
  return {
    type: InvoiceTypes.GET_INVOICE_DETAIL_SUCCESS,
    payload: userDetails,
  };
};

export const getInvoicesIdError = (error: any) => {
  return {
    type: InvoiceTypes.GET_INVOICE_DETAIL_FAIL,
    payload: error,
  };
};

export const getInvoicesByPartIdLoading = (id: string) => {
  return {
    type: InvoiceTypes.GET_INVOICE_DETAIL_BY_PART_LOADING,
    payload: id
  }
}

export const getInvoicesByPartIdSuccess = (userDetails: any) => {
  return {
    type: InvoiceTypes.GET_INVOICE_DETAIL_BY_PART_SUCCESS,
    payload: userDetails,
  };
};

export const getInvoicesByPartIdError = (error: any) => {
  return {
    type: InvoiceTypes.GET_INVOICE_DETAIL_BY_PART_FAIL,
    payload: error,
  };
};

//CREATE INVOICE

export const invoiceCreateLoading = (invoice: any) => {
  return {
    type: InvoiceTypes.POST_INVOICE_DETAIL_LOADING,
    payload: invoice,
  }
}

export const invoiceCreateSuccess = (invoice: any) => {
  return {
    type: InvoiceTypes.POST_INVOICE_DETAIL_SUCCESS,
    payload: invoice,
  }
}

export const invoiceCreateError = (error: any) => {
  return {
    type: InvoiceTypes.POST_INVOICE_DETAIL_FAIL,
    payload: error
  }
}

// get CUSTOMER DROP DOWN 

export const getCustomerDropForInvoiceLoading = () => {
  return {
    type: InvoiceTypes.GET_CUSTOMER_DROP_INVOICE_LOADING,
  }
}

export const getCustomerDropForInvoiceSuccess = (invoice: any) => {
  return {
    type: InvoiceTypes.GET_CUSTOMER_DROP_INVOICE_SUCCESS,
    payload: invoice,
  }
}

export const getCustomerDropForInvoiceError = (error: any) => {
  return {
    type: InvoiceTypes.GET_CUSTOMER_DROP_INVOICE_FAIL,
    payload: error
  }
}
// get DEALER DROP DOWN 

export const getDealerDropForInvoiceLoading = () => {
  return {
    type: InvoiceTypes.GET_DEALER_DROP_INVOICE_LOADING,
  }
}

export const getDealerDropForInvoiceSuccess = (invoice: any) => {
  return {
    type: InvoiceTypes.GET_DEALER_DROP_INVOICE_SUCCESS,
    payload: invoice,
  }
}

export const getDealerDropForInvoiceError = (error: any) => {
  return {
    type: InvoiceTypes.GET_DEALER_DROP_INVOICE_FAIL,
    payload: error
  }
}
// get parts list drop 

export const getPartsListDropForInvoiceLoading = (partsSkuNumber: any, index: number, setFieldValue: any, passpartSkuDetails: any, passtaxesDetails: any, passdeleteState: boolean, reducerState: boolean,push:any,invoiceFranchiseId:any) => {
  
  
  return {
    type: InvoiceTypes.GET_PARTS_SKU_LIST_LOADING,
    payload: {
      index,
      partsSkuNumber,
      setFieldValue,
      passpartSkuDetails,
      passtaxesDetails,
      passdeleteState,
      reducerState,
      push,
      invoiceFranchiseId
    }
  }
}

export const getPartsListDropForInvoiceSuccess = (partsSkuList: any, index: number, setFieldValue: any, passpartSkuDetails: any, passtaxesDetails: any, passdeleteState: any, reducerState: any,push:any) => {
  return {
    type: InvoiceTypes.GET_PARTS_SKU_LIST_SUCCESS,
    payload: {
      partsSkuList, index, setFieldValue, passpartSkuDetails,
      passtaxesDetails,
      passdeleteState,
      reducerState,
      push
    },
  }
}

export const getPartsListDropForInvoiceError = (error: any) => {
  return {
    type: InvoiceTypes.GET_PARTS_SKU_LIST_FAIL,
    payload: error
  }
}

// get taxes list drop 

export const gettaxesListDropForInvoiceLoading = () => {
  return {
    type: InvoiceTypes.GET_TAXES_LIST_FOR_INVOICE_LOADING,
  }
}

export const gettaxesListDropForInvoiceSuccess = (invoice: any) => {
  return {
    type: InvoiceTypes.GET_TAXES_LIST_FOR_INVOICE_SUCCESS,
    payload: invoice,
  }
}

export const gettaxesListDropForInvoiceError = (error: any) => {
  return {
    type: InvoiceTypes.GET_TAXES_LIST_FOR_INVOICE_FAIL,
    payload: error
  }
}

// get taxes list drop 

export const getTaxesDetailsForInvoiceLoading = (id: any) => {
  return {
    type: InvoiceTypes.GET_TAXES_DEATILS_FOR_INVOICE_LOADING,
    payload: id
  }
}

export const getTaxesDetailsForInvoiceSuccess = (invoice: any) => {
  return {
    type: InvoiceTypes.GET_TAXES_DEATILS_FOR_INVOICE_SUCCESS,
    payload: invoice,
  }
}

export const getTaxesDetailsForInvoiceError = (error: any) => {
  return {
    type: InvoiceTypes.GET_TAXES_DEATILS_FOR_INVOICE_FAIL,
    payload: error
  }
}


export const resetPartsSkuIndex = () => {
  return {
    type: InvoiceTypes.RESET_PARTS_SKU_INDEX,
  }
}

//get invoice page taxes list by id

export const taxDetailsForInvoicePageLoading = (id: any = 0) => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_TAXESBYID_DETAILS_LOADING,
    payload: { id }

  }
}

export const taxDetailsForInvoicePageSuccess = (response: any, index: number) => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_TAXESBYID_DETAILS_SUCCESS,
    payload: { response, index }
  }
}

export const taxDetailsForInvoicePageError = (error: any) => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_TAXESBYID_DETAILS_ERROR,
    payload: error
  }
}

//reset invoice details

export const resetInvoicePageDetails = () => {
  return {
    type: InvoiceTypes.RESET_INVOICE_DETAILS,
  }
}


//get invoice page invoice list dropdown

export const getInvoicePageInvoiceListLoading = () => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_INVOICETYPE_LOADING,
  }
}
export const getInvoicePageInvoiceListSuccess = (resp: any) => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_INVOICETYPE_SUCCESS,
    payload: resp
  }
}
export const getInvoicePageInvoiceListError = (error: any) => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_INVOICETYPE_ERROR,
    payload: error
  }
}


//get invoice page joblist dropdown


export const getInvoicePageJoblistListLoading = () => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_JOBLIST_DROPDOWN_LOADING,
  }
}
export const getInvoicePageJoblistListSuccess = (resp: any) => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_JOBLIST_DROPDOWN_SUCCESS,
    payload: resp
  }
}
export const getInvoicePageJoblistListError = (error: any) => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_JOBLIST_DROPDOWN_ERROR,
    payload: error
  }
}

//reset invoice message
export const resetInvoicePageMessages = () => {
  return {
    type: InvoiceTypes.RESET_INVOICE_MESSAGE
  }
}


///get jobsheet id related details

export const getInvoicePageJobSheetIdRelatedDetailsLoading = (id: any) => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_JOBSHEET_ID_RELATED_LOADING,
    payload: id
  }
}
export const getInvoicePageJobSheetIdRelatedDetailsSuccess = (resp: any) => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_JOBSHEET_ID_RELATED_SUCCESS,
    payload: resp
  }
}
export const getInvoicePageJobSheetIdRelatedDetailsError = (error: any) => {
  return {
    type: InvoiceTypes.GET_INVOICEPAGE_JOBSHEET_ID_RELATED_ERROR,
    payload: error
  }
}


//get job estimate dto details

export const getJobEstimateDtoDetailsLoading = (id: any) => {
  return {
    type: InvoiceTypes.GET_JOB_ESTIMATE_DTO_BY_JOB_ID_LOADING,
    payload: id
  }
}
export const getJobEstimateDtoDetailsSuccess = (resp: any) => {
  return {
    type: InvoiceTypes.GET_JOB_ESTIMATE_DTO_BY_JOB_ID_SUCCESS,
    payload: resp
  }
}
export const getJobEstimateDtoDetailsError = (error: any) => {
  return {
    type: InvoiceTypes.GET_JOB_ESTIMATE_DTO_BY_JOB_ID_ERROR,
    payload: error
  }
}



///tax details Onchnages
export const getInvoiceOnchnageLoading = (id: any, index: number, setFieldValue: any, value: any,values:any) => {
  return {
    type: InvoiceTypes.GET_INVOICE_DETAILS_ONCHANGE_LOADING,
    payload: { id, index, setFieldValue, value , formValues:values }
  }
}
export const getInvoiceOnchnageSuccess = (response: any, index: number, setFieldValue: any, value: any,formValues:any) => {
  return {
    type: InvoiceTypes.GET_INVOICE_DETAILS_ONCHANGE_SUCCESS,
    payload: { response, index, setFieldValue, value,formValues }
  }
}
export const getInvoiceOnchnageError = (error: any) => {
  return {
    type: InvoiceTypes.GET_INVOICE_DETAILS_ONCHANGE_ERROR,
    payload: error
  }
}

export const resetonChnagesTaxDetails = () => {
  return {
    type: InvoiceTypes.RESET_TAXES_ONCHANGES_DETAILS,
  }
}


//get received payment details

export const receivedPaymentDetailsLoading = (id: any) => {
  return {
    type: InvoiceTypes.RECEIVED_PAYMENT_LOADING,
    payload: id
  }
}

export const receivedPaymentDetailsSuccess = (resp: any) => {
  return {
    type: InvoiceTypes.RECEIVED_PAYMENT_SUCCESS,
    payload: resp
  }
}

export const receivedPaymentDetailsError = (error: any) => {
  return {
    type: InvoiceTypes.RECEIVED_PAYMENT_SUCCESS,
    payload: error
  }
}

///job sheet return page 

export const jobSheetReturnInvoiceDetailsLoading = (jobId: any) => {
  return {
    type: InvoiceTypes.GET_JOBRETURN_INVOICE_DETAILS_LOADING,
    payload: jobId
  }
}

export const jobSheetReturnInvoiceDetailsSuccess = (resp: any) => {
  return {
    type: InvoiceTypes.GET_JOBRETURN_INVOICE_DETAILS_SUCCESS,
    payload: resp
  }
}
export const jobSheetReturnInvoiceDetailsError = (error: any) => {
  return {
    type: InvoiceTypes.GET_JOBRETURN_INVOICE_DETAILS_ERROR,
    payload: error
  }
}

//download pdf

export const downloadPdfInvoicePageLoading = (id: any) => {
  return {
    type: InvoiceTypes.INVOICE_PAGE_DOWNLOAD_PDF_LOADING,
    payload: id
  }
}
export const downloadPdfInvoicePageSuccess = (resp: any) => {
  return {
    type: InvoiceTypes.INVOICE_PAGE_DOWNLOAD_PDF_SUCCESS,
    payload: resp
  }
}
export const downloadPdfInvoicePageError = (error: any) => {
  return {
    type: InvoiceTypes.INVOICE_PAGE_DOWNLOAD_PDF_ERROR,
    payload: error
  }
}


//send email to delaer invoice page

export const sendEmailToDealerInvoiceLoading = (id: any,thirdButtonLabel:string) => {
  return {
    type: InvoiceTypes.INVOICE_PAGE_SEND_EMAIL_LOADING,
    payload: id,
    label:thirdButtonLabel
  }
}
export const sendEmailToDealerInvoiceSuccess = (resp: any) => {
  return {
    type: InvoiceTypes.INVOICE_PAGE_SEND_EMAIL_SUCCESS,
    payload: resp
  }
}
export const sendEmailToDealerInvoiceError = (error: any) => {
  return {
    type: InvoiceTypes.INVOICE_PAGE_SEND_EMAIL_ERROR,
    payload: error
  }
}

export const sendWhatsappInvoiceLoading = (id: any,label:any) => {
  return {
    type: InvoiceTypes.INVOICE_PAGE_SEND_WHATSAPP_LOADING,
    payload: id,
    label:label
  }
}
export const sendWhatsappInvoiceSuccess = (resp: any) => {
  return {
    type: InvoiceTypes.INVOICE_PAGE_SEND_WHATSAPP_SUCCESS,
    payload: resp
  }
}
export const sendWhatsappInvoiceError = (error: any) => {
  return {
    type: InvoiceTypes.INVOICE_PAGE_SEND_WHATSAPP_ERROR,
    payload: error
  }
}


// get invoice tax types dropdown

export const getInvoiceTypeDropdownListLoading=()=>{
  return {
    type : InvoiceTypes.GET_TAXES_TYPES_INVOICE_PAGE_LOADING
  }
}

export const getInvoiceTypeDropdownListSuccess=(resp : any)=>{
  return {
    type : InvoiceTypes.GET_TAXES_TYPES_INVOICE_PAGE_SUCCESS,
    payload : resp
  }
}

export const getInvoiceTypeDropdownListError=(error : any)=>{
  return {
    type : InvoiceTypes.GET_TAXES_TYPES_INVOICE_PAGE_ERROR,
    payload : error
  }
}


//undo partsku list get

export const getUndoPartsSkuListLoading=(partNumber: any,setFieldValue : any,index :number,formvalues : any,id:any,invoiceFranchiseId:any)=>{
  return {
     type : InvoiceTypes.UNDO_PARTSKU_LIST_LOADING,
     payload : {partNumber,setFieldValue,index,formvalues,id,invoiceFranchiseId}
  }
}
export const getUndoPartsSkuListSuccess=(resp: any,setFieldValue : any,index : number,formvalues : any,id:any)=>{
  return {
     type : InvoiceTypes.UNDO_PARTSKU_LIST_SUCCESS,
     payload : {resp,setFieldValue,index,formvalues,id}
  }
}
export const getUndoPartsSkuListError=(error : any)=>{
  return {
     type : InvoiceTypes.UNDO_PARTSKU_LIST_ERROR,
     payload : error
  }
}

export const getUpdatedByNameLoading=(id:any,index:any)=>{
  return {
     type : InvoiceTypes.GET_UPDATED_BY_NAME_LOADING,
     payload : {id:id,index:index}
  }
}
export const getUpdatedByNameSuccess=(resp: any,index:any)=>{
  return {
     type : InvoiceTypes.GET_UPDATED_BY_NAME_SUCCESS,
     payload : {resp,index}
  }
}
export const getUpdatedByNameError=(error : any)=>{
  return {
     type : InvoiceTypes.GET_UPDATED_BY_NAME_ERROR,
     payload : error
  }
}

//reset undo part
export const resetundoPartsSkuIndex = () => {
  return {
    type: InvoiceTypes.RESET_UNDO_PART,
  }
}

export const removePartsSkuDetails=(index : any)=>{
  return {
    type : InvoiceTypes.REMOVE_PARTS_SKU_DETAILS,
    payload : index
  }
}

export const generatePaymentLinkLoading=(id:any,isAdmin:boolean)=>{
  return {
     type : InvoiceTypes.GENERATE_PAYMENT_LINK,
     payload : id,
     isAdmin:isAdmin
  }
}
export const generatePaymentLinkSuccess=(resp: any)=>{
  return {
     type : InvoiceTypes.GENERATE_PAYMENT_LINK_SUCCESS,
     payload : resp
  }
}
export const generatePaymentLinkError=(error : any)=>{
  return {
     type : InvoiceTypes.GENERATE_PAYMENT_LINK_ERROR,
     payload : error
  }
}
export const resetPaymentLink=()=>{
  return {
     type : InvoiceTypes.GENERATE_PAYMENT_LINK_RESET
  }
}

export const LoadFullJobSheetLoading=()=>{
  return {
     type : InvoiceTypes.LOAD_FULL_JOB_SHEET_LOADING,
  }
}
export const LoadFullJobSheetSuccess=(resp: any)=>{
  return {
     type : InvoiceTypes.LOAD_FULL_JOB_SHEET_SUCCESS,
     payload : resp
  }
}
export const LoadFullJobSheetError=(error : any)=>{
  return {
     type : InvoiceTypes.LOAD_FULL_JOB_SHEET_ERROR,
     payload : error
  }
}



export const LoadFullInvoiceLoading=()=>{
  return {
     type : InvoiceTypes.LOAD_ALL_TYPE_INVOICE_LOADING,
  }
}
export const LoadFullInvoiceSuccess=(resp: any)=>{
  return {
     type : InvoiceTypes.LOAD_ALL_TYPE_INVOICE_SUCCESS,
     payload : resp
  }
}
export const LoadFullInvoiceError=(error : any)=>{
  return {
     type : InvoiceTypes.LOAD_ALL_TYPE_INVOICE_ERROR,
     payload : error
  }
}

export const FranchiseDropdownLoading=()=>{
  return {
     type : InvoiceTypes.FRANCHISE_DROPDOWN_LOADING,
  }
}
export const FranchiseDropdownSuccess=(resp: any)=>{
  return {
     type : InvoiceTypes.FRANCHISE_DROPDOWN_SUCCESS,
     payload : resp
  }
}
export const FranchiseDropdownError=(error : any)=>{
  return {
     type : InvoiceTypes.FRANCHISE_DROPDOWN_ERROR,
     payload : error
  }
}