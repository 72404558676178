import { InvoiceInitialState } from "src/models/pages/invoiceModel";
import { InvoiceTypes } from "./actionTypes";

const initialState: InvoiceInitialState = {
  loading: false,
  message: "",
  invoiceDetails: undefined,
  error: "",
  paginatorCount: 0,
  customerList: [],
  dealerList: [],
  partsSkuList: [],
  partsSkuIndex: -1,
  taxesList: [],
  taxDetails: {},
  partsSetFieldValue: () => { },
  passpartSkuDetails: [],
  passtaxesDetails: {},
  passdeleteState: false,
  reducerState: false,
  push: () => { },
  invoiceList: {
    totalCount: 0,
    items: []
  },
  taxByIdDetails: {
    value: []
  },
  invoiceDropdown: [],
  jobListDropdown: [],
  jobIdRelatedDetails: {},
  taxOnchangesDetails: {
    response: [],
    setFieldValue: () => { },
    index: -1,
    value: {},
    formValues: {}
  },
  receivedPayment: [],
  jobSheetReturnInvoice: [],
  jobsheetChangessetFieldValue: () => { },
  taxesDropdownList: [],
  undoPartSkuDetails: {
    undopartskuresponse: [],
    undosetFieldValue: () => { },
    undopartskuindex: -1,
    undoFormValues: {},
    partSkuId: ""
  },
  updatedByName: "",
  editJobEstimateDto: [],
  ediJobIsInwarranty: false,
  editJobsheetStatus: "",
  paymentLink: "",
  editJobIsExchange: false,
  LoadFullJobsheet: [],
  LoadAllTypeInvoice : [],
  franchiseList:[]
};

const Invoices = (state = initialState, action: any) => {
  switch (action.type) {
    case InvoiceTypes.GET_INVOICES_LOADING:
    case InvoiceTypes.ADD_INVOICE_DETAIL_LOADING:
    case InvoiceTypes.GET_INVOICE_DETAIL_LOADING:
    case InvoiceTypes.GET_INVOICE_DETAIL_BY_PART_LOADING:
    case InvoiceTypes.UPDATE_INVOICE_DETAIL_LOADING:
    case InvoiceTypes.DELETE_INVOICE_DETAIL_LOADING:
    case InvoiceTypes.POST_INVOICE_DETAIL_LOADING:
    case InvoiceTypes.GET_CUSTOMER_DROP_INVOICE_LOADING:
    case InvoiceTypes.GET_DEALER_DROP_INVOICE_LOADING:
    case InvoiceTypes.GET_PARTS_SKU_LIST_LOADING:
    case InvoiceTypes.GET_TAXES_LIST_FOR_INVOICE_LOADING:
    case InvoiceTypes.GET_TAXES_DEATILS_FOR_INVOICE_LOADING:
    case InvoiceTypes.GET_INVOICEPAGE_TAXESBYID_DETAILS_LOADING:
    case InvoiceTypes.GET_INVOICEPAGE_INVOICETYPE_LOADING:
    case InvoiceTypes.GET_INVOICEPAGE_JOBLIST_DROPDOWN_LOADING:
    case InvoiceTypes.GET_INVOICEPAGE_JOBSHEET_ID_RELATED_LOADING:
    case InvoiceTypes.GET_INVOICE_DETAILS_ONCHANGE_LOADING:
    case InvoiceTypes.RECEIVED_PAYMENT_LOADING:
    case InvoiceTypes.GET_JOBRETURN_INVOICE_DETAILS_LOADING:
    case InvoiceTypes.INVOICE_PAGE_DOWNLOAD_PDF_LOADING:
    case InvoiceTypes.INVOICE_PAGE_SEND_EMAIL_LOADING:
    case InvoiceTypes.GET_TAXES_TYPES_INVOICE_PAGE_LOADING:
    case InvoiceTypes.UNDO_PARTSKU_LIST_LOADING:
    case InvoiceTypes.GET_JOB_ESTIMATE_DTO_BY_JOB_ID_LOADING:
    case InvoiceTypes.GENERATE_PAYMENT_LINK:
    case InvoiceTypes.LOAD_FULL_JOB_SHEET_LOADING:
    case InvoiceTypes.LOAD_ALL_TYPE_INVOICE_LOADING:
    case InvoiceTypes.INVOICE_PAGE_SEND_WHATSAPP_LOADING:
    case InvoiceTypes.FRANCHISE_DROPDOWN_LOADING:

      return {
        ...state,
        loading: true,
        error: "",
        message: "",
      };

    case InvoiceTypes.GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceList: {
          ...state.invoiceList,
          items: action.payload.data.items,
          totalCount: action.payload.data.totalCount
        },
        paginatorCount: action.payload.data.items.length
      };

    case InvoiceTypes.ADD_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Invoices created successfully"
      };

    case InvoiceTypes.GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDetails: action.payload.data
      };

    case InvoiceTypes.GET_INVOICE_DETAIL_BY_PART_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDetails: action.payload.data
      };

    case InvoiceTypes.UPDATE_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Invoices updated successfully"
      };

    case InvoiceTypes.POST_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Invoice created successfully",
        error: ""
      };

    case InvoiceTypes.GET_CUSTOMER_DROP_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        customerList: action.payload.data
      };

    case InvoiceTypes.GET_DEALER_DROP_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        dealerList: action.payload.data
      };

    case InvoiceTypes.GET_PARTS_SKU_LIST_SUCCESS:
      // let checkPassParts = action.payload?.passpartSkuDetails?.length - 1
      // if(checkPassParts > 0){
      //   let removePart = action.payload?.passpartSkuDetails?.pop()
      //   let remainParts = action.payload?.passpartSkuDetails
      //   let modifiedData = remainParts?.map((data : any)=>{
      //     let object = {
      //       id : data?.id,
      //       number : data?.partSkuNumber,
      //       partPrice : data?.price,
      //       totalPrice : data?.totalPrice,
      //       partTaxPercentage : data?.taxPercentage,
      //       partNumber : data?.partNumber,
      //       partDescription  :data?.partDescription,
      //       partHsnNumber : data?.partHsnNumber
      //     }
      //     return object
      //   })
      //   state.partsSkuList  = modifiedData
      // }
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        partsSkuList: [
          // ...state.partsSkuList,
          ...action.payload.partsSkuList.data
        ],
        partsSkuIndex: action.payload.index,
        partsSetFieldValue: action.payload.setFieldValue,
        passpartSkuDetails: action.payload.passpartSkuDetails,
        passtaxesDetails: action.payload.passtaxesDetails,
        passdeleteState: action.payload.passdeleteState,
        reducerState: action.payload.reducerState,
        push: action.payload.push
      };
    case InvoiceTypes.RESET_PARTS_SKU_INDEX:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        partsSkuIndex: -1,
      };

    case InvoiceTypes.RESET_UNDO_PART:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        undoPartSkuDetails: {
          undopartskuresponse: [],
          undopartskuindex: -1,
          undoFormValues: {}
        }
      };

    case InvoiceTypes.GET_TAXES_LIST_FOR_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        taxesList: action.payload.data.data
      };

    case InvoiceTypes.GET_INVOICEPAGE_TAXESBYID_DETAILS_SUCCESS:
      const newData = state.taxByIdDetails.value;
      const response = action.payload.response?.data;
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        taxByIdDetails: {
          value: [
            ...(newData || []),
            response
          ]
        }
      };

    case InvoiceTypes.GET_TAXES_DEATILS_FOR_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        taxDetails: action.payload.data
      };

    case InvoiceTypes.RESET_INVOICE_DETAILS:
      let object = {
        value: []
      };
      return {
        ...state,
        message: "",
        error: "",
        taxByIdDetails: object,
        jobIdRelatedDetails: "",
        invoiceDetails: "",
        partsSkuList: "",
        updatedByName: "",
        editJobEstimateDto: [],
        paymentLink: "",
        editJobIsExchange:false
      };
    case InvoiceTypes.RESET_INVOICE_MESSAGE:
      return {
        ...state,
        message: "",
        error: "",
        paymentLink: ""
      };

    case InvoiceTypes.GET_INVOICEPAGE_INVOICETYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDropdown: action.payload.data
      };

    case InvoiceTypes.GET_INVOICEPAGE_JOBLIST_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        jobListDropdown: action.payload.data
      };

    case InvoiceTypes.GET_INVOICEPAGE_JOBSHEET_ID_RELATED_SUCCESS:
      return {
        ...state,
        loading: false,
        jobIdRelatedDetails: action.payload.data,
        jobsheetChangessetFieldValue: action.payload.setFieldValue
      };

    case InvoiceTypes.GET_JOB_ESTIMATE_DTO_BY_JOB_ID_SUCCESS:
      debugger;
      return {
        ...state,
        loading: false,
        editJobEstimateDto: action.payload.data?.jobEstimateDto?.filter((data: any) => data.isDeleted == false),
        ediJobIsInwarranty: action.payload.data?.warrantyStatus == "In Warranty" ? true : false,
        editJobsheetStatus: action.payload.data?.status,
        editJobIsExchange: action.payload.data?.isExchange
      };

    case InvoiceTypes.DELETE_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "invoice deleted successfully"
      };

    case InvoiceTypes.RESET_TAXES_ONCHANGES_DETAILS:
      return {
        ...state,
        loading: false,
        error: "",
        taxOnchangesDetails: {
          response: [],
          index: -1,
          value: {}
        }
      };

    case InvoiceTypes.GET_INVOICE_DETAILS_ONCHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        taxOnchangesDetails: {
          ...state.taxOnchangesDetails,
          response: [
            ...state.taxOnchangesDetails.response,
            action.payload.response.data
          ],
          setFieldValue: action.payload.setFieldValue,
          index: action.payload.index,
          value: action.payload.value,
          formValues:action.payload.formValues
        }
      };

    case InvoiceTypes.UNDO_PARTSKU_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        undoPartSkuDetails: {
          undopartskuresponse: action.payload.resp.data,
          undosetFieldValue: action.payload.setFieldValue,
          undopartskuindex: action.payload.index,
          undoFormValues: action.payload.formvalues,
          partSkuId: action.payload.id
        }
      };

    case InvoiceTypes.RECEIVED_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        receivedPayment: action.payload.data
      };

    case InvoiceTypes.GET_JOBRETURN_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        jobSheetReturnInvoice: action.payload.data
      };

    case InvoiceTypes.INVOICE_PAGE_DOWNLOAD_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Invoice downloaded successfully"
      };

    case InvoiceTypes.INVOICE_PAGE_SEND_EMAIL_SUCCESS:
      let message = action.payload.label ? `successfully emailed the invoice to ${action.payload.label}` : `successfully emailed the invoice`;
      return {
        ...state,
        loading: false,
        message: message
      };

    case InvoiceTypes.INVOICE_PAGE_SEND_WHATSAPP_SUCCESS:
      let messagenew = action.payload.label ? `successfully sent the invoice to ${action.payload.label} via whatsapp` : `successfully sent the invoice via whatsapp`;
      return {
        ...state,
        loading: false,
        message: messagenew
      };

    case InvoiceTypes.GET_TAXES_TYPES_INVOICE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        taxesDropdownList: action.payload.data
      };

    case InvoiceTypes.GET_UPDATED_BY_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedByName: [...state.updatedByName, { details: action.payload.resp.data, index: action.payload.index }]
      };

    case InvoiceTypes.REMOVE_PARTS_SKU_DETAILS:
      const updatedPartsSkuList = state?.partsSkuList?.length > 0 ? state?.partsSkuList?.filter((data: any, index: number) => index !== action.payload) : [];
      return {
        ...state,
        loading: false,
        partsSkuList: updatedPartsSkuList
      };

    case InvoiceTypes.GENERATE_PAYMENT_LINK_SUCCESS:
      if (action.payload.isAdmin == true) {
        return {
          ...state,
          loading: false,
          message: "Link generated successfully"
        };
      } else {
        return {
          ...state,
          loading: false,
          paymentLink: action.payload.response.data
        };
      }
    case InvoiceTypes.GENERATE_PAYMENT_LINK_RESET:
      return {
        ...state,
        loading: false,
        paymentLink: "",
        message: ""
      };

    case InvoiceTypes.LOAD_FULL_JOB_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        LoadFullJobsheet: action.payload.data
      };

    case InvoiceTypes.LOAD_ALL_TYPE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        LoadAllTypeInvoice: action.payload.data
      };

    case InvoiceTypes.FRANCHISE_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        franchiseList: action.payload.data
      };

    case InvoiceTypes.GET_INVOICES_FAIL:
    case InvoiceTypes.GET_INVOICE_DETAIL_FAIL:
    case InvoiceTypes.GET_INVOICE_DETAIL_BY_PART_FAIL:
    case InvoiceTypes.ADD_INVOICE_DETAIL_FAIL:
    case InvoiceTypes.UPDATE_INVOICE_DETAIL_FAIL:
    case InvoiceTypes.DELETE_INVOICE_DETAIL_FAIL:
    case InvoiceTypes.POST_INVOICE_DETAIL_FAIL:
    case InvoiceTypes.GET_CUSTOMER_DROP_INVOICE_FAIL:
    case InvoiceTypes.GET_DEALER_DROP_INVOICE_FAIL:
    case InvoiceTypes.GET_PARTS_SKU_LIST_FAIL:
    case InvoiceTypes.GET_TAXES_LIST_FOR_INVOICE_FAIL:
    case InvoiceTypes.GET_TAXES_DEATILS_FOR_INVOICE_FAIL:
    case InvoiceTypes.GET_INVOICEPAGE_TAXESBYID_DETAILS_ERROR:
    case InvoiceTypes.GET_INVOICEPAGE_INVOICETYPE_ERROR:
    case InvoiceTypes.GET_INVOICEPAGE_JOBLIST_DROPDOWN_ERROR:
    case InvoiceTypes.GET_INVOICEPAGE_JOBSHEET_ID_RELATED_ERROR:
    case InvoiceTypes.GET_INVOICE_DETAILS_ONCHANGE_ERROR:
    case InvoiceTypes.RECEIVED_PAYMENT_ERROR:
    case InvoiceTypes.GET_JOBRETURN_INVOICE_DETAILS_ERROR:
    case InvoiceTypes.INVOICE_PAGE_DOWNLOAD_PDF_ERROR:
    case InvoiceTypes.INVOICE_PAGE_SEND_EMAIL_ERROR:
    case InvoiceTypes.GET_TAXES_TYPES_INVOICE_PAGE_ERROR:
    case InvoiceTypes.UNDO_PARTSKU_LIST_ERROR:
    case InvoiceTypes.GET_JOB_ESTIMATE_DTO_BY_JOB_ID_ERROR:
    case InvoiceTypes.GENERATE_PAYMENT_LINK_ERROR:
    case InvoiceTypes.LOAD_FULL_JOB_SHEET_ERROR:
    case InvoiceTypes.LOAD_ALL_TYPE_INVOICE_ERROR:
    case InvoiceTypes.INVOICE_PAGE_SEND_WHATSAPP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return { ...state };
  }
};

export default Invoices;
